import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types';
class barchart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // series: [
      //   {
      //     data: this.props.monthCategoryIncomeChart,
      //   },
      // ],
      // options: {
      //   chart: {
      //     toolbar: {
      //       show: false,
      //     },
      //   },
      //   plotOptions: {
      //     bar: {
      //       horizontal: true,
      //     },
      //   },
      //   dataLabels: {
      //     enabled: false,
      //   },

      //   colors: ["#34c38f"],
      //   grid: {
      //     borderColor: "#f1f1f1",
      //   },
      //   xaxis: {
      //     categories: this.props.customer,
      //   },
      // },
    }
  }
  componentDidMount() {
    this.props.customer
  }
  render() {

    let cusData = this.props.monthCategoryIncomeChart;
    let series= [{
      data: cusData
  }]

  

  

  let options={ 

        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },

        colors: ["#34c38f"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: this.props.customer,
        },
      
  }
  // let xaxis= {
  //         categories: [
  //           "South Korea",
  //           "Canada",
  //           "United Kingdom",
  //           "Netherlands",
  //           "Italy",
  //           "France",
  //           "Japan",
  //           "United States",
  //           "China",
  //           "Germany",
  //         ],
  //       }
  // console.log("this.props.xaxis",xaxis)

    return (
      <React.Fragment>
        <ReactApexChart
          options={options}
          series={series}
          // options={options}
          type="bar"
          height="350"
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}
barchart.propTypes = {
  chartSeries: PropTypes.any,
  periodData: PropTypes.any,
  monthCategoryIncomeChart:PropTypes.any,
  customer:PropTypes.any,
}
export default barchart
