import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types';

class DashedLine extends Component {
  constructor(props) {
    super(props)

    this.state = {
      series: this.props.monthCategoryIncomeChart,
      options: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },

        colors: ["#556ee6", "#34c38f","#f39206","#c067c8"],
        xaxis: {
          type: "month",
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "April",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",

          ],
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    }
  }
  render() {
    let yearDataIncome =  this.props.monthCategoryIncomeChart;
    let yearDataCIncome =  yearDataIncome;

    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.props.monthCategoryIncomeChart}
          type="area"
          height="350"
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}
DashedLine.propTypes = {
  chartSeries: PropTypes.any,
  periodData: PropTypes.any,
  monthCategoryIncomeChart:PropTypes.any,
}
export default DashedLine
