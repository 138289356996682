import React, { Component } from 'react';
import { MetaTags } from 'react-meta-tags';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import { Card, CardBody, Container, Table } from 'reactstrap';
import PropTypes from "prop-types";
import { getLoanListShow } from 'store/Loan/actions';

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortData: '',
      sortType: 'asc'
    };
  }
  getShowData = (sortField, sortType) => {
    let options = {
      sort_by_field: sortField,
      sort_type: sortType ? sortType : this.state.sortType,
    }
    getLoanListShow(this.props.match.params.id, options).then((response) => {
      this.setState({
        loanData: response?.data?.loanShow,
      })
    })
  }

  handleSort = (data) => {
    this.setState({
      sortData: data
    })
    if (this.state.sortType === "asc") {
      this.setState({ sortType: "desc" })
    }
    else {
      this.setState({ sortType: "asc" })
    }
    this.getShowData(data, this.state.sortType === "asc" ? "desc" : "asc")
  }

  componentDidMount() {
    this.getShowData();
  }
  render() {
    let totalBeginingBal = 0;
    let totalPayment = 0;
    let totalPrincipal = 0;
    let totalInterestAmount = 0;
    let totalInterestPercent = 0;
    let totalEndingBal = 0;
    if (this.state.loanData?.loan_payment_details !== undefined) {
      for (const value of this.state.loanData?.loan_payment_details) {
        totalBeginingBal += value.opening_balance;
        totalPayment += value.payment_total;
        totalPrincipal += value.principal_amount;
        totalInterestAmount += value.interest_amount;
        totalInterestPercent += value.interest_percent;
        totalEndingBal += value.closing_balance;
      }
    }

    return (
      <React.Fragment >
        <MetaTags>
          <title>Business Debt Overview | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18"></h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/business-overview">Business Debt Overview</Link></li>
                    <li className="breadcrumb-item active">Full Overview </li>
                  </ol>
                </div>
              </div>
              <div className='d-flex justify-content-between my-3 printBtn'>
                <h4 className="mb-sm-0 font-size-18">Loan Amortization Schedule, {this.state.loanData?.client_group?.name} Loan {this.state.loanData?.loan_id} </h4>
                <button onClick={window.print} className='btn btn-success'>Print <i className="fa-solid fa-print"></i></button>
              </div>
              <Card>
                <CardBody>
                  <Table responsive striped className='table-bordered'>
                    <thead className="table-light">
                      <tr>
                        <th scope="col">S.No </th>
                        <th scope="col">Payment Date <span>{this.state.sortType === "payment_date" ? <i onClick={() => this.handleSort("payment_date")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("payment_date")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Beginning Balance <span>{this.state.sortType === "beginning_balance" ? <i onClick={() => this.handleSort("beginning_balance")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("beginning_balance")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Payment <span>{this.state.sortType === "payment" ? <i onClick={() => this.handleSort("payment")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("payment")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Principal <span>{this.state.sortType === "principal" ? <i onClick={() => this.handleSort("principal")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("principal")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Interest <span>{this.state.sortType === "interest_amount" ? <i onClick={() => this.handleSort("interest_amount")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("interest_amount")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        {/* <th scope="col">Interest Percentage <span>{this.state.sortType === "interest_percentage" ? <i onClick={() => this.handleSort("interest_percentage")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("interest_percentage")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th> */}
                        <th scope="col">Ending Balance <span>{this.state.sortType === "ending_balance" ? <i onClick={() => this.handleSort("ending_balance")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("ending_balance")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.loanData?.loan_payment_details?.length !== 0 ?
                          <>
                            {
                              this.state.loanData?.loan_payment_details?.map((data, index) =>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.payment_date}</td>
                                  <td>${data.opening_balance.toLocaleString()}</td>
                                  <td>${data.payment_total.toLocaleString()}</td>
                                  <td>${data.principal_amount.toLocaleString()}</td>
                                  <td>${data.interest_amount.toLocaleString()}</td>
                                  {/* <td>{data.interest_percent}%</td> */}
                                  <td>${data.closing_balance.toLocaleString()}</td>
                                </tr>
                              )
                            }
                            <tr>
                              <td className='fw-bold'>Total</td>
                              <td></td>
                              <td className='fw-bold'>${totalBeginingBal.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                              <td className='fw-bold'>${totalPayment.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                              <td className='fw-bold'>${totalPrincipal.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                              <td></td>
                              {/* <td className='fw-bold'>${totalInterestAmount.toLocaleString(undefined, {maximumFractionDigits: 2 })}</td> */}
                              {/* <td className='fw-bold'>{totalInterestPercent}%</td> */}
                              <td className='fw-bold'>${totalEndingBal.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                            </tr>
                          </> : <p className='pt-2 ps-2'>No payments Done Yet</p>}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}
Payments.propTypes = {
  match: PropTypes.any,
};
export default Payments;