import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_FUND,EDIT_FUND,SHOW_FUND,FUND_SUCCESS,FUND_ERROR } from './actionTypes';

import { fundSuccess, fundError} from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData, editJwtData } from '../../helpers/self_accounts_settings_helper';

function* createFund({ payload: { data } }) {

    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(postJwtData, 'fund/create',data);
            
            if (response.success == false) {
                // alert(response.message['message']);
                yield put(fundError(response.message.message));
                setTimeout(function () {
                    window.location.reload();
                }, 500);
            } else if (response.success == true) {
                yield put(fundSuccess(response.data.message));
                // setTimeout(function () {
                //     window.location.reload();
                // }, 500);
            }
            // history.push('/fundList');

        }
    } catch (error) {
        yield put(fundError(error));
    }
}


// function* editFundCategory({ payload: { category } }) {
//     try {
//         if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//             const response = yield call(editJwtData, 'fundCategory/' + category.fund_category_id, category);
//             //  console.log("responseresponse",response)
//             if (response.success == false) {
//                 // alert(response.message['message']);
//                 yield put(categoryFundError(response.message.message));
//                 // setTimeout(function () {
//                 //     window.location.reload();
//                 // }, 500);
//             } else if (response.success == true) {
//                 yield put(categoryFundSuccess(response.data.message));
//                 //  setTimeout(function () {
//                 // window.location.reload();
//                 // }, 500);
//             }

//         }


//     } catch (error) {
//         yield put(categoryFundError(error));
//     }
// }


// function* showCategoryFund({ payload: { category_id } }) {
//     try {
//         if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//             const response = yield call(getJwtData, 'category/' + category_id);
//             yield put(showFundCategory(response));
//         }
//     } catch (error) {
//         yield put(categoryFundError(error));
//     }
// }

export function* watchFund() {
    // yield takeEvery(EDIT_FUND_CATEGORY, editFundCategory)
    yield takeEvery(CREATE_FUND, createFund)
    // yield takeEvery(SHOW_FUND_CATEGORY, showCategoryFund)
}

function* FundSaga() {
    yield all([
        fork(watchFund),
    ]);
}

export default FundSaga;