import { EDIT_CLIENT,SHOW_CLIENT,CLIENT_SUCCESS,CLIENT_ERROR,CREATE_CLIENT } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ClientGroup= (state = initialState, action) => {
    switch (action.type) {
        case EDIT_CLIENT:
            state = { ...state }
            break;
        case CLIENT_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case CLIENT_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ClientGroup;