import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import { Col, Container, Row, Alert, Label, NavItem, TabContent, TabPane, Nav, NavLink, UncontrolledAlert } from "reactstrap";
import classnames from "classnames";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// import images
import logodark from "../../assets/images/ExpenseManager-Logo.png";
import logolight from "../../assets/images/ExpenseManager-Logo.png";
import CarouselPage from "./CarouselPage";
import { apiError, loginUser } from "../../store/actions";
import { connect } from "react-redux";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

class Login2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1" || localStorage.setItem('user', this.state.activeTab),
    }
    // localStorage.setItem('user', this.state.activeTab);
    this.toggleTab = this.toggleTab.bind(this);
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
      localStorage.setItem('user', this.state.activeTab);
    }
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <MetaTags>
            <title>Login</title>
          </MetaTags>
          <Fade cascade>
            <Container fluid className="p-0">
              <Row className="g-0">

                <CarouselPage />

                <Col xl={3}>
                {/* <Fade top cascade> */}
                    <div className="auth-full-page-content p-md-5 p-4">
                      <div className="w-100">
                        <div className="d-flex flex-column h-100">
                          <div className="mb-4 mb-md-5">
                            <Link to="dashboard" className="d-block auth-logo">
                              <img
                                src={logodark}
                                alt=""
                                height="100"
                                className="auth-logo-dark"
                              />
                              <img
                                src={logolight}
                                alt=""
                                height="100"
                                className="auth-logo-light"
                              />
                            </Link>
                            {/* <span className="text-light">Report Manager</span> */}
                          </div>

                          <div className="my-auto">
                            <div>
                              <h5 className="text-primary">Welcome Back !</h5>
                              <p className="text-muted">
                                Sign in to continue to Report Manager.
                              </p>
                            </div>
                            <div>
                              {this.props.error && this.props.error ?
                                <UncontrolledAlert
                                  color="success"
                                  className="alert-dismissible fade show"
                                  role="alert"
                                >{this.props.error}</UncontrolledAlert> : null}

                              {this.props.success && this.props.success ? <UncontrolledAlert
                                color="success"
                                className="alert-dismissible fade show"
                                role="alert"
                              >{this.props.success}</UncontrolledAlert> : null}
                            </div>
                            <Row>
                              <div className='col-md-12'>
                                <Nav pills justified className="bg-white rounded">
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active: this.state.activeTab === "1",
                                      })}
                                      onClick={() => {
                                        this.toggleTab("1");
                                      }}
                                    >
                                      <i className="bx bx-chat font-size-20 d-sm-none" />
                                      <span className="d-none d-sm-block">Admin</span>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active: this.state.activeTab === "2",
                                      })}
                                      onClick={() => {
                                        this.toggleTab("2");
                                      }}
                                    >
                                      <i className="bx bx-group font-size-20 d-sm-none" />
                                      <span className="d-none d-sm-block">Client</span>
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                              </div>
                            </Row>
                            <TabContent className="px-4" activeTab={this.state.activeTab}>
                              <TabPane tabId="1">
                                <div className="mt-4">
                                  <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                      f_user_type: (this.state && this.state.f_user_type) || 1,
                                      mobile: (this.state && this.state.mobile) || "",
                                      password: (this.state && this.state.password) || "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                      mobile: Yup.string().required(
                                        "Please Enter Your Phone No."
                                      ),
                                      password: Yup.string().required(
                                        "Please Enter Valid Password"
                                      ),
                                    })}
                                    onSubmit={(values) => {
                                      // console.log('values', values);
                                      this.props.loginUser(values, this.props.history);
                                    }}
                                  >
                                    {({ errors, status, touched }) => (
                                      <Form className="form-horizontal">
                                        {/* {this.props.error && this.props.error ? (
                                      <Alert color="danger">
                                        {this.props.error}
                                      </Alert>
                                    ) : null} */}
                                        <Field type="hidden" name="f_user_type" id="f_user_type" value={1} />
                                        <div className="mb-3">
                                          <Label for="mobile" className="form-label">
                                            Phone No.
                                          </Label>
                                          <Field
                                            name="mobile"
                                            placeholder="Enter Phone no"
                                            type="text"
                                            className={
                                              "form-control" +
                                              (errors.mobile && touched.mobile
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="mobile"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>

                                        <div className="mb-3">
                                          <div className="float-end">
                                            <Link
                                              to="/pages-forgot-pwd-2"
                                              className="text-muted"
                                            >
                                              Forgot password?
                                            </Link>
                                          </div>
                                          <Label for="password" className="form-label">
                                            Password
                                          </Label>
                                          <Field
                                            name="password"
                                            placeholder="Enter Password"
                                            type="password"
                                            autoComplete="true"
                                            className={
                                              "form-control" +
                                              (errors.password && touched.password
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="remember-check"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="remember-check"
                                          >
                                            Remember me
                                          </label>
                                        </div>

                                        <div className="mt-3 d-grid">
                                          <button
                                            className="btn btn-primary btn-block"
                                            type="submit"
                                          >
                                            {" "}
                                            Log In
                                            {" "}
                                          </button>
                                        </div>


                                      </Form>
                                    )}
                                  </Formik>
                                </div>
                              </TabPane>
                              <TabPane tabId="2">
                                <div className="mt-4">
                                  <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                      f_user_type: (this.state && this.state.f_user_type) || 2,
                                      mobile: (this.state && this.state.mobile) || "",
                                      password: (this.state && this.state.password) || "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                      mobile: Yup.string().required(
                                        "Please Enter Your Phone No."
                                      ),
                                      password: Yup.string().required(
                                        "Please Enter Valid Password"
                                      ),
                                    })}
                                    onSubmit={values => {
                                      // console.log('values', values);
                                      this.props.loginUser(values, this.props.history);
                                    }}
                                  >
                                    {({ errors, status, touched }) => (
                                      <Form className="form-horizontal">
                                        {/* {this.props.error && this.props.error ? (
                                      <Alert color="danger">
                                        {this.props.error}
                                      </Alert>
                                    ) : null} */}
                                        <Field type="hidden" name="f_user_type" value={2} />
                                        <div className="mb-3">
                                          <Label for="mobile" className="form-label">
                                            Phone No.
                                          </Label>
                                          <Field
                                            name="mobile"
                                            placeholder="Enter Phone no"
                                            type="text"
                                            className={
                                              "form-control" +
                                              (errors.mobile && touched.mobile
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="mobile"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>

                                        <div className="mb-3">
                                          <div className="float-end">
                                            <Link
                                              to="/pages-forgot-pwd-2"
                                              className="text-muted"
                                            >
                                              Forgot password?
                                            </Link>
                                          </div>
                                          <Label for="password" className="form-label">
                                            Password
                                          </Label>
                                          <Field
                                            name="password"
                                            placeholder="Enter Password"
                                            type="password"
                                            autoComplete="true"
                                            className={
                                              "form-control" +
                                              (errors.password && touched.password
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="remember-check"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="remember-check"
                                          >
                                            Remember me
                                          </label>
                                        </div>

                                        <div className="mt-3 d-grid">
                                          <button
                                            className="btn btn-primary btn-block"
                                            type="submit"
                                          >
                                            {" "}
                                            Log In
                                            {" "}
                                          </button>
                                        </div>


                                      </Form>
                                    )}
                                  </Formik>
                                </div>
                              </TabPane>
                            </TabContent>
                            {/* <div>
                          <div className="mt-2 text-center">
                            <h5 className="font-size-14">
                              Sign in with
                            </h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                >
                                  <i className="mdi mdi-facebook"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-info text-white border-info"
                                >
                                  <i className="mdi mdi-twitter"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                >
                                  <i className="mdi mdi-google"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                            {/* <div className="mt-2 text-center">
                          <p>
                            Don&apos;t have an account ?{" "}
                            <a
                              href="pages-register-2"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Signup now{" "}
                            </a>{" "}
                          </p>
                        </div> */}
                          </div>

                          {/* <div className=" mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} Report Manager. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger"></i> by
                          Hovata Technologies
                        </p>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  {/* </Fade> */}
                </Col>
              </Row>
            </Container>
          </Fade>
        </div>
      </React.Fragment>
    );
  }
}

Login2.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.Login;
  return { error, success };
}

export default withRouter(connect(mapStateToProps, { loginUser })(Login2));
