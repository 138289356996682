import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
import PropTypes from 'prop-types';

class Line extends Component {
  getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
      },
      legend: {
        // data: ["Income"],
        textStyle: {
          color: ["#8791af"],
        },
      },
      xAxis: {
        type: "category",
        data: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",],
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#8791af",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#8791af"
          },
        },
      },
      series: this.props.monthCategoryIncomeChart,
      color: ["#34c38f"],
      textStyle: {
        color: ["#8791af"],
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "350px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
Line.propTypes = {
  chartSeries: PropTypes.any,
  periodData: PropTypes.any,
  monthCategoryIncomeChart:PropTypes.any,
}
export default Line
