import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CLIENT_ERROR,CLIENT_SUCCESS,EDIT_CLIENT,SHOW_CLIENT,CREATE_CLIENT} from './actionTypes';
import { clientGroupError, clientGroupSuccess, editClient} from './actions';

//Include Both Helper File with needed methods
import { getJwtProfile,postJwtProfile, editJwtData} from '../../helpers/self_accounts_settings_helper';

// function* createUserProfile({ payload: { userProfile } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(postJwtProfile, 'user/registration',userProfile);
//              if(response.success == false){
//                 // alert(response.message['message']);
//                 yield put(profileError(response.message.message));
//             }else if(response.success == true){
//                 yield put(profileSuccess(response.data.message));
//             } 
//           }
//     } catch (error) {
//         yield put(profileError(error));
//     }
// }
 

function* editClientGroup({ payload: { clientGroup } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(editJwtData, 'clientGroup/'+ clientGroup.cleint_group_id, clientGroup);
            if(response.success == false){
                // alert(response.message['message']);
                yield put(clientGroupError(response.message.message));
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }else if(response.success == true){
                yield put(clientGroupSuccess(response));
                 setTimeout(function () {
                window.location.reload();
                }, 1000);
            }
        }


    } catch (error) {
        yield put(clientGroupError(error));
    }
}


// function* showProfile({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtProfile, 'users/id/'+user_id);
//              yield put(profileShow(response));
//           }
          
          
//     } catch (error) {
//         yield put(profileError(error));
//     }
// }

export function* watchClientGroup() {
    yield takeEvery(EDIT_CLIENT, editClientGroup)
    // yield takeEvery(SHOW_PROFILE, showProfile)
    // yield takeEvery(CREATE_USER_PROFILE,createUserProfile)
}

function* ClientGroupSaga() {
    yield all([
        fork(watchClientGroup),
    ]);
}

export default ClientGroupSaga;