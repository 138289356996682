import Breadcrumbs from 'components/Common/Breadcrumb';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import { MetaTags } from 'react-meta-tags';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Container, Form, Row } from 'reactstrap';
import { createBlukPayment } from 'store/Loan/actions';
import csvfile from "../../../assets/sample-payment.csv"
class MultiPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientInfo: {
        // roles: [],
        // status: 1
      },
      all_roles: [],
      date: new Date(),
      custom_div2: false
    };
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.state = { selectedFiles: [] }
  }

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
    console.log('selectedFiles', this.state.selectedFiles)
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }


  changeHandler = (event) => {
    let targetFile = event.target.files[0];
    console.log("targetFile", targetFile);
    this.setState({
      uploadedFile: targetFile
    });
  };

  handleSubmission = () => {
    const formData = new FormData();

    formData.append('uploaded_file', this.state.selectedFiles[0]);

    console.log("uploaded_file", formData)

    createBlukPayment(formData)
      .then((response) => {
        console.log(response);
        if (response.status === true) {
          console.log("Done");
          this.setState({
            message: response.message,
            custom_div2: true
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  render() {
    let clientGroup = [];
    let clientInfoList = [];
    let categoryList = [];
    let categoryCustomerList = [];


    if (this.state.hasOwnProperty('clientData')) {
      clientGroup = this.state.clientData.data;
    }

    if (this.state.hasOwnProperty('clientInfoData')) {
      clientInfoList = this.state.clientInfoData;
    }
    if (this.state.hasOwnProperty('clientCustomerInfoData')) {
      categoryCustomerList = this.state.clientCustomerInfoData;
    }

    if (this.state.hasOwnProperty('categoryList')) {
      categoryList = this.state.categoryList.data;
    }
    const csvData = [
      ["Group Code", "Client Info Code", "Client Customer Code", "Category Code", "Transaction Date", "Transaction Amount", "remarks"]
    ];
    const { date, message } = this.state;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Multiple Payment | Report Manager</title>
        </MetaTags>
        <Fade cascade>

          <div className="page-content">
            {message == "" || message == undefined || message == null ?
              "" :
              // this.state.custom_div1==true &&
              this.state.custom_div2 ?
                <SweetAlert
                  title={message}
                  timeout={2000}
                  showConfirm={false}
                  showCloseButton={false}
                  success
                  onConfirm={() => this.setState({
                    custom_div2: false,
                    message: undefined
                  })}
                >
                  {/* I Will close in 2 Seconds */}
                </SweetAlert> : null
            }
            <Container fluid={true}>
              <Breadcrumbs title="Forms" breadcrumbItem="Multiple Payment | Report Manager" />
              <a href={csvfile} download="sample-payment.csv" className="btn btn-primary pull-right"><i className="fas fa-download"></i> Download sample csv file 1</a>
              <br />
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Excel Upload</CardTitle>
                      <p className="card-title-desc">
                        {" "}
                        Select the file to Upload.
                      </p>
                      <Form encType="multipart/form-data">
                        <Dropzone
                          onDrop={acceptedFiles =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} name="uploaded_file" multiple />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="button"
                            className="btn btn-primary" onClick={this.handleSubmission}
                          >
                            Send Files
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}

export default MultiPayment;