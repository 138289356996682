import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, Form, CardHeader, CardFooter, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
//import { CSVLink, CSVDownload } from "react-csv";
// toaster
import toastr from "toastr";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import { getUserDetails, createOrUpdateUser, getRoleList } from '../../store/auth/profile/actions';
import { createClient } from 'store/clients/actions';
import { createClientInfo, getClientInfoDrop, getClientCustomerInfoDrop, getPublicClientInfoDrop, getPublicClientCustomerInfoDrop } from 'store/clientInfo/actions';
import { getClientList, editClient, getPublicClientList } from '../../store/clients/actions';
import { createTransaction } from 'store/transaction/actions';
import { categoryList } from 'store/actions';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fade from 'react-reveal/Fade';

class TransactionCreate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clientInfo: {

      },
      transInfo: {
      },
      showlist: 0,
      all_roles: [],
      date: new Date(),
      custom_div2: false
    };
  }

  fetchClientGroupList = () => {
    getPublicClientList().then((response) => {
      this.setState({
        clientData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }

  fetchClientInfoList = (clientGroup_id) => {
    getPublicClientInfoDrop(clientGroup_id).then((response) => {
      this.setState({
        clientInfoData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }
  fetchClientCustomerInfoList = (clientGroup_id) => {
    getPublicClientCustomerInfoDrop(clientGroup_id).then((response) => {
      console.log("response", response);
      this.setState({
        clientCustomerInfoData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }

  fetchCategoryList = () => {
    categoryList().then((response) => {
      this.setState({
        categoryList: response.data,
      });
    })
      .catch((err) => {
        console.log(err);
        // window.alert(err.response.data.message);
        // window.location.href='/';
      })
  }


  componentDidMount() {
    if (this.props.match.params.id) {
      this.fetchUserDetails(this.props.match.params.id);
    }
    this.fetchCategoryList();
    this.fetchClientGroupList();
    // this.fetchClientInfoList();
  }

  handleInputChange = (event) => {
    // event.preventDefault();
    console.log("date", event.target.name);
    if (event.target.name == "f_group_id") {
      this.fetchClientInfoList(event.target.value);
    }
    if (event.target.name == "f_client_id") {
      this.fetchClientCustomerInfoList(event.target.value);
    }
    if (event.target.name == "f_category_id") {
      var index = event.target.selectedIndex;
      var optionElement = event.target.childNodes[index]
      var option = optionElement.getAttribute('data_type_id');
      let f_category_id = optionElement.getAttribute('data_type_id');;
      let name = "type_id";
      this.updateUserObject(name, f_category_id);

      if (f_category_id == 3) {
        this.setState({
          showlist: 1
        })
      }
      else {
        this.setState({
          showlist: 0
        })
      }
      // this.fetchClientCustomerInfoList(event.target.value);
    }
    const { target: { name, value } } = event;

    this.updateUserObject(name, value);
  }

  updateUserObject = (name, value, callback = null) => {

    let transInfoCopy = Object.assign({}, this.state.transInfo);
    transInfoCopy[name] = value;

    this.setState({
      transInfo: transInfoCopy
    }, () => {
      if (typeof callback == "function")
        callback()
    });
    if (name == "type_id") {
      this.setState({
        type_id: value
      })
    }

  }

  onHandleSubmit = (event) => {
    event.preventDefault();
    const { f_group_id, f_client_id, payable, f_clients_customer_id, f_category_id, transaction_amount, remarks, transaction_date, transaction_date_time } = this.state.transInfo;
    const { type_id } = this.state;
    if (f_group_id == "") {
      toastr.error("Please Select Client Group");
    }
    else if (f_client_id == "") {
      toastr.error("Please Select Client");
    }
    else if (f_clients_customer_id == "") {
      toastr.error("Please Select Client Customer");
    }
    else if (f_category_id == "") {
      toastr.error("Please Select Category");
    }
    else if (transaction_amount == "") {
      toastr.error("Please Enter Transaction Amount");
    }
    else if (transaction_date == "") {
      toastr.error("Please Select Date");
    }
    else if (transaction_date != "" && transaction_amount != "" && f_category_id != "" && f_client_id != "" && f_group_id != "") {
      const data = {
        f_group_id: f_group_id,
        f_client_id: f_client_id,
        f_clients_customer_id: f_clients_customer_id,
        f_category_id: f_category_id,
        transaction_amount: transaction_amount,
        payable: payable,
        type_id: type_id,
        transaction_date: moment(new Date(this.state.date)).format("yyyy-MM-DD"),
        // transaction_date_time: transaction_date_time,
        remarks: remarks,
      };
      console.log("data===>", data)
      createTransaction(data)
        .then((response) => {
          if (response.data.status === true) {

            this.setState({
              message: response.data.message,
              custom_div2: true
            })
            setTimeout(() => {
              this.props.history.push({
                pathname: '/transaction',
              });
            }, 2000)

          }
        })
        .catch((err) => {
          console.log(err);
          // this.props.handleErrorResponse(err);
          // window.alert(err.response.data.message);
          // window.location.href='/';

        });
    }

  };


  render() {
    let clientGroup = [];
    let clientInfoList = [];
    let categoryList = [];
    let categoryCustomerList = [];


    if (this.state.hasOwnProperty('clientData')) {
      clientGroup = this.state.clientData.data;
    }

    if (this.state.hasOwnProperty('clientInfoData')) {
      clientInfoList = this.state.clientInfoData;
    }
    if (this.state.hasOwnProperty('clientCustomerInfoData')) {
      categoryCustomerList = this.state.clientCustomerInfoData;
    }

    if (this.state.hasOwnProperty('categoryList')) {
      categoryList = this.state.categoryList.data;
    }
    const csvData = [
      ["f_group_id", "f_client_id", "f_clients_customer_id", "f_category_id", "transaction_date", "transaction_amount", "transaction_date_time", "remarks"]

    ];
    const { date, message } = this.state;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Transaction Create | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              {/* Render Breadcrumbs */}
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Transaction Create</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/transaction">Transaction List</Link></li>
                    <li className="breadcrumb-item active">Transaction Create</li>
                  </ol>
                </div>
              </div>

              {message == "" || message == undefined || message == null ?
                "" :
                this.state.custom_div2 ?
                  <SweetAlert
                    title={message}
                    timeout={2000}
                    showConfirm={false}
                    showCloseButton={false}
                    success
                    onConfirm={() => this.setState({
                      custom_div2: false,
                      message: undefined
                    })}
                  >
                  </SweetAlert> : null


              }

              <Row>
                <Col xs="12" sm="12">
                  <Form onSubmit={this.onHandleSubmit}>
                    <Card>
                      <CardHeader className="bg-white">
                        <strong className="fs-4">New Transaction</strong>
                        <small className="fs-6"> Form</small>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs="12" sm="12" md={6}>
                            <FormGroup>
                              <label className="col-form-label">Client Group Name</label>
                              <select className="form-select" name="f_group_id" id="f_group_id" onChange={this.handleInputChange} required>
                                <option>Select</option>
                                {clientGroup.map((client, index) =>
                                  <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                )}
                              </select>
                            </FormGroup>

                            <FormGroup>
                              <label className="col-form-label">Client Info Name</label>
                              <select className="form-select" name="f_client_id" id="f_client_id" onChange={this.handleInputChange} required>
                                <option value="">Select</option>
                                {clientInfoList && clientInfoList.map((clientInfo, index) =>
                                  <option value={clientInfo.id} key={index}>{clientInfo.name}</option>
                                )}

                              </select>
                            </FormGroup>

                            <FormGroup>
                              <label className="col-form-label">Client Customer Name</label>
                              <select className="form-select" name="f_clients_customer_id" id="f_clients_customer_id" onChange={this.handleInputChange} >
                                <option value="">Select</option>
                                {categoryCustomerList && categoryCustomerList.map((clientInfo, index) =>
                                  <option value={clientInfo.customer_id} key={index}>{clientInfo.customer_name}</option>
                                )}

                              </select>
                            </FormGroup>

                            <FormGroup>
                              <label className="col-form-label">Category Name</label>
                              <select className="form-select" name="f_category_id" id="f_category_id" onChange={this.handleInputChange} required>
                                <option value="">Select</option>
                                {categoryList.map((category, index) =>
                                  <option value={category.category_id} key={index} >{category.category_name_en}</option>
                                )}

                              </select>
                            </FormGroup>
                            {
                              this.state.showlist == 1 &&
                              <FormGroup>
                                <label className="col-form-label">Payable Or Receivable</label>
                                <select className="form-select" name="payable" id="payable" onChange={this.handleInputChange} required>
                                  <option value="">Select</option>
                                  <option value="1">Paid</option>
                                  <option value="2">Received</option>

                                </select>
                                `</FormGroup>
                            }
                            <FormGroup>
                              <Label>Transaction Date</Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  value={date}
                                  options={{ allowInput: true }}
                                  onChange={date => {
                                    this.setState({ date });
                                  }}
                                />
                              </InputGroup>
                              <Input type="hidden" name="transaction_date" value={moment(new Date(this.state.date)).format("yyyy-MM-DD")} onChange={this.handleInputChange} />
                            </FormGroup>
                          </Col>
                          <Col xs="12" sm="12" md={6} className="mt-2">
                            <FormGroup >
                              <Label htmlFor="name"> Transaction Amount</Label>
                              <Input type="text" id="transaction_amount" name="transaction_amount" className='fs-2 text-primary' style={{ height: '110px' }}
                                onChange={this.handleInputChange}
                                placeholder="Enter  amount..." required />
                            </FormGroup>

                            <FormGroup>
                              <Label htmlFor="Address">Note</Label>
                              <Input type="textarea" id="remarks" name="remarks" rows={11}
                                onChange={this.handleInputChange}
                                placeholder="Enter Note.." />

                            </FormGroup>
                          </Col>
                          <div className="py-3  d-flex justify-content-end ">
                            <Button type="submit" size="sm" color="primary"
                              className="me-2 pull-right"><i
                                className="fa fa-dot-circle-o" /> Submit</Button>
                            <Link to="/transaction">
                              <Button type="reset" size="sm" color="danger"
                                className="pull-right"><i
                                  className="fa fa-ban" /> Cancel</Button>
                            </Link>
                          </div>
                        </Row>
                      </CardBody>

                    </Card>
                  </Form>

                </Col>
              </Row>
            </Container>
          </div>
        </Fade>
      </React.Fragment>

    );
  }
}
TransactionCreate.propTypes = {
  handleErrorResponse: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = state => {
  const { msg, error, success } = state.Profile;
  return { msg, error, success };
}




export default withRouter(connect(mapStateToProps, { getUserDetails, createClient, getRoleList })(TransactionCreate));

