import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, CardHeader, CustomInput, CardFooter, Table, UncontrolledAlert, Modal } from "reactstrap";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import toastr from "toastr";

import { checkPermission, permissionEnum } from '../../services/service-provider';
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch'
import { CustomPagination } from "../Settings/Common/CustomPagination";
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import { getUserList } from '../../store/auth/profile/actions';

import moment from 'moment';
import { Field, Formik, ErrorMessage, Form } from 'formik';
import DeleteModal from 'pages/Calendar/DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getCustomerList, deleteCustomerInfo, editCustomer } from 'store/Customer/actions';
import { createClientInfo, getClientInfoDrop, getPublicClientInfoDrop } from 'store/clientInfo/actions';
import { getClientList, editClient, getPublicClientList } from '../../store/clients/actions';
import { values } from 'lodash';
import Fade from 'react-reveal/Fade';

function UserRow(props) {
    const customer = props.customer;

    // const useractivityLink = `/users/activity`

    return (
        <tr key={customer.toString() + 1}>
            <td scope="row">{props.index}</td>
            <td>{customer.customer_code}</td>
            <td>{customer.customer_name}</td>
            <td>{customer.client_info_details == null ? "" : customer.client_info_details.name}</td>
            <td>{customer.client_group == null ? "" : customer.client_group.name}</td>
            <td>{customer.customer_phone_no}</td>
            <td>{customer.customer_email}</td>
            <td>{customer.customer_address}</td>
            {/* <td>{client.phone_number}</td>
            <td>{client.email}</td>
            <td>{client.address}</td>
            <td> {moment(new Date(client.created_at)).format("YYYY-MM-DD")}</td> */}
            {(checkPermission(permissionEnum.CLIENT_WISE_CUSTOMER_UPDATE) || checkPermission(permissionEnum.CLIENT_WISE_CUSTOMER_DELETE)) &&
                <td>
                    <div className="d-flex gap-3">
                        {checkPermission(permissionEnum.CLIENT_WISE_CUSTOMER_UPDATE) && <i className="mdi mdi-pencil font-size-18 text-success" onClick={() => { props.tog_scroll(customer) }}></i>}
                        {checkPermission(permissionEnum.CLIENT_WISE_CUSTOMER_DELETE) &&
                            <i
                                className="mdi mdi-delete font-size-18 text-danger"
                                id="deletetooltip"
                                onClick={() => {
                                    props.toggleDeleteModal();
                                    props.onClickDelete(customer);
                                }
                                }
                            ></i>
                        }

                    </div>
                </td>
            }
        </tr>
    )
}

UserRow.propTypes = {
    tog_scroll: PropTypes.any,
    index: PropTypes.any,
    customer: PropTypes.any,
    toggleDeleteModal: PropTypes.func,
    onClickDelete: PropTypes.func,
};

class CustomerIndex extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            per_page: 15,
            search: '',
            modal_scroll: false,
            deleteModal: false,
            client: {

            },
            custom_div1: false,
            custom_div2: false

        };
        this.props.location.state == undefined
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    fetchCustomerList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        getCustomerList(options).then((response) => {
            if (this.props.location.state != null || this.props.location.state != "" || this.props.location.state != undefined) {

                this.setState({
                    custom_div1: true,
                    customerData: response.data,
                })
                // let cleanState = {};
                // Object.keys(this.state.custom_div1).forEach(x => cleanState[x] = null);
                // this.setState(cleanState);
                // this.props.location.state.message = null;
                // console.log('this.pro', this.props.location.state.message);
            }
            else {
                this.setState({
                    customerData: response.data,
                    custom_div1: false,
                })
            }
            // this.setState({
            //     clientData: response.data,
            // })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }
    fetchClientGroupList = () => {
        getPublicClientList().then((response) => {
            this.setState({
                clientData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }

    fetchClientInfoList = (clientGroup_id) => {
        getPublicClientInfoDrop(clientGroup_id).then((response) => {
            this.setState({
                clientInfoData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }
    componentDidMount() {
        this.fetchClientGroupList();
        this.fetchCustomerList();
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchCustomerList();
        });
    }

    tog_scroll = (customer) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.setState({
            customerInfo: customer
        })
        this.fetchClientInfoList(customer.f_client_groups_id);
        this.removeBodyCss();
    }
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };

    onClickDelete = (customer) => {
        this.setState({ deleteCustomer: customer });
        this.setState({ deleteModal: true });
    };

    handleDeleteClient = () => {
        const { deleteCustomer } = this.state;
        if (deleteCustomer.customer_id !== undefined) {
            deleteCustomerInfo(deleteCustomer.customer_id).then(response => {
                this.setState({
                    message: response.data,
                })
                this.fetchCustomerList();
            });
            this.setState({
                deleteModal: false,
                custom_div2: true
            });
        }
    };
    updateUserObject = (name, value, callback = null) => {
        let clientCopy = Object.assign({}, this.state.client);
        clientCopy[name] = value;

        this.setState({
            client: clientCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }
    handleEditChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
        this.updateUserObject(name, value);
    }


    // handleEditSubmit = (e) => {
    //     e.preventDefault();
    //     const { email, name, phone_number, address, cleint_group_id } = this.state.client;

    //     if (name == "") {
    //         toastr.error("Enter Name...");
    //     }

    //     else if (phone_number == "") {
    //         toastr.error("Phone Number...");
    //     }
    //     else {
    //         const clientGroup = {
    //             email: email,
    //             name: name,
    //             phone_number: phone_number,
    //             address: address,
    //             cleint_group_id: cleint_group_id
    //         }
    //         this.props.editClient(clientGroup);
    //         this.setState(prevState => ({
    //             modal_scroll: !prevState.modal_scroll,
    //         }
    //         ));
    //         this.fetchClientGroupList();
    //     }
    // }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchCustomerList();
        });
    }


    render() {
        let paginator;

        let customerList = [];

        if (this.state.hasOwnProperty('customerData')) {
            customerList = this.state.customerData.data;
            paginator = {
                'currentPage': this.state.customerData.current_page,
                'pagesCount': this.state.customerData.last_page,
                'total': this.state.customerData.total,
                'to': this.state.customerData.to,
                'from': this.state.customerData.from,
            };
        }
        const { deleteModal, message } = this.state;
        let clientGroup = [];
        let clientInfoList = [];



        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
        }

        if (this.state.hasOwnProperty('clientInfoData')) {
            clientInfoList = this.state.clientInfoData;
        }

        // console.log(this.state.customer == undefined ? "" : this.state.customer)
        return (

            <React.Fragment>
                <MetaTags>
                    <title>Client Group Info | Report Manager</title>
                </MetaTags>
                <Fade cascade>
                    <div className="page-content">
                        <Container fluid={true}>
                            {/* Render Breadcrumbs */}
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Client Group</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Client Group</li>
                                    </ol>
                                </div>
                            </div>
                            <DeleteModal
                                show={deleteModal}
                                onDeleteClick={this.handleDeleteClient}
                                onCloseClick={() => this.setState({ deleteModal: false })}
                            />
                            <Row>
                                <Col xl={12}>
                                    <div className='d-flex justify-content-end mb-3'>
                                        <div>
                                            {checkPermission(permissionEnum.CLIENT_WISE_CUSTOMER_CREATE) &&
                                                <Link to="/customers/create">
                                                    <button className="btn btn-primary float-right"><i className="mdi mdi-plus-circle-outline me-1" />Create Customer</button>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {message == "" || message == undefined || message == null ?
                                            "" :
                                            // this.state.custom_div1==true &&
                                            this.state.custom_div2 ?
                                                <SweetAlert
                                                    title={message}
                                                    timeout={2000}
                                                    showConfirm={false}
                                                    showCloseButton={false}
                                                    success
                                                    onConfirm={() => this.setState({ custom_div2: false })}
                                                >
                                                    {/* I Will close in 2 Seconds */}
                                                </SweetAlert> : null
                                        }
                                        {/* // <UncontrolledAlert
                                        //     color="success"
                                        //     className="alert-dismissible fade show"
                                        //     role="alert"
                                        // >
                                        //     <i className="mdi mdi-check-all me-2"></i>
                                        //     {message}
                                        // </UncontrolledAlert> */}

                                        {this.props.error && this.props.error ?
                                            <UncontrolledAlert
                                                color="success"
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >{this.props.error}</UncontrolledAlert> : null}

                                        {this.props.success && this.props.success ?
                                            (this.state.custom_div1 ?
                                                <SweetAlert
                                                    title={this.props.success}
                                                    timeout={2000}
                                                    showCloseButton={false}
                                                    showConfirm={false}
                                                    success
                                                    onConfirm={() => this.setState({
                                                        custom_div1: false,
                                                        message: undefined
                                                    })}
                                                >

                                                </SweetAlert> : null)
                                            : null}

                                    </div>
                                    <Card>
                                        <CardBody>
                                            <DataTabeSearch handleInputChange={this.handleInputChange} />
                                            <Table responsive striped className='table-bordered'>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Customer Code</th>
                                                        <th scope="col">Customer Name</th>
                                                        <th scope="col">Client Name</th>
                                                        <th scope="col">Group Name</th>
                                                        <th scope="col">Phone No.</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Address</th>
                                                        {/* <th scope="col">Date</th> */}
                                                        {/* <th scope="col">activity</th> */}
                                                        {(checkPermission(permissionEnum.CLIENT_WISE_CUSTOMER_UPDATE) || checkPermission(permissionEnum.CLIENT_WISE_CUSTOMER_DELETE)) &&
                                                            <th scope="col">Action</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {customerList.map((customer, index) =>
                                                        <UserRow key={index} customer={customer} tog_scroll={this.tog_scroll} toggleDeleteModal={this.toggleDeleteModal} onClickDelete={this.onClickDelete} index={index + 1 + ((this.state.page - 1) * this.state.per_page)} />
                                                    )}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                        {paginator &&
                                            <CardFooter className="bg-white">
                                                <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                            </CardFooter>
                                        }
                                    </Card>
                                    <Modal
                                        isOpen={this.state.modal_scroll}
                                        toggle={this.tog_scroll}
                                        scrollable={true}
                                    >
                                        <div className="modal-header">
                                            <h5 className="modal-title mt-0">
                                                Edit Customer
                                            </h5>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    this.setState({ modal_scroll: false })
                                                }
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    customer_id: (this.state.customerInfo && this.state.customerInfo.customer_id) || "",
                                                    customer_name: (this.state.customerInfo && this.state.customerInfo.customer_name) || "",
                                                    customer_code: (this.state.customerInfo && this.state.customerInfo.customer_code) || "",
                                                    customer_email: (this.state.customerInfo && this.state.customerInfo.customer_email) || "",
                                                    customer_phone_no: (this.state.customerInfo && this.state.customerInfo.customer_phone_no) || "",
                                                    f_client_groups_id: (this.state.customerInfo && this.state.customerInfo.f_client_groups_id) || "",
                                                    f_client_info_details_id: (this.state.customerInfo && this.state.customerInfo.f_client_info_details_id) || "",
                                                    customer_address: (this.state.customerInfo && this.state.customerInfo.customer_address) || "",
                                                    is_Bank: (this.state.customerInfo && this.state.customerInfo.is_bank == 1 ? true : false) || false,

                                                    // cleint_group_id: this.state.client.cleint_group_id || "",
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    customer_name: Yup.string().required(
                                                        "Please Enter Customer Name"
                                                    ),
                                                    f_client_groups_id: Yup.string().required(
                                                        "Please Enter a Group"
                                                    ),
                                                    f_client_info_details_id: Yup.string().required(
                                                        "Please Enter a Client"
                                                    ),
                                                })}
                                                onSubmit={(values) => {
                                                    // console.log(values);
                                                    this.props.editCustomer(values);
                                                    this.setState(prevState => ({
                                                        modal_scroll: !prevState.modal_scroll,
                                                        custom_div1: true,
                                                    }
                                                    ));
                                                    this.fetchCustomerList();
                                                }}
                                            >
                                                {({ errors, status, touched, handleChange, handleUpdate }) => (
                                                    <Form className="form-horizontal">
                                                        <Field type="hidden" id="customer_id" name="customer_id" className="form-control" />

                                                        <FormGroup >
                                                            <Label htmlFor="customer_name">Customer Name</Label>
                                                            <Field type="text" id="customer_name" name="customer_name"
                                                                // onChange={this.handleInputChange}
                                                                placeholder="Enter  name..."
                                                                // required
                                                                className={
                                                                    "form-control" +
                                                                    (errors.customer_name && touched.customer_name
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="customer_name"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <label className="col-form-label">Client Group Name</label>
                                                            <select name="f_client_groups_id" id="f_client_groups_id"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    this.fetchClientInfoList(e.currentTarget.value)

                                                                }}
                                                                //   onClick={this.fetchClientInfoList(this.state.f_client_groups_id)}
                                                                // value={defaultValue(values.f_client_groups_id)}
                                                                className={"form-select"
                                                                    +
                                                                    (errors.f_client_groups_id && touched.f_client_groups_id
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            >
                                                                {/* <option value="">Select</option> */}
                                                                {clientGroup && clientGroup.map((client, index) =>
                                                                    <option value={client.cleint_group_id} key={index} selected>{client.name}</option>
                                                                )}
                                                                {/* defaultValue={this.state.f_client_groups_id} */}
                                                            </select>
                                                            <ErrorMessage
                                                                name="f_client_groups_id"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                            {/* <FormText className="help-block">Please enter client group</FormText> */}
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <label className="col-form-label">Client Info Name</label>
                                                            <select name="f_client_info_details_id" id="f_client_info_details_id"
                                                                // value={values.f_client_info_details_id}
                                                                onChange={handleChange}
                                                                className={"form-select"
                                                                    +
                                                                    (errors.f_client_info_details_id && touched.f_client_info_details_id
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            // onChange={this.handleInputChange} 
                                                            >
                                                                <option value="">Select</option>
                                                                {clientInfoList != null && clientInfoList.map((clientInfo, index) =>
                                                                    <option value={clientInfo.id} key={index} selected>{clientInfo.name}</option>
                                                                )}
                                                                {/* defaultValue={this.state.f_client_groups_id} */}
                                                            </select>
                                                            <ErrorMessage
                                                                name="f_client_info_details_id"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                            {/* <FormText className="help-block">Please enter client Info </FormText> */}
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label htmlFor="email">Email</Label>
                                                            <Field type="email" id="customer_email" name="customer_email"
                                                                className="form-control"
                                                                // onChange={this.handleInputChange}
                                                                placeholder="Enter email..." />

                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label htmlFor="customer_code">Customer Code</Label>
                                                            <Field type="text" id="customer_code" name="customer_code"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.customer_code && touched.customer_code
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                                // onChange={this.handleInputChange}
                                                                placeholder="Enter Customer Code..." />
                                                            <ErrorMessage
                                                                name="customer_code"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label htmlFor="mobile">Phone No.</Label>
                                                            <Field type="text" id="customer_phone_no" name="customer_phone_no"
                                                                className="form-control"
                                                                // onChange={this.handleInputChange} clas
                                                                placeholder="Enter Phone No..." />

                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label htmlFor="Address">Address</Label>
                                                            <Field type="textarea" id="customer_address" name="customer_address"
                                                                className="form-control"
                                                                // onChange={this.handleInputChange}
                                                                placeholder="Enter address..."
                                                            />

                                                        </FormGroup>
                                                        <FormGroup className="fs-4">
                                                            <Field type="checkbox" name="is_Bank" className="fs-4 me-2"
                                                            />
                                                            <label>Is Bank</label>
                                                        </FormGroup>
                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                onClick={() =>
                                                                    this.setState({ modal_scroll: false })
                                                                }
                                                            >
                                                                Close
                                                            </button>
                                                            <button type="submit" className="btn btn-primary">
                                                                Save changes
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>


                                        </div>
                                    </Modal>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Fade>
            </React.Fragment>
        )
    }

}

CustomerIndex.propTypes = {
    location: PropTypes.any,
    error: PropTypes.any,
    success: PropTypes.any,
    msg: PropTypes.any,
    client: PropTypes.any,
    editCustomer: PropTypes.func,
};

const mapStateToProps = state => {
    const { error, success } = state.Customer;
    return { error, success };
}



export default withRouter(connect(mapStateToProps, { getUserList, editCustomer })(CustomerIndex));
