import { CREATE_CUSTOMER,CUSTOMER_ERROR,CUSTOMER_SUCCESS,SHOW_CUSTOMER,EDIT_CUSTOMER} from './actionTypes';

import {getApi} from "../../services/httpService";


export const editCustomer= (customerInfo) => {
    return {
        type: EDIT_CUSTOMER,
        payload: { customerInfo }
    }
}

export const customerSuccess = (response) => {
    return {
        type: CUSTOMER_SUCCESS,
        payload: response.data.message
    }
}

export const customerError = (error) => {
    return {
        type: CUSTOMER_ERROR,
        payload: error
    }
}

export function getCustomerList(params) {
    return getApi().get('clientWiseCustomer/list', {
        params: params
    })
}
export function getCustomerClientCode(params) {
    return getApi().get('clientWiseCustomer/code/account_no', {
        params: params
    })
  }
// export function getClientInfoDrop(id) {
//     return getApi().get('clientInfoDetails/list/'+id)
// }

export function createCustomerInfo(data) {
        return getApi().post('clientWiseCustomer/create', data);
}

export function deleteCustomerInfo(id) {
    return getApi().put('clientWiseCustomer/delete/'+id);
}




