import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  CardHeader,
  Form,
  Input,
  FormText,
  CardFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import toastr from "toastr";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
// actions
import {
  createOrUpdateRole,
  getRoleDetails,
  getPermissionGroupList,
} from "../../store/auth/role/actions";
import { checkStatus } from "../../services/httpService";
import SweetAlert from "react-bootstrap-sweetalert";
import Fade from 'react-reveal/Fade';

function PermissionGroupRow(props) {
  const group = props.permission_group;
  // console.log(group);
  const sadminCheck = name => {
    let allpermissions = [];
    if (localStorage.getItem("permissions") == null) {
      allpermissions = [];
    } else {
      allpermissions = localStorage.getItem("permissions");
      allpermissions = JSON.parse(allpermissions);
      // allItems = JSON.parse(allItems);
    }
    if (allpermissions.indexOf(name) !== -1) {
      return { display: "" };
    } else {
      return { display: "none" };
    }
  };

  return (
    <div>
      <div className="mt-2 text-info">
        <Label for="exampleEmail">{group.name.toUpperCase()} Permission</Label>
      </div>
      <Row className="pt-2 pb-5">
        {group.permissions.map(permission => {
          let id = `permission-${permission.id}`;

          let check_permission = props.role_permissions.filter(
            permission_id => {
              return permission.id === permission_id;
            }
          );
          let status = false;
          if (check_permission.length) {
            status = true;
          }

          return (
            <Col
              sm="3"
              key={permission.id}
              style={sadminCheck(permission.name)}
            >
              <div className="form-check form-switch " dir="ltr">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={id}
                  name={id}
                  data-id={permission.id}
                  onChange={e => props.handleChange(e, permission.name)}
                  checked={status}
                />
                <label className="form-check-label" htmlFor="customSwitch1">
                  {permission.name}
                </label>
              </div>
              {/* <FormGroup check inline>
                                <CustomInput type="switch" id={id} name={id} data-id={permission.id}
                                    label={permission.name}
                                    onChange={(e) => props.handleChange(e, permission.name)} checked={status} />
                            </FormGroup> */}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

PermissionGroupRow.propTypes = {
  role_permissions: PropTypes.any,
  permission_group: PropTypes.any,
  handleChange: PropTypes.any,
};

class CreateRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: {
        permissions: [],
        client: false,
      },
      is_client: false,
      permission_group: [],
      custom_div2: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      // console.log('dhfhf',this.props.match.params.id)
      this.fetchRoleDetails(this.props.match.params.id);
    }

    this.fetchPermissionGroups();
  }

  fetchRoleDetails = role_id => {
    getRoleDetails(role_id)
      .then(response => {
        let role = response.data.role;
        let permissions = response.data.permission.map(
          permission => permission.id
        );
        role["permissions"] = permissions;

        this.setState({
          role: role,
        });
      })
      .catch(err => {
        console.log(err);
        // window.alert(err.response.data.message);
        // window.location.href='/';
      });
  };

  fetchPermissionGroups = () => {
    checkStatus();
    getPermissionGroupList()
      .then(response => {
        // console.log("response", response.data)
        this.setState({
          permission_group: response.data,
        });
      })
      .catch(err => {
        console.log(err);
        // window.alert(err.response.data.message);
        // window.location.href='/';
      });
  };

  autoSearchInputChange = event => {
    event.preventDefault();
    const {
      target: { name, value },
    } = event;
    let roleCopy = Object.assign({}, this.state.role);
    roleCopy[name] = value;

    this.setState({
      role: roleCopy,
    });
  };

  handleChange = (e, name) => {
    let permissions = this.state.role.permissions;

    if (e.target.checked === true) {
      permissions.push(parseInt(e.target.dataset.id));
    } else {
      permissions = permissions.filter(function (permission) {
        return permission !== parseInt(e.target.dataset.id);
      });
    }

    let roleCopy = Object.assign({}, this.state.role);
    roleCopy["permissions"] = permissions;
    this.setState({
      role: roleCopy,
    });
  };

  onHandleSubmit = event => {
    event.preventDefault();

    const { id, name, permissions } = this.state.role;
    const { is_client } = this.state;

    if (name == "") {
      toastr.error("Enter Role Name ....");
    } else if (permissions == "") {
      toastr.error("Please select a Permission ....");
    } else {
      const data = {
        id: id,
        name: name,
        permissions: permissions,
        is_client: is_client,
      };
      console.log(data);
      createOrUpdateRole(id, data)
        .then((response) => {
          if (response.data.status === true) {
            this.setState({
              message: response.data.message,
              custom_div2: true
            })
            setTimeout(() => {
              this.props.history.push({
                pathname: '/role',
              });
            }, 2000)
          }
        })
        .catch((err) => {
          console.log(err);
          // this.props.handleErrorResponse(err);
          // window.alert(err.response.data.message);
          // window.location.href='/';
        });
    }
  };

  render() {
    const { message } = this.state;
    const { role, permission_group } = this.state;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Create Role | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid>
              <Breadcrumb title="Skote" breadcrumbItem="Role" />
              {message == "" || message == undefined || message == null ? (
                ""
              ) : // this.state.custom_div1==true &&
                this.state.custom_div2 ? (
                  <SweetAlert
                    title={message}
                    timeout={2000}
                    showConfirm={false}
                    showCloseButton={false}
                    success
                    onConfirm={() =>
                      this.setState({
                        custom_div2: false,
                        message: undefined,
                      })
                    }
                  ></SweetAlert>
                ) : null}
              <Row>
                <Col xs="12" sm="12">
                  <Form onSubmit={this.onHandleSubmit}>
                    <Card>
                      <CardHeader className="ms-3 bg-white">
                        <strong className="fs-4">Role Information</strong>
                        <small className="fs-6"> Form</small>
                      </CardHeader>
                      <CardBody>
                        <Row className="d-flex justify-content-center">
                          <Col xs="12" sm="6" md={6}>
                            <FormGroup row>
                              <Col md="2">
                                <Label
                                  htmlFor="name"
                                  className=" my-2 fs-5"
                                
                                >
                                  Role Name<sup className="text-danger">*</sup>
                                </Label>
                              </Col>
                              <Col xs="12" md="10">
                                <div className="d-flex justify-content-start">
                                  <div>
                                    <Input
                                      type="text"
                                      id="name"
                                      name="name"
                                      placeholder="Enter Role Name..."
                                      value={role.name || ""}
                                      required
                                      onChange={e =>
                                        this.autoSearchInputChange(e)
                                      }
                                    />
                                  </div>
                                  <div className="form-check form-switch form-switch-md mt-1 mx-5">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd"
                                      name="is_client"
                                      // onChange={this.handleCategoryInputChange}
                                      onClick={() => {
                                        this.setState({
                                          is_client: !this.state.is_client,
                                        });
                                      }}
                                      defaultChecked={this.state.is_client}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customSwitchsizemd"
                                    >
                                      {this.state.is_client
                                        ? "Client"
                                        : "Admin"}
                                    </label>
                                    <Input
                                      name="is_client"
                                      value={this.state.is_client}
                                      type="hidden"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>

                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            {/* <Card> */}
                              {/* <CardHeader> */}
                                <strong className="fs-4 text-primary">Permission Information</strong>
                                <small className="fs-6"> Assign Permission</small>
                              {/* </CardHeader> */}
                              {/* <CardBody> */}
                                {permission_group.map((permission, index) => (
                                  <PermissionGroupRow
                                    key={index}
                                    permission_group={permission}
                                    handleChange={this.handleChange}
                                    role_permissions={role.permissions}
                                  />
                                ))}
                              {/* </CardBody> */}
                            {/* </Card> */}
                          </Col>
                        </Row>
                      </CardBody>
                      <div className="d-flex justify-content-end py-2 px-2">
                        <Button type="submit" size="sm" color="primary">
                          <i className="fa fa-dot-circle-o" /> Submit
                        </Button>
                        <Link to="/role">
                          <Button
                            type="reset"
                            size="sm"
                            color="danger"
                            className="ms-2"
                          >
                            <i className="fa fa-ban" /> Cancel
                          </Button>
                        </Link>
                      </div>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}

CreateRole.propTypes = {
  handleErrorResponse: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  role: PropTypes.object,
};

const mapStatetoProps = state => {
  const { msg, error, success } = state.Role;
  return { msg, error, success };
};

export default withRouter(
  connect(mapStatetoProps, {
    createOrUpdateRole,
    getRoleDetails,
    getPermissionGroupList,
  })(CreateRole)
);
