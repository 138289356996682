import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Table, Container } from "reactstrap";
import { Link } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

// actions
import { getRoleList } from '../../store/auth/role/actions';
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch'
import { checkPermission, permissionEnum } from '../../services/service-provider';

// import DocumentTitle from 'react-document-title';
import { checkStatus } from "../../services/httpService";
import { CustomPagination } from "../Settings/Common/CustomPagination";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Fade from 'react-reveal/Fade';

function RoleRow(props) {
    const role = props.role;
    const roleLink = `/roles/${role.id}`;

    return (
        <tr key={role.id.toString()}>
            <td scope="row">{props.index}</td>
            <td>{role.name}</td>
            <td>{role.guard_name}</td>
            <td>{role.permissions_count}</td>
            {checkPermission(permissionEnum.ROLE_UPDATE) &&
                <td><Link to={roleLink} >
                    <i className="mdi mdi-pencil font-size-18 text-success"></i>
                </Link></td>
            }
        </tr>
    )
}

RoleRow.propTypes = {
    role: PropTypes.array,
    index: PropTypes.number
};


class Roles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            per_page: 15,
            search: '',
            data: {}
        };
    }

    fetchRoleList = () => {

        checkStatus();
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }

        getRoleList(options)
            .then((response) => {
                this.setState({
                    data: response.data
                })
            })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }
    componentDidMount() {
        this.fetchRoleList();
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchRoleList();
        });
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchRoleList();
        });
    }
    render() {

        let roleList = [];
        let paginator

        const { data, page, per_page } = this.state;

        if (data.hasOwnProperty('data')) {
            roleList = data.data;
            paginator = {
                'currentPage': data.current_page,
                'pagesCount': data.last_page,
                'total': data.total,
                'to': data.to,
                'from': data.from,
            };
        }

        return (
            <React.Fragment>
                <MetaTags>
                    <title>Role Info | Report Manager</title>
                </MetaTags>
                <Fade cascade>
                    <div className="page-content">
                        <Container fluid>

                            {/* Render Breadcrumbs */}
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">ROLES FOR USER</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Roles</li>
                                    </ol>
                                </div>
                            </div>
                            <Row>
                                <Col xl={12}>
                                    <div className='d-flex justify-content-between mb-3'>
                                        <div>
                                            <i className="fa fa-align-justify"></i> Roles <small className="text-muted">List</small>
                                        </div>
                                        <div>
                                            {checkPermission(permissionEnum.ROLE_CREATE) &&
                                                <Link to="/create/role">
                                                    <button className="btn btn-primary float-right"><i className="mdi mdi-plus-circle-outline me-1" />Create Role</button>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                    <Card>
                                        {/* <CardHeader>
                                     
                                    </CardHeader> */}
                                        <CardBody>
                                            <DataTabeSearch handleInputChange={this.handleInputChange} />
                                            <Table responsive striped className='table-bordered'>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Guard Name</th>
                                                        <th scope="col">Permissions</th>
                                                        {checkPermission(permissionEnum.ROLE_UPDATE) &&
                                                            <th scope="col">Action</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {roleList.map((role, index) =>
                                                        <RoleRow key={index} role={role} index={index + 1 + ((page - 1) * per_page)} />
                                                    )}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                        {paginator &&
                                            <CardFooter className="bg-white">
                                                <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                            </CardFooter>
                                        }
                                    </Card>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </Fade>
            </React.Fragment>
        );
    }
}



const mapStateToProps = state => {
    const { success, error } = state.Role;
    return { success, error };
}

export default withRouter(connect(mapStateToProps, { getRoleList })(Roles));

