import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
} from "./actionTypes";

import {getApi} from "../../services/httpService";

export function dashboardDataShow(){
    return getApi().get('dashboard/list');
}



export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getChartsData = (periodType) => ({
    type: GET_CHARTS_DATA,
    payload: periodType
});

