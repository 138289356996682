import { CREATE_FUND,EDIT_FUND,SHOW_FUND,FUND_SUCCESS,FUND_ERROR } from './actionTypes';


const initialState = {
    error: "",
    success : ""
}

const Fund = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_FUND:
            state = { ...state }
            break;
        case FUND_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case FUND_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Fund;
