import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types';

class Apaexlinecolumn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },

        colors: ["#52cca0", "#ff0000"],
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "April",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          title: {
            text: "$ (thousands)",
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands"
            },
          },
        },
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.props.monthCategoryIncomeChart}
          type="bar"
          height={350}
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}
Apaexlinecolumn.propTypes = {
  chartSeries: PropTypes.any,
  periodData: PropTypes.any,
  monthCategoryIncomeChart:PropTypes.any,
  customer:PropTypes.any,
}

export default Apaexlinecolumn
