import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, Label, Input, UncontrolledAlert, FormGroup, FormText } from "reactstrap";
// import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
import { checkPermission, permissionEnum } from '../../services/service-provider';

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toastr from "toastr";
// import '../ProductCategory/TreeDesign.scss';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Switch from 'react-switch';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { fundcategoryList, createFundCategory, fundCategoryTypeList, fundCategoryShow, editFundCategory, daleteFundCatagory } from '../../store/fundCategory/actions';

import { createCategory, categoryList, editCategory, categoryTreeList, showCategory, categoryShow, productCategoryImageUpload, daleteCatagory } from '../../store/category/actions';
import DeleteModal from "pages/Calendar/DeleteModal";
import MetaTags from 'react-meta-tags';
import { values } from 'lodash';
import Fade from 'react-reveal/Fade';

function CategoryRow(props) {
    const category = props.category;

    // const useractivityLink = `/users/activity`

    return (
        <tr key={category.toString() + 1}>
            <td scope="row">{props.index}</td>
            <td>{category.fund_category_code}</td>
            <td>{category.fund_category_name}</td>
            <td>{category.fund_type.fund_type_name}</td>
            {/* {checkPermission(permissionEnum.USER_UPDATE) && */}
            <td>
                <div className="d-flex gap-3">
                    <i className="mdi mdi-pencil font-size-18 text-success" onClick={() => { props.tog_scroll(category) }}></i>
                    <i
                        className="mdi mdi-delete font-size-18 text-danger"
                        id="deletetooltip"
                        onClick={() => {
                            props.toggleDeleteModal();
                            props.onClickDelete(category);
                        }
                        }
                    ></i>
                </div>
            </td>
            {/* } */}
        </tr>
    )
}

CategoryRow.propTypes = {
    tog_scroll: PropTypes.any,
    index: PropTypes.any,
    category: PropTypes.any,
    toggleDeleteModal: PropTypes.func,
    onClickDelete: PropTypes.func,
};

class FundCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            status: true,
            parentCategory: 0,
            selectedOptions: {},
            category_icon: {},
            category: {

            },
            deleteModal: false,
        };
        // this.onClickDelete = this.onClickDelete.bind(this);
        this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchFundCategoryList();
        this.fetchFundTypeList();
        this.fetchCategoryTreeList();
    }

    fetchFundCategoryList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        fundcategoryList(options).then((response) => {
            this.setState({
                categoryList: response.data,
            });

        })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })
    }

    fetchFundTypeList = () => {

        fundCategoryTypeList().then((response) => {
            // console.log("categoryTypeList",response.data)
            this.setState({

                categoryTypeList: response.data,
            });

        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchCategoryTreeList = () => {
        categoryTreeList().then((response) => {
            this.setState({
                rootCategories: response.data.rootCategories,
                categoryTree: response.data.childTree,
            });
        })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })
    }

    fetchFundCategoryDetails = (category_id) => {
        // console.log("category_id",category_id)
        fundCategoryShow(category_id).then((response) => {
            // console.log("fetchFundCategoryDetails",response);
            this.setState({
                categorySingle: response.data.FundCategory,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (category) => {
        console.log('category', category);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.setState({
            categorySingle: category
        })
        // this.fetchFundCategoryDetails(category_id);
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    handleImageUpload = (e) => {
        var id = e.target.id;
        var category_id = this.state.category.category_id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type, category_id: category_id };

            productCategoryImageUpload(formData, formData.category_id).then((response) => {
                this.fetchFundCategoryList();
                this.setState({
                    category_icon: response.data.productCategoryImage.category_icon,
                });
                // alert('Logo Uploaded Successfully');
            })
                .catch((err) => {
                    console.log(err);
                    // window.alert(err.response.data.message);
                    // window.location.href='/';
                })
        }
    }

    handleCategoryInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;

        this.updateUserObject(name, value);
    }

    updateUserObject = (name, value, callback = null) => {
        let categoryInfoCopy = Object.assign({}, this.state.categoryInfo);
        categoryInfoCopy[name] = value;

        this.setState({
            categoryInfo: categoryInfoCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }


    onHandleSubmit(values) {
        // event.preventDefault();
        const data = {
            f_fund_type_id: values.f_fund_type_id,
            fund_category_code: values.fund_category_code,
            fund_category_name: values.fund_category_name
        }
        // console.log('values', values);


        // event.target.reset();

        this.fetchFundCategoryList();

    }

    handleEditSubmit(event) {
        event.preventDefault();
        const { target: { name, value } } = event;
        // console.log("ssss", event.target[1].name)

        // alert(value)
        const data = {
            category_name_en: event.target[0].value,
            category_id: event.target[1].value
        }
        this.props.editFundCategory(data);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchFundCategoryList();

        // alert("Category Updated Successfully");
        // this.form && this.form.reset();
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchFundCategoryList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault();
        this.setState({
            page: index
        }, () => {
            this.fetchFundCategoryList();
        });
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };

    onClickDelete = (categoryInfo) => {
        // console.log(categoryInfo)
        this.setState({ deleteCategory: categoryInfo });
        this.setState({ deleteModal: true });
    };

    handleDeleteCategory = () => {
        const { deleteCategory } = this.state;
        // console.log("deleteCategory", deleteCategory)

        if (deleteCategory !== undefined) {
            daleteFundCatagory(deleteCategory.fund_category_id).then(response => {
                this.setState({
                    message: response.data
                })
                this.fetchFundCategoryList();
            });
            // setTimeout(function () {
            //     window.location.reload();
            // }, 2000);
            this.setState({ deleteModal: false }), () => {
                this.fetchFundCategoryList();
            };


        }
    };

    handleChange(status) {
        this.setState({ status });
    }

    // handleDeleteSubmit(values) {
    //     daleteCatagory(values)
    //         .then((response) => {
    //             if (response.data.status == true) {
    //                 //   console.log(response)
    //                 // alert(response.data.message);
    //                 this.fetchFundCategoryList();
    //                 // this.fetchCategoryTreeList();
    //                 // window.location.reload();
    //             }
    //             else if (response.data.status == false) {
    //                 toastr.error(response.data.message);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             // this.props.handleErrorResponse(err);
    //             //  console.log('err', this.props.handleErrorResponse(err));
    //         });
    // }

    render() {
        let categoryList = [];
        let categoryTypeList = [];
        let categoryTree = [];
        let paginator;
        if (this.state.hasOwnProperty('categoryList')) {
            categoryList = this.state.categoryList.data;
            // console.log(categoryList)
            paginator = {
                'currentPage': this.state.categoryList.current_page,
                'pagesCount': this.state.categoryList.last_page,
                'total': this.state.categoryList.total,
                'to': this.state.categoryList.to,
                'from': this.state.categoryList.from,
            };
        }
        if (this.state.hasOwnProperty('categoryTypeList')) {
            categoryTypeList = this.state.categoryTypeList;

        }
        // if (this.state.hasOwnProperty('categorySingle')) {
        //     console.log("this.state.categorySingle",this.state.categorySingle.fund_type.fund_type_name)

        // }


        const { deleteModal, message } = this.state;
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        if (this.state.hasOwnProperty('categoryTree')) { categoryTree = this.state.categoryTree; }
        // const { fund_category_id, fund_category_name, fund_category_code, f_fund_type_id } = this.state.categorySingle;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        // console.log("this.props.success", this.props.success)

        const treeStyle = { background: '#2d98f8', color: 'white', borderRadius: '8px', padding: '4px', fontFamily: 'arial', fontSize: '16px' };


        return (
            <>
                <MetaTags>
                    <title>Category Info | Report Manager</title>
                </MetaTags>
                <Fade cascade>
                    <div className="page-content">
                        <Container fluid={true}>

                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">FUND CATEGORY</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Fund Category</li>
                                    </ol>
                                </div>
                            </div>
                            <DeleteModal
                                show={deleteModal}
                                onDeleteClick={this.handleDeleteCategory}
                                onCloseClick={() => this.setState({ deleteModal: false })}
                            />
                            {/* <div>
                            {message == "" || message == undefined || message == null ?
                                "" : <UncontrolledAlert
                                    color="success"
                                    className="alert-dismissible fade show"
                                    role="alert"
                                >
                                    <i className="mdi mdi-check-all me-2"></i>
                                    {message}
                                </UncontrolledAlert>}
                        </div> */}

                            <Row>

                                <Col lg="9" md="9">
                                    <Card >
                                        <CardBody>
                                            <CardTitle >Fund Category List</CardTitle>

                                            <div className="col-md-12" >
                                                {/* <OptionsList
                                                options={categoryTree}
                                                onChange={(selectedOptions) => this.setState({ selectedOptions })}
                                                selectedOptions={this.state.selectedOptions}
                                            /> */}
                                                <DataTabeSearch handleInputChange={this.handleInputChange} />
                                                {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                                {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                                                {this.props.msg && this.props.msg ? (
                                                    <Alert color="success">
                                                        Category Created Successfully!
                                                    </Alert>
                                                ) : null}
                                                <Table responsive striped className='table-bordered'>
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">S.No</th>
                                                            <th scope="col">Fund Category Code </th>
                                                            <th scope="col">Category Name </th>
                                                            <th scope="col">Fund Category Type </th>
                                                            {/* <th scope="col">Date</th> */}
                                                            {/* <th scope="col">activity</th> */}
                                                            {checkPermission(permissionEnum.USER_UPDATE) &&
                                                                <th scope="col">Action</th>
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {categoryList.map((category, index) =>
                                                            <CategoryRow key={index} category={category} tog_scroll={this.tog_scroll} toggleDeleteModal={this.toggleDeleteModal} onClickDelete={this.onClickDelete} index={index + 1 + ((this.state.page - 1) * this.state.per_page)} />
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                        {paginator &&
                                            <CardFooter className="bg-white">
                                                <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                            </CardFooter>
                                        }
                                    </Card>
                                    <Modal
                                        isOpen={this.state.modal_scroll}
                                        toggle={this.tog_scroll}
                                        scrollable={true}
                                    >
                                        <div className="modal-header">
                                            <h5 className="modal-title mt-0 ">Fund Category Edit</h5>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    this.setState({ modal_scroll: false })
                                                }
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">

                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    fund_category_code: this.state.categorySingle && this.state.categorySingle.fund_category_code || "",
                                                    fund_category_name: this.state.categorySingle && this.state.categorySingle.fund_category_name || "",
                                                    f_fund_type_id: this.state.categorySingle && this.state.categorySingle.f_fund_type_id || "",
                                                    fund_category_id: this.state.categorySingle && this.state.categorySingle.fund_category_id || "",
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    fund_category_name: Yup.string().required(
                                                        "Please Enter Fund Category Name"
                                                    ),
                                                    fund_category_code: Yup.string().required(
                                                        "Please Enter Fund Category Code"
                                                    ),
                                                    f_fund_type_id: Yup.string().required(
                                                        "Please Select Fund Category Type"
                                                    )

                                                })}
                                                onSubmit={values => {
                                                    // console.log("onSubmit",values);
                                                    this.props.editFundCategory(values);
                                                    this.setState(prevState => ({
                                                        modal_scroll: !prevState.modal_scroll,
                                                    }));
                                                    this.fetchFundCategoryList();
                                                    // this.props.loginUser(values, this.props.history);
                                                }}
                                            >
                                                {({ errors, status, touched, handleChange }) => (
                                                    <Form className="form-horizontal">


                                                        <Field type="hidden" className="form-control" name="fund_category_id"></Field>

                                                        <div className="mb-3">
                                                            <Label for="fund_category_code" className="form-label">
                                                                Fund  Category Code<sup className="text-danger">*</sup>
                                                            </Label>
                                                            <Field
                                                                id="fund_category_code"
                                                                name="fund_category_code"
                                                                placeholder="Enter Fund Category Code"
                                                                type="text"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.fund_category_code && touched.fund_category_code
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="fund_category_code"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label for="category_name" className="form-label">
                                                                Fund Category Name<sup className="text-danger">*</sup>
                                                            </Label>
                                                            <Field
                                                                id="fund_category_name"
                                                                name="fund_category_name"
                                                                placeholder="Enter Category Name"
                                                                type="text"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.fund_category_name && touched.fund_category_name
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="fund_category_name"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="col-form-label">Fund Category Type<sup className="text-danger">*</sup></label>
                                                            <select name="f_fund_type_id" id="f_fund_type_id"
                                                                className={"form-select"
                                                                    +
                                                                    (errors.f_fund_type_id && touched.f_fund_type_id
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                                value={values.f_fund_type_id}
                                                                onChange={handleChange}
                                                            >
                                                                <option>Select</option>
                                                                {categoryTypeList && categoryTypeList.map((client, index) =>
                                                                    <option value={client.fund_type_id} key={index} selected>{client.fund_type_name}</option>
                                                                )}
                                                            </select>
                                                            <ErrorMessage
                                                                name="f_fund_type_id"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>


                                                        {/* <div
                                                        className="form-check form-switch form-switch-md mb-3"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customSwitchsizemd"
                                                            name="status"
                                                            // onChange={this.handleCategoryInputChange}
                                                            onClick={() => {
                                                                this.setState({
                                                                    status: !this.state.status,
                                                                })
                                                            }}
                                                            defaultChecked={this.state.status}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizemd"
                                                        >
                                                            {this.state.status ? ' Is Active' : ' Is Inactive'}
                                                        </label>
                                                        <Field name="status" value={this.state.status} type="hidden" />
                                                    </div> */}
                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                onClick={() =>
                                                                    this.setState({ modal_scroll: false })
                                                                }
                                                            >
                                                                Close
                                                            </button>
                                                            <button type="submit" className="btn btn-primary">
                                                                Save changes
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </Modal>
                                </Col>

                                <Col lg="3" md="3">
                                    <Card className=" shadow rounded-5">
                                        <CardBody>
                                            <CardTitle className="mb-3 text-primary">Create Fund Category</CardTitle>

                                            {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                                        {this.props.msg && this.props.msg ? (
                                            <Alert color="success">
                                                Category Created Successfully!
                                            </Alert>
                                        ) : null} */}
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    fund_category_code: this.state.fund_category_code || "",
                                                    fund_category_name: this.state.fund_category_name || "",
                                                    f_fund_type_id: this.state.f_fund_type_id || "",
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    fund_category_name: Yup.string().required(
                                                        "Please Enter Fund Category Name"
                                                    ),
                                                    fund_category_code: Yup.string().required(
                                                        "Please Enter Fund Category Code"
                                                    ),
                                                    f_fund_type_id: Yup.string().required(
                                                        "Please Select Fund Category Type"
                                                    )

                                                })}
                                                onSubmit={values => {
                                                    // console.log(values);
                                                    this.props.createFundCategory(values)
                                                    // .then((response) => {
                                                    // // console.log('response', response);

                                                    //   if (response.data.status === true) {
                                                    //   //   this.props.history.push({
                                                    //   //     pathname: '/users',
                                                    //   //     state: { message: response.data.message }
                                                    //   //   });
                                                    //   }
                                                    // })
                                                    // .catch((err) => {
                                                    //   console.log(err);
                                                    //   // this.props.handleErrorResponse(err);
                                                    // });                                               
                                                    this.fetchFundCategoryList();
                                                    // this.props.loginUser(values, this.props.history);
                                                }}
                                            >
                                                {({ errors, status, touched, handleChange }) => (
                                                    <Form className="form-horizontal">


                                                        <div className="mb-3">
                                                            <Label for="fund_category_code" className="form-label text-primary">
                                                                Fund  Category Code<sup className="text-danger">*</sup>
                                                            </Label>
                                                            <Field
                                                                id="fund_category_code"
                                                                name="fund_category_code"
                                                                placeholder="Enter Fund Category Code"
                                                                type="text"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.fund_category_code && touched.fund_category_code
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="fund_category_code"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label for="category_name" className="form-label text-primary">
                                                                Category Name<sup className="text-danger">*</sup>
                                                            </Label>
                                                            <Field
                                                                id="fund_category_name"
                                                                name="fund_category_name"
                                                                placeholder="Enter Category Name"
                                                                type="text"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.fund_category_name && touched.fund_category_name
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="fund_category_name"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="col-form-label text-primary">Fund Category Type<sup className="text-danger ">*</sup></label>
                                                            <select name="f_fund_type_id" id="f_fund_type_id"
                                                                //  onChange={this.handleInputChange} 

                                                                value={values.f_fund_type_id}
                                                                onChange={handleChange}
                                                                className={"form-select"
                                                                    +
                                                                    (errors.f_fund_type_id && touched.f_fund_type_id
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            >
                                                                <option >Select</option>
                                                                {categoryTypeList != undefined && categoryTypeList.map((client, index) =>
                                                                    <option value={client.fund_type_id} key={index}>{client.fund_type_name}</option>
                                                                )}
                                                                {/* {clientGroup.map((client, index) =>
                                                                <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                                            )} */}
                                                            </select>
                                                            <ErrorMessage
                                                                name="f_fund_type_id"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>


                                                        {/* <div
                                                        className="form-check form-switch form-switch-md mb-3"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customSwitchsizemd"
                                                            name="status"
                                                            // onChange={this.handleCategoryInputChange}
                                                            onClick={() => {
                                                                this.setState({
                                                                    status: !this.state.status,
                                                                })
                                                            }}
                                                            defaultChecked={this.state.status}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customSwitchsizemd"
                                                        >
                                                            {this.state.status ? ' Is Active' : ' Is Inactive'}
                                                        </label>
                                                        <Field name="status" value={this.state.status} type="hidden" />
                                                    </div> */}
                                                        <div className="mt-3">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary btn-block"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Fade>
            </>
        );
    }
}

FundCategory.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    msg: PropTypes.any,
    handleErrorResponse: PropTypes.any,
    createFundCategory: PropTypes.any,
    editFundCategory: PropTypes.any,


};


const mapStatetoProps = state => {
    const { error, success } = state.FundCategory;
    return { error, success };
}

export default withRouter(connect(mapStatetoProps, { createCategory, editCategory, showCategory, daleteCatagory, createFundCategory, editFundCategory })(FundCategory));
