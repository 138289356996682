import React, { Component } from 'react'
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table, FormGroup, Label, Input, InputGroup, Form, Modal, CardFooter } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import { DataTabeSearch } from '../Settings/Common/DataTabeSearch'
import { CustomPagination } from "../Settings/Common/CustomPagination";
import MetaTags from 'react-meta-tags';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fade from 'react-reveal/Fade';
import moment from 'moment';
import DeleteModal from 'pages/Calendar/DeleteModal';
import { deleteLoan, editLoan, getLoanList } from 'store/Loan/actions';

class Loan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      per_page: 10,
      search: '',
      modal_scroll: false,
      deleteModal: false,
      client: [],
      showlist: 0,
      custom_div1: false,
      custom_div2: false,
      editData: {},
      short: false,
      sortData: '',
      sortType: 'asc'
    };
  }

  fetchLoanList = (sortField, sortType) => {
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search,
      sort_by_field: sortField,
      sort_type: sortType ? sortType : this.state.sortType,
    }
    getLoanList(options).then((response) => {
      this.setState({
        loanData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        // window.location.href = '/';
      })
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = (loanData) => {
    this.setState({ deleteLoanData: loanData });
    this.setState({ deleteModal: true });
  };

  handleDeleteLoan = () => {
    const { deleteLoanData } = this.state;
    if (deleteLoanData.loan_id !== undefined) {
      deleteLoan(deleteLoanData.loan_id).then(response => {
        console.log(response);
        this.setState({
          message: response.data
        })
        this.fetchLoanList();
      });
      this.setState({
        deleteModal: false,
        custom_div2: true,
      });
    }
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.fetchLoanList();
    });
  }

  handlePageClick = (e, index) => {
    e.preventDefault()
    this.setState({
      page: index
    }, () => {
      this.fetchLoanList();
    });
  }
  handleSort = (data) => {
    this.setState({
      sortData: data
    })
    if (this.state.sortType === "asc") {
      this.setState({ sortType: "desc" })
    }
    else {
      this.setState({ sortType: "asc" })
    }
    this.fetchLoanList(data, this.state.sortType === "asc" ? "desc" : "asc")
  }

  componentDidMount() {
    this.fetchLoanList();
  }

  render() {
    let paginator;
    let loanList = [];
    let clientGroup = [];
    let clientInfoList = [];
    let totalOrginalAmount = 0;
    let totalInterestAmount = 0;
    if (this.state.hasOwnProperty('clientData')) {
      clientGroup = this.state.clientData.data;
    }
    if (this.state.hasOwnProperty('clientInfoData')) {
      clientInfoList = this.state.clientInfoData;
    }
    if (this.state.hasOwnProperty('loanData')) {
      loanList = this.state.loanData.data;
      paginator = {
        'currentPage': this.state.loanData.current_page,
        'pagesCount': this.state.loanData.last_page,
        'total': this.state.loanData.total,
        'to': this.state.loanData.to,
        'from': this.state.loanData.from,
      };
    }
    for (const value of loanList) {
      totalOrginalAmount += value.original_amount;
      totalInterestAmount += value.interest_rate_percentage;
    }
    // console.log(this.state.sortData);
    const total = totalOrginalAmount.toFixed(2)
    return (
      <React.Fragment>
        <MetaTags>
          <title>Loan List | Report Manager</title>
        </MetaTags>
        <DeleteModal
          show={this.state.deleteModal}
          onDeleteClick={this.handleDeleteLoan}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Loan List</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Loan List</li>
                  </ol>
                </div>
              </div>
              <Row>
                <Col xl={12}>
                  <div className='d-flex justify-content-start mb-3'>
                    <div>
                      <Link to="/loanList/create">
                        <button className="btn btn-primary"><i className="mdi mdi-plus-circle-outline me-1" />Create Loan</button>
                      </Link>
                      <Link to="/loanList/multiple/create">
                        <button className="btn btn-primary mx-2"><i className="mdi mdi-plus-circle-outline me-1" />Create Multiple Loan</button>
                      </Link>
                      <Link to="/payment/multiple/create">
                        <button className="btn btn-primary mx-2"><i className="mdi mdi-plus-circle-outline me-1" />Create Multiple Payment</button>
                      </Link>
                      <Link to="/business-overview">
                        <button className="btn btn-primary mx-2"><i className="mdi mdi-plus-circle-outline me-1" />Business Debt Overview </button>
                      </Link>
                      <Link to="/business-schedule">
                        <button className="btn btn-primary mx-2"><i className="mdi mdi-plus-circle-outline me-1" />Business Debt Schedule </button>
                      </Link>
                    </div>
                  </div>
                  <div>
                  </div>
                  <Card>
                    <CardBody>
                      <DataTabeSearch handleInputChange={this.handleInputChange} />
                      <Table responsive striped className='table-bordered'>
                        <thead className="table-light">
                          <tr>
                            <th scope="col" className='d-flex'>S.No</th>
                            <th scope="col">Company Name <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("company_name")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("company_name")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                            {/* <th scope="col">Group Name</th> */}
                            <th scope="col">Loan Origination Date <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("original_loan_date")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("original_loan_date")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                            <th scope="col">Maturity Date <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("maturity_date")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("maturity_date")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                            <th scope="col">Due Date <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("loan_due_date")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("loan_due_date")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                            <th scope="col">Loan Account Number <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("loan_ac_no")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("loan_ac_no")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                            <th scope="col">Creditor <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("creditor")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("creditor")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                            <th scope="col">Original Amount <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("original_amount")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("original_amount")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                            <th scope="col">Interest Rate <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("interest_rate_percentage")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("interest_rate_percentage")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                            {/* <th scope="col">Loan Code</th> */}
                            <th scope="col">Remarks <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("taken_for_remarks")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("taken_for_remarks")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                            <th scope="col">Payments</th>
                            <th scope="col">Action</th>

                          </tr>
                        </thead>
                        <tbody>
                          {loanList?.map((loanData, index) =>
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{loanData.client_info == null || loanData.client_info == undefined ? '' : loanData.client_info.name}</td>
                              {/* <td>{loanData.client_group == null || loanData.client_group == undefined ? '' : loanData.client_group.name}</td> */}
                              <td> {moment(new Date(loanData.original_loan_date)).format("YYYY-MM-DD")}</td>
                              <td> {moment(new Date(loanData.maturity_date)).format("YYYY-MM-DD")}</td>
                              <td> {moment(new Date(loanData.next_loan_due_date)).format("YYYY-MM-DD")}</td>
                              <td>{loanData.loan_ac_no == null || loanData.loan_ac_no == undefined ? '' : loanData.loan_ac_no}</td>
                              <td>{loanData.creditor == null || loanData.creditor == undefined ? '' : loanData.creditor}</td>
                              <td>${loanData.original_amount == null || loanData.original_amount == undefined ? '' : (loanData.original_amount.toLocaleString())}</td>
                              <td>{loanData.interest_rate_percentage == null || loanData.interest_rate_percentage == undefined ? '' : loanData.interest_rate_percentage}%</td>
                              {/* <td>{loanData.loan_code == null || loanData.loan_code == undefined ? '' : loanData.loan_code}</td> */}
                              <td>{loanData.taken_for_remarks == null || loanData.taken_for_remarks == undefined ? '' : loanData.taken_for_remarks}</td>
                              <td> <Link to={`loanList/payment/${loanData.loan_id}`}> <p className='d-flex justify-content-center mt-2'><i className="fa-regular fa-money-bill-1 font-size-18"></i></p> </Link> </td>
                              <td>
                                <div className='d-flex gap-3'>
                                  <Link to={`loanList/details/${loanData.loan_id}`}>
                                    <i className="mdi mdi-eye font-size-18 text-success"></i>
                                  </Link>
                                  <i className="mdi mdi-delete font-size-18 text-danger"
                                    onClick={() => {
                                      this.toggleDeleteModal();
                                      this.onClickDelete(loanData);
                                    }
                                    }
                                    id="deletetooltip"> </i>
                                </div>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td className='fw-bold'>Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='fw-bold'>${totalOrginalAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                    {paginator &&
                      <CardFooter className="bg-white py-0">
                        <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                      </CardFooter>
                    }
                  </Card>
                  {/* <Modal
                            isOpen={this.state.modal_scroll}
                            toggle={this.tog_scroll}
                            scrollable={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">
                                    Edit Transaction
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </Modal> */}
                </Col>
              </Row>
            </Container>
          </div>
        </Fade>
        {/* <Modal
          isOpen={this.state.modal_scroll}
          toggle={this.tog_scroll}
          scrollable={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              Edit Loan
            </h5>
            <button
              type="button"
              onClick={() =>
                this.setState({ modal_scroll: false })
              }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={this.onHandleSubmit}>
              <Row>
                <Col xs="12" sm="12" md={6}>
                  <FormGroup>
                    <label className="col-form-label">Client Group Name</label>
                    <select className="form-select" name="f_client_group_id" defaultValue={this.state.editData.f_client_group_id} id="f_group_id" onChange={this.handleInputChange} required>
                      {clientGroup.map((client, index) =>
                        <option value={client.cleint_group_id} key={index}>{client.name}</option>
                      )}
                    </select>
                  </FormGroup>

                  <FormGroup>
                    <label className="col-form-label">Client Info Name</label>
                    <select className="form-select" defaultValue={this.state.editData.f_client_customer_info_id} name="f_client_customer_info_id" id="f_client_id" onChange={this.handleInputChange} required>
                      {clientInfoList && clientInfoList.map((clientInfo, index) =>
                        <option value={clientInfo.id} key={index}>{clientInfo.name}</option>
                      )}

                    </select>
                  </FormGroup>
                  <FormGroup>
                    <Label>Loan Date</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        name='orginal_loan_date'
                        options={{ allowInput: true }}
                        defaultValue={this.state.editData.original_loan_date}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label>Loan Closing Date</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        name='maturity_date'
                        options={{ allowInput: true }}
                        defaultValue={this.state.editData.maturity_date}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label>Creditor</Label>
                    <InputGroup>
                      <Input defaultValue={this.state.editData.creditor} type="text" id="creditor" name="creditor" className=' text-primary' required />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label>Orginal Amount</Label>
                    <InputGroup>
                      <Input defaultValue={this.state.editData.original_amount} type="number" id="orginal_amount" name="orginal_amount" className=' text-primary' required />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label>Loan Account Number</Label>
                    <InputGroup>
                      <Input defaultValue={this.state.editData.loan_ac_no} type="text" id="loan_ac_no" name="loan_ac_no" className=' text-primary' required />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup >
                    <Label htmlFor="name">Interest Rate Percentage</Label>
                    <Input defaultValue={this.state.editData.interest_rate_percentage} type="number" id="interest_rate_percentage" name="interest_rate_percentage" className='text-primary' required />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="12" md={6} className="mt-2">
                  <FormGroup >
                    <Label htmlFor="name">Interest Rate Amount</Label>
                    <Input defaultValue={this.state.editData.interest_rate_amount} type="number" id="transaction_amount" name="interest_rate_amount" className='text-primary' required />
                  </FormGroup>
                  <FormGroup >
                    <Label htmlFor="name">Loan Code</Label>
                    <Input defaultValue={this.state.editData.loan_code} type="text" id="transaction_amount" name="loan_code" className='text-primary' required />
                  </FormGroup>
                  <FormGroup >
                    <Label htmlFor="name">Monthly Payment</Label>
                    <Input defaultValue={this.state.editData.monthly_payment} type="number" id="monthly_payment" name="monthly_payment" className='text-primary' required />
                  </FormGroup>
                  <FormGroup >
                    <Label htmlFor="name">Collateral/Security</Label>
                    <Input defaultValue={this.state.editData.collateral_or_security} type="text" id="transaction_amount" name="collateral_or_security" className='text-primary' required />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="Address">Remarks</Label>
                    <Input type="textarea" id="remarks" defaultValue={this.state.editData.taken_for_remarks} name="taken_for_remarks" rows={5}
                      placeholder="Enter Remarks.." />
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() =>
                    this.setState({ modal_scroll: false })
                  }
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </Form>
          </div>
        </Modal> */}
      </React.Fragment>
    )
  }
}
export default Loan;