import { CLIENT_ERROR,CLIENT_SUCCESS,EDIT_CLIENT,SHOW_CLIENT,CREATE_CLIENT} from './actionTypes';
import {getApi} from "../../services/httpService";


export const editClient= (clientGroup) => {
    return {
        type: EDIT_CLIENT,
        payload: { clientGroup }
    }
}

export const clientGroupSuccess = (response) => {
    return {
        type: CLIENT_SUCCESS,
        payload: response.data.message
    }
}

export const clientGroupError = (error) => {
    return {
        type: CLIENT_ERROR,
        payload: error
    }
}

export function getClientList(params) {
    return getApi().get('clientGroup/list', {
        params: params
    })
}


export function getPublicClientList(params) {
    return getApi().get('clientGroup/public/list', {
        params: params
    })
}
export function getClientSingleList(id) {
    return getApi().get('clientGroup/'+id);
}

export function createClient(data) {
        return getApi().post('clientGroup/create', data);
}

export function deleteClient(id) {
    return getApi().put('clientGroup/delete/'+ id);
}


