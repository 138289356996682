import React, { Component } from 'react';
import PropTypes from "prop-types";
import classnames from "classnames";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, Form, CardHeader, CardFooter, NavItem, TabContent, TabPane } from "reactstrap";

import { Link, NavLink } from "react-router-dom";
import toastr from "toastr";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import { getUserDetails, createOrUpdateUser, getRoleList, getClientGroup } from '../../store/auth/profile/actions';
import { getClientList, getClientSingleList, editClient, getPublicClientList } from '../../store/clients/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fade from 'react-reveal/Fade';

class UpdateUserProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {
                roles: [],
                status: 1
            },
            all_roles: [],

            activeTab: "1",
            custom_div2: false
        };
        this.toggleTab = this.toggleTab.bind(this);
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }
    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchUserDetails(this.props.match.params.id);
        }
        this.fetchUserRoles();
        this.fetchClientGroupList();
    }


    fetchGetClientSingleList = (id) => {
        getClientSingleList(id).then((response) => {
            console.log("response ", response.data.ClientGroup);
            this.setState({
                clientSingleData: response.data,
            })
            this.setState({
                name: this.state.clientSingleData.ClientGroup.name,
                email: this.state.clientSingleData.ClientGroup.email,
                address: this.state.clientSingleData.ClientGroup.address,
                eiin: this.state.clientSingleData.ClientGroup.eiin,
                mobile: this.state.clientSingleData.ClientGroup.phone_number,
                f_client_group: this.state.clientSingleData.ClientGroup.cleint_group_id,
            })
        })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })

    }
    fetchClientGroupList = () => {
        getPublicClientList().then((response) => {
            this.setState({
                clientData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })

    }
    fetchSingleClientInfo = (id) => {
        getClientGroup(id).then(response => {
            this.setState({
                clientGroup: response.data,
            })
        })
    }
    fetchUserDetails = (user_id) => {
        getUserDetails(user_id)
            .then((response) => {

                let user = response.data
                let f_user_type = response.data.f_user_type
                if (f_user_type == "2") {
                    this.setState({ activeTab: "2" })
                    this.fetchGetClientSingleList(response.data.f_client_id);
                    this.setState({
                        eiin: response.data.eiin,
                        id: response.data.id
                    })
                }
                else if (f_user_type == "1") {
                    this.setState({ activeTab: "1" })
                }
                let roles = user.roles.map((role) => role.id);
                user["roles"] = roles;
                this.setState({
                    user: user
                });
            })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })
    };

    fetchUserRoles = () => {

        getRoleList()
            .then((response) => {
                this.setState({
                    all_roles: response.data.data
                })
            })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })
    }

    updateUserObject = (name, value, callback = null) => {
        let userCopy = Object.assign({}, this.state.user);
        userCopy[name] = value;

        this.setState({
            user: userCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }

    switchInputChange = (event) => {
        event.preventDefault();

        let name = event.target.name;
        let value = event.target.checked;
        this.updateUserObject(name, value);

    }

    handleInputChange = (event) => {
        event.preventDefault();
        // if(event.target.name == "f_client_group_id"){
        //   this.fetchSingleClientInfo(event.target.value);
        // }
        if (event.target.name == "f_client_group_id") {
            this.fetchGetClientSingleList(event.target.value);
        }

        if (event.target.name == 'email') {
            this.setState({ email: event.target.value });
        }
        if (event.target.name == 'mobile') {
            this.setState({ mobile: event.target.value });
        }
        if (event.target.name == 'eiin') {
            this.setState({ eiin: event.target.value });
        }
        const { target: { name, value } } = event;

        this.updateUserObject(name, value);
    }

    handleCounterChange = (item) => {
        this.updateUserObject('counter', item, () => {
            this.updateCoachObject('counter_id', item !== null ? item.value : 0);
        });
    };


    onHandleSubmit = (event) => {
        event.preventDefault();
        if (this.state.activeTab == "1") {
            const { id, name, mobile, email, password, password_confirmation, f_employee_id, f_wings_id, counter_id, roles, image, status } = this.state.user;
            if (name == "") {
                toastr.error("Enter User Name ....");
            }
            else if (mobile == "") {
                toastr.error("Enter User Number / EIN ....");
            }
            else if (roles == "") {
                toastr.error("Please Select a Role");
            }
            else {

                const data = {
                    name: name,
                    mobile: mobile,
                    email: email,
                    image: image,
                    counter_id: counter_id,
                    status: status,
                    roles: roles,
                    f_client_type: 1,

                };

                createOrUpdateUser(id, data)
                    .then((response) => {
                        if (response.data.status === true) {
                            this.setState({
                                message: response.data.message,
                                custom_div2: true
                            })
                            setTimeout(() => {
                                this.props.history.push({
                                    pathname: '/users',
                                });
                            }, 2000)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        // this.props.handleErrorResponse(err);
                        // window.alert(err.response.data.message);
                        // window.location.href='/';

                    });
            }
        }
        else if (this.state.activeTab == "2") {
            // console.log('v', this.state.activeTab)
            const { id, name, mobile, email } = this.state;
            const { roles, status, eiin } = this.state.user;

            if (name == "") {
                toastr.error("Enter User Name ....");
            }
            else if (mobile == "") {
                toastr.error("Enter Phone Number / EIN ....");
            }
            else if (roles == "") {
                toastr.error("Please Select a Role");
            }
            else {

                const data = {
                    name: name,
                    mobile: mobile,
                    // password: password,
                    email: email,
                    eiin: eiin,
                    // password_confirmation: password_confirmation,
                    status: status,
                    roles: roles,
                    f_client_type: 2,

                };
                console.log('data', data);
                createOrUpdateUser(id, data)
                    .then((response) => {
                        if (response.data.status === true) {
                            this.setState({
                                message: response.data.message,
                                custom_div2: true
                            })
                            setTimeout(() => {
                                this.props.history.push({
                                    pathname: '/users',
                                });
                            }, 2000)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        // this.props.handleErrorResponse(err);
                        // window.alert(err.response.message);
                        // window.location.href = '/';

                    });
            }
        }

    };

    handleChange = (e) => {
        let roles = this.state.user.roles;

        if (e.target.checked === true) {
            roles.push(parseInt(e.target.dataset.id));
        } else {
            roles = roles.filter(function (role) {
                return role !== parseInt(e.target.dataset.id);
            });
        }

        let userCopy = Object.assign({}, this.state.user);
        userCopy["roles"] = roles;
        this.setState({
            user: userCopy
        });

    }

    getRoleInput = (role) => {

        const { user } = this.state;

        let check_role = user.roles.filter((role_id) => {
            return role.id === role_id
        })

        let status = false
        if (check_role.length) {
            status = true
        }

        return (
            <div
                className="form-check form-switch mb-3"
                dir="ltr"
            >
                <input
                    type="checkbox"
                    className="form-check-input"
                    id={role.id} name={role.id} data-id={role.id}
                    onChange={(e) => this.handleChange(e)} checked={status}
                />
                <label
                    className="form-check-label"
                    htmlFor="customSwitch1"
                >
                    {role.name.toUpperCase()}
                </label>
            </div>
            // <CustomInput className="m-2" type="switch" id={role.id} name={role.id} data-id={role.id}
            //              label={role.name.toUpperCase()}
            //              onChange={(e) => this.handleChange(e)} checked={status}/>
        )
    }

    render() {
        const { user, all_roles } = this.state;
        let { trimmedDataURL } = this.state;

        let selectedCounter = user.counter ? { label: user.counter.name, value: user.counter.id } : null
        let clientGroup = [];
        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
        }
        // let clientSingleData = [];
        // let name = '';
        // let email = '';
        // let mobile = '';
        // let eiin = '';
        // let address = '';
        let clientSingleData = [];
        if (this.state.hasOwnProperty('clientSingleData')) {
            clientSingleData = this.state.clientSingleData.ClientGroup;
        }
        const { name, email, address, eiin, mobile, f_client_group, message } = this.state;
        return (
            <React.Fragment>
                <Fade cascade>
                    <div className="page-content">
                        <Container fluid={true}>
                            {message == "" || message == undefined || message == null ?
                                "" :
                                // this.state.custom_div1==true &&
                                this.state.custom_div2 ?
                                    <SweetAlert
                                        title={message}
                                        timeout={2000}
                                        showConfirm={false}
                                        showCloseButton={false}
                                        success
                                        onConfirm={() => this.setState({
                                            custom_div2: false,
                                            message: undefined
                                        })}
                                    >
                                    </SweetAlert> : null
                            }
                            <Row>
                                <ul
                                    className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
                                    role="tablist"
                                >
                                    <NavItem className='mx-2'>
                                        {
                                            this.state.activeTab == "1" &&
                                            <NavLink
                                                to="#"
                                                className={classnames({
                                                    active: this.state.activeTab === "1",
                                                })}
                                                onClick={() => {
                                                    this.toggleTab("1")
                                                }}
                                            >
                                                {/* User */}
                                            </NavLink>
                                        }

                                    </NavItem>
                                    <NavItem className='mx-2'>
                                        {
                                            this.state.activeTab == "2" &&
                                            <NavLink
                                                to="#"
                                                className={classnames({
                                                    active: this.state.activeTab === "2",
                                                })}
                                                onClick={() => {
                                                    this.toggleTab("2")
                                                }}
                                            >
                                                {/* Client */}
                                            </NavLink>
                                        }
                                    </NavItem>
                                </ul>
                                <TabContent className="p-4" activeTab={this.state.activeTab}>

                                    <TabPane tabId="1">
                                        <Col xs="12" sm="12">
                                            <Form onSubmit={this.onHandleSubmit}>
                                                <Card>
                                                    {/* <CardHeader>
                                                    <strong>Update User</strong>
                                                    <small> Form</small>
                                                </CardHeader> */}
                                                    <CardBody>
                                                        <Row>
                                                            <Col xs="12" sm="12" md={{ size: 6, offset: 1 }}>
                                                                <Card>

                                                                    <CardHeader>
                                                                        <strong>Edit User Information</strong>
                                                                        {/* <small> Form</small> */}
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <FormGroup >
                                                                            <Label htmlFor="name"> Name<sup className="text-danger">*</sup></Label>
                                                                            <Input type="text" id="name" name="name"
                                                                                value={user.name} onChange={this.handleInputChange}
                                                                                placeholder="Enter  name..." required />
                                                                            {/* <FormText className="help-block">Please enter User
                                                                            Name</FormText> */}
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="email">Email</Label>
                                                                            <Input type="email" id="email" name="email"
                                                                                value={user.email} onChange={this.handleInputChange}
                                                                                placeholder="Enter email..." />
                                                                            {/* <FormText className="help-block">Please enter user
                                                                            email</FormText> */}
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="mobile">Phone No.<sup className="text-danger">*</sup></Label>
                                                                            <Input type="text" id="mobile" name="mobile"
                                                                                value={user.mobile} onChange={this.handleInputChange}
                                                                                placeholder="Enter Phone no..." required />

                                                                        </FormGroup>
                                                                        {/* <FormGroup>
                                                                        <Label htmlFor="password">Password</Label>
                                                                        <Input type="password" id="password" name="password"
                                                                            onChange={this.handleInputChange}
                                                                            placeholder="Enter password..." />
                                                                      
                                                                    </FormGroup>

                                                                    <FormGroup>
                                                                        <Label htmlFor="password_confirmation">Confirm password</Label>
                                                                        <Input type="password" id="password_confirmation"
                                                                            name="password_confirmation" onChange={this.handleInputChange}
                                                                            placeholder="Enter password again..." />
                                                                       
                                                                    </FormGroup> */}
                                                                    </CardBody>
                                                                    <CardFooter className="py-3 text-white bg-white  d-flex justify-content-end">
                                                                        <Button type="submit" size="sm" color="primary"
                                                                            className="me-2 pull-right"><i
                                                                                className="fa fa-dot-circle-o" /> Submit</Button>
                                                                        <Link to="/users">
                                                                            <Button type="reset" size="sm" color="danger"
                                                                                className="pull-right"><i
                                                                                    className="fa fa-ban" /> Cancel</Button>
                                                                        </Link>
                                                                    </CardFooter>

                                                                </Card>
                                                            </Col>
                                                            <Col md={{ size: 4 }}>
                                                                <Card>
                                                                    <CardHeader>
                                                                        <strong>User Role Information</strong>
                                                                        <small> Form</small>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <FormGroup>
                                                                            <Label for="exampleCheckbox">Attach User Role</Label>
                                                                            <div>
                                                                                {all_roles.map((role) => {
                                                                                    return this.getRoleInput(role);
                                                                                })}
                                                                            </div>
                                                                        </FormGroup>

                                                                    </CardBody>
                                                                </Card>
                                                            </Col>

                                                        </Row>
                                                    </CardBody>

                                                </Card>
                                            </Form>
                                        </Col>
                                    </TabPane>

                                    {/* tab-2 client info */}
                                    <TabPane tabId="2">
                                        <Col xs="12" sm="12">
                                            <Form onSubmit={this.onHandleSubmit}>
                                                <Card>
                                                    {/* <CardHeader>
                                                    <strong>Update Client</strong>
                                                    <small> Form</small>
                                                </CardHeader> */}
                                                    <CardBody>
                                                        <Row>
                                                            <Col xs="12" sm="12" md={{ size: 6, offset: 1 }}>
                                                                <Card>

                                                                    <CardHeader>
                                                                        <strong>Edit Client Information</strong>
                                                                        {/* <small> Form</small> */}
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <FormGroup>
                                                                            <label className="col-form-label">Client Group<sup className="text-danger">*</sup></label>
                                                                            <select className="form-select" name="f_client_group_id" id="f_client_group_id" value={f_client_group} onChange={this.handleInputChange} disabled required>
                                                                                <option value="">Select</option>
                                                                                {clientGroup.map((client, index) =>
                                                                                    <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                                                                )}

                                                                            </select>

                                                                        </FormGroup>
                                                                        <FormGroup >
                                                                            <Label htmlFor="name"> Name<sup className="text-danger">*</sup></Label>
                                                                            <Input type="text" id="name" name="name"
                                                                                onChange={this.handleInputChange}
                                                                                value={name}
                                                                                placeholder="Enter  name..." required readOnly />

                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="email">Email</Label>
                                                                            <Input type="email" id="email" name="email"
                                                                                onChange={this.handleInputChange}
                                                                                value={email}

                                                                                placeholder="Enter email..." />

                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="mobile">Phone No.<sup className="text-danger">*</sup></Label>
                                                                            <Input type="text" id="mobile" name="mobile"
                                                                                value={mobile}
                                                                                onChange={this.handleInputChange}
                                                                                placeholder="Enter phone no..." required />

                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="eiin">EIN<sup className="text-danger">*</sup></Label>
                                                                            <Input type="text" id="eiin" name="eiin"
                                                                                onChange={this.handleInputChange}
                                                                                value={eiin}

                                                                                placeholder="Enter EIN no..." readOnly required />

                                                                        </FormGroup>
                                                                        {/* <FormGroup>
                                                                        <Label htmlFor="password">Password</Label>
                                                                        <Input type="password" id="password" name="password"
                                                                            onChange={this.handleInputChange}
                                                                            placeholder="Enter password..." />
                                                                       
                                                                    </FormGroup>

                                                                    <FormGroup>
                                                                        <Label htmlFor="password_confirmation">Confirm password</Label>
                                                                        <Input type="password" id="password_confirmation"
                                                                            name="password_confirmation" onChange={this.handleInputChange}
                                                                            placeholder="Enter password again..." />
                                                                       
                                                                    </FormGroup> */}

                                                                        {/* <FormGroup>
                                                                        <Label htmlFor="Address">Address</Label>
                                                                        <Input type="textarea" id="address" name="address"
                                                                            onChange={this.handleInputChange}
                                                                            value={address}
                                                                            readOnly
                                                                            placeholder="Enter mobile address..." />
                                                                        
                                                                    </FormGroup> */}
                                                                    </CardBody>
                                                                    <CardFooter className="py-2  d-flex bg-white justify-content-end">
                                                                        <Button type="submit" size="sm" color="primary"
                                                                            className="me-2 pull-right"><i
                                                                                className="fa fa-dot-circle-o" /> Submit</Button>
                                                                        <Link to="/users">
                                                                            <Button type="reset" size="sm" color="danger"
                                                                                className="pull-right"><i
                                                                                    className="fa fa-ban" /> Cancel</Button>
                                                                        </Link>
                                                                    </CardFooter>

                                                                </Card>
                                                            </Col>
                                                            <Col md={{ size: 4 }}>
                                                                <Card>
                                                                    <CardHeader>
                                                                        <strong>User Role Information</strong>
                                                                        <small> Form</small>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <FormGroup>
                                                                            <Label for="exampleCheckbox">Attach User Role<sup className="text-danger">*</sup></Label>
                                                                            <div>
                                                                                {all_roles.map((role) => {
                                                                                    return this.getRoleInput(role);
                                                                                })}
                                                                            </div>
                                                                        </FormGroup>

                                                                    </CardBody>
                                                                </Card>
                                                            </Col>

                                                        </Row>
                                                    </CardBody>

                                                </Card>
                                            </Form>
                                        </Col>
                                    </TabPane>
                                </TabContent>
                            </Row>
                        </Container>
                    </div>
                </Fade>
            </React.Fragment>

        );
    }
}
UpdateUserProfile.propTypes = {
    handleErrorResponse: PropTypes.any,
    match: PropTypes.any,
    history: PropTypes.any,
};

const mapStateToProps = state => {
    const { msg, error, success } = state.Profile;
    return { msg, error, success };
}




export default withRouter(connect(mapStateToProps, { getUserDetails, createOrUpdateUser, getRoleList })(UpdateUserProfile));

