import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    this.initMenu();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    var pathName = this.props.location.pathname;

    let menubar = JSON.parse(localStorage.getItem("menubar"));
    menubar = Object.keys(menubar).map(key => menubar[key]);
    var matchingMenuItem = null;
    menubar.map(function (menu, i) {

      new MetisMenu("#sideMenu" + i);
      var ul = document.getElementById("sideMenu" + i);
      // console.log('ul', ul);
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];

          break;
        }
      }

    });
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }
  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    let menubar = JSON.parse(localStorage.getItem("menubar"));
    menubar = Object.keys(menubar).map(key => menubar[key]);
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            {menubar.map((menu, i) => (
              <ul className="metismenu list-unstyled " id={"sideMenu" + i} key={"sideMenu" + i}>
                <li className="menu-title color-white">
                  <span> {this.props.t(menu.module_name_en)}</span>
                </li>
                {(Object.keys(menu.group).map(key => menu.group[key])).map((group, j) => (
                  <li className="color-white" key={'group' + j}>
                    <Link to={(group.is_children == 0) ? group.children[0].react_url : '/#'} className={group.is_children != 1 ? "waves-effect" : "waves-effect has-arrow"}>
                      <i className={group.icon}></i>
                      <span>{this.props.t(group.main_menu_name)}</span>
                    </Link>
                    {group.is_children == 1 &&
                      <ul className="sub-menu bg-dark" aria-expanded="false" >
                        {Object.keys(group.children).map(key => group.children[key]).map((children, k) => (
                          <li className="color-white" key={"sub-menu" + k}>
                            <Link to={children.react_url}><i className={children.icon == null ? '' : children.icon}></i>{this.props.t(children.menu_name)}</Link></li>
                        ))}
                      </ul>
                    }
                  </li>
                ))}
              </ul>
            ))}

          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));