import { CREATE_TRANSACTION, TRANSACTION_ERROR, TRANSACTION_SUCCESS, SHOW_TRANSACTION, EDIT_TRANSACTION, DELETE_CLIENT } from './actionTypes';
import { getApi } from "../../services/httpService";


export const editTransaction = (transInfo) => {
  console.log('transInfo', transInfo);
  return {
    type: EDIT_TRANSACTION,
    payload: { transInfo }
  }
}

export const transactionSuccess = (response) => {
  return {
    type: TRANSACTION_SUCCESS,
    payload: response.data.message
  }
}

export const transactionError = (error) => {
  return {
    type: TRANSACTION_ERROR,
    payload: error
  }
}

export function getTransactionList(params) {
  return getApi().get('transactionInfo/list', {
    params: params
  })
}

export function getFilterTransactionList(params) {
  return getApi().get('transactionInfo/filter/list', {
    params: params
  })
}


export function createTransaction(data) {
  return getApi().post('transactionInfo/create', data);
}

export function createBlukTransaction(data) {
  return getApi().post('transactionInfo/bulk/create', data);
}

export function deleteTransaction(id) {
  return getApi().put('transactionInfo/delete/' + id);
}




