import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormText,
  FormGroup,
  Label, CardTitle,
  Form,
  CardHeader,
  CustomInput,
  CardFooter,
  Table,
  UncontrolledAlert,
  Modal,
  InputGroup,
} from "reactstrap";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import SplineArea from "../AllCharts/apex/SplineArea"

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  checkPermission,
  permissionEnum,
} from "../../services/service-provider";
import { DataTabeSearch } from "../Settings/Common/DataTabeSearch";
import { CustomPagination } from "../Settings/Common/CustomPagination";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// actions
import { getUserList } from "../../store/auth/profile/actions";
import { getPublicClientList, editClient } from "../../store/clients/actions";
import moment from "moment";
import { Field, Formik } from "formik";
import {
  getClientInfoDrop,
  getClientCustomerInfoDrop,
  getPublicClientCustomerInfoDrop,
  getPublicClientInfoDrop,
} from "store/clientInfo/actions";
import {
  getTransactionList,
  getFilterTransactionList,
  deleteTransaction,
  editTransaction,
} from "store/transaction/actions";
import { categoryList } from "store/actions";
import DeleteModal from "pages/Calendar/DeleteModal";
import Fade from 'react-reveal/Fade';

function TransactionRow(props) {
  const transInfo = props.transInfo;

  return (
    <tr key={transInfo.toString() + 1}>
      <td scope="row">{props.index}</td>
      <td>
        {transInfo.category_info == null || transInfo.category_info == undefined
          ? ""
          : transInfo.category_info.category_name_en}
      </td>
      <td>${transInfo.transaction_amount.toLocaleString()}</td>
      <td>
        {transInfo.client_wish_customer == null ||
          transInfo.client_wish_customer == undefined
          ? ""
          : transInfo.client_wish_customer.customer_name}
      </td>
      <td>
        {transInfo.client_info == null || transInfo.client_info == undefined
          ? ""
          : transInfo.client_info.name}
      </td>
      <td>
        {transInfo.client_group == null || transInfo.client_group == undefined
          ? ""
          : transInfo.client_group.name}
      </td>
      <td>
        {" "}
        {moment(new Date(transInfo.transaction_date)).format("YYYY-MM-DD")}
      </td>
    </tr>
  );
}

TransactionRow.propTypes = {
  tog_scroll: PropTypes.func,
  index: PropTypes.any,
  transInfo: PropTypes.any,
  toggleDeleteModal: PropTypes.func,
  onClickDelete: PropTypes.func,
};

class TransactionReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      per_page: 15,
      search: "",
      value: "",
      f_group_id: "",
      duration_type: "",
      f_client_info_id: "",
      f_clients_customer_id: "",
      f_category_id: "",
      modal_scroll: false,
      deleteModal: false,
      client: [],
      boolvalue: 0,
    };
  }

  fetchClientGroupList = () => {
    getPublicClientList()
      .then(response => {
        this.setState({
          clientData: response.data,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  fetchCategoryList = () => {
    categoryList()
      .then(response => {
        this.setState({
          categoryList: response.data,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  fetchTransferList = () => {
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search,
    };
    getTransactionList(options)
      .then(response => {
        this.setState({
          transactionData: response.data,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  fetchFilterTransferList = () => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let f_g_id = authUser.data.user[0].f_client_id;
    let group_id = "";
    this.setState({
      f_group_id: f_g_id,
    });
    if (authUser.data.is_admin == 0) {
      group_id = f_g_id;
    } else {
      group_id = this.state.f_group_id;
    }
    console.log("group_id", group_id)
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search,
      value: this.state.value,
      f_group_id: group_id,
      f_clients_customer_id: this.state.f_clients_customer_id,
      f_client_info_id: this.state.f_client_info_id,
      f_category_id: this.state.f_category_id,
      duration_type: this.state.duration_type,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
    getFilterTransactionList(options)
      .then(response => {
        this.setState({
          transactionData: response.data.TransactioninfoDetails,
          cateChartData: response.data.chartArr,

        });
      })
      .catch(err => {
        console.log(err);
      });

  };

  fetchClientInfoList = clientGroup_id => {
    getPublicClientInfoDrop(clientGroup_id)
      .then(response => {
        this.setState({
          clientInfoData: response.data,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  fetchClientCustomerInfoList = clientGroup_id => {
    getPublicClientCustomerInfoDrop(clientGroup_id)
      .then(response => {
        this.setState({
          clientCustomerInfoData: response.data,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.fetchCategoryList();
    this.fetchClientGroupList();
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let is_admin = authUser.data.is_admin;
    const user = authUser.data.is_admin;
    const userClient = authUser.data.user[0].f_client_id;

    if (user == 0) {
      this.fetchClientCustomerInfoList(authUser.data.user[0].f_client_id);
      this.setState({
        f_group_id: authUser.data.user[0].f_client_id,
      });
      this.setGroupId(authUser.data.user[0].f_client_id);
      this.fetchFilterTransferList();
      this.fetchClientInfoList(userClient);
      console.log("aaaaa3333");

    }
    if (user == 1) {
      this.fetchFilterTransferList();
      console.log("aaaaa");
    }
    // this.fetchTransferList();
  }
  setGroupId(f_group_id) {
    this.setState({
      f_group_id: f_group_id,
    });
  }

  handlePageClick = (e, index) => {
    e.preventDefault();
    this.setState(
      {
        page: index,
      },
      () => {
        this.fetchTransferList();
      }
    );
  };

  tog_scroll = transactionInfo => {
    this.fetchClientInfoList(transactionInfo.f_group_id);
    this.fetchClientCustomerInfoList(transactionInfo.f_client_id);
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll,
    }));

    this.setState({
      transInfo: transactionInfo,
      date: transactionInfo.transaction_date,
    });

    this.removeBodyCss();
  };
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = transInfo => {
    this.setState({ deleteTransInfo: transInfo });
    this.setState({ deleteModal: true });
  };

  handleDeleteTransaction = () => {
    const { deleteTransInfo } = this.state;
    if (deleteTransInfo.transaction_id !== undefined) {
      deleteTransaction(deleteTransInfo.transaction_id).then(response => {
        this.setState({
          message: response.data,
        });
        this.fetchTransferList();
      });
      this.setState({ deleteModal: false });
    }
  };

  updateUserObject = (name, value, callback = null) => {
    let transInfoCopy = Object.assign({}, this.state.transInfo);
    transInfoCopy[name] = value;

    this.setState(
      {
        transInfo: transInfoCopy,
      },
      () => {
        if (typeof callback == "function") callback();
      }
    );
  };
  handleEditChange = event => {
    // event.preventDefault();

    if (event.target.name == "f_group_id") {
      this.fetchClientInfoList(event.target.value);
      this.setState({
        f_group_id: event.target.value,
      });
      this.setState.f_group_id = event.target.value;
      console.log("event value", this.state.f_group_id);
    }
    if (event.target.name == "f_clients_customer_id") {
      // this.fetchClientInfoList(event.target.value);
      this.setState({
        f_clients_customer_id: event.target.value,
      });
      this.setState.f_clients_customer_id = event.target.value;
    }
    if (event.target.name == "f_category_id") {
      // this.fetchClientInfoList(event.target.value);
      this.setState({
        f_category_id: event.target.value,
      });
      this.setState.f_category_id = event.target.value;
    }
    if (event.target.name == "startDate") {
      this.setState({
        startDate: event.target.value,
      });
      this.setState.startDate = event.target.value;
    }
    if (event.target.name == "endDate") {
      this.setState({
        endDate: event.target.value,
      });
      this.setState.endDate = event.target.value;
    }
    if (event.target.name == "f_client_info_name") {
      this.setState({
        f_client_info_id: event.target.value,
      });
      this.fetchClientCustomerInfoList(event.target.value);
    }
    if (event.target.name == "duration_type") {
      if (event.target.value == "customType") {
        this.setState({
          boolvalue: 1,
          duration_type: event.target.value,
        });
      } else {
        this.setState({
          boolvalue: 0,
          duration_type: event.target.value,
        });
      }
      // this.fetchClientCustomerInfoList(event.target.value);
    }

    const {
      target: { name, value },
    } = event;
    this.updateUserObject(name, value);
    // this.handleSearch();
  };

  handleSearch = e => {
    e.preventDefault();
    // const { f_clients_customer_id, startDate , endDate , f_category_id, f_client_info_id, f_group_id } = this.state;

    //     const transInfo = {
    //         f_category_id: f_category_id,
    //         startDate:startDate,
    //         endDate:endDate,
    //         f_client_info_id: f_client_info_id,
    //         f_group_id: f_group_id,
    //         f_clients_customer_id:f_clients_customer_id
    //     }
    //     console.log("transInfo",transInfo);
    //     this.setState({
    //         value: transInfo
    //     })

    this.fetchFilterTransferList();
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.fetchFilterTransferList();
    });
  };

  render() {
    let paginator;

    let clientGroup = [];
    let clientInfoList = [];
    let transactionList = [];
    let categoryList = [];
    let categoryCustomerList = [];

    let monthCategoryIncome = [];


    const { message } = this.state;

    if (this.state.hasOwnProperty("clientData")) {
      clientGroup = this.state.clientData.data;
    }

    if (this.state.hasOwnProperty("clientInfoData")) {
      clientInfoList = this.state.clientInfoData;
    }

    if (this.state.hasOwnProperty("categoryList")) {
      categoryList = this.state.categoryList.data;
    }
    if (this.state.hasOwnProperty("clientCustomerInfoData")) {
      categoryCustomerList = this.state.clientCustomerInfoData;
    }

    if (this.state.hasOwnProperty("transactionData")) {
      transactionList = this.state.transactionData.data;
      monthCategoryIncome = this.state.cateChartData;

      paginator = {
        currentPage: this.state.transactionData.current_page,
        pagesCount: this.state.transactionData.last_page,
        total: this.state.transactionData.total,
        to: this.state.transactionData.to,
        from: this.state.transactionData.from,
        f_group_id: this.state.f_group_id,
        f_clients_customer_id: this.state.f_clients_customer_id,
        f_client_info_id: this.state.f_client_info_id,
        f_category_id: this.state.f_category_id,
        duration_type: this.state.duration_type,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
    }
    const { deleteModal, date } = this.state;
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let is_admin = authUser.data.is_admin;
    // console.log("localStorage.getItem('authUser')",authUser.data.is_admin);
    return (
      <React.Fragment>
        <MetaTags>
          <title>Transaction List | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              {/* Render Breadcrumbs */}
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Transaction List</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Transaction List</li>
                  </ol>
                </div>
              </div>
              <DeleteModal
                show={deleteModal}
                onDeleteClick={this.handleDeleteTransaction}
                onCloseClick={() => this.setState({ deleteModal: false })}
              />

              <Row>
                <Col xl={12}>


                  <div>
                    {message == "" || message == undefined || message == null ? (
                      ""
                    ) : (
                      <UncontrolledAlert
                        color="success"
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        <i className="mdi mdi-check-all me-2"></i>
                        {message}
                      </UncontrolledAlert>
                    )}
                    {this.props.location.state == null ||
                      this.props.location.state == "" ||
                      this.props.location.state == undefined ? (
                      ""
                    ) : (
                      <UncontrolledAlert
                        color="success"
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        <i className="mdi mdi-check-all me-2"></i>
                        {this.props.location.state.message || message}
                      </UncontrolledAlert>
                    )}

                    {this.props.error && this.props.error ? (
                      <UncontrolledAlert
                        color="success"
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.props.error}
                      </UncontrolledAlert>
                    ) : null}

                    {this.props.success && this.props.success ? (
                      <UncontrolledAlert
                        color="success"
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.props.success}
                      </UncontrolledAlert>
                    ) : null}
                  </div>
                  <Form onSubmit={e => this.handleSearch(e)}>
                    <Row className="mb-3">
                      {authUser.data.is_admin == 1 && (
                        <Col md={2}>

                          <select
                            className="form-select"
                            name="f_group_id"
                            id="f_group_id"
                            onChange={e => this.handleEditChange(e)}
                            required
                          >
                            <option value="0">Select Client Group</option>
                            {clientGroup != undefined &&
                              clientGroup.map((client, index) => (
                                <option
                                  value={client.cleint_group_id}
                                  key={index}
                                >
                                  {client.name}
                                </option>
                              ))}
                          </select>
                        </Col>
                      )}

                      <Col md={authUser.data.is_admin == 1 ? 2 : 3}>

                        <select
                          className="form-select"
                          name="f_client_info_name"
                          id="f_client_info_name"
                          onChange={e => this.handleEditChange(e)}
                        >
                          <option value="">Select Client Name</option>
                          {clientInfoList != undefined &&
                            clientInfoList.map((client, index) => (
                              <option value={client.id} key={index}>
                                {client.name}
                              </option>
                            ))}
                        </select>
                      </Col>
                      <Col md={authUser.data.is_admin == 1 ? 2 : 3}>
                        <select
                          className="form-select"
                          name="f_clients_customer_id"
                          id="f_clients_customer_id"
                          onChange={e => this.handleEditChange(e)}
                        >
                          <option value="">Select Customer Name</option>
                          {categoryCustomerList &&
                            categoryCustomerList.map((clientInfo, index) => (
                              <option value={clientInfo.customer_id} key={index}>
                                {clientInfo.customer_name}
                              </option>
                            ))}
                        </select>
                      </Col>
                      <Col md={this.state.boolvalue == 1 ? 1 : 2}>
                        <select
                          className="form-select"
                          name="f_category_id"
                          id="f_category_id"
                          onChange={e => this.handleEditChange(e)}
                        >
                          <option value="">Select Category Name</option>
                          {categoryList.map((category, index) => (
                            <option value={category.category_id} key={index}>
                              {category.category_name_en}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col md={this.state.boolvalue == 1 ? 1 : 2}>
                        <select
                          className="form-select"
                          name="duration_type"
                          id="duration_type"
                          onChange={e => this.handleEditChange(e)}
                        >
                          <option value="">Select Duration Type</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Yearly">Yearly</option>
                          <option value="customType">Custom Range</option>
                        </select>
                      </Col>
                      {this.state.boolvalue == 1 && (
                        <Col md={3}>
                          {/* <div className="col-md-2"> */}
                          {/* <label className="col-form-label">
                            Custom Start Date
                          </label> */}
                          <div className="d-flex justifyContent-center">
                            {/* <Col className="col-md-12"> */}
                            <input
                              className="form-control"
                              type="date"
                              name="startDate"
                              id="CusStartDate"
                              title="Start Date"
                              placeholder="start Date"
                              onChange={e => this.handleEditChange(e)}
                            ></input>
                            {/* </Col> */}

                            {/* <Col className="col-md-6"> */}
                            <input
                              className="form-control"
                              type="date"
                              id="CusEndDate"
                              name="endDate"
                              placeholder="end Date"
                              title="End Date"
                              onChange={e => this.handleEditChange(e)}
                            ></input>
                            {/* </Col> */}
                          </div>
                        </Col>

                      )}


                      <Col md={this.state.boolvalue == 1 ? 1 : 2}>
                        {/* <br />
                                <br /> */}
                        <FormGroup>
                          <button type="submit" className="btn btn-primary form-control">Search</button>
                        </FormGroup>

                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-4"> Spline Area </CardTitle>
                          <SplineArea monthCategoryIncomeChart={monthCategoryIncome} />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Card>
                    <CardBody>

                      <DataTabeSearch
                        handleInputChange={this.handleInputChange}
                      />
                      <Table responsive striped className="table-bordered">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Category Name</th>
                            <th scope="col">Transaction Amount</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Client Name</th>
                            <th scope="col">Group Name</th>
                            <th scope="col">Transaction Date</th>
                            {/* <th scope="col">activity</th> */}
                            {/* {checkPermission(permissionEnum.USER_UPDATE) &&
                                                        <th scope="col">Action</th>
                                                    } */}
                          </tr>
                        </thead>
                        <tbody>
                          {transactionList != undefined &&
                            transactionList.map((transInfo, index) => (
                              <TransactionRow
                                key={index}
                                transInfo={transInfo}
                                tog_scroll={this.tog_scroll}
                                onClickDelete={this.onClickDelete}
                                toggleDeleteModal={this.toggleDeleteModal}
                                index={
                                  index +
                                  1 +
                                  (this.state.page - 1) * this.state.per_page
                                }
                              />
                            ))}
                        </tbody>
                      </Table>
                    </CardBody>
                    {paginator && (
                      <CardFooter className="bg-white">
                        <CustomPagination
                          paginator={paginator}
                          handlePageClick={this.handlePageClick}
                        />
                      </CardFooter>
                    )}
                  </Card>
                  {/* <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">
                                            Edit Transaction
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                // category_name_en: category_name_en||"",
                                                // category_icon: '',
                                                // status: true,
                                                // name: this.state.client.name || "",
                                                // email: this.state.client.email || "",
                                                // password: this.state.client.password || "",
                                                // address: this.state.client.address || "",
                                                // phone_number: this.state.client.phone_number || "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                name: Yup.string().required(
                                                    "Please Enter Client Group Name"
                                                ),
                                                phone_number: Yup.string().required("Please Enter Phone Number")

                                            })}
                                        // onSubmit={(e, values) => {
                                        //     // console.log('values', values);
                                        //     this.handleEditSubmit(e, v);
                                        //     // this.props.editClient(values);
                                        //     // this.setState(prevState => ({
                                        //     //     modal_scroll: !prevState.modal_scroll,
                                        //     // }));
                                        //     // this.fetchCategoryDetails(values.category_id);
                                        //     // this.fetchClientGroupList();
                                        //     // this.props.loginUser(values, this.props.history);
                                        // }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="form-horizontal" onSubmit={(e) => this.handleEditSubmit(e)} >
                                                    <Input type="hidden" id="transaction_id" value={this.state.transInfo.transaction_id} name="transaction_id"
                                                        onChange={(e) => this.handleEditChange(e)} />
                                                    <FormGroup>
                                                        <label className="col-form-label">Client Group Name</label>
                                                        <select className="form-select" name="f_group_id" id="f_group_id" value={this.state.transInfo.f_group_id} onChange={(e) => this.handleEditChange(e)} required>
                                                            <option value="">Select</option>
                                                            {clientGroup.map((client, index) =>
                                                                <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                                            )}
                                                        </select>
                                                        {/* <FormText className="help-block">Please enter client group</FormText> 
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <label className="col-form-label">Client Info Name</label>
                                                        <select className="form-select" name="f_client_id" id="f_client_id" value={this.state.transInfo.f_client_id} onChange={(e) => this.handleEditChange(e)} required>
                                                            <option value="">Select</option>
                                                            {clientInfoList && clientInfoList.map((clientInfo, index) =>
                                                                <option value={clientInfo.id} key={index}>{clientInfo.name}</option>
                                                            )}

                                                        </select>
                                                        {/* <FormText className="help-block">Please enter client Info </FormText> 
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <label className="col-form-label">Client Customer Name</label>
                                                        <select className="form-select" name="f_clients_customer_id" id="f_clients_customer_id" value={this.state.transInfo.f_clients_customer_id}  onChange={(e) => this.handleEditChange(e)} required>
                                                            <option value="">Select</option>
                                                            {categoryCustomerList && categoryCustomerList.map((clientInfo, index) =>
                                                                <option value={clientInfo.customer_id} key={index}>{clientInfo.customer_name}</option>
                                                            )}
                                                        </select>
                                                        {/* <FormText className="help-block">Please enter client Info </FormText> 
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <label className="col-form-label">Category Name</label>
                                                        <select className="form-select" name="f_category_id" value={this.state.transInfo.f_category_id} id="f_category_id" onChange={(e) => this.handleEditChange(e)} required>
                                                            <option value="">Select</option>
                                                            {categoryList.map((category, index) =>
                                                                <option value={category.category_id} key={index}>{category.category_name_en}</option>
                                                            )}

                                                        </select>
                                                        {/* <FormText className="help-block">Please enter category</FormText> 
                                                    </FormGroup>

                                                    <FormGroup >
                                                        <Label htmlFor="name"> Transaction Amount</Label>
                                                        <Input type="text" id="transaction_amount" value={this.state.transInfo.transaction_amount} name="transaction_amount"
                                                            onChange={(e) => this.handleEditChange(e)} required
                                                            placeholder="Enter  amount..." />
                                                        {/* <FormText className="help-block">Please enter transaction amount</FormText> 
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Transaction Date</Label>
                                                        {/* <Input type="date" id="transaction_date" name="transaction_date"
                                                        value={moment(new Date(this.state.transInfo.transaction_date)).format("yyyy-MM-DD")}
                                                            onChange={(e) => this.handleEditChange(e)}
                                                            placeholder="Enter  date..." required/> 
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                value={date}
                                                                options={{ allowInput: true }}
                                                                onChange={date => {
                                                                    this.setState({ date });
                                                                }}
                                                            />
                                                        </InputGroup>
                                                        <Input type="hidden" name="transaction_date" value={this.state.date} onChange={this.handleInputChange} />
                                                        {/* <FormText className="help-block">Please enter transaction Date</FormText> 
                                                    </FormGroup>

                                                    {/* <FormGroup>
                                                        <Label>Transaction Date & time</Label>
                                                        <Input
                                                            className="form-control"
                                                            type="datetime-local"
                                                            name="transaction_date_time"
                                                            id="transaction_date_time"
                                                            value={moment(new Date(this.state.transInfo.transaction_date_time)).format("YYYY-MM-DDThh:mm:ss")}
                                                            onChange={(e) => this.handleEditChange(e)} required
                                                            placeholder="Enter  date and time..."
                                                        />
                                                        <FormText className="help-block">Please enter transaction Date and time</FormText>
                                                    </FormGroup> 

                                                    <FormGroup>
                                                        <Label htmlFor="Address">Note</Label>
                                                        <Input type="textarea" id="remarks" name="remarks" value={this.state.transInfo.remarks == null ? '' : this.state.transInfo.remarks}
                                                            onChange={(e) => this.handleEditChange(e)}
                                                            placeholder="Enter Note..." />
                                                        {/* <FormText className="help-block">Please enter your remarks</FormText> 
                                                    </FormGroup>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={() =>
                                                                this.setState({ modal_scroll: false })
                                                            }
                                                        >
                                                            Close
                                                        </button>
                                                        <button type="submit" className="btn btn-primary">
                                                            Save changes
                                                        </button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>


                                    </div>
                                </Modal> */}
                </Col>
              </Row>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}

TransactionReport.propTypes = {
  location: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  msg: PropTypes.any,
  client: PropTypes.any,
  editTransaction: PropTypes.func,
  deleteTransaction: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.ClientTransaction;
  return { error, success };
};

export default withRouter(
  connect(mapStateToProps, { getUserList, editTransaction })(TransactionReport)
);
