import { CREATE_FUND_CATEGORY,EDIT_FUND_CATEGORY,SHOW_FUND_CATEGORY,FUND_CATEGORY_SUCCESS,FUND_CATEGORY_ERROR } from './actionTypes';


const initialState = {
    error: "",
    success : ""
}

const FundCategory = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_FUND_CATEGORY:
            state = { ...state }
            break;
        case FUND_CATEGORY_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case FUND_CATEGORY_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default FundCategory;
