import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, CardHeader, CustomInput, CardFooter, Table, UncontrolledAlert, Modal } from "reactstrap";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import toastr from "toastr";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { checkPermission, permissionEnum } from '../../../services/service-provider';
import { DataTabeSearch } from '../../Settings/Common/DataTabeSearch'
import { CustomPagination } from "../../Settings/Common/CustomPagination";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
// actions
import { getUserList } from '../../../store/auth/profile/actions';
import { getClientList, editClient, getPublicClientList } from '../../../store/clients/actions';
import moment from 'moment';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { deleteClientInfo, getClientInfoList, editClientInfo } from 'store/clientInfo/actions';
import DeleteModal from 'pages/Calendar/DeleteModal';
import MetaTags from 'react-meta-tags';
import { values } from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fade from 'react-reveal/Fade';

function ClientInfoRow(props) {
    const clientInfo = props.clientInfo;

    // const useractivityLink = `/users/activity`

    return (
        <tr key={clientInfo.toString() + 1}>
            <td scope="row">{props.index}</td>
            <td>{clientInfo.client_info_detail_code}</td>
            <td>{clientInfo.name}</td>
            <td>{clientInfo.eiin}</td>
            <td>{clientInfo.client_group == null ? "" : clientInfo.client_group.name}</td>
            <td>{clientInfo.phone_number}</td>
            <td>{clientInfo.email}</td>
            <td>{clientInfo.address}</td>
            <td> {moment(new Date(clientInfo.created_at)).format("YYYY-MM-DD")}</td>
            {(checkPermission(permissionEnum.CLIENT_INFO_DETIALS_UPDATE) || checkPermission(permissionEnum.CLIENT_INFO_DETIALS_DELETE)) &&
                <td>
                    <div className="d-flex gap-3">
                        {checkPermission(permissionEnum.CLIENT_INFO_DETIALS_UPDATE) && <i className="mdi mdi-pencil font-size-18 text-success" onClick={() => { props.tog_scroll(clientInfo) }}></i>}
                        {checkPermission(permissionEnum.CLIENT_INFO_DETIALS_UPDATE) &&
                            <i
                                className="mdi mdi-delete font-size-18 text-danger"
                                id="deletetooltip"
                                onClick={() => {
                                    props.toggleDeleteModal();
                                    props.onClickDelete(clientInfo);
                                }
                                }
                            ></i>
                        }

                    </div>
                </td>
            }
        </tr>
    )
}

ClientInfoRow.propTypes = {
    tog_scroll: PropTypes.any,
    index: PropTypes.any,
    clientInfo: PropTypes.any,
    toggleDeleteModal: PropTypes.any,
    onClickDelete: PropTypes.any,
};

class ClientInfoListIndex extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            per_page: 15,
            search: '',
            modal_scroll: false,
            deleteModal: false,
            client: [],
            custom_div2: false,
            custom_div1:false
        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    fetchClientGroupList = () => {
        getPublicClientList().then((response) => {
            this.setState({
                clientData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }

    fetchClientInfoList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        getClientInfoList(options).then((response) => {
            this.setState({
                clientInfoData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }
    componentDidMount() {
        this.fetchClientGroupList();
        this.fetchClientInfoList();
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchClientInfoList();
        });
    }

    tog_scroll = (client) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.setState({
            clientInfo: client
        })
        this.removeBodyCss();
    }
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };

    onClickDelete = (clientInfo) => {
        this.setState({ deleteClientInformation: clientInfo });
        this.setState({ deleteModal: true });
    };

    handleDeleteClientInfo = () => {
        const { deleteClientInformation } = this.state;

        if (deleteClientInformation.id !== undefined) {
            deleteClientInfo(deleteClientInformation.id).then(response => {
                this.setState({
                    message: response.data
                })
                this.fetchClientInfoList();
            });
            this.setState({
                 deleteModal: false,
                 custom_div1:true,
            
            });
        }

    };

    updateUserObject = (name, value, callback = null) => {
        let clientInfoCopy = Object.assign({}, this.state.clientInfo);
        clientInfoCopy[name] = value;

        this.setState({
            clientInfo: clientInfoCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }
    handleEditChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
        this.updateUserObject(name, value);
    }
    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchClientInfoList();
        });
    }

    render() {
        let paginator;
        let clientGroup = [];
        let clientInfoList = [];


        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
        }

        if (this.state.hasOwnProperty('clientInfoData')) {
            clientInfoList = this.state.clientInfoData.data;
            paginator = {
                'currentPage': this.state.clientInfoData.current_page,
                'pagesCount': this.state.clientInfoData.last_page,
                'total': this.state.clientInfoData.total,
                'to': this.state.clientInfoData.to,
                'from': this.state.clientInfoData.from,
            };
        }

        const { deleteModal, message } = this.state;

        return (

            <React.Fragment>
                <MetaTags>
                    <title>Client Info | Report Manager</title>
                </MetaTags>
                <Fade  cascade>
                <div className="page-content">
                    <Container fluid={true}>
                        {/* Render Breadcrumbs */}
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">Client List</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Client List</li>
                                </ol>
                            </div>
                        </div>
                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={this.handleDeleteClientInfo}
                            onCloseClick={() => this.setState({ deleteModal: false })}
                        />
                        <Row>
                            <Col xl={12}>
                                <div className='d-flex justify-content-end mb-3'>
                                    <div>
                                        {checkPermission(permissionEnum.CLIENT_INFO_DETIALS_CREATE) &&
                                            <Link to="/clientInfoList/create">
                                                <button className="btn btn-primary float-right"><i className="mdi mdi-plus-circle-outline me-1" />Create Client Info</button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                <div>
                                    {message == "" || message == undefined || message == null ?
                                        "" :
                                        this.state.custom_div1 ?
                                            <SweetAlert
                                                title={message}
                                                timeout={2000}
                                                showConfirm={false}
                                                showCloseButton={false}
                                                success
                                                onConfirm={() => this.setState({
                                                    custom_div1: false,
                                                })}
                                            >
                                            </SweetAlert> : null

                                    }
                                    {/* {this.props.location.state == null || this.props.location.state == "" || this.props.location.state == undefined ?
                                        "" :
                                        <UncontrolledAlert
                                            color="success"
                                            className="alert-dismissible fade show"
                                            role="alert"
                                        >
                                            <i className="mdi mdi-check-all me-2"></i>
                                            {this.props.location.state.message}
                                        </UncontrolledAlert>

                                    } */}
                                    {this.props.error && this.props.error ?
                                        <UncontrolledAlert
                                            color="success"
                                            className="alert-dismissible fade show"
                                            role="alert"
                                        >{this.props.error}</UncontrolledAlert> : null}

                                    {this.props.success && this.props.success ?
                                        (this.state.custom_div2 ?
                                            <SweetAlert
                                                title={this.props.success}
                                                timeout={2000}
                                                showConfirm={false}
                                                showCloseButton={false}
                                                success
                                                onConfirm={() => this.setState({
                                                    custom_div2: false,
                                                })}
                                            >
                                            </SweetAlert> : null)

                                        : null}

                                </div>
                                <Card>
                                    <CardBody>
                                        <DataTabeSearch handleInputChange={this.handleInputChange} />
                                        <Table responsive striped className='table-bordered'>
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col">S.No</th>
                                                    <th scope="col">Client Code</th>
                                                    <th scope="col">Client Name</th>
                                                    <th scope="col">EIN</th>
                                                    <th scope="col">Group Name</th>
                                                    <th scope="col">Phone No.</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Address</th>
                                                    <th scope="col">Created</th>
                                                    {/* <th scope="col">activity</th> */}
                                                    {(checkPermission(permissionEnum.CLIENT_INFO_DETIALS_UPDATE) || checkPermission(permissionEnum.CLIENT_INFO_DETIALS_DELETE)) &&
                                                        <th scope="col">Action</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {clientInfoList.map((clientInfo, index) =>
                                                    <ClientInfoRow key={index} clientInfo={clientInfo} tog_scroll={this.tog_scroll} toggleDeleteModal={this.toggleDeleteModal} onClickDelete={this.onClickDelete} index={index + 1 + ((this.state.page - 1) * this.state.per_page)} />
                                                )}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="bg-white">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">
                                            Edit Client Info
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                id: this.state.clientInfo && this.state.clientInfo.id || "",
                                                name: this.state.clientInfo && this.state.clientInfo.name || "",
                                                email: this.state.clientInfo && this.state.clientInfo.email || "",
                                                eiin: this.state.clientInfo && this.state.clientInfo.eiin || "",
                                                client_info_detail_code: this.state.clientInfo && this.state.clientInfo.client_info_detail_code || "",
                                                // password: this.state.clientInfo&& this.state.client.password || "",
                                                address: this.state.clientInfo && this.state.clientInfo.address || "",
                                                phone_number: this.state.clientInfo && this.state.clientInfo.phone_number || "",
                                                f_client_group_id: this.state.clientInfo && this.state.clientInfo.f_client_group_id || ""
                                            }}
                                            validationSchema={Yup.object().shape({
                                                name: Yup.string().required(
                                                    "Please Enter Client Info Name"
                                                ),
                                                f_client_group_id: Yup.string().required(
                                                    "Please Enter Group"
                                                ),
                                                eiin: Yup.string().required(
                                                    "Please Enter EIN"
                                                ),
                                                client_info_detail_code: Yup.string().required(
                                                    "Please Enter Client Code"
                                                ),

                                            })}
                                            onSubmit={(values) => {
                                                console.log(values);
                                                this.props.editClientInfo(values);
                                                this.setState(prevState => ({
                                                    modal_scroll: !prevState.modal_scroll,
                                                    custom_div2: true
                                                }));
                                                this.fetchClientInfoList();
                                            }}
                                        >
                                            {({ errors, status, touched, handleChange }) => (
                                                <Form className="form-horizontal" >
                                                    <Field type="hidden" id="id" name="id" />
                                                    <FormGroup >
                                                        <Label htmlFor="name"> Name</Label>
                                                        <Field type="text" id="name" name="name"
                                                            className={
                                                                "form-control" +
                                                                (errors.name && touched.name
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            // onChange={(e) => this.handleEditChange(e)}
                                                            // value={this.state.clientInfo.name} 
                                                            placeholder="Enter  name..." required />
                                                        <ErrorMessage
                                                            name="name"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                        {/* <FormText className="help-block">Please enter user name</FormText> */}
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Label htmlFor="client_info_detail_code"> Client Info Code </Label>
                                                        <Field type="text" id="client_info_detail_code" name="client_info_detail_code"
                                                            className={
                                                                "form-control" +
                                                                (errors.client_info_detail_code && touched.client_info_detail_code
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            // onChange={(e) => this.handleEditChange(e)}
                                                            // value={this.state.clientInfo.name} 
                                                            placeholder="Enter  client info detail code..." required />
                                                        <ErrorMessage
                                                            name="client_info_detail_code"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                        {/* <FormText className="help-block">Please enter user name</FormText> */}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label htmlFor="email">Email</Label>
                                                        <Field type="email" id="email" name="email"
                                                            className="form-control"
                                                            // onChange={(e) => this.handleEditChange(e)}
                                                            // value={this.state.clientInfo.email}
                                                            placeholder="Enter email..." />
                                                        {/* <FormText className="help-block">Please enter user email</FormText> */}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label htmlFor="eiin">EIN</Label>
                                                        <Field type="text" id="eiin" name="eiin"
                                                            className={
                                                                "form-control" +
                                                                (errors.eiin && touched.eiin
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            // onChange={(e) => this.handleEditChange(e)}
                                                            // value={this.state.clientInfo.eiin}
                                                            placeholder="Enter EIN..." readOnly />
                                                        <ErrorMessage
                                                            name="eiin"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                        {/* <FormText className="help-block">Please enter user EIIN</FormText> */}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label htmlFor="mobile">Phone No.</Label>
                                                        <Field type="text" id="phone_number" name="phone_number" className="form-control"
                                                            // onChange={(e) => this.handleEditChange(e)}
                                                            // value={this.state.clientInfo.phone_number}
                                                            placeholder="Enter phone no..." />
                                                        {/* <FormText className="help-block">Please enter user mobile number.</FormText> */}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <label className="col-form-label">Client Group</label>
                                                        <select name="f_client_group_id" id="f_client_group_id"
                                                            className={
                                                                "form-select" +
                                                                (errors.f_client_group_id && touched.f_client_group_id
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            value={values.f_client_group_id}
                                                            onChange={handleChange}

                                                        >
                                                            <option>Select</option>
                                                            {clientGroup.map((client, index) =>
                                                                <option value={client.cleint_group_id} key={index} selected>{client.name}</option>
                                                            )}
                                                        </select>
                                                        <ErrorMessage
                                                            name="f_client_group_id"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                        {/* <FormText className="help-block">Please enter client group</FormText> */}
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label htmlFor="address">Address</Label>
                                                        <Field type="text" id="address" name="address" as="textarea"
                                                            className="form-control"
                                                            // onChange={this.handleInputChange}
                                                            placeholder="Enter address..."
                                                        />
                                                        {/* <FormText className="help-block">Please enter user address</FormText> */}
                                                    </FormGroup>

                                                    {/* <div className="mb-3">
                                                        <Label htmlFor="password" className="form-label">Password</Label>
                                                        <Field type="password" id="password" className="form-control" name="password"
                                                            // onChange={this.handleInputChange}
                                                            placeholder="Enter password..." />
                                                        <FormText className="help-block">Please enter user
                                                            password</FormText>
                                                    </div> */}

                                                    {/* <FormGroup>
                                                        <Label htmlFor="password_confirmation">Confirm password</Label>
                                                        <Field type="password" id="password_confirmation"
                                                            name="password_confirmation" onChange={this.handleInputChange}
                                                            placeholder="Enter password again..." />
                                                        <FormText className="help-block">Please enter user password again</FormText>
                                                    </FormGroup> */}

                                                    {/* <div className="mb-3">
                                                        <Label htmlFor="Address" className="form-label">Address</Label>
                                                        <Field type="textarea" className="form-control" id="address" name="address"
                                                            // onChange={this.handleInputChange} 

                                                            placeholder="Enter mobile address..." />
                                                        <FormText className="help-block">Please enter user
                                                            address</FormText>
                                                    </div> */}
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={() =>
                                                                this.setState({ modal_scroll: false })
                                                            }
                                                        >
                                                            Close
                                                        </button>
                                                        <button type="submit" className="btn btn-primary">
                                                            Save changes
                                                        </button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>


                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
                </Fade>
            </React.Fragment>
        )
    }

}

ClientInfoListIndex.propTypes = {
    location: PropTypes.any,
    error: PropTypes.any,
    success: PropTypes.any,
    msg: PropTypes.any,
    client: PropTypes.any,
    editClientInfo: PropTypes.any,
};

const mapStateToProps = state => {
    const { error, success } = state.ClientInfo;
    return { error, success };
}



export default withRouter(connect(mapStateToProps, { getUserList, editClientInfo })(ClientInfoListIndex));

