import { CLIENT_INFO_ERROR,CLIENT_INFO_SUCCESS,EDIT_CLIENT_INFO,SHOW_INFO_CLIENT,CREATE_INFO_CLIENT} from './actionTypes';
import {getApi} from "../../services/httpService";


export const editClientInfo= (clientInfo) => {
    return {
        type: EDIT_CLIENT_INFO,
        payload: { clientInfo }
    }
}

export const clientInfoSuccess = (response) => {
    return {
        type: CLIENT_INFO_SUCCESS,
        payload: response.data.message
    }
}

export const clientInfoError = (error) => {
    return {
        type: CLIENT_INFO_ERROR,
        payload: error
    }
}

export function getClientInfoList(params) {
    return getApi().get('clientInfoDetails/list', {
        params: params
    })
}
export function getClientCode(params) {
    return getApi().get('clientInfoDetails/code/account_no', {
        params: params
    })
  }

export function getClientInfoDrop(id) {
    return getApi().get('clientInfoDetails/list/'+id)
}

export function getPublicClientInfoDrop(id) {
    return getApi().get('clientInfoDetails/public/list/'+id)
}

export function getClientCustomerInfoDrop(id) {
    return getApi().get('clientInfoDetails/customer/list/'+id)
}

export function getPublicClientCustomerInfoDrop(id) {
    return getApi().get('clientInfoDetails/public/customer/list/'+id)
}

export function createClientInfo(data) {
        return getApi().post('clientInfoDetails/create', data);
}

export function deleteClientInfo(id) {
    return getApi().put('clientInfoDetails/delete/'+id);
}




