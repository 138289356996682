import React, { Component } from 'react'
import PropTypes from "prop-types";
import SweetAlert from 'react-bootstrap-sweetalert';
import { MetaTags } from 'react-meta-tags';
import { Fade } from 'react-reveal';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, Form, CardHeader, CardFooter, InputGroup } from "reactstrap";
import Flatpickr from "react-flatpickr"
import moment from 'moment';
import { getPublicClientInfoDrop, getPublicClientList } from 'store/actions';
import { connect } from 'react-redux';
import { getUserDetails, createOrUpdateUser, getRoleList } from '../../store/auth/profile/actions';
import { createClient } from 'store/clients/actions';
import toastr from "toastr";
import { createLoan } from 'store/Loan/actions';

class CreateLoan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      per_page: 15,
      search: '',
      modal_scroll: false,
      deleteModal: false,
      client: [],
      showlist: 0,
      custom_div1: false,
      custom_div2: false,
      orginal_amount: 0,
      interest_rate: 0,
      checked: false
    };
  }
  fetchClientGroupList = () => {
    getPublicClientList().then((response) => {
      this.setState({
        clientData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }

  fetchClientInfoList = (clientGroup_id) => {
    getPublicClientInfoDrop(clientGroup_id).then((response) => {
      this.setState({
        clientInfoData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }
  componentDidMount() {
    this.fetchClientGroupList();
    // this.fetchClientInfoList();
    // this.fetchClientInfoList();
  }
  handleInputChange = (event) => {
    // event.preventDefault();
    if (event.target.name == "f_client_group_id") {
      this.fetchClientInfoList(event.target.value);
    }
  }
  handleOrginalAmount = (e) => {
    this.setState({
      orginal_amount: e.target.value
    })
  }
  handleInterestRate = (e) => {
    this.setState({
      interest_rate: e.target.value
    })
  }

  onHandleSubmit = (e) => {
    e.preventDefault()
    const data = {
      "loan_code": e.target.loan_code.value,
      "loan_ac_no": e.target.loan_ac_no.value,
      "f_client_group_id": e.target.f_client_group_id.value,
      "f_client_customer_info_id": e.target.f_client_customer_info_id.value,
      "original_loan_date": e.target.orginal_loan_date.value,
      "maturity_date": e.target.maturity_date.value,
      "loan_due_date": e.target.loan_due_date.value,
      "creditor": e.target.creditor.value,
      "original_amount": e.target.orginal_amount.value,
      "interest_rate_percentage": e.target.interest_rate_percentage.value,
      "taken_for_remarks": e.target.taken_for_remarks.value,
      "monthly_payment": e.target.monthly_payment.value,
      "collateral_or_security": e.target.collateral_or_security.value,
      "interest_rate_amount": e.target.interest_rate_amount.value,
      "is_fixed": this.state.checked ? 1 : 0,
      "next_loan_due_date": e.target.loan_due_date.value
    }
    if (e.target.loan_code.value === "") {
      toastr.error("Please Select Loan Code");
    }
    else if (e.target.loan_ac_no.value === "") {
      toastr.error("Please Select Loan Account Number");
    }
    else if (e.target.f_client_group_id.value === "") {
      toastr.error("Please Select Client Group Name");
    }
    else if (e.target.f_client_customer_info_id.value === "") {
      toastr.error("Please Select Client Info Name");
    }
    else if (e.target.orginal_loan_date.value === "") {
      toastr.error("Please Enter Orginal Loan Date");
    }
    else if (e.target.maturity_date.value === "") {
      toastr.error("Please Select Loan Closing Date");
    }
    else if (e.target.loan_due_date.value === "") {
      toastr.error("Please Select Loan Due Date");
    }
    else if (e.target.creditor.value === "") {
      toastr.error("Please Select creditor");
    }
    else if (e.target.orginal_amount.value === "") {
      toastr.error("Please Select Orginal Amount");
    }
    else if (e.target.interest_rate_percentage.value === "") {
      toastr.error("Please Select Interest Rate");
    }
    else if (e.target.taken_for_remarks.value === "") {
      toastr.error("Please Select Remarks");
    }
    else if (e.target.monthly_payment.value === "") {
      toastr.error("Please Select Monthly Payment");
    }
    else if (e.target.collateral_or_security.value === "") {
      toastr.error("Please Select Collateral/Security");
    }
    else if (e.target.interest_rate_amount.value === "") {
      toastr.error("Please Select Collateral/Security");
    }
    else if (e.target.loan_code.value !== "" && e.target.loan_ac_no.value !== "" && e.target.f_client_group_id.value !== "" && e.target.f_client_customer_info_id.value !== ""
      && e.target.orginal_loan_date.value !== "" && e.target.maturity_date.value !== "" && e.target.creditor.value !== "" && e.target.orginal_amount.value !== ""
      && e.target.interest_rate_percentage.value !== "" && e.target.taken_for_remarks.value !== "" && e.target.monthly_payment.value !== "" && e.target.collateral_or_security.value !== "" && e.target.interest_rate_amount.value !== "" && e.target.loan_due_date.value !== "") {

      createLoan(data)
        .then((response) => {
          if (response.data.status === true) {
            this.setState({
              message: response.data.message,
              custom_div2: true
            })
            setTimeout(() => {
              this.props.history.push({
                pathname: '/loanList',
              });
            }, 2000)

          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  handleCheckbox = () => {
    if (this.state.checked) {
      this.setState({
        checked: false
      })
    }
    else {
      this.setState({
        checked: true
      })
    }
  }

  render() {
    let clientGroup = [];
    let clientInfoList = [];
    if (this.state.hasOwnProperty('clientData')) {
      clientGroup = this.state.clientData.data;
    }
    if (this.state.hasOwnProperty('clientInfoData')) {
      clientInfoList = this.state.clientInfoData;
    }
    const { date, message } = this.state;
    console.log(this.state.orginal_amount);
    return (
      <React.Fragment>
        <MetaTags>
          <title>Loan Create | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              {/* Render Breadcrumbs */}
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Loan Create</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/loanList">Loan List</Link></li>
                    <li className="breadcrumb-item active">Loan Create</li>
                  </ol>
                </div>
              </div>

              {message == "" || message == undefined || message == null ?
                "" :
                this.state.custom_div2 ?
                  <SweetAlert
                    title={message}
                    timeout={2000}
                    showConfirm={false}
                    showCloseButton={false}
                    success
                    onConfirm={() => this.setState({
                      custom_div2: false,
                      message: undefined
                    })}
                  >
                  </SweetAlert> : null


              }

              <Row>
                <Col xs="12" sm="12">
                  <Form autoComplete='off' onSubmit={this.onHandleSubmit}>
                    <Card>
                      <CardHeader className="bg-white">
                        <strong className="fs-4">New Loan</strong>
                        <small className="fs-6"> Form</small>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs="12" sm="12" md={6}>
                            <FormGroup>
                              <label className="col-form-label">Client Group Name</label>
                              <select className="form-select" name="f_client_group_id" id="f_group_id" onChange={this.handleInputChange} required>
                                <option>Select</option>
                                {clientGroup.map((client, index) =>
                                  <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                )}
                              </select>
                            </FormGroup>

                            <FormGroup>
                              <label className="col-form-label">Client Info Name</label>
                              <select className="form-select" name="f_client_customer_info_id" id="f_client_id" onChange={this.handleInputChange} required>
                                <option value="">Select</option>
                                {clientInfoList && clientInfoList.map((clientInfo, index) =>
                                  <option value={clientInfo.id} key={index}>{clientInfo.name}</option>
                                )}

                              </select>
                            </FormGroup>
                            <FormGroup>
                              <Label>Loan Origination Date</Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  name='orginal_loan_date'
                                  options={{ allowInput: true }}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <Label>Maturity Date</Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  name='maturity_date'
                                  options={{ allowInput: true }}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <Label>Monthly Loan Due Date</Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  name='loan_due_date'
                                  options={{ allowInput: true }}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <Label>Creditor</Label>
                              <InputGroup>
                                <Input type="text" id="transaction_amount" name="creditor" className=' text-primary' required />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <Label>Orginal Amount</Label>
                              <InputGroup>
                                <Input onChange={this.handleOrginalAmount} type="number" step="any" id="transaction_amount" name="orginal_amount" className=' text-primary' required />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <Label>Loan Account Number</Label>
                              <InputGroup>
                                <Input type="text" id="transaction_amount" name="loan_ac_no" className=' text-primary' required />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col xs="12" sm="12" md={6} className="mt-2">
                            <FormGroup >
                              <Label htmlFor="name">Interest Rate Percentage</Label>
                              <Input onChange={this.handleInterestRate} type="number" id="transaction_amount" step="any" name="interest_rate_percentage" className='text-primary' required />
                            </FormGroup>
                            <FormGroup >
                              <input checked={this.state.checked} onChange={this.handleCheckbox} type="checkbox" /> <label htmlFor="">Fixed</label> <br />
                              <Label htmlFor="name">{this.state.checked ? "Fixed Rate Amount" : "Interest Rate Amount"}</Label>
                              <Input value={(this.state.orginal_amount * this.state.interest_rate) / 100} type="number" step="any" id="transaction_amount" name="interest_rate_amount" className='text-primary' required />
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Loan Code</Label>
                              <Input type="text" id="transaction_amount" name="loan_code" className='text-primary' required />
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Monthly Payment</Label>
                              <Input type="number" step="any" id="transaction_amount" name="monthly_payment" className='text-primary' required />
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Collateral/Security</Label>
                              <Input type="text" id="transaction_amount" name="collateral_or_security" className='text-primary' required />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="Address">Remarks</Label>
                              <Input type="textarea" id="remarks" name="taken_for_remarks" rows={11}
                                placeholder="Enter Remarks.." />
                            </FormGroup>
                          </Col>
                          <div className="py-3  d-flex justify-content-end ">
                            <Button type="submit" size="sm" color="primary"
                              className="me-2 pull-right"><i
                                className="fa fa-dot-circle-o" /> Submit</Button>
                            <Link to="/loanList">
                              <Button type="reset" size="sm" color="danger"
                                className="pull-right"><i
                                  className="fa fa-ban" /> Cancel</Button>
                            </Link>
                          </div>
                        </Row>
                      </CardBody>

                    </Card>
                  </Form>

                </Col>
              </Row>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    )
  }
}
CreateLoan.propTypes = {
  handleErrorResponse: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = state => {
  const { msg, error, success } = state.Profile;
  return { msg, error, success };
}
export default withRouter(connect(mapStateToProps, { getUserDetails, createClient })(CreateLoan));