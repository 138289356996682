
import { CREATE_FUND_CATEGORY,EDIT_FUND_CATEGORY,SHOW_FUND_CATEGORY,FUND_CATEGORY_SUCCESS,FUND_CATEGORY_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createFundCategory = (category) => {
    return {
        type: CREATE_FUND_CATEGORY,
        payload: { category }
    }
}

export const showFundCategory = (category_id) => {
    return {
        type: SHOW_FUND_CATEGORY,
        payload: { category_id }
    }
}

export const editFundCategory = (category) => {
    return {
        type: EDIT_FUND_CATEGORY,
        payload: { category }
    }
}


export const categoryFundSuccess = (msg) => {
    return {
        type: FUND_CATEGORY_SUCCESS,
        payload: msg
    }
}

export const categoryFundError = (error) => {
    return {
        type: FUND_CATEGORY_ERROR,
        payload: error
    }
}

export function fundcategoryList(){
    return getApi().get('fundCategory/list/')
}

export function fundCategoryShow(category_id){
    return getApi().get('fundCategory/'+category_id)
}


export function categoryList(params){
    return getApi().get('category/list',{
        params: params
    })
}

export function categoryListAll(params){
    return getApi().get('category/list/all',{
        params: params
    })
}


export function subcategoryList(params,subcategory_id){
    return getApi().get('category/sub/list/'+subcategory_id,{
        params: params
    })
}

export function fundCategoryTypeList(params){
    return getApi().get('fundCategory/fundtype/list',{
        params: params
    })
}


export const daleteFundCatagory = (id) => {
    // console.log("two",id)
      return getApi().put('fundCategory/delete/'+id,{
         // params: params
      })
  }