import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Input,
    FormGroup,
    InputGroup
} from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import { dashboardDataShow } from '../../store/dashboard/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { categoryList } from '../../store/category/actions';
import { createClientInfo, getClientInfoDrop, getClientCustomerInfoDrop, getPublicClientInfoDrop, getPublicClientCustomerInfoDrop } from 'store/clientInfo/actions';
import { getClientList, editClient, getPublicClientList } from '../../store/clients/actions';

//i18n
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from 'moment';

import { fundReport } from "store/fund/actions";
import Fade from 'react-reveal/Fade';




class FundReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            date: '',
            startDate: '',
            enDate: '',
            clientGroup: 0,
            clientInfo: 0,

            showDate: false,
            showClientGroup: false,
            showReport: false
        }

        this.togglemodal.bind(this)
        this.togglesubscribemodal.bind(this)
    }
    fetchDashboardList = () => {
        dashboardDataShow().then((response) => {
            this.setState({
                dashboardData: response.data,
                cateChartData: response.data.chartData,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchClientGroupList = () => {
        getPublicClientList().then((response) => {
            this.setState({
                clientData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }

    fetchClientInfoList = (clientGroup_id) => {
        getPublicClientInfoDrop(clientGroup_id).then((response) => {
            this.setState({
                clientInfoData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }
    fetchClientCustomerInfoList = (clientGroup_id) => {
        getPublicClientCustomerInfoDrop(clientGroup_id).then((response) => {
            // console.log("response", response);
            this.setState({
                clientCustomerInfoData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }

    fetchCategoryList = () => {

        categoryList().then((response) => {
            this.setState({
                categoryList: response.data,
            });

        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })
    }

    fetchFundReport = (data) => {
        fundReport(data).then((response) => {
            // console.log("response", response);
            if (response.success == true) {
                this.setState({
                    fundData: response.data.fundShow,
                    showReport: !this.state.showReport
                })
            } else {
                this.setState({
                    showReport: false
                })
            }
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }

    componentDidMount() {
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        const user = authUser.data.is_admin;
        const userInfo = authUser.data.user[0];
        if (user == 1) {
            this.fetchClientGroupList();
            this.setState({
                showClientGroup: !this.state.showClientGroup
            })
        }
        else if (user == 0) {
            if (userInfo.f_client_id != null) {
                this.fetchClientInfoList(userInfo.f_client_id);
            }
            this.setState({
                showClientGroup: false,
                clientGroup:userInfo.f_client_id
            })

        }
        this.fetchDashboardList();

        this.fetchCategoryList();

    }

    handleInputChange = (event, values) => {
        // event.preventDefault();
        // console.log("date", values);

        if (event.target.name == "f_group_id") {
            this.setState({
                clientGroup: event.target.value,
            })
            this.fetchClientInfoList(event.target.value);
        }
        if (event.target.name == "f_client_id") {
            // this.setState({
            //     clientInfo: event.target.value
            // })
            console.log(event.target.value);
            let data = {
                f_client_group_id: this.state.clientGroup,
                f_client_customer_info_id: event.target.value
            }
            // this.fetchClientCustomerInfoList(event.target.value);
            this.fetchFundReport(data);
        }

        // let clientGroup=this.state.clientGroup;
        // let clientInfo=this.state.clientInfo

    }

    // const { target: { name, value } } = event;

    // this.updateUserObject(name, value);



    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }

    togglesubscribemodal = () => {
        this.setState(prevState => ({
            subscribemodal: !prevState.subscribemodal,
        }))
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({ ...this.state, chartSeries: this.props.chartsData })
        }
    }

    render() {

        let monthWiseIncome = [];
        let monthCategoryIncome = [];

        if (this.state.hasOwnProperty('dashboardData')) {
            monthWiseIncome = this.state.dashboardData.monthWiseIncome;
            monthCategoryIncome = this.state.cateChartData;
        }
        let clientGroup = [];
        let clientInfoList = [];
        let categoryList = [];
        let categoryCustomerList = [];

        const authUser = JSON.parse(localStorage.getItem('authUser'));
        const userInfo = authUser.data.user;

        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
        }


        if (this.state.hasOwnProperty('clientInfoData')) {
            clientInfoList = this.state.clientInfoData;
        }
        if (this.state.hasOwnProperty('clientCustomerInfoData')) {
            categoryCustomerList = this.state.clientCustomerInfoData;
        }
        if (this.state.hasOwnProperty('categoryList')) {
            categoryList = this.state.categoryList.data;
        }

        let clientGroupInfo = [];
        let clientInfo = [];
        let fundTransaction = [];
        let fundNote = [];

        let fundTransactionDetail = [];
        if (this.state.hasOwnProperty('fundData')) {
            fundTransactionDetail = this.state.fundData == undefined ? '' : this.state.fundData;
            clientGroupInfo = this.state.fundData == undefined ? '' : this.state.fundData.client_group;
            clientInfo = this.state.fundData == undefined ? '' : this.state.fundData.client_info;
            fundTransaction = this.state.fundData == undefined ? '' : this.state.fundData.fund_tran_deatails;
            fundNote = this.state.fundData == undefined ? '' : this.state.fundData.fund_note;

            // console.log('fundTransactionDetail', fundTransaction);
        }



        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Fund Report | Report Manager</title>
                    </MetaTags>
                    <Fade cascade>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">Fund Report</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Fund Report</li>
                                </ol>
                            </div>
                        </div>
                        <Row>
                            <Col xl="12">
                                <Card className="mini-stats-wid">
                                    <CardBody>

                                        {/* Reports Render */}

                                        {/* {this.state.reports.map((report, key) => ( */}


                                        <Row>
                                            {this.state.showClientGroup ?
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <label className="col-form-label text-uppercase fw-bold">Client Group </label>
                                                        <select className="form-select" name="f_group_id" id="f_group_id" onChange={this.handleInputChange} >
                                                            <option selected>Select</option>
                                                            {clientGroup && clientGroup.map((client, index) =>
                                                                <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                                            )}
                                                        </select>

                                                    </FormGroup>
                                                </Col> : null}
                                            <Col md={this.state.showClientGroup==true?6:12}>
                                                <FormGroup>
                                                    <label className="col-form-label text-uppercase fw-bold">Client</label>
                                                    <select className="form-select" name="f_client_id" id="f_client_id" onChange={this.handleInputChange} >
                                                        <option >Select</option>
                                                        {clientInfoList && clientInfoList.map((clientInfo, index) =>
                                                            <option value={clientInfo.id} key={index} >{clientInfo.name}</option>
                                                        )}

                                                    </select>
                                                </FormGroup>


                                            </Col>
                                        </Row>

                                        {this.state.showReport ?
                                            <div className="mt-5">
                                                <Row>
                                                    <Col md={12}>
                                                        <div>
                                                            <h2 className="text-center fw-bold text-uppercase">Fund Report</h2>
                                                        </div>
                                                        {/* <Table responsive striped className='table-bordered'>
                                                            <thead className="table-light">
                                                                <tr className="text-center">
                                                                    <th>Client Group</th>
                                                                    <th>Client</th>
                                                                    <th>Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr id="addrMain" key="">
                                                                    <td>
                                                                        {clientGroup.name}
                                                                    </td>
                                                                    <td>
                                                                        {clientInfo.name}
                                                                    </td>
                                                                    <td>
                                                                        {fundTransactionDetail.transection_date}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table> */}
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="fw-bold">Client Group:{clientGroupInfo.name}</h6>
                                                                <h6 className="fw-bold">Client:{clientInfo.name}</h6>
                                                                <h6 className="fw-bold">Date:{fundTransactionDetail.transection_date}</h6>
                                                            </div>
                                                            <div className="col-md-6 float-end">
                                                                <h6 className="fw-bold ">Net Receivable Amount:  ${fundTransactionDetail.total_receivable_amount}</h6>
                                                                <h6 className="fw-bold ">Net Payable Amount: ${fundTransactionDetail.total_payble_amount}</h6>
                                                                <h6 className="fw-bold ">Available Fund: ${fundTransactionDetail.total_available_amount}</h6>

                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-5">
                                                    <Col md={6}>
                                                        {/* <CardTitle className="text-center py-3 ">Fund Account Receivable</CardTitle> */}
                                                        <Table responsive striped className='table-bordered border border-dark'>
                                                            <thead>
                                                                <tr>
                                                                    <th className="fw-bold text-center py-2 fs-3 text-uppercase" colSpan="2">Fund Account Receivable</th>
                                                                </tr>
                                                                <tr className="text-center">
                                                                    <th>Receivable Category</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {fundTransaction && fundTransaction.filter(receive => receive.is_receivable === 1).map((item1, idx) => (
                                                                    <tr key={idx}>
                                                                        <td>
                                                                            {item1.fund_category.fund_category_name}
                                                                        </td>
                                                                        <td>
                                                                            {item1.fund_amount}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>

                                                    </Col>

                                                    <Col md={6}>
                                                        {/* <CardTitle className="text-center py-3 ">Fund Account Payable</CardTitle> */}
                                                        <Table responsive striped className='table-bordered border border-dark'>
                                                            <thead>
                                                                <tr>
                                                                    <th className="fw-bold text-center py-2 fs-3 text-uppercase" colSpan="2">Fund Account Payable</th>
                                                                </tr>
                                                                <tr className="text-center">
                                                                    <th>Payable Category</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {fundTransaction && fundTransaction.filter(payable => payable.is_payable === 1).map((item2, idx) => (
                                                                    <tr id={"payable" + idx} key={idx}>
                                                                        <td>
                                                                            {item2.fund_category.fund_category_name}

                                                                        </td>
                                                                        <td>
                                                                            {item2.fund_amount}

                                                                        </td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>


                                                    </Col>
                                                </Row>

                                                {/* <Row>
                                            <Col md={12}>
                                                <Card>
                                                    <CardBody>
                                                        <Table responsive striped className='table-bordered'>
                                                            <thead className="table-primary">
                                                                <tr className="text-center">
                                                                    <th>Net Receivable Amount</th>
                                                                    <th>Net Payable Amount</th>
                                                                    <th>Available Fund</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr id="addrMain" key="">
                                                                    <td>
                                                                        ${fundTransactionDetail.total_receivable_amount}
                                                                    </td>
                                                                    <td>
                                                                        ${fundTransactionDetail.total_payble_amount}
                                                                    </td>
                                                                    <td>
                                                                        ${fundTransactionDetail.total_available_amount}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>

                                                    </CardBody>

                                                </Card>
                                            </Col>

                                        </Row> */}

                                                <Row>
                                                    <Col md={12}>
                                                        {/* <Card>
                                                            <CardBody> */}
                                                        <Table responsive striped className='table-bordered border border-dark'>
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th>Note Name</th>
                                                                    <th>Note Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {fundNote && fundNote.map((item3, idx) => (
                                                                    <tr key={idx}>
                                                                        <td>
                                                                            {item3.fund_note_name}
                                                                        </td>
                                                                        <td>
                                                                            ${item3.fund_note_amount}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                        {/* </CardBody>
                                                        </Card> */}
                                                    </Col>


                                                </Row>
                                            </div> : null
                                        }



                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                    </Fade>
                </div>

            </React.Fragment >
        )
    }
}

FundReport.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func
}



export default FundReport;

