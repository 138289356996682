import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
import PropTypes from 'prop-types';

class Doughnut extends Component {
  getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "left",
        data: this.props.customer,
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#556ee6", "#f1b44c", "#f46a6a", "#50a5f1", "#34c38f"],
      series: [
        {
          name: "Total sales",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: this.props.monthCategoryIncomeChart,
        },
      ],
    }
  }
  render() {
    
    let cusData = this.props.monthCategoryIncomeChart;
    let series= [{
      data: cusData
  }]

    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "350px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
Doughnut.propTypes = {
  chartSeries: PropTypes.any,
  periodData: PropTypes.any,
  monthCategoryIncomeChart:PropTypes.any,
  customer:PropTypes.any,
}
export default Doughnut
