import React, { Component } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from "react-router-dom";
import { Tbody } from "react-super-responsive-table";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from 'moment';
import { createClientInfo, getClientInfoDrop, getClientCustomerInfoDrop } from 'store/clientInfo/actions';
import { getClientList, editClient } from '../../store/clients/actions';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { payableList, receivableList, createFund, fundDetails } from "store/fund/actions";
import { connect } from "react-redux";
import Fade from 'react-reveal/Fade';

class FundTransactionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      noteArray: [{ note_name: "", note_amount: "" }],
      receivable: [{ is_receivable: 1, is_payable: 0, f_fund_receivable_category_id: "", receivable_amount: "" }],
      payable: [{ is_receivable: 0, is_payable: 1, f_fund_payable_category_id: "", payable_amount: "" }],
      receivableCount: 0,
      payableCount: 0,
      total_receivable_amount: 0,
      total_payable_amount: 0,
      total_available_amount: 0,
      date: new Date(),
    };
    // this.textareaReceveiablechange = this.textareaReceveiablechange.bind(this);
    // this.textareaPayablechange = this.textareaPayablechange.bind(this);
  }

  fetchClientGroupList = () => {
    getClientList().then((response) => {
      this.setState({
        clientData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
      })

  }

  fetchClientInfoList = (clientGroup_id) => {
    getClientInfoDrop(clientGroup_id).then((response) => {
      this.setState({
        clientInfoData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
      })

  }

  fetchReceivableList = () => {
    receivableList().then((response) => {
      this.setState({
        receivableList: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
      })

  }

  fetchPayableList = () => {
    payableList().then((response) => {
      this.setState({
        payableList: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
      })

  }

  fetchFundTransactionDetails = (id) => {
    fundDetails(id).then((response) => {
      this.setState({
        fundDetails: response.data.fundShow,
      })
    })
      .catch((err) => {
        console.log(err);
      })

  }




  componentDidMount() {
    if (this.props.match.params.id) {
      this.fetchFundTransactionDetails(this.props.match.params.id);
    }
  }


  handleAddReceivableNested = () => {
    const item1 = {
      is_receivable: 1,
      is_payable: 0,
      f_fund_receivable_category_id: "",
      receivable_amount: "",
    };
    this.setState({
      receivable: [...this.state.receivable, item1],
    });
  };

  handleAddPayableNested = () => {
    const item2 = {
      is_receivable: 0,
      is_payable: 1,
      f_fund_payable_category_id: "",
      payable_amount: ""
    };
    this.setState({
      payable: [...this.state.payable, item2],
    });
  };

  handleAddNoteNested = () => {
    const item3 = {
      note_name: "",
      note_amount: "",
    };
    this.setState({
      noteArray: [...this.state.noteArray, item3],
    });
  };

  handleRemoveRow = (e, idx) => {
    if (idx === "01") {
      document.getElementById("addr" + idx).style.display = "block";
    } else if (typeof idx != "undefined") {
      document.getElementById("addr" + idx).style.display = "none";
    }

  };

  handleRemoveReceivableNested = (e, idx) => {
    const list = [...this.state.receivable];
    list.splice(idx, 1);

    let receivable_amount = 0
    list.map(receive => {
      receivable_amount = receivable_amount + receive.receivable_amount * 1
    })

    let total_remove_receivable_amount = this.state.total_receivable_amount - receivable_amount;

    this.setState({
      total_receivable_amount: this.state.total_receivable_amount - total_remove_receivable_amount,
      total_available_amount: (this.state.total_receivable_amount - total_remove_receivable_amount) - this.state.total_payable_amount
    })

    this.setState({
      receivable: list,
    });
    this.setState.receivable = list;
  };

  handleRemovePayableNested = (e, idx) => {

    const list = [...this.state.payable];
    list.splice(idx, 1);

    let payable_amount = 0
    list.map(payable => {
      payable_amount = payable_amount + payable.payable_amount * 1
    })

    let total_remove_payable_amount = this.state.total_payable_amount - payable_amount

    this.setState({
      total_payable_amount: this.state.total_payable_amount - total_remove_payable_amount,
      total_available_amount: this.state.total_receivable_amount - (this.state.total_payable_amount - total_remove_payable_amount)
    })

    this.setState({
      payable: list,
    });
    this.setState.payable = list;
    // console.log("payable", this.state.payable)
  };

  handleRemoveNote = (e, idx) => {
    // console.log("idx", idx)

    const list = [...this.state.noteArray];
    list.splice(idx, 1);
    this.setState({
      noteArray: list,
    });
    this.setState.noteArray = list;
    // console.log("sss", this.state.noteArray)
  };


  // textareaReceveiablechange(event) {
  //     const count = event.target.value.length
  //     if (count > 0) {
  //         this.setState({ textareaReceiveablebadge: true })
  //     } else {
  //         this.setState({ textareaReceiveablebadge: false })
  //     }
  //     this.setState({ receivableCount: event.target.value.length })
  // }

  // textareaPayablechange(event) {
  //     const count = event.target.value.length
  //     if (count > 0) {
  //         this.setState({ textareaPayablechange: true })
  //     } else {
  //         this.setState({ textareaPayablebadge: false })
  //     }
  //     this.setState({ payableCount: event.target.value.length })
  // }

  handleMasterChange = (event) => {
    // event.preventDefault();
    // console.log("date", event.target.name);
    if (event.target.name == "f_client_group_id") {
      this.fetchClientInfoList(event.target.value);
    }
    // if (event.target.name == "f_client_id") {
    //     this.fetchClientCustomerInfoList(event.target.value);
    // }
    const { target: { name, value } } = event;

    this.updateUserObject(name, value);
  }

  updateUserObject = (name, value, callback = null) => {
    let fundCopy = Object.assign({}, this.state.fundMaster);
    fundCopy[name] = value;

    this.setState({
      fundMaster: fundCopy
    }, () => {
      if (typeof callback == "function")
        callback()
    });
  }

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const receivableFund = [...this.state.receivable];
    receivableFund[index][name] = value;
    this.setState({
      receivable: receivableFund,
    });

    let total_receivable_amount = 0;
    this.state.receivable.map(receive =>
      total_receivable_amount = total_receivable_amount + receive.receivable_amount * 1
    )

    this.setState({
      total_receivable_amount: total_receivable_amount,
      total_available_amount: total_receivable_amount - this.state.total_payable_amount
    })
    // console.log('total_receivable_amount', total_receivable_amount)

  }

  handleNoteInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.noteArray];
    list[index][name] = value;
    this.setState({
      noteArray: list,
    });
  }
  handlePayableChange = (e, index) => {
    const { name, value } = e.target;
    const payableFund = [...this.state.payable];
    payableFund[index][name] = value;
    this.setState({
      payable: payableFund,
    });

    let total_payable_amount = 0;
    this.state.payable.map(payable =>
      total_payable_amount = total_payable_amount + payable.payable_amount * 1
    )

    this.setState({
      total_payable_amount: total_payable_amount,
      total_available_amount: this.state.total_receivable_amount - total_payable_amount
    })
    // console.log('total_receivable_amount', total_payable_amount)
  }


  onHandleSubmit = (event) => {
    event.preventDefault();
    const { f_client_group_id, f_client_Info_id } = this.state.fundMaster
    const master = {
      f_client_group_id: f_client_group_id,
      f_client_Info_id: f_client_Info_id,
      total_receivable_amount: this.state.total_receivable_amount,
      total_payable_amount: this.state.total_payable_amount,
      total_available_amount: this.state.total_available_amount,
      receivable: this.state.receivable,
      payable: this.state.payable,
      noteArray: this.state.noteArray,
      transection_date: moment(new Date(this.state.date)).format("yyyy-MM-DD")
    };
    const data = {
      master: master,
    }

    this.props.createFund(data);

    // console.log("receivable", this.state.receivable);
    // console.log("payable", this.state.payable);
    // console.log("noteArray", this.state.noteArray);
    // console.log("data", data);
    // const { f_group_id, f_client_id, f_clients_customer_id, f_category_id, transaction_amount, remarks, transaction_date, transaction_date_time } = this.state.transInfo;
    // if (f_group_id == "") {
    //     toastr.error("Please Select Client Group");
    // }
    // else if (f_client_id == "") {
    //     toastr.error("Please Select Client");
    // }
    // else if (f_clients_customer_id == "") {
    //     toastr.error("Please Select Client Customer");
    // }
    // else if (f_category_id == "") {
    //     toastr.error("Please Select Category");
    // }
    // else if (transaction_amount == "") {
    //     toastr.error("Please Enter Transaction Amount");
    // }
    // else if (transaction_date == "") {
    //     toastr.error("Please Select Date");
    // }
    // else if (transaction_date != "" && transaction_amount != "" && f_category_id != "" && f_client_id != "" && f_group_id != "") {
    //     const data = {
    //         f_group_id: f_group_id,
    //         f_client_id: f_client_id,
    //         f_clients_customer_id: f_clients_customer_id,
    //         f_category_id: f_category_id,
    //         transaction_amount: transaction_amount,
    //         transaction_date: moment(new Date(this.state.date)).format("yyyy-MM-DD"),
    //         // transaction_date_time: transaction_date_time,
    //         remarks: remarks,
    //     };

    //     createTransaction(data)
    //         .then((response) => {
    //             if (response.data.status === true) {
    //                 this.props.history.push({
    //                     pathname: '/transaction',
    //                     state: { message: response.data.message }
    //                 });
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             // this.props.handleErrorResponse(err);

    //         });
    // }

  };

  render() {
    const { date } = this.state;
    let clientInfoList = [];
    let receivableCategoryList = [];
    let payableCategoryList = [];

    let clientGroup = [];
    let clientInfo = [];
    let fundTransaction = [];
    let fundNote = [];

    let fundTransactionDetail = [];
    if (this.state.hasOwnProperty('fundDetails')) {
      fundTransactionDetail = this.state.fundDetails;
      clientGroup = this.state.fundDetails.client_group;
      clientInfo = this.state.fundDetails.client_info;
      fundTransaction = this.state.fundDetails.fund_tran_deatails;
      fundNote = this.state.fundDetails.fund_note;

      // console.log('fundTransactionDetail', fundTransaction);
    }

    // if (this.state.hasOwnProperty('clientData')) {
    //     clientGroup = this.state.clientData.data;
    // }

    // if (this.state.hasOwnProperty('clientInfoData')) {
    //     clientInfoList = this.state.clientInfoData;
    // }


    // if (this.state.hasOwnProperty('receivableList')) {
    //     receivableCategoryList = this.state.receivableList;
    // }


    // if (this.state.hasOwnProperty('payableList')) {
    //     payableCategoryList = this.state.payableList;
    // }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Fund Transaction Details</title>
          </MetaTags>
          <Fade cascade>
            <div className="container-fluid">
              <Breadcrumbs title="Dashboard" breadcrumbItem="Fund Report Details" />

              {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
              {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
              {this.props.msg && this.props.msg ? (
                <Alert color="success">
                  Create Fund Successfully!
                </Alert>
              ) : null}
              {/* <Form onSubmit={this.onHandleSubmit}> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Card className="shadow bg-body rounded">
                        <CardBody>
                          <Table responsive striped className='table-bordered'>
                            <thead className="table-light">
                              <tr className="text-center">
                                <th>Client Group</th>
                                <th>Client</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id="addrMain" key="">
                                <td>
                                  {clientGroup.name}
                                </td>
                                <td>
                                  {clientInfo.name}
                                </td>
                                <td>
                                  {fundTransactionDetail.transection_date}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Card className="shadow bg-body rounded">
                        <CardBody>
                          <div className="inner-repeater mb-4">
                            <CardTitle className="text-center bg-primary py-3 text-light">Fund Account Receivable</CardTitle>
                            <Table responsive striped className='table-bordered table-hover'>
                              <thead className="table-light">
                                <tr className="text-center">
                                  <th>Receivable Category</th>
                                  <th>Amount</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {fundTransaction && fundTransaction.filter(receive => receive.is_receivable === 1).map((item1, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      {item1.fund_category.fund_category_name}
                                    </td>
                                    <td>
                                      ${item1.fund_amount.toLocaleString()}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card className="shadow bg-body rounded">
                        <CardBody>
                          <div className="inner-repeater mb-4">
                            <CardTitle className="text-center bg-primary py-3 text-light">Fund Account Payable</CardTitle>
                            <Table responsive striped className='table-bordered table-hover'>
                              <thead className="table-light">
                                <tr className="text-center">
                                  <th>Payable Category</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {fundTransaction && fundTransaction.filter(payable => payable.is_payable === 1).map((item2, idx) => (
                                  <tr id={"payable" + idx} key={idx}>
                                    <td>
                                      {item2.fund_category.fund_category_name}

                                    </td>
                                    <td>
                                      ${item2.fund_amount.toLocaleString()}

                                    </td>

                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <Table responsive striped className='table-bordered'>
                            <thead className="table-primary">
                              <tr className="text-center">
                                <th>Net Receivable Amount</th>
                                <th>Net Payable Amount</th>
                                <th>Available Fund</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id="addrMain" key="">
                                <td>
                                  ${fundTransactionDetail.total_receivable_amount?.toLocaleString()}
                                </td>
                                <td>
                                  ${fundTransactionDetail.total_payble_amount?.toLocaleString()}
                                </td>
                                <td>
                                  ${fundTransactionDetail.total_available_amount?.toLocaleString()}
                                </td>
                              </tr>
                            </tbody>
                          </Table>

                        </CardBody>

                      </Card>
                    </Col>

                  </Row>

                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <Table responsive striped className='table-bordered'>
                            <thead className="table-light">
                              <tr className="text-center">
                                <th>Note Name</th>
                                <th>Note Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fundNote && fundNote.map((item3, idx) => (
                                <tr key={idx}>
                                  <td>
                                    {item3.fund_note_name}
                                  </td>
                                  <td>
                                    ${item3.fund_note_amount.toLocaleString()}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          {/* <div className="mt-3">
                                                    <Label className="fw-bold fs-3 text-info">Note</Label>

                                                </div> */}
                        </CardBody>
                      </Card>
                    </Col>


                  </Row>


                  <CardFooter className="pt-0 bg-white d-flex justify-content-end">
                    <Button type="" size="sm" color="primary"
                      className="me-2 pull-right"><i
                        className="fa fa-dot-circle-o" /> Print </Button>
                    <Link to="/fundList">
                      <Button type="reset" size="sm" color="danger"
                        className="pull-right"><i
                          className="fa fa-ban" /> Back </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              {/* </Form> */}
            </div>
          </Fade>
        </div>
      </React.Fragment >
    )
  }
}

FundTransactionDetails.propTypes = {
  match: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  msg: PropTypes.any,
  handleErrorResponse: PropTypes.any,
  createFund: PropTypes.any,
  editFundCategory: PropTypes.any,


};


const mapStatetoProps = state => {
  const { error, success } = state.Fund;
  return { error, success };
}

export default withRouter(connect(mapStatetoProps, { createFund })(FundTransactionDetails));

