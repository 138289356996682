import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, Label, Input, UncontrolledAlert, FormGroup, FormText } from "reactstrap";
// import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../../Settings/Common/DataTabeSearch';
import { checkPermission, permissionEnum } from '../../../services/service-provider';

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toastr from "toastr";
// import '../ProductCategory/TreeDesign.scss';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Switch from 'react-switch';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createCategory, getCategoryType,  getAccountHeadType,categoryList, editCategory, categoryTreeList, showCategory, categoryShow, productCategoryImageUpload, daleteCatagory } from '../../../store/category/actions';
import DeleteModal from "pages/Calendar/DeleteModal";
import MetaTags from 'react-meta-tags';
import { values } from "lodash";


function CategoryRow(props) {
    const category = props.category;


    return (
        <tr key={category.toString() + 1}>
            <td scope="row">{props.index}</td>
            <td className="text-uppercase">{category.category_type == null || category.category_type == undefined ? '' : category.category_type.category_type_name}</td>
            <td>{category.category_name_en}</td>
            <td>{category.category_code}</td>
            {(checkPermission(permissionEnum.CATEGORY_UPDATE) || checkPermission(permissionEnum.CATEGORY_DELETE)) &&
                <td>
                    <div className="d-flex gap-3">
                        {checkPermission(permissionEnum.CATEGORY_UPDATE) &&
                            <i className="mdi mdi-pencil font-size-18 text-success" onClick={() => { props.tog_scroll(category) }}></i>
                        }
                        {checkPermission(permissionEnum.CATEGORY_DELETE) &&
                            <i
                                className="mdi mdi-delete font-size-18 text-danger"
                                id="deletetooltip"
                                onClick={() => {
                                    props.toggleDeleteModal();
                                    props.onClickDelete(category);
                                }
                                }
                            ></i>
                        }
                    </div>
                </td>
            }

        </tr>
    )
}

CategoryRow.propTypes = {
    tog_scroll: PropTypes.any,
    index: PropTypes.any,
    category: PropTypes.any,
    toggleDeleteModal: PropTypes.func,
    onClickDelete: PropTypes.func,
};

class CategoryCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            showAccounthead:false,
            status: true,
            is_bank: false,
            showEditAccountHead: false,
            parentCategory: 0,
            category_name_en: '',
            selectedOptions: {},
            category_icon: {},
            category: {

            },
            deleteModal: false,
        };
        // this.onClickDelete = this.onClickDelete.bind(this);
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryList();
        this.fetchCategoryTreeList();
        this.fetchgetCategoryType();
    }

    fetchCategoryList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        categoryList(options).then((response) => {
            this.setState({
                categoryList: response.data,
            });

        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })
    }

    fetchCategoryTreeList = () => {
        categoryTreeList().then((response) => {
            this.setState({
                rootCategories: response.data.rootCategories,
                categoryTree: response.data.childTree,
            });
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })
    }

    fetchCategoryDetails = (category_id) => {
        categoryShow(category_id).then((response) => {
            this.setState({
                category: response.data.productCategory,
            });
        })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })
    }

    fetchgetCategoryType = (category_id) => {
        getCategoryType(category_id).then((response) => {
            // console.log(response);
            this.setState({

                categoryType: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })
    }
    fetchAccountHeadType = () => {
        getAccountHeadType().then((response) => {
            console.log(response);
            this.setState({
                accountHeadType: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })
    }

    //modal
    tog_scroll = (category) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
            category: category
        }));

        if (category.category_type_id==2) {
            this.fetchAccountHeadType();
            this.setState({
                showEditAccountHead:true
            })
        }else{
            this.setState({
                showEditAccountHead:false
            })   
        }
        // this.fetchCategoryDetails(category_id);
        // this.fetchgetCategoryType();
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    handleImageUpload = (e) => {
        var id = e.target.id;
        var category_id = this.state.category.category_id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type, category_id: category_id };

            productCategoryImageUpload(formData, formData.category_id).then((response) => {
                this.fetchCategoryList();
                this.setState({
                    category_icon: response.data.productCategoryImage.category_icon,
                });
                // alert('Logo Uploaded Successfully');
            })
                .catch((err) => {
                    console.log(err);
                    // window.alert(err.response.data.message);
                    // window.location.href='/';
                })
        }
    }

    handleCategoryInputChange = (event) => {
        
        event.preventDefault();
        const { target: { name, value } } = event;

        if (name == 'category_type_id') {
            this.setState({
                category: event.target.value
            })
            
        }
        
        if (name == 'category_type') {
            if (event.target.value==2) {
                this.fetchAccountHeadType();
                this.setState({
                    showAccounthead: !this.state.showAccounthead,
                })
                console.log("event.target.value",event.target.value);
            }else {
                this.setState({
                    showAccounthead: false,
                })
            }
            
        }
        

        this.updateUserObject(name, value);
    }

    updateUserObject = (name, value, callback = null) => {
        let categoryInfoCopy = Object.assign({}, this.state.categoryInfo);
        categoryInfoCopy[name] = value;

        this.setState({
            categoryInfo: categoryInfoCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }


    // handleValidSubmit
    onHandleSubmit(event, values) {
        event.preventDefault();

        const { category_name_en, category_type, category_code,account_head } = this.state.categoryInfo;

        if (category_name_en == "") {
            toastr.error('Please write category name');
        }
        else if (category_type == "") {
            toastr.error('Please Select Category type');
        }
        else if (category_code == "") {
            toastr.error('Please give a category code');
        }
        else {
            const data = {
                category_name_en: category_name_en,
                category_code: category_code,
                category_type: category_type,
                account_head:account_head,
            }
            console.log('values', data);
            this.props.createCategory(data);
            event.target.reset();

            this.fetchCategoryList();
            this.fetchgetCategoryType();

        }

    }

    handleEditSubmit(event) {
        event.preventDefault();
        const { category_name_en, category_type_id, category_id, category_code,f_account_head_id } = this.state.category;
        const { target: { name, value } } = event;

        const data = {
            category_name_en: category_name_en,
            category_code: category_code,
            category_id: category_id,
            category_type_id: category_type_id,
            f_account_head_id: f_account_head_id
        }
        // console.log("data--->",data)
        this.props.editCategory(data);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
            showEditAccountHead:false
        }));
        this.fetchCategoryList();

        // toastr.success("Category Updated Successfully");
        this.form && this.form.reset();
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchCategoryList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault();
        this.setState({
            page: index
        }, () => {
            this.fetchCategoryList();
        });
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };

    onClickDelete = (categoryInfo) => {
        this.setState({ deleteCategory: categoryInfo });
        this.setState({ deleteModal: true });
    };

    handleDeleteCategory = () => {
        const { deleteCategory } = this.state;
        // console.log("deleteCategory", deleteCategory)

        if (deleteCategory !== undefined) {
            daleteCatagory(deleteCategory.category_id).then(response => {
                this.setState({
                    message: response.data
                })
            });
            setTimeout(function () {
                window.location.reload();
            }, 500);
            this.setState({ deleteModal: false }), () => {
                this.fetchCategoryList();
            };


        }
    };

    handleChange(status) {
        this.setState({ status });
    }

    handleCategoryEdit(e) {
        e.preventDefault();
        if (e.target.name == 'category_name_en') {
            this.setState({
                category_name_en: e.target.value
            });

        }
        // console.log('category_name_en', this.state.category_name_en)
        const { target: { name, value } } = e;

        if (name == 'category_type_id') {
            this.setState({
                category: e.target.value
            })
            if (e.target.value==2) {
                this.fetchAccountHeadType();
                this.setState({
                    showEditAccountHead: true,
                })  
            }else {
                this.setState({
                    showEditAccountHead: false,
                })  
            }
            
        }
        if (name == "category_code") {
            this.setState({
                category_code: e.target.value
            })
        }
        // if (name == 'category_type') {
        //     if (event.target.value==2) {
        //         this.fetchAccountHeadType();
        //         this.setState({
        //             showAccounthead: !this.state.showAccounthead,
        //         })
        //         console.log("event.target.value",event.target.value);
        //     }else {
        //         this.setState({
        //             showAccounthead: false,
        //         })
        //     }
            
        // }

        this.updateCategoryEditObject(name, value);
        // updateCategoryEditObject()
    }
    updateCategoryEditObject = (name, value, callback = null) => {
        let categoryInfoCopy = Object.assign({}, this.state.category);
        categoryInfoCopy[name] = value;

        this.setState({
            category: categoryInfoCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }

    render() {
        let categoryList = [];
        let CategoryTypeList = [];
        let accountHeadTypeList=[];
        let categoryTree = [];
        let paginator;
        if (this.state.hasOwnProperty('categoryList')) {
            categoryList = this.state.categoryList.data;
            // console.log(categoryList);
            paginator = {
                'currentPage': this.state.categoryList.current_page,
                'pagesCount': this.state.categoryList.last_page,
                'total': this.state.categoryList.total,
                'to': this.state.categoryList.to,
                'from': this.state.categoryList.from,
            };
        }

        if (this.state.hasOwnProperty('categoryType')) {
            CategoryTypeList = this.state.categoryType;

        }
        if (this.state.hasOwnProperty('accountHeadType')) {
            accountHeadTypeList = this.state.accountHeadType;

        }
        const { deleteModal, message } = this.state;
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        if (this.state.hasOwnProperty('categoryTree')) { categoryTree = this.state.categoryTree; }
        const { category_id, category_name_en, category_type_id, category_name_bn, f_category_info_id, is_menu, category_icon, f_account_head_id, category_code } = this.state.category;

        console.log("this.state.category",this.state.category);
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        // console.log("this.props.success", this.props.success)

        const treeStyle = { background: '#2d98f8', color: 'white', borderRadius: '8px', padding: '4px', fontFamily: 'arial', fontSize: '16px' };
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Category Info | Report Manager</title>
                </MetaTags>
                <div className="page-content">
                    <Container fluid={true}>

                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">CATEGORY</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Category</li>
                                </ol>
                            </div>
                        </div>
                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={this.handleDeleteCategory}
                            onCloseClick={() => this.setState({ deleteModal: false })}
                        />


                        <Row>

                            <Col lg="8" md="8">
                                <Card >
                                    <CardBody>
                                        <CardTitle >Category List</CardTitle>

                                        <div className="col-md-12" >

                                            <DataTabeSearch handleInputChange={this.handleInputChange} />
                                            {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                            {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    Category Created Successfully!
                                                </Alert>
                                            ) : null}
                                            <Table responsive striped className='table-bordered'>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Sub-Category </th>
                                                        <th scope="col">Sub-Category Code </th>
                                                        {/* <th scope="col">Date</th> */}
                                                        {/* <th scope="col">activity</th> */}
                                                        {(checkPermission(permissionEnum.CATEGORY_UPDATE) || checkPermission(permissionEnum.CATEGORY_DELETE)) &&
                                                            <th scope="col">Action</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {categoryList.map((category, index) =>
                                                        <CategoryRow key={index} category={category} tog_scroll={this.tog_scroll} toggleDeleteModal={this.toggleDeleteModal} onClickDelete={this.onClickDelete} index={index + 1 + ((this.state.page - 1) * this.state.per_page)} />
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="bg-white">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0 ">Category Edit</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                category_id: category_id || '',
                                                category_name_en: category_name_en || "",
                                                category_type_id: category_type_id || "",
                                                category_code: category_code || "",
                                                f_account_head_id: f_account_head_id || "",

                                            }}
                                            validationSchema={Yup.object().shape({
                                                category: Yup.string().required(
                                                    "Please Enter Category Name"
                                                ),
                                                category_type_id: Yup.string().required(
                                                    "Please Enter Category Type"
                                                )
                                            })}

                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="form-horizontal" onSubmit={(e) => this.handleEditSubmit(e)}>

                                                    <Field name="category_id" id="category_id" value={category_id} type="hidden" onChange={(e) => this.handleCategoryEdit(e)} />
                                                    <div className="mb-3">
                                                        <label className="col-form-label">Category</label>
                                                        <select name="category_type_id" id="category_type_id"
                                                            onChange={(e) => this.handleCategoryEdit(e)}

                                                            className={"form-select"
                                                                +
                                                                (errors.category_type_id && touched.category_type_id
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            value={category_type_id}
                                                            required="required"
                                                        >
                                                            <option value="">Select</option>
                                                            {CategoryTypeList != undefined && CategoryTypeList.map((client, index) =>
                                                                <option value={client.id} key={index} selected>{client.category_type_name}</option>
                                                            )}
                                                        </select>
                                                        <ErrorMessage
                                                            name="category_type_id"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                    {this.state.showEditAccountHead==true && 
                                                        <div className="mb-3">
                                                        <label className="col-form-label">Account Head</label>
                                                        <select name="f_account_head_id" id="f_account_head_id"
                                                            onChange={(e) => this.handleCategoryEdit(e)}

                                                            className={"form-select"
                                                                +
                                                                (errors.f_account_head_id && touched.f_account_head_id
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            value={f_account_head_id}
                                                            required="required"
                                                        >
                                                            <option value="">Select</option>
                                                            {accountHeadTypeList != undefined && accountHeadTypeList.map((client, index) =>
                                                                <option value={client.id} key={index} selected>{client.account_head_name}</option>
                                                            )}
                                                        </select>
                                                        <ErrorMessage
                                                            name="f_account_head_id"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                        </div>
                                                    }
                                                    <div className="mb-3">
                                                        <Label for="category_name" className="form-label">
                                                           Sub-Category<sup className="text-danger">*</sup>
                                                        </Label>
                                                        <Field type="text" id="category_name_en" name="category_name_en"
                                                            className={
                                                                "form-control" +
                                                                (errors.category && touched.category
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            value={category_name_en}
                                                            required

                                                            onChange={(e) => this.handleCategoryEdit(e)}

                                                            placeholder="Enter  Sub-Category..." />
                                                        {/* <ErrorMessage
                                                            name="category_name_en"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        /> */}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label for="category_code" className="form-label">
                                                            Sub-Category Code
                                                        </Label>
                                                        <Field
                                                            id="category_code"
                                                            name="category_code"
                                                            placeholder="Enter Sub-Category Code..."
                                                            value={category_code}
                                                            onChange={(e) => this.handleCategoryEdit(e)}
                                                            type="text"
                                                            className="form-control"
                                                            required
                                                        />
                                                    </div>
                                                    

                                                    <div className="mt-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary me-3"
                                                            onClick={() =>
                                                                this.setState({ modal_scroll: false })
                                                            }
                                                        >
                                                            Close
                                                        </button>
                                                        <button type="submit" className="btn btn-primary">
                                                            Update
                                                        </button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>




                                    </div>
                                </Modal>
                            </Col>

                            {checkPermission(permissionEnum.CATEGORY_CREATE) &&
                                <Col lg="4" md="4">
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-3">Create Category</CardTitle>


                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    // category_name_en: "",
                                                    // category_icon: '',
                                                    // status: true,
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    category: Yup.string().required(
                                                        "Please Enter Category Name"
                                                    )
                                                })}

                                            >
                                                {({ errors, status, touched }) => (
                                                    <Form className="form-horizontal" onSubmit={(e, v) => this.onHandleSubmit(e, v)}>
                                                        <div className="mb-3">
                                                            <label className="col-form-label">Category<sup className="text-danger">*</sup></label>
                                                            <select name="category_type" id="category_type"
                                                                onChange={(e) => this.handleCategoryInputChange(e)}
                                                                className="form-select"
                                                                required="required"
                                                            >
                                                                <option value="">Select</option>
                                                                {CategoryTypeList && CategoryTypeList.map((client, index) =>
                                                                    <option value={client.id} key={index}>{client.category_type_name}</option>
                                                                )}
                                                            </select>
                                                            <ErrorMessage
                                                                name="category_type"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        {this.state.showAccounthead==true &&
                                                            <div className="mb-3">
                                                            <label className="col-form-label">Account Head<sup className="text-danger">*</sup></label>
                                                            <select name="account_head" id="account_head"
                                                                onChange={(e) => this.handleCategoryInputChange(e)}
                                                                className="form-select"
                                                                required="required"
                                                            >
                                                                <option value="">Select</option>
                                                                {accountHeadTypeList && accountHeadTypeList.map((head, index) =>
                                                                    <option value={head.id} key={index}>{head.account_head_name}</option>
                                                                )}
                                                            </select>
                                                            <ErrorMessage
                                                                name="account_head"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                            </div>
                                                        }
                                                        

                                                        <div className="mb-3">
                                                            <Label for="category_name" className="form-label">
                                                               Sub-Category <sup className="text-danger">*</sup>
                                                            </Label>
                                                            <Field
                                                                id="category_name_en"
                                                                name="category_name_en"
                                                                placeholder="Enter Sub-Category..."
                                                                onChange={(e) => this.handleCategoryInputChange(e)}
                                                                type="text"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.category && touched.category
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                                required
                                                            />
                                                            <ErrorMessage
                                                                name="mobile"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label for="category_code" className="form-label">
                                                                Sub-Category Code <sup className="text-danger">*</sup>
                                                            </Label>
                                                            <Field
                                                                id="category_code"
                                                                name="category_code"
                                                                placeholder="Enter Sub-Category Code..."
                                                                onChange={(e) => this.handleCategoryInputChange(e)}
                                                                type="text"
                                                                className="form-control"
                                                                required

                                                            />
                                                        </div>



                                                        <div
                                                            className="form-check form-switch form-switch-md mb-3"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="customSwitchsizemd"
                                                                name="status"
                                                                // onChange={this.handleCategoryInputChange}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        status: !this.state.status,
                                                                    })
                                                                }}
                                                                defaultChecked={this.state.status}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="customSwitchsizemd"
                                                            >
                                                                {this.state.status ? ' Is Active' : ' Is Inactive'}
                                                            </label>
                                                            <Field name="status" value={this.state.status} type="hidden" />
                                                        </div>
                                                        <div className="mt-3">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary btn-block"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>


                                        </CardBody>
                                    </Card>
                                </Col>
                            }
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

CategoryCreate.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    msg: PropTypes.any,
    handleErrorResponse: PropTypes.any,
    createCategory: PropTypes.any,
    editCategory: PropTypes.any,


};


const mapStatetoProps = state => {
    const { error, success } = state.ProductCategory;
    return { error, success };
}

export default withRouter(connect(mapStatetoProps, { createCategory, editCategory, showCategory, daleteCatagory })(CategoryCreate));
