import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

export class DataTabeSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
render() {
        return (
            <Row className="p-0">
                <Col xs="2" md="2" className="float-start">
                    <FormGroup className="d-flex justify-content-between" check inline >
                        <Label className="form-check-label me-1 mt-1" check htmlFor="inline-radio1">Show</Label>
                        <Input type="select" name="per_page" id="per_page" bsSize="sm" className="border-none" onChange={this.props.handleInputChange}>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Input>
                        <Label className="form-check-label ms-1 mt-1" check htmlFor="inline-radio1">Entities</Label>
                    </FormGroup>
                </Col>
                <Col md={{ size: 3, offset: 7 }} xs={{ size: 3, offset: 7 }} className="float-end">
                    <FormGroup>
                        {/* <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default"><i className="fa fa-search"></i></InputGroup.Text>
                            <Input type="text" id="search" name="search" placeholder="Search" onChange={this.props.handleInputChange} />
                        </InputGroup> */}
                        <InputGroup>
                                <InputGroupText><i className="fa fa-search"></i></InputGroupText>
                            <Input type="text" id="search" name="search" placeholder="Search" onChange={this.props.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

DataTabeSearch.propTypes = {
    handleInputChange: PropTypes.func,
};