import React, { Component } from 'react';
import PropTypes from "prop-types";
import classnames from "classnames";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, Form, CardHeader, CardFooter, NavItem, TabContent, TabPane } from "reactstrap";

import { Link, NavLink } from "react-router-dom";
import toastr from "toastr";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import { getUserDetails, createOrUpdateUser, getRoleList, getClientGroup } from '../../store/auth/profile/actions';
import { getClientList, getClientSingleList, editClient } from '../../store/clients/actions';
import { getUserProfileInfo, updateUserProfile } from "../../actions/userProfileAction";
import Fade from 'react-reveal/Fade';

class UpdateProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {
                roles: [],
                status: 1
            },
            all_roles: [],

            activeTab: "1",
        };
        this.toggleTab = this.toggleTab.bind(this);
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }
    componentDidMount() {
        this.fetchUserDetails();

        this.fetchUserRoles();
        this.fetchClientGroupList();
    }


    fetchGetClientSingleList = (id) => {
        getClientSingleList(id).then((response) => {
            this.setState({
                clientSingleData: response.data,
            })
            this.setState({
                name: this.state.clientSingleData.ClientGroup.name,
                email: this.state.clientSingleData.ClientGroup.email,
                address: this.state.clientSingleData.ClientGroup.address,
                eiin: this.state.clientSingleData.ClientGroup.eiin,
                mobile: this.state.clientSingleData.ClientGroup.phone_number,
                f_client_group: this.state.clientSingleData.ClientGroup.cleint_group_id,
            })
        })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })

    }
    fetchClientGroupList = () => {
        getClientList().then((response) => {
            this.setState({
                clientData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })

    }
    fetchSingleClientInfo = (id) => {
        getClientGroup(id).then(response => {
            this.setState({
                clientGroup: response.data,
            })
        })
    }
    fetchUserDetails = () => {
        getUserProfileInfo()
            .then((response) => {
                let user = response.data.user
                let clientGroup = response.data.clientGroup

                if (user.eiin === null) {
                    document.getElementById('eiinFormGroup').style.display = "none";
                } else {
                    document.getElementById('eiin').readOnly = true;
                }

                this.setState({ activeTab: "1" })
                this.setState({
                    user: user,
                    clientGroup: clientGroup
                });

                // this.state.user= response.data.user;
            })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })
    };

    fetchUserRoles = () => {

        getRoleList()
            .then((response) => {
                this.setState({
                    all_roles: response.data.data
                })
            })
            .catch((err) => {
                console.log(err);
                // window.alert(err.response.data.message);
                // window.location.href='/';
            })
    }

    updateUserObject = (name, value, callback = null) => {
        let userCopy = Object.assign({}, this.state.user);
        userCopy[name] = value;

        this.setState({
            user: userCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }

    switchInputChange = (event) => {
        event.preventDefault();

        let name = event.target.name;
        let value = event.target.checked;
        this.updateUserObject(name, value);

    }

    handleInputChange = (event) => {
        event.preventDefault();
        // if(event.target.name == "f_client_group_id"){
        //   this.fetchSingleClientInfo(event.target.value);
        // }
        const { target: { name, value } } = event;
        if (event.target.name == "f_client_group_id") {
            this.fetchGetClientSingleList(event.target.value);
        }
        if (name == "address") {
            this.updateClientObject(name, value);
        }
        this.updateUserObject(name, value);

    }

    updateClientObject = (name, value, callback = null) => {
        let clientGroupCopy = Object.assign({}, this.state.clientGroup);
        clientGroupCopy[name] = value;

        this.setState({
            clientGroup: clientGroupCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }

    handleCounterChange = (item) => {
        this.updateUserObject('counter', item, () => {
            this.updateCoachObject('counter_id', item !== null ? item.value : 0);
        });
    };


    onHandleSubmit = (event) => {
        event.preventDefault();
        if (this.state.activeTab == "1") {
            let confirm = 1;
            const { id, name, eiin, mobile, email, password, password_confirmation, f_employee_id, f_wings_id, counter_id, roles, image, status } = this.state.user;
            const { address } = this.state.clientGroup;

            if (name == "") {
                toastr.error("Enter User Name ....");
                confirm = 0;
            }
            else if (mobile == "") {
                toastr.error("Enter User Number / EIIN ....");
                confirm = 0;
            }
            else if (password != "") {
                if (password !== password_confirmation) {
                    toastr.error("Passwords don't match");
                    confirm = 0;
                } else {
                    confirm = 1;
                }
            }


            if (confirm == 1) {

                const data = {
                    name: name,
                    mobile: mobile,
                    password: password,
                    email: email,
                    image: image,
                    eiin: eiin,
                    password_confirmation: password_confirmation,
                    status: status,
                    address: address

                };

                updateUserProfile(id, data)
                    .then((response) => {
                        if (response.data.status === true) {
                            this.props.history.push({
                                pathname: '/dashboard',
                                state: { message: response.data.message }
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        // this.props.handleErrorResponse(err);
                        // window.alert(err.response.data.message);
                        // window.location.href='/';

                    });
            }
        }

    };

    handleChange = (e) => {
        let roles = this.state.user.roles;

        if (e.target.checked === true) {
            roles.push(parseInt(e.target.dataset.id));
        } else {
            roles = roles.filter(function (role) {
                return role !== parseInt(e.target.dataset.id);
            });
        }

        let userCopy = Object.assign({}, this.state.user);
        userCopy["roles"] = roles;
        this.setState({
            user: userCopy
        });

    }

    getRoleInput = (role) => {

        const { user } = this.state;

        let check_role = user.roles.filter((role_id) => {
            return role.id === role_id
        })

        let status = false
        if (check_role.length) {
            status = true
        }

        return (
            <div
                className="form-check form-switch mb-3"
                dir="ltr"
            >
                <input
                    type="checkbox"
                    className="form-check-input"
                    id={role.id} name={role.id} data-id={role.id}
                    onChange={(e) => this.handleChange(e)} checked={status}
                />
                <label
                    className="form-check-label"
                    htmlFor="customSwitch1"
                >
                    {role.name.toUpperCase()}
                </label>
            </div>
            // <CustomInput className="m-2" type="switch" id={role.id} name={role.id} data-id={role.id}
            //              label={role.name.toUpperCase()}
            //              onChange={(e) => this.handleChange(e)} checked={status}/>
        )
    }

    render() {
        const { all_roles, user } = this.state;
        let { trimmedDataURL } = this.state;

        // let selectedCounter = user.counter ? { label: user.counter.name, value: user.counter.id } : null
        let clientGroup = [];
        if (this.state.hasOwnProperty('clientGroup')) {
            clientGroup = this.state.clientGroup;
        }
        // let clientSingleData = [];
        // let name = '';
        // let email = '';
        // let mobile = '';
        // let eiin = '';
        // let address = '';
        // const { name, email, address, eiin, mobile, f_client_group } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Fade cascade>
                        <Container fluid={true}>
                            <Row>
                                <ul
                                    className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
                                    role="tablist"
                                >
                                    <NavItem className='mx-2'>
                                        {
                                            this.state.activeTab == "1" &&
                                            <NavLink
                                                to="#"
                                                className={classnames({
                                                    active: this.state.activeTab === "1",
                                                })}
                                                onClick={() => {
                                                    this.toggleTab("1")
                                                }}
                                            >
                                                {/* User */}
                                            </NavLink>
                                        }

                                    </NavItem>
                                    <NavItem className='mx-2'>
                                        {
                                            this.state.activeTab == "2" &&
                                            <NavLink
                                                to="#"
                                                className={classnames({
                                                    active: this.state.activeTab === "2",
                                                })}
                                                onClick={() => {
                                                    this.toggleTab("2")
                                                }}
                                            >
                                                {/* Client */}
                                            </NavLink>
                                        }
                                    </NavItem>
                                </ul>
                                <TabContent className="p-4" activeTab={this.state.activeTab}>

                                    <TabPane tabId="1">
                                        <Col xs="12" sm="12">
                                            <Form onSubmit={this.onHandleSubmit}>
                                                <Card>
                                                    {/* <CardHeader>
                                                    <strong>Update User</strong>
                                                    <small> Form</small>
                                                </CardHeader> */}
                                                    <CardBody>
                                                        <Row>
                                                            <Col xs="12" sm="12" md={{ size: 6, offset: 1 }}>
                                                                <Card>

                                                                    <CardHeader>
                                                                        <strong>Update Profile</strong>
                                                                        {/* <small> Form</small> */}
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <FormGroup >
                                                                            <Label htmlFor="name"> Name</Label>
                                                                            <Input type="text" id="name" name="name"
                                                                                value={user.name} onChange={this.handleInputChange}
                                                                                placeholder="Enter  name..." required />
                                                                            {/* <FormText className="help-block">Please enter User
                                                                            Name</FormText> */}
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="email">Email</Label>
                                                                            <Input type="email" id="email" name="email"
                                                                                value={user.email} onChange={this.handleInputChange}
                                                                                placeholder="Enter email..." />
                                                                            {/* <FormText className="help-block">Please enter user
                                                                            email</FormText> */}
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="mobile">Phone No.</Label>
                                                                            <Input type="text" id="mobile" name="mobile"
                                                                                value={user.mobile} onChange={this.handleInputChange}
                                                                                placeholder="Enter Phone no..." required />
                                                                            {/* <FormText className="help-block">Please enter user
                                                                            mobile</FormText> */}
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="Address">Address</Label>
                                                                            <Input type="textarea" id="address" name="address"
                                                                                onChange={this.handleInputChange}
                                                                                value={clientGroup.address}
                                                                                placeholder="Enter Your address..." />
                                                                            {/* <FormText className="help-block">Please enter user
                                                                            address</FormText> */}
                                                                        </FormGroup>
                                                                        <FormGroup id="eiinFormGroup">
                                                                            <Label htmlFor="eiin">EIN</Label>
                                                                            <Input type="text" id="eiin" name="eiin"
                                                                                onChange={this.handleInputChange}
                                                                                value={user.eiin}
                                                                                placeholder="Enter EIN no..."
                                                                                readOnly />
                                                                            {/* <FormText className="help-block">Please enter user
                                                                            EIIN.</FormText> */}
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Label htmlFor="password">Password</Label>
                                                                            <Input type="password" id="password" name="password"
                                                                                onChange={this.handleInputChange}
                                                                                placeholder="Enter password..." />
                                                                            {/* <FormText className="help-block">Please enter your
                                                                            password</FormText> */}
                                                                        </FormGroup>

                                                                        <FormGroup>
                                                                            <Label htmlFor="password_confirmation">Confirm password</Label>
                                                                            <Input type="password" id="password_confirmation"
                                                                                name="password_confirmation" onChange={this.handleInputChange}
                                                                                placeholder="Enter password again..." />
                                                                            {/* <FormText className="help-block">Please enter user password again</FormText> */}
                                                                        </FormGroup>
                                                                    </CardBody>
                                                                    <CardFooter className="py-3 text-white  d-flex justify-content-end">
                                                                        <Button type="submit" size="sm" color="primary"
                                                                            className="me-2 pull-right"><i
                                                                                className="fa fa-dot-circle-o" /> Submit</Button>
                                                                        <Link to="/users">
                                                                            <Button type="reset" size="sm" color="danger"
                                                                                className="pull-right"><i
                                                                                    className="fa fa-ban" /> Cancel</Button>
                                                                        </Link>
                                                                    </CardFooter>

                                                                </Card>
                                                            </Col>

                                                        </Row>
                                                    </CardBody>

                                                </Card>
                                            </Form>
                                        </Col>
                                    </TabPane>

                                </TabContent>
                            </Row>
                        </Container>
                    </Fade>
                </div>
            </React.Fragment>

        );
    }
}
UpdateProfile.propTypes = {
    handleErrorResponse: PropTypes.any,
    match: PropTypes.any,
    history: PropTypes.any,
};

const mapStateToProps = state => {
    const { msg, error, success } = state.Profile;
    return { msg, error, success };
}




export default withRouter(connect(mapStateToProps, { getUserDetails, createOrUpdateUser, getRoleList })(UpdateProfile));

