import { CREATE_TRANSACTION,TRANSACTION_ERROR,TRANSACTION_SUCCESS,SHOW_TRANSACTION,EDIT_TRANSACTION, DELETE_CLIENT} from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ClientTransaction= (state = initialState, action) => {
    switch (action.type) {
        case EDIT_TRANSACTION:
            state = { ...state }
            break;
        case DELETE_CLIENT:
                state = { ...state }
                break;
        case TRANSACTION_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case TRANSACTION_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ClientTransaction;