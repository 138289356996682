import { PROFILE_ERROR,PROFILE_SUCCESS,EDIT_PROFILE,SHOW_PROFILE,CREATE_USER_PROFILE} from './actionTypes';
import {getApi} from "../../../services/httpService";

export const showProfile = (user_id) => {
    return {
        type: SHOW_PROFILE,
        payload: { user_id }
    }
}

export const editProfile = (user) => {
    return {
        type: EDIT_PROFILE,
        payload: { user }
    }
}

export const profileSuccess = (msg) => {
    return {
        type: PROFILE_SUCCESS,
        payload: msg
    }
}


export function profileShow(user_id){
    return getApi().get('users/id/'+user_id)
}

export const profileError = (error) => {
    return {
        type: PROFILE_ERROR,
        payload: error
    }
}


export function getUserDetails(user_id) {
    return getApi().get('user/' + user_id)
}

export function createOrUpdateUser(user_id, data) {
    if (user_id) {
        return getApi().put('user/'+user_id, data)
    } else {
        return getApi().post('user/registration', data)
    }
}

export function getRoleList(params) {
    return getApi().get('user/role/list', {
        params: params
    })
}
export function getCustomerCode(params) {
    return getApi().get('clientGroup/code/account_no', {
        params: params
    })
  }

export function getUserList(params) {
    return getApi().get('user/list', {
        params: params
    })
}

export function getClientGroup(id) {
    return getApi().get('clientGroup/',+id)
}