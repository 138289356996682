import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Form,
  Input,
  FormGroup,
  InputGroup,
  Pagination,
  PaginationItem
} from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
//import Charts
import StackedColumnChart from "./StackedColumnChart"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

//import action
import { getChartsData } from "../../store/actions"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"

//Import Breadcrumb
import { dashboardDataShow } from '../../store/dashboard/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { categoryList, clientWiseData, monthWiseData, expenseWiseData, expenseWiseFilterData, getFilterDashboardData, clientWiseFilterData, monthWiseFilterData } from '../../store/category/actions';
import { createClientInfo, getClientInfoDrop, getClientCustomerInfoDrop, getPublicClientInfoDrop, getPublicClientCustomerInfoDrop } from 'store/clientInfo/actions';
import { getClientList, editClient, getPublicClientList } from '../../store/clients/actions';
import { getTransactionList, deleteTransaction, editTransaction } from 'store/transaction/actions';
import { checkPermission, permissionEnum } from '../../services/service-provider';
import PieChart from "../AllCharts/apex/PieChart"
import DonutChart from "../AllCharts/apex/dountchart"
import BarChart from "../AllCharts/apex/barchart"
import Line from "../AllCharts/echart/linechart"
import Doughnut from "../AllCharts/echart/doughnutchart"

//i18n
import { withTranslation } from "react-i18next"
import classNames from "classnames";
import SplineArea from "../AllCharts/apex/SplineArea"
import Apaexlinecolumn from "../AllCharts/apex/apaexlinecolumn"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from 'moment';
import { CustomPagination } from "pages/Settings/Common/CustomPagination";
import './dashboard.scss';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import maintanence from "../../assets/images/coming-soon.svg"



class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      per_page: 12,
      search: '',
      reports: [
        { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: "$35, 723",
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: "$16.2",
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "yearly",
      date: '',
      startDate: '',
      enDate: '',


      showDate: false,
      showClientGroup: false,
      modal_large: false,
      modal_expense: false,
      client_modal_large: false,
      incomeVexp_modal_large: false,
      profit_modal_large: false,
      loan_modal_large: false,
      Breakdown_modal_large: false,
      displayPosition: ' ',
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
    this.tog_expense = this.tog_expense.bind(this)
    this.tog_large = this.tog_large.bind(this)
    this.client_tog_large = this.client_tog_large.bind(this)
    this.incomeVexp_tog_large = this.incomeVexp_tog_large.bind(this)
    this.profit_tog_large = this.profit_tog_large.bind(this)
    this.loan_tog_large = this.loan_tog_large.bind(this)
    this.Breakdown_tog_large = this.Breakdown_tog_large.bind(this)
  }
  fetchDashboardList = () => {
    dashboardDataShow().then((response) => {
      this.setState({
        dashboardData: response.data,
        cateChartData: response.data.chartData,
      });
    })
      .catch((err) => {
        console.log(err);
      })
  }
  fetchClientGroupList = () => {
    getPublicClientList().then((response) => {
      this.setState({
        clientData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }

  fetchClientInfoList = (clientGroup_id) => {
    getPublicClientInfoDrop(clientGroup_id).then((response) => {
      this.setState({
        clientInfoData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }
  fetchClientCustomerInfoList = (clientGroup_id) => {
    getPublicClientCustomerInfoDrop(clientGroup_id).then((response) => {
      this.setState({
        clientCustomerInfoData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }

  fetchCategoryList = () => {

    categoryList().then((response) => {
      this.setState({
        categoryList: response.data,
      });

    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })
  }

  fetchClientWiseList = () => {

    clientWiseData().then((response) => {
      this.setState({
        clientWiseData: response.data,
      });

    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })
  }

  fetchMonthWiseList = () => {

    monthWiseData().then((response) => {
      this.setState({
        monthWiseDataArray: response.data.data,
        cumulativeLoanGrph: response.data.cumlGraph,
      });

    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })
  }
  fetchExpensePercentagesList = () => {
    expenseWiseData().then((response) => {
      this.setState({
        expenseWiseDataArray: response.data.data,
        totalIncome: response.data.totalIncome,
        totalNetProfit: response.data.totalnetProfit,
        totalgrossProfit: response.data.totalgrossProfit
      });

    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })
  }
  componentDidMount() {
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    const user = authUser.data.is_admin;
    const userInfo = authUser.data.user[0];
    console.log('userInfo.f_client_id', userInfo.f_client_id)

    if (user == 1) {
      this.fetchClientGroupList();
      this.setState({
        showClientGroup: !this.state.showClientGroup
      })
    }
    else if (user == 0) {
      if (userInfo.f_client_id != null) {
        this.fetchClientInfoList(userInfo.f_client_id);
        this.fetchFilterClientWiseData();
        this.fetchFilterDashboardData();
        this.fetchFilterMonthWiseData();
        this.fetchFilterExpenseData();
      }

      this.setState({
        showClientGroup: false
      })

    }
    const { onGetChartsData } = this.props;
    this.fetchDashboardList();
    onGetChartsData("yearly");
    this.fetchCategoryList();
    this.fetchClientWiseList();
    this.fetchMonthWiseList();
    this.fetchExpensePercentagesList();

    if (typeof window !== "undefined") {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;
        let maxScroll = window.innerHeight;
        // console.log(maxScroll);
        if (currentScrollPos > 0 && currentScrollPos != maxScroll) {
          this.setState({ displayPosition: 'none' })
        } else {
          this.setState({ displayPosition: ' ' })
        }
      }
    }

  }


  handleDate = (startDate, endDate) => {

    let start = startDate && moment(new Date(startDate)).format("yyyy-MM-DD");

    let end = endDate && moment(new Date(endDate)).format("yyyy-MM-DD");

    this.setState({
      startDate: start,
      endDate: end,
    })
    console.log(start, end);

  }
  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large,
    }))
    this.removeBodyCss()
  }
  tog_expense() {
    this.setState(prevState => ({
      modal_expense: !prevState.modal_expense,
    }))
    this.removeBodyCss()
  }


  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  client_tog_large() {
    this.setState(prevState => ({
      client_modal_large: !prevState.client_modal_large,
    }))
    this.removeBodyCss()
  }
  incomeVexp_tog_large() {
    this.setState(prevState => ({
      incomeVexp_modal_large: !prevState.incomeVexp_modal_large,
    }))
    this.removeBodyCss()
  }
  profit_tog_large() {
    this.setState(prevState => ({
      profit_modal_large: !prevState.profit_modal_large,
    }))
    this.removeBodyCss()
  }
  loan_tog_large() {
    this.setState(prevState => ({
      loan_modal_large: !prevState.loan_modal_large,
    }))
    this.removeBodyCss()
  }

  Breakdown_tog_large() {
    this.setState(prevState => ({
      Breakdown_modal_large: !prevState.Breakdown_modal_large,
    }))
    this.removeBodyCss()
  }




  handlePageClick = (e, index) => {
    e.preventDefault();
    this.setState({
      page: index
    }, () => {
      this.fetchTransferList();
    });
  }

  fetchFilterDashboardData = () => {
    let sdate = this.state.date && this.state.date[0];
    let eDate = this.state.date && this.state.date[1];
    let startDate = moment(new Date(sdate)).format("yyyy-MM-DD");
    let endDate = moment(new Date(eDate)).format("yyyy-MM-DD");
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let f_g_id = authUser.data.user.f_client_id;
    let group_id = "";

    if (authUser.data.is_admin == 0) {
      console.log("group_id", this.state.f_group_id)
      group_id = f_g_id;
      this.setState({
        f_group_id: f_g_id,
      });
    }
    if (authUser.data.is_admin == 1) {
      group_id = this.state.f_group_id;
      console.log("aaaaagroup_id", this.state.f_group_id)
    }
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search,
      value: this.state.value,
      f_group_id: group_id,
      f_clients_customer_id: this.state.f_clients_customer_id,
      f_client_info_id: this.state.f_client_info_id,
      f_category_id: this.state.f_category_id,
      duration_type: this.state.type_id,
      startDate: startDate,
      endDate: endDate,
    };
    getFilterDashboardData(options)
      .then(response => {
        this.setState({
          dashboardData: response.data,
          cateChartData: response.data.chartData,
        });
      })
      .catch(err => {
        console.log(err);
      });

  };

  fetchFilterMonthWiseData = () => {
    let sdate = this.state.date && this.state.date[0];
    let eDate = this.state.date && this.state.date[1];
    let startDate = moment(new Date(sdate)).format("yyyy-MM-DD");
    let endDate = moment(new Date(eDate)).format("yyyy-MM-DD");
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let f_g_id = authUser.data.user.f_client_id;
    let group_id = "";

    if (authUser.data.is_admin == 0) {
      console.log("fetchFilterMonthWiseData-not", this.state.f_group_id)
      group_id = f_g_id;
      this.setState({
        f_group_id: f_g_id,
      });
    }
    if (authUser.data.is_admin == 1) {
      group_id = this.state.f_group_id;
      console.log("fetchFilterMonthWiseData", this.state.f_group_id)
    }
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search,
      value: this.state.value,
      f_group_id: group_id,
      f_clients_customer_id: this.state.f_clients_customer_id,
      f_client_info_id: this.state.f_client_info_id,
      f_category_id: this.state.f_category_id,
      duration_type: this.state.type_id,
      startDate: startDate,
      endDate: endDate,
    };
    monthWiseFilterData(options).then((response) => {

      this.setState({
        monthWiseDataArray: response.data.data,
        cumulativeLoanGrph: response.data.cumlGraph,
      });

    })
      .catch((err) => {
        console.log(err);

      })

  };
  fetchFilterClientWiseData = () => {
    let sdate = this.state.date && this.state.date[0];
    let eDate = this.state.date && this.state.date[1];
    let startDate = moment(new Date(sdate)).format("yyyy-MM-DD");
    let endDate = moment(new Date(eDate)).format("yyyy-MM-DD");
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let f_g_id = authUser.data.user.f_client_id;
    let group_id = "";

    if (authUser.data.is_admin == 0) {
      console.log("group_id", this.state.f_group_id)
      group_id = f_g_id;
      this.setState({
        f_group_id: f_g_id,
      });
    }
    if (authUser.data.is_admin == 1) {
      group_id = this.state.f_group_id;
      console.log("aaaaagroup_id", this.state.f_group_id)
    }
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search,
      value: this.state.value,
      f_group_id: group_id,
      f_clients_customer_id: this.state.f_clients_customer_id,
      f_client_info_id: this.state.f_client_info_id,
      f_category_id: this.state.f_category_id,
      duration_type: this.state.type_id,
      startDate: startDate,
      endDate: endDate,
    };

    clientWiseFilterData(options)
      .then((response) => {
        console.log("clientWiseFilterData", response);
        this.setState({
          clientWiseData: response.data,
        });

      })
      .catch((err) => {
        console.log(err);
      })

  };
  fetchFilterExpenseData = () => {
    let sdate = this.state.date && this.state.date[0];
    let eDate = this.state.date && this.state.date[1];
    let startDate = moment(new Date(sdate)).format("yyyy-MM-DD");
    let endDate = moment(new Date(eDate)).format("yyyy-MM-DD");
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let f_g_id = authUser.data.user.f_client_id;
    let group_id = "";

    if (authUser.data.is_admin == 0) {
      console.log("group_id", this.state.f_group_id)
      group_id = f_g_id;
      this.setState({
        f_group_id: f_g_id,
      });
    }
    if (authUser.data.is_admin == 1) {
      group_id = this.state.f_group_id;
      console.log("aaaaagroup_id", this.state.f_group_id)
    }
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search,
      value: this.state.value,
      f_group_id: group_id,
      f_clients_customer_id: this.state.f_clients_customer_id,
      f_client_info_id: this.state.f_client_info_id,
      f_category_id: this.state.f_category_id,
      duration_type: this.state.type_id,
      startDate: startDate,
      endDate: endDate,
    };

    expenseWiseFilterData(options).then((response) => {
      console.log("expenseWiseDataArray--response", response.data.data)
      this.setState({
        expenseWiseDataArray: response.data.data,
        totalIncome: response.data.totalIncome,
        totalNetProfit: response.data.totalnetProfit,
        totalgrossProfit: response.data.totalgrossProfit
      });

    })
      .catch((err) => {
        console.log(err);
        // window.alert(err.response.data.message);
        // window.location.href = '/';
      })

  };
  handleInputChange = (event, values) => {
    if (event.target.name == "f_group_id") {
      this.setState({
        f_group_id: event.target.value,
      });
      this.fetchClientInfoList(event.target.value);
      if (this.state.clientInfoData != null) {
        this.setState({
          clientCustomerInfoData: null
        })
      }

    }
    if (event.target.name == "f_client_info_id") {
      this.setState({
        f_client_info_id: event.target.value,
      });
      this.fetchClientCustomerInfoList(event.target.value);
    }
    if (event.target.name == "f_clients_customer_id") {
      this.setState({
        f_clients_customer_id: event.target.value,
      });

    }
    if (event.target.name == "f_category_id") {
      this.setState({
        f_category_id: event.target.value,
      });
      this.setState.f_category_id = event.target.value;
    }

    if (event.target.name == "f_filter_type") {
      if (event.target.value != "Custom") {
        this.setState({
          showDate: false,
          type_id: event.target.value
        })
      }
      else {
        this.setState({
          showDate: !this.state.showDate,
          type_id: event.target.value
        })
      }
    }
  }
  handleSearch = (e) => {
    e.preventDefault();
    // console.log("Submit")
    this.fetchFilterDashboardData();
    this.fetchFilterMonthWiseData();
    this.fetchFilterClientWiseData();
    this.fetchFilterExpenseData();

  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  printDiv = () => {
    var content = document.getElementById('modal-content').innerHTML;
    var title = document.title;
    var printWindow = window.open("", "_blank", "width=800,height=600");
    printWindow.document.open();
    printWindow.document.write('<html><head><title>' + title + '</title><style>@media print { @page {size: A4 portrait;}  footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width:297mm  !important; height:210mm !important; font-size:25px; } .modal-title {text-align:center; font-size:50px} .close{display:none;} .table{ margin-top:50px !important;  } thead{text-align:left !important; font-size:25px;} tbody{font-size:25px;} .table-bordered th, .table-bordered td {border: 1px solid black !important; }.table th, .table td {border-top: 1px solid black !important; } h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {margin-bottom: 0 !important; font-weight: 800 !important;  line-height: 1 !important;} .table th,.table td { padding: 1.35rem !important; }</style></head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    setTimeout(function () {
      printWindow.print();
      printWindow.close();
    }, 500);
  }

  printDiv1 = () => {
    var content = document.getElementById('modal-content1').innerHTML;
    var title = document.title;
    var printWindow = window.open("", "_blank", "width=800,height=600");
    printWindow.document.open();
    printWindow.document.write('<html><head><title>' + title + '</title><style>@media print { @page {size: A4 portrait;}  footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width:297mm  !important; height:210mm !important; font-size:25px; } .modal-title {text-align:center; font-size:50px} .close{display:none;} .table{ margin-top:50px !important;  } thead{text-align:left !important; font-size:25px;} tbody{font-size:25px;} .table-bordered th, .table-bordered td {border: 1px solid black !important; }.table th, .table td {border-top: 1px solid black !important; } h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {margin-bottom: 0 !important; font-weight: 800 !important;  line-height: 1 !important;} .table th,.table td { padding: 1.35rem !important; }</style></head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    setTimeout(function () {
      printWindow.print();
      printWindow.close();
    }, 500);
  }




  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, chartSeries: this.props.chartsData })
    }
  }

  render() {

    let monthWiseIncome = [];
    let monthCategoryIncome = [];
    let monthIncome = [];
    let customerIncome = [];
    let customer = [];
    let customerDogout = [];
    let incomeVsExpense = [];
    let incomeList = [];
    let expList = [];
    if (this.state.hasOwnProperty('dashboardData')) {
      monthWiseIncome = this.state.dashboardData.monthWiseIncome;
      monthCategoryIncome = this.state.cateChartData;
      monthIncome = this.state.cateChartData[0];
      customerIncome = this.state.dashboardData.customerArr;
      customer = this.state.dashboardData.customer;
      customerDogout = this.state.dashboardData.customerArrDougChart;
      incomeVsExpense = this.state.dashboardData.incomeVsExpense;
      incomeList = this.state.dashboardData.incomeVsExpense[0].data;
      expList = this.state.dashboardData.incomeVsExpense[1].data;
      console.log("incomeVsExpense", incomeVsExpense);
    }
    let clientGroup = [];
    let clientInfoList = [];
    let categoryList = [];
    let categoryCustomerList = [];
    let transactionList = [];
    let clientWiseDataList = [];
    let monthWiseDataList = [];
    let expenseWiseDataArrayList = [];
    let cumulaLoanGrph = [];
    let paginator;
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    const userInfo = authUser.data.user;

    if (this.state.hasOwnProperty('clientData')) {
      clientGroup = this.state.clientData.data;
    }


    if (this.state.hasOwnProperty('clientWiseData')) {
      clientWiseDataList = this.state.clientWiseData.data;
    }
    if (this.state.hasOwnProperty('monthWiseDataArray')) {
      monthWiseDataList = this.state.monthWiseDataArray;
      cumulaLoanGrph = this.state.cumulativeLoanGrph;
    }
    var average_month_profit = 0;
    var average_gross_profit_per = 0;
    var average_net_profit_per = 0;
    if (this.state.hasOwnProperty('expenseWiseDataArray')) {
      expenseWiseDataArrayList = this.state.expenseWiseDataArray;
      console.log("expenseWiseDataArrayList", expenseWiseDataArrayList);
      average_gross_profit_per = (this.state.totalgrossProfit * 1 / this.state.totalIncome * 1) * 100;
      average_net_profit_per = (this.state.totalNetProfit * 1 / this.state.totalIncome * 1) * 100;
    }

    expenseWiseDataArrayList.map(function (values) {
      average_month_profit += (values.netProfit) / expenseWiseDataArrayList.length;
    });
    var avg_month_income = 0;
    var avg_month_growth = 0;
    var ytd_profit = 0;
    var loan_balance = 0;
    monthWiseDataList.map(function (values) {
      avg_month_income += (values.income) / monthWiseDataList.length;
      avg_month_growth += (values.growth) / monthWiseDataList.length;
      loan_balance = (values.cumulativeLoan);
    });
    clientWiseDataList.map(function (values) {
      ytd_profit += (values.income * 1 - values.expense * 1) != NaN ? (values.income * 1 - values.expense * 1) : 0;
    });

    if (this.state.hasOwnProperty('clientInfoData')) {
      clientInfoList = this.state.clientInfoData;
    }
    if (this.state.hasOwnProperty('clientCustomerInfoData')) {
      categoryCustomerList = this.state.clientCustomerInfoData;
    }
    if (this.state.hasOwnProperty('categoryList')) {
      categoryList = this.state.categoryList.data;
    }
    return (
      <React.Fragment>
        <div className="page-content">

          <MetaTags>
            <title>Dashboard | Report Manager</title>
          </MetaTags>
          <Fade cascade>
            <Container fluid>

              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.props.t("Dashboards")}
                breadcrumbItem={this.props.t("Dashboard")}
              />
              <div id="fixed-div" style={{ position: 'fixed', width: '100%', top: '120px', zIndex: 50, display: this.state.displayPosition == ' ' ? 'none' : ' ', overflowY: 'scroll' }}>
                <Slide top>
                  <Row className="container-fluid show-div gx-5">

                    <Col lg="12" className="mt-4">

                      <Form onSubmit={e => this.handleSearch(e)}>
                        <Row>
                          {this.state.showClientGroup ?
                            <Col md={3}>
                              <FormGroup>
                                <select className="form-select" name="f_group_id" id="f_group_id" value={this.state.f_group_id} onChange={this.handleInputChange} >
                                  <option value={0}>Select Client Group </option>
                                  {clientGroup.map((client, index) =>
                                    <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                  )}
                                </select>

                              </FormGroup>
                            </Col> : null}
                          <Col md={this.state.showClientGroup == true ? 3 : 4}>
                            <FormGroup>
                              <select className="form-select" name="f_client_info_id" id="f_client_info_id" value={this.state.f_client_info_id} onChange={this.handleInputChange} >
                                <option value={0}>Select Client Name</option>
                                {clientInfoList && clientInfoList.map((clientInfo, index) =>
                                  <option value={clientInfo.id} key={index} >{clientInfo.name}</option>
                                )}

                              </select>
                            </FormGroup>
                          </Col>
                          <Col md={this.state.showClientGroup == true ? 3 : 4}>
                            <FormGroup>
                              <select className="form-select" name="f_clients_customer_id" id="f_clients_customer_id" value={this.state.f_clients_customer_id} onChange={this.handleInputChange} >
                                <option value={0}>Select Customer Name</option>
                                {categoryCustomerList && categoryCustomerList.map((clientInfo, index) =>
                                  <option value={clientInfo.customer_id} key={index}>{clientInfo.customer_name}</option>
                                )}

                              </select>
                            </FormGroup>
                          </Col>
                          <Col md={this.state.showClientGroup == true ? 3 : 4}>
                            <FormGroup>
                              <button type="submit" className="btn btn-primary form-control">Search</button>
                            </FormGroup>
                          </Col>
                        </Row>

                      </Form>

                    </Col>

                    <Col lg="12">
                      <Row >
                        <Col md="3" className="card-popup-design">
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-muted fw-medium">
                                    Average Monthly Income
                                  </p>
                                  <h4 className="mb-0"> ${(avg_month_income).toFixed()}
                                  </h4>
                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i
                                      className={
                                        "bx bx-purchase-tag-alt  font-size-24"
                                      }
                                    />

                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="3" className="card-popup-design">
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-muted fw-medium">
                                    Average Monthly Growth
                                  </p>
                                  <h4 className="mb-0"> {avg_month_growth.toFixed(2)}%
                                  </h4>

                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i
                                      className={
                                        "bx bx-archive-in font-size-24"
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="3" className="card-popup-design">
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-muted fw-medium">
                                    YTD Profit
                                  </p>
                                  <h4 className="mb-0">${(ytd_profit).toFixed(2)}
                                  </h4>
                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i
                                      className={
                                        "bx bx-copy-alt font-size-24"
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="3" className="card-popup-design">
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-muted fw-medium">
                                    Loan Balance
                                  </p>
                                  <h4 className="mb-0 text-danger">${(loan_balance).toFixed(2)}
                                  </h4>
                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i
                                      className={
                                        "bx bx-archive-in font-size-24"
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="3" className="card-popup-design">
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-muted fw-medium">
                                    Average Monthly Profit
                                  </p>
                                  <h4 className="mb-0"> ${(average_month_profit).toFixed()}
                                  </h4>
                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i
                                      className={
                                        "bx bx-purchase-tag-alt  font-size-24"
                                      }
                                    />

                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="3" className="card-popup-design">
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-muted fw-medium">
                                    Gross Profit %
                                  </p>
                                  <h4 className="mb-0"> {average_gross_profit_per.toFixed(2)}%
                                  </h4>

                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i
                                      className={
                                        "bx bx-archive-in font-size-24"
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="3" className="card-popup-design">
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-muted fw-medium">
                                    Net Profit %
                                  </p>
                                  <h4 className="mb-0">${(average_net_profit_per).toFixed(2)}
                                  </h4>
                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i
                                      className={
                                        "bx bx-copy-alt font-size-24"
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>

                    </Col>

                  </Row>
                </Slide>
              </div>
              <Slide bottom>
                <Row id="fixed-main-content" style={{ display: `${this.state.displayPosition}` }}>

                  <Form onSubmit={e => this.handleSearch(e)}>

                    <Row>
                      {this.state.showClientGroup ?
                        <Col md={3}>
                          <FormGroup>
                            <select className="form-select" name="f_group_id" id="f_group_id" onChange={this.handleInputChange} >
                              <option value={0}>Select Client Group </option>
                              {clientGroup.map((client, index) =>
                                <option value={client.cleint_group_id} key={index}>{client.name}</option>
                              )}
                            </select>

                          </FormGroup>
                        </Col> : null}
                      <Col md={this.state.showClientGroup == true ? 3 : 4}>
                        <FormGroup>
                          <select className="form-select" name="f_client_info_id" id="f_client_info_id" onChange={this.handleInputChange} >
                            <option value={0}>Select Client Name</option>
                            {clientInfoList && clientInfoList.map((clientInfo, index) =>
                              <option value={clientInfo.id} key={index} >{clientInfo.name}</option>
                            )}

                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={this.state.showClientGroup == true ? 3 : 4}>
                        <FormGroup>
                          <select className="form-select" name="f_clients_customer_id" id="f_clients_customer_id" onChange={this.handleInputChange} >
                            <option value={0}>Select Customer Name</option>
                            {categoryCustomerList && categoryCustomerList.map((clientInfo, index) =>
                              <option value={clientInfo.customer_id} key={index}>{clientInfo.customer_name}</option>
                            )}

                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={this.state.showClientGroup == true ? 3 : 4}>
                        <FormGroup>
                          <button type="submit" className="btn btn-primary form-control">Search</button>
                        </FormGroup>
                      </Col>

                      {this.state.showDate ?
                        <Col md={4}>
                          <FormGroup className="mb-4">
                            <label className="col-form-label text-uppercase fw-bold">Date Range</label>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                name="date_range"
                                placeholder="dd M,yyyy"
                                value={""}
                                onChange={
                                  (date) => {
                                    this.setState({ date })
                                  }
                                }
                                options={{
                                  mode: "range",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col> : null
                      }

                    </Row>
                  </Form>
                  <Col xl="4">
                    <WelcomeComp />
                  </Col>

                  <Col xl="8">
                    <Row>
                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Average Monthly Income
                                </p>
                                <h4 className="mb-0"> ${(avg_month_income).toLocaleString()}
                                </h4>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i
                                    className={
                                      "bx bx-purchase-tag-alt  font-size-24"
                                    }
                                  />

                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Average Monthly Growth
                                </p>
                                <h4 className="mb-0"> {avg_month_growth.toFixed(2)}%
                                </h4>

                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i
                                    className={
                                      "bx bx-archive-in font-size-24"
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  YTD Profit
                                </p>
                                <h4 className="mb-0">${(ytd_profit).toLocaleString()}
                                </h4>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i
                                    className={
                                      "bx bx-copy-alt font-size-24"
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Loan Balance
                                </p>
                                <h4 className="mb-0 text-danger">${(loan_balance).toLocaleString()}
                                </h4>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i
                                    className={
                                      "bx bx-archive-in font-size-24"
                                    }
                                  />
                                </span>
                              </div>

                            </div>
                          </CardBody>
                        </Card>
                      </Col>


                    </Row>

                  </Col>
                </Row>
                <Row>
                  <Col md="4" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Average Monthly Profit
                            </p>
                            <h4 className="mb-0"> ${(average_month_profit).toLocaleString()}
                            </h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx bx-purchase-tag-alt  font-size-24"
                                }
                              />

                            </span>
                          </div>
                          <div>
                            <i
                              type="button"
                              onClick={this.tog_expense}
                              className="fas fa-file-alt fs-5 text-secondary"
                              data-toggle="modal"
                              data-target=".bs-example-modal-lg"
                              title="Income By Month"
                            ></i>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Gross Profit %
                            </p>
                            <h4 className="mb-0"> {average_gross_profit_per.toFixed(2)}%
                            </h4>

                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx bx-archive-in font-size-24"
                                }
                              />
                            </span>
                          </div>
                          <div>
                            <i
                              type="button"
                              onClick={this.tog_expense}
                              className="fas fa-file-alt fs-5 text-secondary"
                              data-toggle="modal"
                              data-target=".bs-example-modal-lg"
                              title="Income By Month"
                            ></i>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Net Profit %
                            </p>
                            <h4 className="mb-0">${(average_net_profit_per).toLocaleString()}
                            </h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx bx-copy-alt font-size-24"
                                }
                              />
                            </span>
                          </div>
                          <div>
                            <i
                              type="button"
                              onClick={this.tog_expense}
                              className="fas fa-file-alt fs-5 text-secondary"
                              data-toggle="modal"
                              data-target=".bs-example-modal-lg"
                              title="Income By Month"
                            ></i>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Modal
                    size="lg"
                    isOpen={this.state.modal_expense}
                    toggle={this.tog_expense}
                  >
                    <div id="modal-content">
                      <div className="modal-header ">
                        <h5
                          className="modal-title mt-0"
                          id="exampleModalFullscreenLabel"
                        >
                          Montly Expense And Profit
                        </h5>
                        <button
                          onClick={() =>
                            this.setState({ modal_expense: false })
                          }
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body" >
                        <Row>
                          <Col lg="12">
                            <Card>
                              <CardBody>
                                <Table responsive striped className='table-bordered '>
                                  <thead className="table-light income1" >
                                    <tr>
                                      <th scope="col">Month</th>
                                      <th scope="col">Income</th>
                                      <th scope="col">COGS</th>
                                      <th scope="col">Operating Expense</th>
                                      <th scope="col">Non Operating Expense</th>
                                      <th scope="col">Gross Profit</th>
                                      <th scope="col">Net Profit</th>
                                      <th scope="col">GP %</th>
                                      <th scope="col">NP %</th>
                                    </tr>
                                  </thead>
                                  <tbody className="income">
                                    {expenseWiseDataArrayList != null && expenseWiseDataArrayList.map((cData, index) =>
                                      <>
                                        <tr>
                                          <td >{cData.month}</td>
                                          <td >{cData.monthWiseIncome}</td>
                                          <td>-{cData.monthWiseCogs}</td>
                                          <td>-{cData.monthWiseOperating}</td>
                                          <td>-{cData.monthWiseNonOperating}</td>
                                          <td>{(cData.grossProfit)}</td>
                                          <td>{(cData.netProfit)}</td>
                                          <td>{(cData.grossProfitPercent.toFixed(2))}%</td>
                                          <td>{(cData.netProfitPercent.toFixed(2))}%</td>
                                        </tr>
                                      </>
                                    )}

                                  </tbody>
                                </Table>

                              </CardBody>
                            </Card>
                          </Col>




                        </Row>
                      </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.printDiv()}
                      >
                        Print
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ modal_expense: false })
                        }
                        className="btn btn-secondary mx-3"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </Modal>
                </Row>
              </Slide>
              <Row>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <div>
                          <CardTitle className="mb-4 h4">Income By Month</CardTitle>

                        </div>
                        <div>
                          <i
                            type="button"
                            onClick={this.tog_large}
                            className="fas fa-file-alt fs-5 text-secondary"
                            data-toggle="modal"
                            data-target=".bs-example-modal-lg"
                            title="Income By Month"
                          ></i>
                        </div>
                      </div>
                      <div id="mix-line-bar" className="e-chart">
                        <Line monthCategoryIncomeChart={monthIncome} />
                      </div>
                    </CardBody>
                  </Card>

                  <Modal
                    size="lg"
                    isOpen={this.state.modal_large}
                    toggle={this.tog_large}
                  >
                    <div id="modal-content">
                      <div className="modal-header ">
                        <h5
                          className="modal-title mt-0"
                          id="exampleModalFullscreenLabel"
                        >
                          Income By Month
                        </h5>
                        <button
                          onClick={() =>
                            this.setState({ modal_large: false })
                          }
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body" >
                        <Row>
                          <Col lg="12">
                            <Card>
                              <CardBody>
                                <Table responsive striped className='table-bordered '>
                                  <thead className="table-light income1" >
                                    <tr>
                                      <th scope="col" style={{ borderLeft: '1px solid red', borderTop: '1px solid red' }}>Month</th>
                                      <th scope="col" style={{ borderRight: '1px solid red', borderTop: '1px solid red' }}>Income</th>
                                      <th scope="col">Growth</th>
                                      <th scope="col">Expense</th>
                                      <th scope="col">Loan</th>
                                      <th scope="col">Cumulative Loan</th>
                                      {/* <th scope="col">activity</th> */}
                                      {/* {(checkPermission(permissionEnum.TRANSACTION_INFO_UPDATE) || checkPermission(permissionEnum.TRANSACTION_INFO_DELETE)) &&
                            <th scope="col">Action</th>
                          } */}
                                    </tr>
                                  </thead>
                                  <tbody className="income">

                                    {monthWiseDataList != null && monthWiseDataList.map((cData, index) =>
                                      <>
                                        <tr>
                                          <td style={{ borderLeft: '1px solid red' }}>{cData.month}</td>
                                          <td style={{ borderRight: '1px solid red' }} className="">{cData.income}</td>
                                          <td>{cData.growth}%</td>
                                          <td>{cData.expense}</td>
                                          <td>{cData.receiveloan * 1 - cData.paidloan * 1}</td>
                                          <td>{(cData.cumulativeLoan * 1)}</td>
                                        </tr>
                                      </>
                                    )}

                                  </tbody>
                                </Table>

                              </CardBody>
                            </Card>
                          </Col>




                        </Row>
                      </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.printDiv()}
                      >
                        Print
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ modal_large: false })
                        }
                        className="btn btn-secondary mx-3"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </Modal>

                </Col>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <div>
                          <CardTitle className="mb-4 h4">Loan By Month </CardTitle>
                        </div>
                        <div>
                          <i
                            type="button"
                            onClick={this.loan_tog_large}
                            className="fas fa-file-alt fs-5 text-secondary"
                            data-toggle="modal"
                            data-target=".bs-example-modal-lg"
                            title="Income By Month"
                          ></i>
                        </div>
                      </div>
                      <div id="mix-line-bar" className="e-chart">
                        <Line monthCategoryIncomeChart={cumulaLoanGrph} />
                      </div>
                    </CardBody>
                  </Card>
                  <Modal
                    size="lg"
                    isOpen={this.state.loan_modal_large}
                    toggle={this.loan_tog_large}
                  >
                    <div id="modal-content1">
                      <div className="modal-header" >
                        <h5
                          className="modal-title mt-0"
                          id="exampleModalFullscreenLabel"
                        >
                          Loan By Month
                        </h5>
                        <button
                          onClick={() =>
                            this.setState({ loan_modal_large: false })
                          }
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Row>
                          <Col lg="12">
                            <Card>
                              <CardBody>
                                <Table responsive striped className='table-bordered '>
                                  <thead className="table-light">
                                    <tr>
                                      <th scope="col" style={{ borderLeft: '1px solid red', borderTop: '1px solid red', borderRight: '1px solid red' }}>Month</th>
                                      <th scope="col" >Income</th>
                                      <th scope="col">Growth</th>
                                      <th scope="col" style={{ borderRight: '1px solid red' }}>Expense</th>
                                      <th scope="col" style={{ borderLeft: '1px solid red', borderTop: '1px solid red' }}>Loan</th>
                                      <th scope="col" style={{ borderRight: '1px solid red', borderTop: '1px solid red' }}>Cumulative Loan</th>
                                    </tr>
                                  </thead>
                                  <tbody className="loan">

                                    {monthWiseDataList != null && monthWiseDataList.map((cData, index) =>
                                      <>
                                        <tr>
                                          <td style={{ borderLeft: '1px solid red', borderRight: '1px solid red' }}>{cData.month}</td>
                                          <td>{cData.income}</td>
                                          <td>{cData.growth}%</td>
                                          <td style={{ borderRight: '1px solid red' }}>{cData.expense}</td>
                                          <td >{cData.receiveloan * 1 - cData.paidloan * 1}</td>
                                          <td style={{ borderRight: '1px solid red' }}>{(cData.cumulativeLoan * 1)}</td>
                                        </tr>
                                      </>
                                    )}

                                  </tbody>
                                </Table>

                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.printDiv1()}
                      >
                        Print
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ loan_modal_large: false })
                        }
                        className="btn btn-secondary mx-3"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </Modal>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <div>
                          <CardTitle className="mb-4"> Income Vs Expense </CardTitle>
                        </div>
                        <div>
                          <i
                            type="button"
                            onClick={this.incomeVexp_tog_large}
                            className="fas fa-file-alt fs-5 text-secondary"
                            data-toggle="modal"
                            data-target=".bs-example-modal-lg"
                            title="Data Show"
                          >
                          </i>
                        </div>
                      </div>
                      <Apaexlinecolumn monthCategoryIncomeChart={incomeVsExpense} />
                    </CardBody>
                  </Card>
                  <Modal
                    size="lg"
                    isOpen={this.state.incomeVexp_modal_large}
                    toggle={this.incomeVexp_tog_large}
                  >
                    <div className="modal-header ">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        Income Vs Expense
                      </h5>
                      <button
                        onClick={() =>
                          this.setState({ incomeVexp_modal_large: false })
                        }
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>

                        <Col lg="12">
                          <Card>
                            <CardBody>
                              <Table responsive striped className='table-bordered'>
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col" style={{ borderLeft: '1px solid red', borderTop: '1px solid red' }}>Month</th>
                                    <th scope="col" style={{ borderLeft: '1px solid red', borderTop: '1px solid red', borderRight: '1px solid red' }} >Income</th>
                                    <th scope="col" style={{ borderRight: '1px solid red' }}>Growth</th>
                                    <th scope="col" style={{ borderRight: '1px solid red', borderTop: '1px solid red' }}>Expense</th>
                                    <th scope="col" >Loan</th>
                                    <th scope="col" >Cumulative Loan</th>
                                  </tr>
                                </thead>
                                <tbody className="inVSexp">

                                  {monthWiseDataList != null && monthWiseDataList.map((cData, index) =>
                                    <>
                                      <tr>
                                        <td style={{ borderLeft: '1px solid red' }}>{cData.month}</td>
                                        <td style={{ borderRight: '1px solid red' }}>{cData.income}</td>
                                        <td style={{ borderRight: '1px solid red' }}>{cData.growth}%</td>
                                        <td style={{ borderRight: '1px solid red' }}>{cData.expense}</td>
                                        <td >{cData.receiveloan * 1 - cData.paidloan * 1}</td>
                                        <td >{(cData.cumulativeLoan * 1)}</td>
                                      </tr>
                                    </>
                                  )}

                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>


                      </Row>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ incomeVexp_modal_large: false })
                        }
                        className="btn btn-secondary mx-3"
                        data-dismiss="modal"
                      >
                        Close
                      </button>

                    </div>
                  </Modal>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <div>
                          <CardTitle className="mb-4"> Income Expense Breakdown </CardTitle>
                        </div>
                        <div>
                          <i
                            type="button"
                            onClick={this.Breakdown_tog_large}
                            className="fas fa-file-alt fs-5 text-secondary"
                            data-toggle="modal"
                            data-target=".bs-example-modal-lg"
                            title="Data Show"
                          >
                          </i>
                        </div>
                      </div>
                      <div >
                        <Container>
                          <Row className="py-0">
                            <Col lg="12" >
                              <div className="text-center">
                                {/* <Link to="/">
                                  <img src={logo} alt="" height="20" />
                                </Link> */}
                                <Row className="justify-content-center">
                                  <Col sm="4">
                                    <div className="maintenance-img">
                                      <img
                                        src={maintanence}
                                        alt=""
                                        className="img-fluid mx-auto d-block"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <h4 className="mt-5">Coming Soon</h4>
                                <p className="text-muted">
                                  Thanks &#9829; For Your Patients!
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </CardBody>
                  </Card>
                  <Modal
                    size="lg"
                    isOpen={this.state.Breakdown_modal_large}
                    toggle={this.Breakdown_tog_large}
                  >
                    <div className="modal-header ">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        Income Expense Breakdown
                      </h5>
                      <button
                        onClick={() =>
                          this.setState({ Breakdown_modal_large: false })
                        }
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="my-auto">
                        <h4 className="fs-1">Coming Soon</h4>
                        <p className="text-muted fs-3">
                          Thanks &#9829; For Your Patients!
                        </p>
                      </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ Breakdown_modal_large: false })
                        }
                        className="btn btn-secondary mx-3"
                        data-dismiss="modal"
                      >
                        Close
                      </button>

                    </div>
                  </Modal>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <div>
                          <CardTitle className="mb-4">Income By Customer</CardTitle>
                        </div>
                        <div>
                          <i
                            type="button"
                            onClick={this.client_tog_large}
                            className="fas fa-file-alt fs-5 text-secondary"
                            data-toggle="modal"
                            data-target=".bs-example-modal-lg"
                            title="Data Show"
                          >

                          </i>

                        </div>
                      </div>
                      <BarChart monthCategoryIncomeChart={customerIncome} customer={customer} />
                    </CardBody>
                  </Card>
                  <Modal
                    size="lg"
                    isOpen={this.state.client_modal_large}
                    toggle={this.client_tog_large}
                  >
                    <div className="modal-header ">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        Income By Customer
                      </h5>
                      <button
                        onClick={() =>
                          this.setState({ client_modal_large: false })
                        }
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>

                        <Col lg="12">
                          <Card>
                            <CardBody>
                              <Table responsive striped className='table-bordered'>
                                <thead className="table-light ">
                                  <tr>
                                    <th scope="col" style={{ borderLeft: '1px solid red', borderTop: '1px solid red' }}>Client</th>
                                    <th scope="col" style={{ borderLeft: '1px solid red', borderTop: '1px solid red', borderRight: '1px solid red' }}>Income</th>
                                    <th scope="col">Expense</th>
                                    <th scope="col">Net Income</th>

                                  </tr>
                                </thead>
                                <tbody className="customer">
                                  {clientWiseDataList != null && clientWiseDataList.map((cData, index) =>
                                    <>
                                      <tr>
                                        <td style={{ borderLeft: '1px solid red' }}>{cData.customer_code}</td>
                                        <td style={{ borderRight: '1px solid red' }}>${cData.income}</td>
                                        <td>(${cData.expense})</td>
                                        <td>${(cData.income * 1 - cData.expense * 1) != NaN ? (cData.income * 1 - cData.expense * 1) : 0}</td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>


                      </Row>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ client_modal_large: false })
                        }
                        className="btn btn-secondary mx-3"
                        data-dismiss="modal"
                      >
                        Close
                      </button>

                    </div>
                  </Modal>
                </Col>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <div>
                          <CardTitle className="mb-4">Profit % By Customer</CardTitle>
                        </div>
                        <div>
                          <i
                            type="button"
                            onClick={this.profit_tog_large}
                            className="fas fa-file-alt fs-5 text-secondary"
                            data-toggle="modal"
                            data-target=".bs-example-modal-lg"
                            title="Data Show"
                          ></i>
                        </div>
                      </div>
                      <div id="doughnut-chart" className="e-chart">
                        <Doughnut monthCategoryIncomeChart={customerDogout} customer={customer} />
                      </div>
                    </CardBody>
                  </Card>
                  <Modal
                    size="lg"
                    isOpen={this.state.profit_modal_large}
                    toggle={this.profit_tog_large}
                  >
                    <div className="modal-header ">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        Profit % By Customer
                      </h5>
                      <button
                        onClick={() =>
                          this.setState({ profit_modal_large: false })
                        }
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>

                        <Col lg="12">
                          <Card>
                            <CardBody>
                              <Table responsive striped className='table-bordered'>
                                <thead className="table-light ">
                                  <tr>
                                    <th scope="col" style={{ borderLeft: '1px solid red', borderTop: '1px solid red' }}>Client</th>
                                    <th scope="col" style={{ borderTop: '1px solid red' }}>Income</th>
                                    <th scope="col" style={{ borderTop: '1px solid red', borderRight: '1px solid red' }}>Expense</th>
                                    <th scope="col">Net Income</th>

                                  </tr>
                                </thead>
                                <tbody className="profit">
                                  {clientWiseDataList != null && clientWiseDataList.map((cData, index) =>
                                    <>
                                      <tr>
                                        <td style={{ borderLeft: '1px solid red' }}>{cData.customer_code}</td>
                                        <td >${cData.income}</td>
                                        <td style={{ borderRight: '1px solid red' }}>(${cData.expense})</td>
                                        <td>${(cData.income * 1 - cData.expense * 1) != NaN ? (cData.income * 1 - cData.expense * 1) : 0}</td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </Table>

                            </CardBody>
                          </Card>
                        </Col>


                      </Row>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">

                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ profit_modal_large: false })
                        }
                        className="btn btn-secondary mx-3"
                        data-dismiss="modal"
                      >
                        Close
                      </button>

                    </div>
                  </Modal>
                </Col>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4"> Spline Area </CardTitle>
                      <SplineArea monthCategoryIncomeChart={monthCategoryIncome} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Fade>
        </div>
        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabindex="-1"
          toggle={this.togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.togglemodal}>Order Details</ModalHeader>
            <ModalBody>
              <p className="mb-2">
                Product id: <span className="text-primary">#SK2540</span>
              </p>
              <p className="mb-4">
                Billing Name:{" "}
                <span className="text-primary">Neal Matthews</span>
              </p>

              <div className="table-responsive">
                <Table className="table align-middle table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage1} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Solid Color T-Shirt
                          </h5>
                          <p className="text-muted mb-0">$ 225 x 1</p>
                        </div>
                      </td>
                      <td>$ 255</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage2} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Hoodie (Blue)
                          </h5>
                          <p className="text-muted mb-0">$ 145 x 1</p>
                        </div>
                      </td>
                      <td>$ 145</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Sub Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Shipping:</h6>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.togglemodal}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment >
    )
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
}

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
})

const mapDispatchToProps = dispatch => ({
  onGetChartsData: (periodType) => dispatch(getChartsData(periodType)),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(withTranslation()(Dashboard))
