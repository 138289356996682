import React, { Component } from 'react';
import { MetaTags } from 'react-meta-tags';
import { Fade } from 'react-reveal';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Table } from 'reactstrap';
import Flatpickr from "react-flatpickr"
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { createPayment, getLoanListShow } from 'store/Loan/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custom_div2: false,
      endingBalance: 0,
      sortData: '',
      sortType: 'asc',
      next_loan_due_date: ""
    };
  }
  getShowData = (sortField, sortType) => {
    let options = {
      sort_by_field: sortField,
      sort_type: sortType ? sortType : this.state.sortType,
    }
    getLoanListShow(this.props.match.params.id, options).then((response) => {
      this.setState({
        loanData: response?.data?.loanShow,
      })
    })
  }
  handlePrinciple = (e) => {
    this.setState({
      endingBalance: e.target.value
    })
  }
  onHandleSubmit = (e) => {
    const randomNumber = Math.random().toString(36).substring(2, 9);
    e.preventDefault()
    const data = {
      "payment_code": randomNumber.toUpperCase(),
      "f_loan_id": this.state.loanData?.loan_id,
      "f_client_group_id": this.state.loanData?.f_client_group_id,
      "f_client_customer_info_id": this.state.loanData?.f_client_customer_info_id,
      "payment_date": e.target.payment_date.value,
      "opening_balance": e.target.opening_balance.value,
      "payment_total": e.target.payment_total.value,
      "principal_amount": e.target.principal_amount.value,
      "interest_amount": e.target.interest_amount.value,
      "interest_percent": 0,
      "closing_balance": e.target.closing_balance.value,
      "next_loan_due_date": this.state.next_loan_due_date
    }

    createPayment(data)
      .then((response) => {
        if (response.data.status === true) {
          this.setState({
            message: response.data.message,
            custom_div2: true
          })
          setTimeout(() => {
            this.props.history.push({
              pathname: '/loanList',
            });
          }, 2000)

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleSort = (data) => {
    this.setState({
      sortData: data
    })
    if (this.state.sortType === "asc") {
      this.setState({ sortType: "desc" })
    }
    else {
      this.setState({ sortType: "asc" })
    }
    this.getShowData(data, this.state.sortType === "asc" ? "desc" : "asc")
  }

  handlePaymentDate = (e) => {
    const startDate = moment(this.state.loanData?.loan_due_date);
    const timeEnd = moment(e[0]);
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    function addMonths(numOfMonths, date = this.state.loanData?.loan_due_date) {
      date.setMonth(date.getMonth() + numOfMonths);
      return date;
    }
    const date = new Date(this.state.loanData?.loan_due_date)
    const callDate = addMonths(diffDuration._months + 1, date)
    this.setState({
      next_loan_due_date: moment(callDate).format("YYYY-MM-DD")
    })
  }

  componentDidMount() {
    this.getShowData();
  }
  render() {
    const { message } = this.state;
    let totalBeginingBal = 0;
    let totalPayment = 0;
    let totalPrincipal = 0;
    let totalInterestAmount = 0;
    let totalInterestPercent = 0;
    let totalEndingBal = 0;
    if (this.state.loanData?.loan_payment_details !== undefined) {
      for (const value of this.state.loanData?.loan_payment_details) {
        totalBeginingBal += value.opening_balance;
        totalPayment += value.payment_total;
        totalPrincipal += value.principal_amount;
        totalInterestAmount += value.interest_amount;
        totalInterestPercent += value.interest_percent;
        totalEndingBal += value.closing_balance;
      }
    }


    return (
      <React.Fragment>
        <MetaTags>
          <title>Payment | Report Manager</title>
        </MetaTags>
        {message == "" || message == undefined || message == null ?
          "" :
          this.state.custom_div2 ?
            <SweetAlert
              title={message}
              timeout={2000}
              showConfirm={false}
              showCloseButton={false}
              success
              onConfirm={() => this.setState({
                custom_div2: false,
                message: undefined
              })}
            >
            </SweetAlert> : null


        }
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              {/* Render Breadcrumbs */}
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Payment</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/loanList">Loan List</Link></li>
                    <li className="breadcrumb-item active">Payment</li>
                  </ol>
                </div>
              </div>

              <Row>
                <Col xs="12" sm="12">
                  <Form autoComplete='off' onSubmit={this.onHandleSubmit}>
                    <Card>
                      <CardHeader className="bg-white">
                        <strong className="fs-4">Payment</strong>
                        <small className="fs-6"> Form</small>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs="12" sm="12" md={6}>
                            <FormGroup>
                              <label className="col-form-label">Group Name : </label> <span>{this.state.loanData?.client_info?.name}</span>
                            </FormGroup>
                            <FormGroup>
                              <label className="col-form-label">Name :</label> <span>{this.state.loanData?.client_group?.name}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label>Loan Account Number</Label> : {this.state.loanData?.loan_ac_no}
                            </FormGroup>
                            <FormGroup>
                              <Label>Loan Orgination Date </Label> : <span>{this.state.loanData?.original_loan_date}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label>Loan Due Date </Label> : <span>{this.state.loanData?.next_loan_due_date}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label>Original Amount</Label> : $<span>{this.state.loanData?.original_amount.toLocaleString()} </span>
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Collateral or Security </Label> : {this.state.loanData?.collateral_or_security}
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">The loan was taken for  </Label> : {this.state.loanData?.taken_for_remarks}
                            </FormGroup>
                            <FormGroup>
                              <Label>Maturity Date</Label> : <span>{this.state.loanData?.maturity_date}</span>
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Present Balance </Label> : $<span>{this.state.loanData?.present_balance.toLocaleString()}</span>
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Interest Rate</Label> : <span>{this.state.loanData?.interest_rate_percentage}%</span>
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Monthly Payment</Label> : $<span>{this.state.loanData?.monthly_payment.toLocaleString()}</span>
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Creditor </Label> : <span>{this.state.loanData?.creditor}</span>
                            </FormGroup>
                          </Col>
                          <Col xs="12" sm="12" md={6} className="mt-2">
                            <FormGroup>
                              <Label>Payment Date</Label>
                              <InputGroup>
                                <Flatpickr
                                  onChange={this.handlePaymentDate}
                                  className="form-control d-block"
                                  name='payment_date'
                                  options={{ allowInput: true }}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Beginning Balance</Label>
                              <Input type="number" step="any" value={this.state.loanData?.loan_payment_details.length !== 0 ? this.state.loanData?.loan_payment_details[this.state.loanData?.loan_payment_details.length - 1]?.closing_balance : this.state.loanData?.original_amount} name="opening_balance" className='text-primary' required />
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Payment</Label>
                              <Input type="number" step="any" name="payment_total" className='text-primary' required />
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Principal</Label>
                              <Input onChange={this.handlePrinciple} type="number" step="any" name="principal_amount" className='text-primary' required />
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Interest Amount</Label>
                              <Input type="number" step="any" name="interest_amount" className='text-primary' required />
                            </FormGroup>
                            {/* <FormGroup >
                              <Label htmlFor="name">Interest Percentage</Label>
                              <Input type="number" step="any" name="interest_percent" className='text-primary' required />
                            </FormGroup> */}
                            <FormGroup >
                              <Label htmlFor="name">Ending Balance </Label>
                              <Input type="number" step="any" value={this.state.loanData?.loan_payment_details.length !== 0 ? this.state.loanData?.loan_payment_details[this.state.loanData?.loan_payment_details.length - 1]?.closing_balance - this.state.endingBalance : this.state.loanData?.original_amount - this.state.endingBalance} name="closing_balance" className='text-primary' required />
                            </FormGroup>
                          </Col>
                          <div className="py-3  d-flex justify-content-end ">
                            <Button type="submit" size="sm" color="primary"
                              className="me-2 pull-right"><i
                                className="fa fa-dot-circle-o" /> Submit</Button>
                            <Link to="/loanList">
                              <Button type="reset" size="sm" color="danger"
                                className="pull-right"><i
                                  className="fa fa-ban" /> Cancel</Button>
                            </Link>
                          </div>
                        </Row>
                      </CardBody>

                    </Card>
                  </Form>

                </Col>
              </Row>
              <div className="page-title-box d-sm-flex align-items-center mt-5">
                <h4 className="mb-sm-0 font-size-18">Loan Payment Details</h4>
              </div>
              <Card>
                <CardBody>
                  <Table responsive striped className='table-bordered'>
                    <thead className="table-light">
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Payment Date <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("payment_date")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("payment_date")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Beginning Balance <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("beginning_balance")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("beginning_balance")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Payment <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("payment")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("payment")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Principal <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("principal")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("principal")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Interest <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("interest_amount")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("interest_amount")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        {/* <th scope="col">Interest Percentage <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("interest_percentage")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("interest_percentage")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th> */}
                        <th scope="col">Ending Balance <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("ending_balance")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("ending_balance")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.loanData?.loan_payment_details.length !== 0 ?
                          <>
                            {
                              this.state.loanData?.loan_payment_details?.map((data, index) =>
                                <tr key={data.toString() + 1}>
                                  <td>{index + 1}</td>
                                  <td>{data.payment_date}</td>
                                  <td>${data.opening_balance.toLocaleString()}</td>
                                  <td>${data.payment_total.toLocaleString()}</td>
                                  <td>${data.principal_amount.toLocaleString()}</td>
                                  <td>${data.interest_amount.toLocaleString()}</td>
                                  {/* <td>{data.interest_percent}%</td> */}
                                  <td>${data.closing_balance.toLocaleString()}</td>
                                </tr>
                              )
                            }
                            <tr>
                              <td className='fw-bold'>Total</td>
                              <td></td>
                              <td className='fw-bold'>${totalBeginingBal.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                              <td className='fw-bold'>${(totalPayment.toLocaleString(undefined, { maximumFractionDigits: 2 }))}</td>
                              <td className='fw-bold'>${totalPrincipal.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                              <td className='fw-bold'></td>
                              {/* <td className='fw-bold'>${totalInterestAmount}</td> */}
                              {/* <td className='fw-bold'>{totalInterestPercent}%</td> */}
                              <td className='fw-bold'>${totalEndingBal.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                            </tr>
                          </>
                          : <p className='ps-2 pt-2 fw-bold'>No Payment Done Yet</p>}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}
Payment.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,

};
export default Payment;