import { DataTabeSearch } from 'pages/Settings/Common/DataTabeSearch';
import React, { Component } from 'react';
import { MetaTags } from 'react-meta-tags';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import { Card, CardBody, Container, Table } from 'reactstrap';
import { debtOverview } from 'store/Loan/actions';

class BusinessDebtOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      per_page: 10,
      f_group_id: "",
      sortData: '',
      sortType: 'asc'
    };
  }
  fetchDebtOverview = (sortField, sortType) => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let f_g_id = authUser.data.user[0].f_client_id;
    let is_admine = authUser.data.is_admin
    let group_id = "";
    this.setState({
      f_group_id: f_g_id,
    });
    if (authUser.data.is_admin == 0) {
      group_id = f_g_id;
    } else {
      group_id = this.state.f_group_id;
    }
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search,
      value: this.state.value,
      f_group_id: group_id,
      is_admin: is_admine,
      sort_by_field: sortField,
      sort_type: sortType ? sortType : this.state.sortType,
    };
    debtOverview(options).then((response) => {
      this.setState({
        debtOverview: response.data.loanReport,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })
  }

  handleSort = (data) => {
    this.setState({
      sortData: data
    })
    if (this.state.sortType === "asc") {
      this.setState({ sortType: "desc" })
    }
    else {
      this.setState({ sortType: "asc" })
    }
    this.fetchDebtOverview(data, this.state.sortType === "asc" ? "desc" : "asc")
  }


  componentDidMount() {
    this.fetchDebtOverview();
  }
  render() {
    let debtOverviewArray = this.state.debtOverview;
    let totalLoanBal = 0;
    let totalAvg = 0;
    let totalMonthlyPayment = 0;
    if (debtOverviewArray !== undefined) {
      for (const value of debtOverviewArray) {
        totalLoanBal += value.current_balance;
        totalAvg += value.interest_percent;
        totalMonthlyPayment += value.monthly_payment;
      }
    }
    return (
      <React.Fragment >
        <MetaTags>
          <title>Business Debt Overview | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Business Debt Overview </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/loanList">Loan List</Link></li>
                    <li className="breadcrumb-item active">Business Debt Overview </li>
                  </ol>
                </div>
              </div>
              <Card >
                <CardBody>
                  <DataTabeSearch />
                  <Table responsive striped className='table-bordered '>
                    <thead className="table-light">
                      <tr>
                        <th scope="col">S.No </th>
                        <th scope="col">Company {this.state.sortType === "desc" ? <i onClick={() => this.handleSort("company_name")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("company_name")} className="fa-sharp fa-solid fa-caret-down"></i>}</th>
                        <th scope="col">Total Loan Balance {this.state.sortType === "desc" ? <i onClick={() => this.handleSort("current_balance")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("current_balance")} className="fa-sharp fa-solid fa-caret-down"></i>}</th>
                        <th scope="col">Average Interest Rate {this.state.sortType === "desc" ? <i onClick={() => this.handleSort("interest_percent")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("interest_percent")} className="fa-sharp fa-solid fa-caret-down"></i>}</th>
                        <th scope="col">Monthly Payment {this.state.sortType === "desc" ? <i onClick={() => this.handleSort("monthly_payment")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("monthly_payment")} className="fa-sharp fa-solid fa-caret-down"></i>}</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {debtOverviewArray?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.company_name}</td>
                          <td>${data.current_balance.toLocaleString()}</td>
                          <td>{data.interest_percent.toFixed(1)}%</td>
                          <td>${data.monthly_payment.toLocaleString()}</td>
                          <td>
                            <Link to={`/overview/${data.company_id}`}>
                              <i className="mdi mdi-eye font-size-18 text-success"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td className='fw-bold'>Total</td>
                        <td className='fw-bold'></td>
                        <td className='fw-bold'>${totalLoanBal.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        <td className='fw-bold'></td>
                        {/* <td className='fw-bold'>{totalAvg.toFixed(1)}%</td> */}
                        <td className='fw-bold'>${totalMonthlyPayment.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        <td className='fw-bold'></td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
                {/* {paginator &&
                      <CardFooter className="bg-white py-0">
                        <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                      </CardFooter>
                    } */}
              </Card>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}

export default BusinessDebtOverview;