
import { CREATE_FUND,EDIT_FUND,SHOW_FUND,FUND_SUCCESS,FUND_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createFund = (data) => {

    return {
        type: CREATE_FUND,
        payload: { data }
    }
}

// export const showFundCategory = (category_id) => {
//     return {
//         type: SHOW_FUND_CATEGORY,
//         payload: { category_id }
//     }
// }

// export const editFundCategory = (category) => {
//     return {
//         type: EDIT_FUND_CATEGORY,
//         payload: { category }
//     }
// }


export const fundSuccess = (msg) => {
    return {
        type: FUND_SUCCESS,
        payload: msg
    }
}

export const fundError = (error) => {
    return {
        type: FUND_ERROR,
        payload: error
    }
}

// export function fundcategoryList(){
//     return getApi().get('fundCategory/list/')
// }

// export function fundCategoryShow(category_id){
//     return getApi().get('fundCategory/'+category_id)
// }


// export function categoryList(params){
//     return getApi().get('category/list',{
//         params: params
//     })
// }

// export function categoryListAll(params){
//     return getApi().get('category/list/all',{
//         params: params
//     })
// }


// export function subcategoryList(params,subcategory_id){
//     return getApi().get('category/sub/list/'+subcategory_id,{
//         params: params
//     })
// }

// export function fundCategoryTypeList(params){
//     return getApi().get('fundCategory/fundtype/list',{
//         params: params
//     })
// }


// export const daleteFundCatagory = (id) => {
//     // console.log("two",id)
//       return getApi().put('fundCategory/delete/'+id,{
//          // params: params
//       })
//   }


export function receivableList(){
    return getApi().get('fundCategory/receival/list')
}
export function payableList(){
    return getApi().get('fundCategory/payable/list')
}

export function fundList(){
    return getApi().get('fund/list')
}

export function fundDetails(id){
    return getApi().get('fund/'+id)
}
export function fundReport(params){
    console.log('params', params)
    return getApi().get('/transactionInfo/fund/report',{
        params:params,
    })
}