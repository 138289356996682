import React, { Component } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"
import {getUserProfileInfo} from "../../actions/userProfileAction";


class WelcomeComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      UserProfileInfo : {}
    }
  }

  componentDidMount() {
    this.fetchUserProfileInfo();
}

fetchUserProfileInfo = () => {
    getUserProfileInfo()
        .then((response) => {
          
            this.setState({
                UserProfileInfo: response.data.user,
            })
            
        }, (respose) => {

        })
        .catch((err) => {
            console.log(err);
            window.alert(err.response.data.message);
            window.location.href='/';
        })
  }


  render() {
    return (
      <React.Fragment>
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="7">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p>Dashboard</p>
                </div>
              </Col>
              <Col xs="5" className="align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>

              <Col sm="8">
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                    src={avatar1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 className="font-size-15 text-truncate">{this.state.UserProfileInfo.name}</h5>
                <p className="text-muted mb-0 text-truncate">{this.state.UserProfileInfo.eiin != null ? this.state.UserProfileInfo.eiin : ""}</p>
              </Col>

              <Col sm="4">
                  <div className="mt-5" style={{paddingTop: "25px"}}>
                    <Link
                      to={"/user/profile"}
                      className="btn btn-primary btn-sm"
                    >
                      View Profile {" "}<i className="mdi mdi-arrow-right ms-1"/>
                    </Link>
                  </div>
              </Col>

            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default WelcomeComp