import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postJwtLogin } from '../../../helpers/self_accounts_login_helper';

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(postJwtLogin, 'user/employee/login', { mobile: user.mobile, password: user.password, f_user_type: user.f_user_type });
            if (response.success == false) {
                yield put(apiError(response.message));
            }
            else if (response.success == true) (

                yield put(loginSuccess(response))
            )
            history.push('/dashboard');
            //  localStorage.setItem("authUser", JSON.stringify(response));

        }
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authUser");
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("menubar");
        localStorage.removeItem("permissions");
        // localStorage.removeItem("user");
        // localStorage.removeItem("I18N_LANGUAGE");
        // localStorage.removeItem("i18nextLng");
        setTimeout(function () {
            window.location.reload();
        });
        history.push('/login');

    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;