import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, Form, CardHeader, CustomInput, CardFooter, Table, UncontrolledAlert } from "reactstrap";

import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { checkPermission, permissionEnum } from '../../services/service-provider';
import { checkStatus } from '../../services/httpService';
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch'
import { CustomPagination } from "../Settings/Common/CustomPagination";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import { getUserList } from '../../store/auth/profile/actions';
import moment from 'moment';
import Fade from 'react-reveal/Fade';


function UserRow(props) {
    const user = props.user
    const userLink = `/users/${user.id}`
    // const useractivityLink = `/users/activity`

    return (
        <tr key={user.id.toString()}>
            <td scope="row">{props.index}</td>
            <td>{user.name}</td>
            <td>{user.mobile}</td>
            <td>{user.email == "" || user.email == undefined || user.email == null ? "" : user.email}</td>
            <td>
                {user.roles.map((role, index) =>

                    <Button size="sm" color={'primary'} key={index} className="me-2">{role.name}</Button>

                )}

                {/* {user.roles.map((role, index) => {
              return role.name
          })} */}
            </td>
            <td>{moment(new Date(user.created_at)).format("YYYY-MM-DD")}</td>
            <td>{user.f_user_type == 2 ? "Client" : "Admin"}</td>
            {/* <td><Link to={userLink}><Badge color={getBadge(user.status)}>{getStatusValue(user.status)}</Badge></Link></td> */}
            {/* <td><Link to={useractivityLink}><i className="fa fa-archive"></i></Link></td> */}
            {checkPermission(permissionEnum.USER_UPDATE) &&
                <td>
                    <Link to={userLink} >
                        <i className="mdi mdi-pencil font-size-18 text-success"></i>
                    </Link>
                </td>
            }
        </tr>
    )
}

UserRow.propTypes = {
    index: PropTypes.any,
    user: PropTypes.any,
};

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            data: {}
        };
    }

    fetchUserList = () => {

        checkStatus();
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }

        getUserList(options)
            .then((response) => {
                this.setState({
                    data: response.data
                })
            })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }
    componentDidMount() {
        this.fetchUserList();
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchUserList();
        });
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchUserList();
        });
    }

    render() {

        let userList = [];
        let paginator

        const { data, page, per_page } = this.state;

        if (data.hasOwnProperty('data')) {
            userList = data.data;
            paginator = {
                'currentPage': data.current_page,
                'pagesCount': data.last_page,
                'total': data.total,
                'to': data.to,
                'from': data.from,
            };
        }

        return (

            <React.Fragment>
                <MetaTags>
                    <title>User Info | Report Manager</title>
                </MetaTags>
                <Fade cascade>
                    <div className="page-content">
                        <Container fluid={true}>
                            {/* Render Breadcrumbs */}
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">USERS</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Users</li>
                                    </ol>
                                </div>
                            </div>
                            <Row>
                                <Col xl={12}>
                                    <div className='d-flex justify-content-between mb-3'>
                                        <div>
                                            <i className="fa fa-align-justify"></i> Users <small className="text-muted">List</small>
                                        </div>
                                        <div>
                                            {checkPermission(permissionEnum.USER_CREATE) &&
                                                <Link to="/users/create">
                                                    <button className="btn btn-primary float-right"><i className="mdi mdi-plus-circle-outline me-1" />Create User</button>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {this.props.location.state == null || this.props.location.state == "" || this.props.location.state == undefined ?
                                            "" :
                                            <UncontrolledAlert
                                                color="success"
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >
                                                <i className="mdi mdi-check-all me-2"></i>
                                                {this.props.location.state.message}
                                            </UncontrolledAlert>

                                        }
                                    </div>
                                    <Card>
                                        {/* <CardHeader>
                                        <i className="fa fa-align-justify"></i> Users <small className="text-muted">List</small>
                                        {checkPermission(permissionEnum.USER_CREATE) &&
                                            <Link to="/users/create">
                                                <button className="btn btn-primary float-right">Create User</button>
                                            </Link>
                                        }
                                    </CardHeader> */}
                                        <CardBody>
                                            <DataTabeSearch handleInputChange={this.handleInputChange} />
                                            <Table responsive striped className='table-bordered'>
                                                <thead className="table-light">
                                                    <tr className="text-center">
                                                        <th scope="col">S.No.</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Phone No.</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Role</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">User Type</th>
                                                        {/* <th scope="col">activity</th> */}
                                                        {checkPermission(permissionEnum.USER_UPDATE) &&
                                                            <th scope="col">action</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userList.map((user, index) =>
                                                        <UserRow key={index} user={user} index={index + 1 + ((page - 1) * per_page)} />
                                                    )}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                        {paginator &&
                                            <CardFooter className="bg-white">
                                                <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                            </CardFooter>
                                        }
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Fade>
            </React.Fragment>
        )
    }

}



const mapStatetoProps = state => {
    const { msg, error, success } = state.Profile;
    return { msg, error, success };
}

Users.propTypes = {
    location: PropTypes.any,
}



export default withRouter(connect(mapStatetoProps, { getUserList })(Users));

