import React from "react"
import PropTypes from 'prop-types'
import { Route, Redirect } from "react-router-dom"
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_VERSION + '/';
axios.defaults.headers = {'X-Requested-With': 'XMLHttpRequest'};
axios.defaults.timeout = 20000;

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected &&  localStorage.getItem("jwtToken") == null) {

        return (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        );
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default AppRoute
