import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, CardHeader, CustomInput, CardFooter, Table, UncontrolledAlert, Modal } from "reactstrap";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import toastr from "toastr";

import { checkPermission, permissionEnum } from '../../services/service-provider';

import { DataTabeSearch } from '../Settings/Common/DataTabeSearch'
import { CustomPagination } from "../Settings/Common/CustomPagination";
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import { getUserList } from '../../store/auth/profile/actions';
import { getClientList, editClient, deleteClient } from '../../store/clients/actions';
import { categoryList, clientWiseData, monthWiseData, getFilterDashboardData, clientWiseFilterData, monthWiseFilterData } from '../../store/category/actions';
import moment from 'moment';
import { Field, Formik, ErrorMessage, Form } from 'formik';
import DeleteModal from 'pages/Calendar/DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';



class CustomerReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            per_page: 15,
            search: '',
            modal_scroll: false,
            deleteModal: false,
            client: {

            },
            custom_div1: false,
            custom_div2: false,

        };
        this.props.location.state == undefined
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    fetchClientGroupList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        getClientList(options).then((response) => {

            this.setState({
                clientData: response.data,
            })

        })
            .catch((err) => {
                console.log(err.response.data.message);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }
    fetchClientWiseList = () => {

        clientWiseData().then((response) => {
          // console.log("clientWiseData", response)
          this.setState({
            clientWiseData: response.data,
          });
    
        })
          .catch((err) => {
            console.log(err);
            window.alert(err.response.data.message);
            window.location.href = '/';
          })
      }
    componentDidMount() {
        this.fetchClientGroupList();
        this.fetchClientWiseList();
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchClientGroupList();
        });
    }

    tog_scroll = (client) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.setState({
            client: client
        })
        this.removeBodyCss();
    }
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };

    onClickDelete = (client) => {
        this.setState({ deleteClientGroup: client });
        this.setState({ deleteModal: true });
    };

    handleDeleteClient = () => {
        const { deleteClientGroup } = this.state;
        if (deleteClientGroup.cleint_group_id !== undefined) {
            deleteClient(deleteClientGroup.cleint_group_id).then(response => {
                this.setState({
                    message: response.data,
                })
                this.fetchClientGroupList();
            });
            this.setState({
                deleteModal: false,
                custom_div2: true,
            });
        }

    };
    updateUserObject = (name, value, callback = null) => {
        let clientCopy = Object.assign({}, this.state.client);
        clientCopy[name] = value;

        this.setState({
            client: clientCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }
    handleEditChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;

        // console.log('edit value', event.target.value)
        this.updateUserObject(name, value);
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchClientGroupList();
        });
    }


    render() {
        let paginator;

        let clientGroup = [];
        let clientWiseDataList = [];
        if (this.state.hasOwnProperty('clientWiseData')) {
            clientWiseDataList = this.state.clientWiseData.data;
          }
        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
            paginator = {
                'currentPage': this.state.clientData.current_page,
                'pagesCount': this.state.clientData.last_page,
                'total': this.state.clientData.total,
                'to': this.state.clientData.to,
                'from': this.state.clientData.from,
            };
        }
        const { deleteModal, message } = this.state;

        return (

            <React.Fragment>
                <MetaTags>
                    <title>Client Group Info | Report Manager</title>
                </MetaTags>
                <div className="page-content">
                    <Container fluid={true}>
                        {/* Render Breadcrumbs */}
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">Client Group</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Client Group</li>
                                </ol>
                            </div>
                        </div>
                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={this.handleDeleteClient}
                            onCloseClick={() => this.setState({ deleteModal: false })}
                        />
                        <Row>
                            <Col xl={12}>
                                {/* <div className='d-flex justify-content-end mb-3'>
                                    <div>
                                        {checkPermission(permissionEnum.CLIENT_GROUPS_CREATE) &&
                                            <Link to="/clientGroup/create">
                                                <button className="btn btn-primary float-right"><i className="mdi mdi-plus-circle-outline me-1" />Create Client Group</button>
                                            </Link>
                                        }
                                    </div>
                                </div> */}
                                <div>
                                    {message == "" || message == undefined || message == null ?
                                        "" :
                                        // this.state.custom_div1==true &&
                                        this.state.custom_div2 ?
                                            <SweetAlert
                                                title={message}
                                                timeout={2000}
                                                showConfirm={false}
                                                showCloseButton={false}
                                                success
                                                onConfirm={() => this.setState({
                                                    custom_div2: false,
                                                })}
                                            >
                                            </SweetAlert> : null
                                    }

                                    {this.props.error && this.props.error ?
                                        <UncontrolledAlert
                                            color="success"
                                            className="alert-dismissible fade show"
                                            role="alert"
                                        >{this.props.error}</UncontrolledAlert> : null}

                                    {this.props.success && this.props.success ?
                                        (this.state.custom_div1 ?
                                            <SweetAlert
                                                title={this.props.success}
                                                timeout={2000}
                                                showConfirm={false}
                                                showCloseButton={false}
                                                success
                                                onConfirm={() => this.setState({
                                                    custom_div1: false,
                                                })}
                                            >
                                            </SweetAlert> : null)

                                        : null}

                                </div>
                                <Card>
                                    <CardBody>
                                        <DataTabeSearch handleInputChange={this.handleInputChange} />

                                        <Table responsive striped className='table-bordered'>
                                            <thead className="table-light ">
                                                <tr>
                                                    <th scope="col">Client</th>
                                                    <th scope="col">Income</th>
                                                    <th scope="col">Expense</th>
                                                    <th scope="col">Net Income</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {clientWiseDataList != null && clientWiseDataList.map((cData, index) =>
                                                    <>
                                                        <tr>
                                                            <td >{cData.customer_code}</td>
                                                            <td >${cData.income}</td>
                                                            <td>(${cData.expense})</td>
                                                            <td>${(cData.income * 1 - cData.expense * 1) != NaN ? (cData.income * 1 - cData.expense * 1) : 0}</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </Table>
                                        {/* <Col lg="12">
                              <Row>
                                <Col lg="12">
                                  <Pagination className="pagination pagination-rounded justify-content-end">
                                    {paginator &&
                                      <PaginationItem>
                                        <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </PaginationItem>
                                    }
                                  </Pagination>
                                </Col>
                              </Row>
                            </Col> */}

                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="bg-white">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">
                                            Edit Client Group
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                name: this.state.client.name || "",
                                                client_group_code: this.state.client.client_group_code || "",
                                                cleint_group_id: this.state.client.cleint_group_id || "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                name: Yup.string().required(
                                                    "Please Enter Client Group Name"
                                                ),
                                                client_group_code: Yup.string().required(
                                                    "Please Enter Client Group Code"
                                                ),
                                            })}
                                            onSubmit={(values) => {
                                                this.props.editClient(values);
                                                this.setState(prevState => ({
                                                    modal_scroll: !prevState.modal_scroll,
                                                    custom_div1: true
                                                }
                                                ));
                                                this.fetchClientGroupList();
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="form-horizontal">
                                                    <Field type="hidden" id="cleint_group_id" name="cleint_group_id" className="form-control" />

                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="name" className="form-label"> Name</Label>
                                                        <Field type="text" id="name" name="name" className={
                                                            "form-control" +
                                                            (errors.name && touched.name
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                            // onChange={(e) => this.handleEditChange(e)}
                                                            placeholder="Enter  name..." />
                                                        <ErrorMessage
                                                            name="name"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Label htmlFor="client_group_code"> Client Group Code</Label>
                                                        <Field type="text" id="client_group_code" name="client_group_code"
                                                            className={
                                                                "form-control" +
                                                                (errors.client_group_code && touched.client_group_code
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            placeholder="Enter  Customer Code..." />
                                                        <ErrorMessage
                                                            name="client_group_code"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={() =>
                                                                this.setState({ modal_scroll: false })
                                                            }
                                                        >
                                                            Close
                                                        </button>
                                                        <button type="submit" className="btn btn-primary">
                                                            Save changes
                                                        </button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>


                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

}

CustomerReport.propTypes = {
    location: PropTypes.any,
    error: PropTypes.any,
    success: PropTypes.any,
    msg: PropTypes.any,
    client: PropTypes.any,
    editClient: PropTypes.func,
};

const mapStateToProps = state => {
    const { error, success } = state.ClientGroup;
    return { error, success };
}



export default withRouter(connect(mapStateToProps, { getUserList, editClient })(CustomerReport));

