import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_TRANSACTION, TRANSACTION_ERROR, TRANSACTION_SUCCESS, SHOW_TRANSACTION, EDIT_TRANSACTION, DELETE_CLIENT } from './actionTypes';
import { transactionError, transactionSuccess } from './actions';

//Include Both Helper File with needed methods
import { getJwtProfile, postJwtProfile, editJwtData } from '../../helpers/self_accounts_settings_helper';

// function* createUserProfile({ payload: { userProfile } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(postJwtProfile, 'user/registration',userProfile);
//              if(response.success == false){
//                 // alert(response.message['message']);
//                 yield put(profileError(response.message.message));
//             }else if(response.success == true){
//                 yield put(profileSuccess(response.data.message));
//             } 
//           }
//     } catch (error) {
//         yield put(profileError(error));
//     }
// }


function* editClientTransaction({ payload: { transInfo } }) {
    // console.log('clientGroup', clientGroup);
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(editJwtData, 'transactionInfo/' + transInfo.transaction_id, transInfo);
            if (response.success == false) {
                // alert(response.message['message']);
                yield put(transactionError(response.message.message));
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            } else if (response.success == true) {
                yield put(transactionSuccess(response))
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }
        }


    } catch (error) {
        yield put(transactionError(error));
    }
}

// function* deleteTransaction({ payload: { id } }) {
//     try {
//         if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//             const response = yield call(editJwtData, 'transactionInfo/delete/'+ id);
//             yield put(transactionSuccess(response));
//         }

//     } catch (error) {
//         yield put(transactionError(error));
//     }
// }


// function* showProfile({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtProfile, 'users/id/'+user_id);
//              yield put(profileShow(response));
//           }


//     } catch (error) {
//         yield put(profileError(error));
//     }
// }

export function* watchTransaction() {
    yield takeEvery(EDIT_TRANSACTION, editClientTransaction)
    // yield takeEvery(SHOW_PROFILE, showProfile)
    // yield takeEvery(CREATE_USER_PROFILE,createUserProfile)
}

function* TransactionSaga() {
    yield all([
        fork(watchTransaction),
    ]);
}

export default TransactionSaga;