import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, Form, CardHeader, CustomInput, CardFooter, Table, UncontrolledAlert, Modal, InputGroup } from "reactstrap";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import toastr from "toastr";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import { checkPermission, permissionEnum } from '../../services/service-provider';
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch'
import { CustomPagination } from "../Settings/Common/CustomPagination";
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import { getUserList } from '../../store/auth/profile/actions';
import { getClientList, editClient, getPublicClientList } from '../../store/clients/actions';
import moment from 'moment';
import { Field, Formik } from 'formik';
import { getClientInfoDrop, getClientCustomerInfoDrop, getPublicClientInfoDrop, getPublicClientCustomerInfoDrop } from 'store/clientInfo/actions';
import { getTransactionList, deleteTransaction, editTransaction } from 'store/transaction/actions';
import { categoryList } from 'store/actions';
import DeleteModal from 'pages/Calendar/DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fade from 'react-reveal/Fade';


function TransactionRow(props) {
  const transInfo = props.transInfo;

  return (
    <tr key={transInfo.toString() + 1}>
      <td scope="row">{props.index}</td>
      <td>{transInfo.category_info == null || transInfo.category_info == undefined ? '' : transInfo.category_info.category_name_en}</td>
      <td>{transInfo.is_loan == 1 ? transInfo.is_paid == 1 ? 'Payable' : transInfo.is_received == 1 ? 'Receivable' : '' : ''}</td>
      <td>${transInfo.transaction_amount?.toLocaleString()}</td>
      <td>{transInfo.client_wish_customer == null || transInfo.client_wish_customer == undefined ? '' : transInfo.client_wish_customer.customer_name}</td>
      <td>{transInfo.client_info == null || transInfo.client_info == undefined ? '' : transInfo.client_info.name}</td>
      <td>{transInfo.client_group == null || transInfo.client_group == undefined ? '' : transInfo.client_group.name}</td>
      <td> {moment(new Date(transInfo.transaction_date)).format("YYYY-MM-DD")}</td>
      {(checkPermission(permissionEnum.TRANSACTION_INFO_UPDATE) || checkPermission(permissionEnum.TRANSACTION_INFO_DELETE)) &&
        <td>
          <div className='d-flex gap-3'>
            {checkPermission(permissionEnum.TRANSACTION_INFO_UPDATE) && <i className="mdi mdi-pencil font-size-18 text-success" onClick={() => props.tog_scroll(transInfo)}></i>}
            {checkPermission(permissionEnum.TRANSACTION_INFO_DELETE) && <i
              className="mdi mdi-delete font-size-18 text-danger"
              id="deletetooltip"
              onClick={() => {
                props.toggleDeleteModal();
                props.onClickDelete(transInfo);
              }
              }
            ></i>}

          </div>
        </td>
      }
    </tr>
  )
}

TransactionRow.propTypes = {
  tog_scroll: PropTypes.func,
  index: PropTypes.any,
  transInfo: PropTypes.any,
  toggleDeleteModal: PropTypes.func,
  onClickDelete: PropTypes.func,
};

class TransactionListIndex extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      per_page: 15,
      search: '',
      modal_scroll: false,
      deleteModal: false,
      client: [],
      showlist: 0,
      custom_div1: false,
      custom_div2: false
    };
  }


  fetchClientGroupList = () => {
    getPublicClientList().then((response) => {
      this.setState({
        clientData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }

  fetchCategoryList = () => {
    categoryList().then((response) => {
      this.setState({
        categoryList: response.data,
      });
    })
      .catch((err) => {
        console.log(err);
        // window.alert(err.response.data.message);
        // window.location.href='/';
      })
  }

  fetchTransferList = () => {
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search
    }
    getTransactionList(options).then((response) => {
      this.setState({
        transactionData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }

  fetchClientInfoList = (clientGroup_id) => {
    getPublicClientInfoDrop(clientGroup_id).then((response) => {
      this.setState({
        clientInfoData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }

  fetchClientCustomerInfoList = (clientGroup_id) => {
    getPublicClientCustomerInfoDrop(clientGroup_id).then((response) => {
      this.setState({
        clientCustomerInfoData: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })

  }

  componentDidMount() {
    this.fetchCategoryList();
    this.fetchClientGroupList();
    this.fetchTransferList();
  }

  handlePageClick = (e, index) => {
    e.preventDefault()
    this.setState({
      page: index
    }, () => {
      this.fetchTransferList();
    });
  }

  tog_scroll = (transactionInfo) => {
    // console.log("transactionInfo",transactionInfo)
    this.fetchClientInfoList(transactionInfo.f_group_id);
    this.fetchClientCustomerInfoList(transactionInfo.f_client_id)
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll,

    }));
    if (transactionInfo.is_loan == 1) {
      this.setState({
        showlist: 1,
        type_id: 3
      });
      if (transactionInfo.is_paid) {
        this.setState({
          payable: 1
        });
      } if (transactionInfo.is_received) {
        this.setState({
          payable: 2
        });
      }
    } else {
      this.setState({
        showlist: 0
      });
    }


    this.setState({
      transInfo: transactionInfo,
      date: transactionInfo.transaction_date
    })


    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }


  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = (transInfo) => {
    this.setState({ deleteTransInfo: transInfo });
    this.setState({ deleteModal: true });
  };

  handleDeleteTransaction = () => {
    const { deleteTransInfo } = this.state;
    if (deleteTransInfo.transaction_id !== undefined) {
      deleteTransaction(deleteTransInfo.transaction_id).then(response => {
        this.setState({
          message: response.data
        })
        this.fetchTransferList();
      });
      this.setState({
        deleteModal: false,
        custom_div2: true,
      });
    }
  };

  updateUserObject = (name, value, callback = null) => {
    let transInfoCopy = Object.assign({}, this.state.transInfo);
    transInfoCopy[name] = value;

    this.setState({
      transInfo: transInfoCopy
    }, () => {
      if (typeof callback == "function")
        callback()
    });
    if (name == "type_id") {
      this.setState({
        type_id: value
      })
      console.log("updateUserObjectf_category_id=====>", name, value);
    }
  }
  handleEditChange = (event) => {
    event.preventDefault();

    if (event.target.name == "f_group_id") {
      this.fetchClientInfoList(event.target.value);
    }
    if (event.target.name == "f_client_id") {
      this.fetchClientCustomerInfoList(event.target.value);
    }
    if (event.target.name == "f_category_id") {
      var index = event.target.selectedIndex;
      var optionElement = event.target.childNodes[index]
      var option = optionElement.getAttribute('data_type_id');
      let f_category_id = optionElement.getAttribute('data_type_id');;
      // cons
      let name = "type_id";
      this.updateUserObject(name, f_category_id);
      if (f_category_id == 3) {
        this.setState({
          showlist: 1
        })
      }
      else {
        this.setState({
          showlist: 0
        })
      }
      // this.fetchClientCustomerInfoList(event.target.value);
    }
    if (event.target.name == "payable") {
      this.setState({
        payable: event.target.value
      });
    }
    const { target: { name, value } } = event;
    this.updateUserObject(name, value);
  }


  handleEditSubmit = (e) => {
    e.preventDefault();
    const { transaction_id, transaction_date, f_clients_customer_id, transaction_date_time, transaction_amount, f_category_id, f_client_id, f_group_id } = this.state.transInfo;
    const { type_id, payable } = this.state;
    if (f_group_id == "") {
      toastr.error("Please Select Client Group");
    }
    else if (f_client_id == "") {
      toastr.error("Please Select Client");
    }
    else if (f_clients_customer_id == "") {
      toastr.error("Please Select Client Customer");
    }
    else if (f_category_id == "") {
      toastr.error("Please Select Category");
    }
    else if (transaction_amount == "") {
      toastr.error("Please Enter Transaction Amount");
    }
    else if (transaction_date == "") {
      toastr.error("Please Select Date");
    }
    else if (transaction_date != "" && transaction_amount != "" && f_category_id != "" && f_client_id != "" && f_group_id != "" && f_clients_customer_id != "") {
      const transInfo = {
        transaction_id: transaction_id,
        transaction_date: moment(new Date(this.state.date)).format("yyyy-MM-DD"),
        transaction_amount: transaction_amount,
        payable: payable,
        type_id: type_id,
        // transaction_amount: transaction_amount,
        f_category_id: f_category_id,
        f_client_id: f_client_id,
        f_group_id: f_group_id,
        f_clients_customer_id: f_clients_customer_id
      }
      // console.log("transaction===>",transInfo);
      this.props.editTransaction(transInfo);
      this.setState(prevState => ({
        modal_scroll: !prevState.modal_scroll,
        custom_div1: true
      }));
      this.fetchTransferList();
      // <TransactionListIndex/>
    }
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.fetchTransferList();
    });
  }

  render() {
    let paginator;

    let clientGroup = [];
    let clientInfoList = [];
    let transactionList = [];
    let categoryList = [];
    let categoryCustomerList = [];
    const { message } = this.state;

    if (this.state.hasOwnProperty('clientData')) {
      clientGroup = this.state.clientData.data;
    }

    if (this.state.hasOwnProperty('clientInfoData')) {
      clientInfoList = this.state.clientInfoData;
    }

    if (this.state.hasOwnProperty('categoryList')) {
      categoryList = this.state.categoryList.data;
    }
    if (this.state.hasOwnProperty('clientCustomerInfoData')) {
      categoryCustomerList = this.state.clientCustomerInfoData;
    }

    if (this.state.hasOwnProperty('transactionData')) {
      transactionList = this.state.transactionData.data;
      paginator = {
        'currentPage': this.state.transactionData.current_page,
        'pagesCount': this.state.transactionData.last_page,
        'total': this.state.transactionData.total,
        'to': this.state.transactionData.to,
        'from': this.state.transactionData.from,
      };
    }
    const { deleteModal, date } = this.state;

    return (

      <React.Fragment>
        <MetaTags>
          <title>Transaction List | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              {/* Render Breadcrumbs */}
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Transaction List</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Transaction List</li>
                  </ol>
                </div>
              </div>
              <DeleteModal
                show={deleteModal}
                onDeleteClick={this.handleDeleteTransaction}
                onCloseClick={() => this.setState({ deleteModal: false })}
              />
              <Row>
                <Col xl={12}>
                  <div className='d-flex justify-content-start mb-3'>
                    <div>
                      {checkPermission(permissionEnum.TRANSACTION_INFO_CREATE) &&
                        <Link to="/transaction/create">
                          <button className="btn btn-primary"><i className="mdi mdi-plus-circle-outline me-1" />Create Transaction</button>
                        </Link>
                      }
                      {checkPermission(permissionEnum.TRANSACTION_INFO_CREATE) &&
                        <Link to="/transaction/multiple/create">
                          <button className="btn btn-primary mx-2"><i className="mdi mdi-plus-circle-outline me-1" />Create Multiple Transaction</button>
                        </Link>
                      }
                    </div>
                    {/* <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-primary">Income</button>
                                        <button type="button" className="btn btn-primary">Expense</button>
                                        <button type="button" className="btn btn-primary">Loan</button>
                                    </div> */}
                  </div>
                  <div>
                    {message == "" || message == undefined || message == null ?
                      "" : (this.state.custom_div2 ?
                        <SweetAlert
                          title={message}
                          timeout={2000}
                          showCloseButton={false}
                          showConfirm={false}
                          success
                          onConfirm={() => this.setState({
                            custom_div1: false,
                            message: undefined
                          })}
                        >

                        </SweetAlert> : null)}
                    {
                      this.props.location.state == null || this.props.location.state == "" || this.props.location.state == undefined ?
                        "" :
                        <UncontrolledAlert
                          color="success"
                          className="alert-dismissible fade show"
                          role="alert"
                        >
                          <i className="mdi mdi-check-all me-2"></i>
                          {this.props.location.state.message || message}
                        </UncontrolledAlert>
                    }

                    {this.props.error && this.props.error ?
                      <UncontrolledAlert
                        color="success"
                        className="alert-dismissible fade show"
                        role="alert"
                      >{this.props.error}</UncontrolledAlert> : null}

                    {this.props.success && this.props.success ?
                      (this.state.custom_div1 ?
                        <SweetAlert
                          title={this.props.success}
                          timeout={2000}
                          showCloseButton={false}
                          showConfirm={false}
                          success
                          onConfirm={() => this.setState({
                            custom_div1: false,
                            message: undefined
                          })}
                        >

                        </SweetAlert> : null)
                      : null}
                  </div>


                  <Card>
                    <CardBody>
                      <DataTabeSearch handleInputChange={this.handleInputChange} />
                      <Table responsive striped className='table-bordered'>
                        <thead className="table-light">
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Category Name</th>
                            <th scope="col">Transaction Type</th>
                            <th scope="col">Transaction Amount</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Client Name</th>
                            <th scope="col">Group Name</th>
                            <th scope="col">Transaction Date</th>
                            {/* <th scope="col">activity</th> */}
                            {(checkPermission(permissionEnum.TRANSACTION_INFO_UPDATE) || checkPermission(permissionEnum.TRANSACTION_INFO_DELETE)) &&
                              <th scope="col">Action</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {transactionList.map((transInfo, index) =>
                            <TransactionRow key={index} transInfo={transInfo} tog_scroll={this.tog_scroll} onClickDelete={this.onClickDelete} toggleDeleteModal={this.toggleDeleteModal} index={index + 1 + ((this.state.page - 1) * this.state.per_page)} />
                          )}
                        </tbody>
                      </Table>
                    </CardBody>
                    {paginator &&
                      <CardFooter className="bg-white py-0">
                        <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                      </CardFooter>
                    }
                  </Card>
                  <Modal
                    isOpen={this.state.modal_scroll}
                    toggle={this.tog_scroll}
                    scrollable={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">
                        Edit Transaction
                      </h5>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ modal_scroll: false })
                        }
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          // category_name_en: category_name_en||"",
                          // category_icon: '',
                          // status: true,
                          // name: this.state.client.name || "",
                          // email: this.state.client.email || "",
                          // password: this.state.client.password || "",
                          // address: this.state.client.address || "",
                          // phone_number: this.state.client.phone_number || "",
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required(
                            "Please Enter Client Group Name"
                          ),
                          phone_number: Yup.string().required("Please Enter Phone Number")

                        })}
                      // onSubmit={(e, values) => {
                      //     // console.log('values', values);
                      //     this.handleEditSubmit(e, v);
                      //     // this.props.editClient(values);
                      //     // this.setState(prevState => ({
                      //     //     modal_scroll: !prevState.modal_scroll,
                      //     // }));
                      //     // this.fetchCategoryDetails(values.category_id);
                      //     // this.fetchClientGroupList();
                      //     // this.props.loginUser(values, this.props.history);
                      // }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal" onSubmit={(e) => this.handleEditSubmit(e)} >
                            <Input type="hidden" id="transaction_id" value={this.state.transInfo.transaction_id} name="transaction_id"
                              onChange={(e) => this.handleEditChange(e)} />
                            <FormGroup>
                              <label className="col-form-label">Client Group Name</label>
                              <select className="form-select" name="f_group_id" id="f_group_id" value={this.state.transInfo.f_group_id} onChange={(e) => this.handleEditChange(e)} required>
                                <option value="">Select</option>
                                {clientGroup.map((client, index) =>
                                  <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                )}
                              </select>
                              {/* <FormText className="help-block">Please enter client group</FormText> */}
                            </FormGroup>

                            <FormGroup>
                              <label className="col-form-label">Client Info Name</label>
                              <select className="form-select" name="f_client_id" id="f_client_id" value={this.state.transInfo.f_client_id} onChange={(e) => this.handleEditChange(e)} required>
                                <option value="">Select</option>
                                {clientInfoList && clientInfoList.map((clientInfo, index) =>
                                  <option value={clientInfo.id} key={index}>{clientInfo.name}</option>
                                )}

                              </select>
                              {/* <FormText className="help-block">Please enter client Info </FormText> */}
                            </FormGroup>

                            <FormGroup>
                              <label className="col-form-label">Client Customer Name</label>
                              <select className="form-select" name="f_clients_customer_id" id="f_clients_customer_id" value={this.state.transInfo.f_clients_customer_id} onChange={(e) => this.handleEditChange(e)} required>
                                <option value="">Select</option>
                                {categoryCustomerList && categoryCustomerList.map((clientInfo, index) =>
                                  <option value={clientInfo.customer_id} key={index}>{clientInfo.customer_name}</option>
                                )}
                              </select>
                              {/* <FormText className="help-block">Please enter client Info </FormText> */}
                            </FormGroup>

                            <FormGroup>
                              <label className="col-form-label">Category Name</label>
                              <select className="form-select" name="f_category_id" value={this.state.transInfo.f_category_id} id="f_category_id" onChange={(e) => this.handleEditChange(e)} required>
                                <option value="">Select</option>
                                {categoryList.map((category, index) =>
                                  <option value={category.category_id} key={index} >{category.category_name_en}</option>

                                )}

                              </select>
                              {/* <FormText className="help-block">Please enter category</FormText> */}
                            </FormGroup>
                            {
                              this.state.showlist == 1 &&
                              <FormGroup>
                                <label className="col-form-label">Payable Or Receivable</label>
                                <select className="form-select" name="payable" id="payable" value={this.state.payable} onChange={(e) => this.handleEditChange(e)} required>
                                  <option value="">Select</option>
                                  <option value="1">Is Payable</option>
                                  <option value="2">Is Receivable</option>


                                </select>
                                {/* <FormText className="help-block">Please enter category</FormText> */}
                              </FormGroup>
                            }
                            <FormGroup >
                              <Label htmlFor="name"> Transaction Amount</Label>
                              <Input type="text" id="transaction_amount" value={this.state.transInfo.transaction_amount} name="transaction_amount"
                                onChange={(e) => this.handleEditChange(e)} required
                                placeholder="Enter  amount..." />
                              {/* <FormText className="help-block">Please enter transaction amount</FormText> */}
                            </FormGroup>

                            <FormGroup>
                              <Label>Transaction Date</Label>
                              {/* <Input type="date" id="transaction_date" name="transaction_date"
                                                        value={moment(new Date(this.state.transInfo.transaction_date)).format("yyyy-MM-DD")}
                                                            onChange={(e) => this.handleEditChange(e)}
                                                            placeholder="Enter  date..." required/> */}
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  value={date}
                                  options={{ allowInput: true }}
                                  onChange={date => {
                                    this.setState({ date });
                                  }}
                                />
                              </InputGroup>
                              <Input type="hidden" name="transaction_date" value={this.state.date} onChange={this.handleInputChange} />
                              {/* <FormText className="help-block">Please enter transaction Date</FormText> */}
                            </FormGroup>

                            {/* <FormGroup>
                                                        <Label>Transaction Date & time</Label>
                                                        <Input
                                                            className="form-control"
                                                            type="datetime-local"
                                                            name="transaction_date_time"
                                                            id="transaction_date_time"
                                                            value={moment(new Date(this.state.transInfo.transaction_date_time)).format("YYYY-MM-DDThh:mm:ss")}
                                                            onChange={(e) => this.handleEditChange(e)} required
                                                            placeholder="Enter  date and time..."
                                                        />
                                                        <FormText className="help-block">Please enter transaction Date and time</FormText>
                                                    </FormGroup> */}

                            <FormGroup>
                              <Label htmlFor="Address">Note</Label>
                              <Input type="textarea" id="remarks" name="remarks" value={this.state.transInfo.remarks == null ? '' : this.state.transInfo.remarks}
                                onChange={(e) => this.handleEditChange(e)}
                                placeholder="Enter Note..." />
                              {/* <FormText className="help-block">Please enter your remarks</FormText> */}
                            </FormGroup>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                  this.setState({ modal_scroll: false })
                                }
                              >
                                Close
                              </button>
                              <button type="submit" className="btn btn-primary">
                                Save changes
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>


                    </div>
                  </Modal>
                </Col>
              </Row>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    )
  }

}

TransactionListIndex.propTypes = {
  location: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  msg: PropTypes.any,
  client: PropTypes.any,
  editTransaction: PropTypes.func,
  deleteTransaction: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.ClientTransaction;
  return { error, success };
}



export default withRouter(connect(mapStateToProps, { getUserList, editTransaction })(TransactionListIndex));

