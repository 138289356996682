import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_CATEGORY,CATEGORY_SUCCESS,EDIT_CATEGORY,SHOW_CATEGORY } from './actionTypes';
import { categorySuccess, categoryError,categoryShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createCategory({ payload: { category } }) {
    // console.log('I am here', category);

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'category/create', { 
                    category_name_en : category.category_name_en,
                    category_name_bn : category.category_name_bn,
                    is_menu : category.is_menu,  
                    category_type : category.category_type,  
                    f_category_info_id : category.f_category_info_id,
                    category_code: category.category_code,
                    account_head: category.account_head
                });
                if(response.success == false){
                    // alert(response.message['message']);
                    yield put(categoryError(response.message.message));
                    setTimeout(function () {
                        window.location.reload();
                    }, 500);
                }else if(response.success == true){
                    yield put(categorySuccess(response.data.message));
                    setTimeout(function () {
                        window.location.reload();
                    }, 500);
                } 
          }
    } catch (error) {
        yield put(categoryError(error));
    }
}


function* editCategory({ payload: { category } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'category/'+category.category_id, category);
             console.log("responseresponse",response)
             if(response.success == false){
                // alert(response.message['message']);
                yield put(categoryError(response.message.message));
                setTimeout(function () {
                    window.location.reload();
                }, 500);
            }else if(response.success == true){
                yield put(categorySuccess(response.data.message));
                 setTimeout(function () {
                window.location.reload();
                }, 500);
            }
            
          }
          
          
    } catch (error) {
        yield put(categoryError(error));
    }
}


function* showCategory({ payload: { category_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'category/'+category_id);
             yield put(categoryShow(response));
          }
          
          
    } catch (error) {
        yield put(categoryError(error));
    }
}

export function* watchCategory() {
    yield takeEvery(EDIT_CATEGORY, editCategory)
    yield takeEvery(CREATE_CATEGORY, createCategory)
    yield takeEvery(SHOW_CATEGORY, showCategory)
}

function* CategorySaga() {
    yield all([
        fork(watchCategory),
    ]);
}

export default CategorySaga;