import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, Form, CardHeader, CustomInput, CardFooter, Table, UncontrolledAlert, Modal, InputGroup } from "reactstrap";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import toastr from "toastr";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import { checkPermission, permissionEnum } from '../../services/service-provider';
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch'
import { CustomPagination } from "../Settings/Common/CustomPagination";
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import { getUserList } from '../../store/auth/profile/actions';
import { getClientList, editClient } from '../../store/clients/actions';
import moment from 'moment';
import { Field, Formik } from 'formik';
import { getClientInfoDrop, getClientCustomerInfoDrop } from 'store/clientInfo/actions';
import { fundList } from 'store/fund/actions';
import { getTransactionList, deleteTransaction, editTransaction } from 'store/transaction/actions';
import { categoryList } from 'store/actions';
import DeleteModal from 'pages/Calendar/DeleteModal';
import Fade from 'react-reveal/Fade';


function FundRow(props) {
  const fund = props.fund;
  const fundDetailsLink = `/fund/details/`
  return (
    <tr key={fund.toString() + 1}>
      <td scope="row">{props.index}</td>
      <td>{fund.fund_tran_code}</td>
      <td>{fund.client_group == null || fund.client_group == undefined ? '' : fund.client_group.name}</td>
      <td>{fund.client_info == null || fund.client_info == undefined ? '' : fund.client_info.name}</td>
      <td>{fund.transection_date}</td>
      <td>${fund.total_receivable_amount.toLocaleString()}</td>
      <td>${fund.total_payble_amount.toLocaleString()}</td>
      <td>${fund.total_available_amount.toLocaleString()}</td>
      {checkPermission(permissionEnum.USER_UPDATE) &&
        <td>
          <div className='d-flex justify-content-center gap-3'>
            <Link to={fundDetailsLink + fund.fund_tran_master_id}>
              <i className="mdi mdi-eye font-size-18 text-success"></i>
            </Link>
            {/* <i
                            className="mdi mdi-delete font-size-18 text-danger"
                            id="deletetooltip"
                            onClick={() => {
                                props.toggleDeleteModal();
                                props.onClickDelete(fund);
                            }
                            }
                        ></i> */}
          </div>
        </td>
      }
    </tr>
  )
}

FundRow.propTypes = {
  tog_scroll: PropTypes.func,
  index: PropTypes.any,
  fund: PropTypes.any,
  toggleDeleteModal: PropTypes.func,
  onClickDelete: PropTypes.func,
};

class Fund extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      per_page: 15,
      search: '',
      modal_scroll: false,
      deleteModal: false,
      client: [],
    };
  }

  fetchFundList = () => {
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search
    }
    fundList(options).then((response) => {
      this.setState({
        fundList: response.data,
      })
    })
      .catch((err) => {
        console.log(err);
      })

  }

  componentDidMount() {
    this.fetchFundList();
  }

  handlePageClick = (e, index) => {
    e.preventDefault()
    this.setState({
      page: index
    }, () => {
      this.fetchFundList();
    });
  }

  // tog_scroll = (transactionInfo) => {
  //     this.fetchClientInfoList(transactionInfo.f_group_id);
  //     this.fetchClientCustomerInfoList(transactionInfo.f_client_id)
  //     this.setState(prevState => ({
  //         modal_scroll: !prevState.modal_scroll,
  //     }));

  //     this.setState({
  //         transInfo: transactionInfo,
  //         date: transactionInfo.transaction_date
  //     })

  //     this.removeBodyCss();
  // }
  // removeBodyCss() {
  //     document.body.classList.add("no_padding");
  // }


  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = (transInfo) => {
    this.setState({ deleteTransInfo: transInfo });
    this.setState({ deleteModal: true });
  };

  // handleDeleteTransaction = () => {
  //     const { deleteTransInfo } = this.state;
  //     if (deleteTransInfo.transaction_id !== undefined) {
  //         deleteTransaction(deleteTransInfo.transaction_id).then(response => {
  //             this.setState({
  //                 message: response.data
  //             })
  //             this.fetchTransferList();
  //         });
  //         this.setState({ deleteModal: false });
  //     }
  // };



  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.fetchTransferList();
    });
  }

  render() {
    let paginator;

    let clientGroup = [];
    let clientInfoList = [];
    let fundList = [];
    let categoryList = [];
    let categoryCustomerList = [];
    const { message } = this.state;

    if (this.state.hasOwnProperty('fundList')) {
      fundList = this.state.fundList.data;
      console.log(fundList);
      paginator = {
        'currentPage': this.state.fundList.current_page,
        'pagesCount': this.state.fundList.last_page,
        'total': this.state.fundList.total,
        'to': this.state.fundList.to,
        'from': this.state.fundList.from,
      };
    }
    const { deleteModal, date } = this.state;

    return (

      <React.Fragment>
        <MetaTags>
          <title>Fund List | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              {/* Render Breadcrumbs */}
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Fund List</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Fund List</li>
                  </ol>
                </div>
              </div>
              <DeleteModal
                show={deleteModal}
                onDeleteClick={this.handleDeleteTransaction}
                onCloseClick={() => this.setState({ deleteModal: false })}
              />
              <Row>
                <Col xl={12}>
                  <div className='d-flex justify-content-end mb-3'>
                    <div>
                      {checkPermission(permissionEnum.CLIENT_GROUP_VIEW) &&
                        <Link to="/fundCreate">
                          <button className="btn btn-primary"><i className="mdi mdi-plus-circle-outline me-1" />Create Fund</button>
                        </Link>
                      }
                    </div>
                  </div>
                  <div>
                    {message == "" || message == undefined || message == null ?
                      "" : <UncontrolledAlert
                        color="success"
                        className="alert-dismissible fade show"
                        role="alert"    >
                        <i className="mdi mdi-check-all me-2"></i>
                        {message}
                      </UncontrolledAlert>}
                    {/* {
                                        this.props.location.state == null || this.props.location.state == "" || this.props.location.state == undefined ?
                                            "" :
                                            <UncontrolledAlert
                                                color="success"
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >
                                                <i className="mdi mdi-check-all me-2"></i>
                                                {this.props.location.state.message || message}
                                            </UncontrolledAlert>
                                    } */}

                    {this.props.error && this.props.error ?
                      <UncontrolledAlert
                        color="success"
                        className="alert-dismissible fade show"
                        role="alert"
                      >{this.props.error}</UncontrolledAlert> : null}

                    {this.props.success && this.props.success ? <UncontrolledAlert
                      color="success"
                      className="alert-dismissible fade show"
                      role="alert"
                    >{this.props.success}</UncontrolledAlert> : null}
                  </div>


                  <Card>
                    <CardBody>
                      <DataTabeSearch handleInputChange={this.handleInputChange} />
                      <Table responsive striped className='table-bordered'>
                        <thead className="table-light">
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Fund Transaction Code</th>
                            <th scope="col">Client Group</th>
                            <th scope="col">Client Name</th>
                            <th scope="col">Transaction Date</th>
                            <th scope="col">Total Receivable Amount</th>
                            <th scope="col">Total_Payable Amount</th>
                            <th scope="col">Total Available Fund</th>
                            {/* <th scope="col">activity</th> */}
                            {checkPermission(permissionEnum.USER_UPDATE) &&
                              <th scope="col">Action</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {fundList.map((fund, index) =>
                            <FundRow key={index} fund={fund} onClickDelete={this.onClickDelete} toggleDeleteModal={this.toggleDeleteModal} index={index + 1 + ((this.state.page - 1) * this.state.per_page)} />
                          )}
                        </tbody>
                      </Table>
                    </CardBody>
                    {paginator &&
                      <CardFooter className="bg-white">
                        <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                      </CardFooter>
                    }
                  </Card>
                  {/* <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">
                                            Edit Transaction
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                            
                                            }}
                                            validationSchema={Yup.object().shape({
                                                name: Yup.string().required(
                                                    "Please Enter Client Group Name"
                                                ),
                                                phone_number: Yup.string().required("Please Enter Phone Number")

                                            })}
                                        
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="form-horizontal" onSubmit={(e) => this.handleEditSubmit(e)} >
                                                    <Input type="hidden" id="transaction_id" value={this.state.transInfo.transaction_id} name="transaction_id"
                                                        onChange={(e) => this.handleEditChange(e)} />
                                                    <FormGroup>
                                                        <label className="col-form-label">Client Group Name</label>
                                                        <select className="form-select" name="f_group_id" id="f_group_id" value={this.state.transInfo.f_group_id} onChange={(e) => this.handleEditChange(e)} required>
                                                            <option value="">Select</option>
                                                            {clientGroup.map((client, index) =>
                                                                <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                                            )}
                                                        </select>
                                                       
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <label className="col-form-label">Client Info Name</label>
                                                        <select className="form-select" name="f_client_id" id="f_client_id" value={this.state.transInfo.f_client_id} onChange={(e) => this.handleEditChange(e)} required>
                                                            <option value="">Select</option>
                                                            {clientInfoList && clientInfoList.map((clientInfo, index) =>
                                                                <option value={clientInfo.id} key={index}>{clientInfo.name}</option>
                                                            )}

                                                        </select>
                                                        
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <label className="col-form-label">Client Customer Name</label>
                                                        <select className="form-select" name="f_clients_customer_id" id="f_clients_customer_id" value={this.state.transInfo.f_clients_customer_id}  onChange={(e) => this.handleEditChange(e)} required>
                                                            <option value="">Select</option>
                                                            {categoryCustomerList && categoryCustomerList.map((clientInfo, index) =>
                                                                <option value={clientInfo.customer_id} key={index}>{clientInfo.customer_name}</option>
                                                            )}
                                                        </select>
                                                       
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <label className="col-form-label">Category Name</label>
                                                        <select className="form-select" name="f_category_id" value={this.state.transInfo.f_category_id} id="f_category_id" onChange={(e) => this.handleEditChange(e)} required>
                                                            <option value="">Select</option>
                                                            {categoryList.map((category, index) =>
                                                                <option value={category.category_id} key={index}>{category.category_name_en}</option>
                                                            )}

                                                        </select>
                                                       
                                                    </FormGroup>

                                                    <FormGroup >
                                                        <Label htmlFor="name"> Transaction Amount</Label>
                                                        <Input type="text" id="transaction_amount" value={this.state.transInfo.transaction_amount} name="transaction_amount"
                                                            onChange={(e) => this.handleEditChange(e)} required
                                                            placeholder="Enter  amount..." />
                                                        
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Transaction Date</Label>
                                                       
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                value={date}
                                                                options={{ allowInput: true }}
                                                                onChange={date => {
                                                                    this.setState({ date });
                                                                }}
                                                            />
                                                        </InputGroup>
                                                        <Input type="hidden" name="transaction_date" value={this.state.date} onChange={this.handleInputChange} />
                                                     
                                                    </FormGroup>

                                                   

                                                    <FormGroup>
                                                        <Label htmlFor="Address">Note</Label>
                                                        <Input type="textarea" id="remarks" name="remarks" value={this.state.transInfo.remarks == null ? '' : this.state.transInfo.remarks}
                                                            onChange={(e) => this.handleEditChange(e)}
                                                            placeholder="Enter Note..." />
                                                    </FormGroup>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={() =>
                                                                this.setState({ modal_scroll: false })
                                                            }
                                                        >
                                                            Close
                                                        </button>
                                                        <button type="submit" className="btn btn-primary">
                                                            Save changes
                                                        </button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>


                                    </div>
                                </Modal> */}
                </Col>
              </Row>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    )
  }

}

Fund.propTypes = {
  location: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  msg: PropTypes.any,
  client: PropTypes.any,
  editTransaction: PropTypes.func,
  deleteTransaction: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.ClientTransaction;
  return { error, success };
}



export default withRouter(connect(mapStateToProps, { getUserList, editTransaction })(Fund));

