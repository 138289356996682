import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_FUND_CATEGORY,EDIT_FUND_CATEGORY,SHOW_FUND_CATEGORY,FUND_CATEGORY_SUCCESS,FUND_CATEGORY_ERROR } from './actionTypes';

import { categoryFundSuccess, categoryFundError,showFundCategory } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createFundCategory({ payload: { category } }) {
    // console.log('I am here',category);

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'fundCategory/create', { 
                f_fund_type_id : category.f_fund_type_id,
                fund_category_code : category.fund_category_code,
                fund_category_name : category.fund_category_name,
                });
                if(response.success == false){
                    // alert(response.message['message']);
                    yield put(categoryFundError(response.message.message));
                    setTimeout(function () {
                        window.location.reload();
                    }, 500);
                }else if(response.success == true){
                    yield put(categoryFundSuccess(response.data.message));
                    setTimeout(function () {
                        window.location.reload();
                    }, 500);
                } 
          }
    } catch (error) {
        yield put(categoryFundError(error));
    }
}


function* editFundCategory({ payload: { category } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'fundCategory/'+category.fund_category_id, category);
            //  console.log("responseresponse",response)
             if(response.success == false){
                // alert(response.message['message']);
                yield put(categoryFundError(response.message.message));
                setTimeout(function () {
                    window.location.reload();
                }, 500);
            }else if(response.success == true){
                yield put(categoryFundSuccess(response.data.message));
                 setTimeout(function () {
                window.location.reload();
                }, 500);
            }
            
          }
          
          
    } catch (error) {
        yield put(categoryFundError(error));
    }
}


function* showCategoryFund({ payload: { category_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'category/'+category_id);
             yield put(showFundCategory(response));
          }
          
          
    } catch (error) {
        yield put(categoryFundError(error));
    }
}

export function* watchFundCategory() {
    yield takeEvery(EDIT_FUND_CATEGORY, editFundCategory)
    yield takeEvery(CREATE_FUND_CATEGORY, createFundCategory)
    yield takeEvery(SHOW_FUND_CATEGORY, showCategoryFund)
}

function* FundCategorySaga() {
    yield all([
        fork(watchFundCategory),
    ]);
}

export default FundCategorySaga;