import { EDIT_LOAN, LOAN_ERROR, LOAN_SUCCESS } from './actionTypes';
import { getApi } from "../../services/httpService";


export const editLoan = (loanInfo) => {
  console.log('loanInfo', loanInfo);
  return {
    type: EDIT_LOAN,
    payload: { loanInfo }
  }
}

export const loanSuccess = (response) => {
  return {
    type: LOAN_SUCCESS,
    payload: response.data.message
  }
}

export const loanError = (error) => {
  return {
    type: LOAN_ERROR,
    payload: error
  }
}

export function getLoanList(params) {
  return getApi().get('loan/list', {
    params: params
  })
}
export function getLoanListShow(id, params) {
  return getApi().get(`loan/show/${id}`, {
    params: params
  })
}

export function createLoan(data) {
  return getApi().post('loan/create', data);
}

export function deleteLoan(id) {
  return getApi().put('loan/delete/' + id);
}

export function createBlukLoan(data) {
  return getApi().post('loan/bulk/create', data);
}
export function createBlukPayment(data) {
  return getApi().post('loan/payment/bulk/create', data);
}
export function createPayment(data) {
  return getApi().post('loan/payment/create', data);
}
export function debtOverview(params) {
  return getApi().get('loan/business/debt/overview', {
    params: params
  });
}
export function debtFullOverview(id, params) {
  return getApi().get(`loan/company/debt/overview/${id}`, {
    params: params
  });
}
export function debtschedule(start, end, params) {
  return getApi().get(`loan/report/business/debt/schedule/${start}/${end}`, {
    params: params
  });
}

