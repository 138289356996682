import * as types from '../actions';
import {getApi} from "../services/httpService";

export function getUserProfileInfo() {
    return getApi().get('user/profile/show')
}

export function updateUserProfile(user_id, data) {
    if (user_id) {
        return getApi().put('user/profile/update', data)
    }
}