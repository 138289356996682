import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CLIENT_INFO_ERROR, CLIENT_INFO_SUCCESS, EDIT_CLIENT_INFO, CREATE_INFO_CLIENT, SHOW_INFO_CLIENT } from './actionTypes';
import { clientInfoError, clientInfoSuccess, editClientInfo } from './actions';

//Include Both Helper File with needed methods
import { getJwtProfile, postJwtProfile, editJwtData } from '../../helpers/self_accounts_settings_helper';

// function* createUserProfile({ payload: { userProfile } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(postJwtProfile, 'user/registration',userProfile);
//              if(response.success == false){
//                 // alert(response.message['message']);
//                 yield put(profileError(response.message.message));
//             }else if(response.success == true){
//                 yield put(profileSuccess(response.data.message));
//             } 
//           }
//     } catch (error) {
//         yield put(profileError(error));
//     }
// }


function* editClientInformation({ payload: { clientInfo } }) {
    // console.log('clientGroup', clientGroup);
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(editJwtData, 'clientInfoDetails/' + clientInfo.id, clientInfo);
            // if (response.success == false) {
            //     // alert(response.message['message']);
            //     yield put(clientInfoError(response.message.message));
            // } else if (response.success == true) {
            //     yield put(clientInfoSuccess(response.data.message));
            // }
            if(response.success == false){
                yield put(clientInfoError(response.message.message));
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }else if(response.success == true){
                yield put(clientInfoSuccess(response));
                 setTimeout(function () {
                window.location.reload();
                }, 1000);
            }
        }


    } catch (error) {
        yield put(clientInfoError(error));
    }
}


// function* showProfile({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtProfile, 'users/id/'+user_id);
//              yield put(profileShow(response));
//           }


//     } catch (error) {
//         yield put(profileError(error));
//     }
// }

export function* watchClientInfo() {
    yield takeEvery(EDIT_CLIENT_INFO, editClientInformation)
    // yield takeEvery(SHOW_PROFILE, showProfile)
    // yield takeEvery(CREATE_USER_PROFILE,createUserProfile)
}

function* ClientInfoSaga() {
    yield all([
        fork(watchClientInfo),
    ]);
}

export default ClientInfoSaga;