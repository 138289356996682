import { takeEvery, fork, put, all, call } from 'redux-saga/effects';


import { CREATE_CUSTOMER,CUSTOMER_ERROR,CUSTOMER_SUCCESS,SHOW_CUSTOMER,EDIT_CUSTOMER} from './actionTypes';
import { customerSuccess, customerError, editClient} from './actions';

//Include Both Helper File with needed methods
import { getJwtProfile,postJwtProfile, editJwtData} from '../../helpers/self_accounts_settings_helper';

// function* createUserProfile({ payload: { userProfile } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(postJwtProfile, 'user/registration',userProfile);
//              if(response.success == false){
//                 // alert(response.message['message']);
//                 yield put(profileError(response.message.message));
//             }else if(response.success == true){
//                 yield put(profileSuccess(response.data.message));
//             } 
//           }
//     } catch (error) {
//         yield put(profileError(error));
//     }
// }
 

function* editCustomerInfo({ payload: { customerInfo } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(editJwtData, 'clientWiseCustomer/'+ customerInfo.customer_id, customerInfo);
            if(response.success == false){
                yield put(customerError(response.message.message));
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }else if(response.success == true){
                yield put(customerSuccess(response));
                 setTimeout(function () {
                window.location.reload();
                }, 1000);
            }
        }


    } catch (error) {
        yield put(customerError(error));
    }
}


// function* showProfile({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtProfile, 'users/id/'+user_id);
//              yield put(profileShow(response));
//           }
          
          
//     } catch (error) {
//         yield put(profileError(error));
//     }
// }

export function* watchCustomer() {
    yield takeEvery(EDIT_CUSTOMER, editCustomerInfo)
    // yield takeEvery(SHOW_PROFILE, showProfile)
    // yield takeEvery(CREATE_USER_PROFILE,createUserProfile)
}

function* CustomerSaga() {
    yield all([
        fork(watchCustomer),
    ]);
}

export default CustomerSaga;