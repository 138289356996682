import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Table } from 'reactstrap';
import { Fade } from 'react-reveal';
import { MetaTags } from 'react-meta-tags';
import { getLoanListShow } from 'store/Loan/actions';
class LoanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortData: '',
      sortType: 'asc'
    };
  }
  getShowData = (sortField, sortType) => {
    let options = {
      sort_by_field: sortField,
      sort_type: sortType ? sortType : this.state.sortType,
    }
    getLoanListShow(this.props.match.params.id, options).then((response) => {
      this.setState({
        loanData: response?.data?.loanShow,
      })
    })
  }
  handleSort = (data) => {
    this.setState({
      sortData: data
    })
    if (this.state.sortType === "asc") {
      this.setState({ sortType: "desc" })
    }
    else {
      this.setState({ sortType: "asc" })
    }
    this.getShowData(data, this.state.sortType === "asc" ? "desc" : "asc")
  }
  componentDidMount() {
    this.getShowData();
  }
  render() {
    let loanList = {};
    loanList = this.state.loanData;
    // console.log(new Date(date.setMonth(date.getMonth() + 8)));
    return (
      <React.Fragment>
        <MetaTags>
          <title>Loan Details | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              {/* Render Breadcrumbs */}
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Loan Details</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/loanList">Loan List</Link></li>
                    <li className="breadcrumb-item active">Loan Details</li>
                  </ol>
                </div>
              </div>
              <div className='d-flex justify-content-end my-3 printBtn'>
                <button onClick={window.print} className='btn btn-success'>Print <i className="fa-solid fa-print"></i></button>
              </div>
              <Row>
                <Col xs="12" sm="12">
                  <Form>
                    <Card>
                      {/* <CardHeader className="bg-white">
                        <strong className="fs-4">Loan Details</strong>
                      </CardHeader> */}
                      <h3 className='text-center my-5'>{loanList?.client_info?.name}</h3>
                      <div className='pb-5'>
                        <Table bordered responsive style={{ width: "95%", margin: "auto" }}>
                          <tbody>
                            {/* <tr>
                              <th>Client Group Name</th>
                              <td >{loanList?.client_info?.name}</td>
                            </tr> */}
                            <tr>
                              <th>Name</th>
                              <td>{loanList?.client_group?.name}</td>
                              <th>Maturity Date</th>
                              <td>{loanList?.maturity_date}</td>
                            </tr>
                            {/* <tr>
                              <th>Client Info Name</th>
                              <td>{loanList?.client_group?.name}</td>
                              
                            </tr> */}
                            <tr>
                              <th>Loan Account Number</th>
                              <td>{loanList?.loan_ac_no}</td>
                              <th>Loan Due Date</th>
                              <td>{loanList?.next_loan_due_date}</td>
                            </tr>
                            {/* <tr>
                              <th>Loan Account Number</th>
                              <td>{loanList?.loan_ac_no}</td>
                              
                            </tr> */}
                            <tr>
                              <th>Creditor</th>
                              <td>{loanList?.creditor}</td>
                              <th>Orginal Amount</th>
                              <td>${loanList?.original_amount.toLocaleString()}</td>
                            </tr>
                            <tr>
                              <th>Loan Code</th>
                              <td>{loanList?.loan_code}</td>
                              <th>Present Balance</th>
                              <td>${loanList?.present_balance.toLocaleString()}</td>
                            </tr>
                            <tr>
                              <th>Remarks</th>
                              <td>{loanList?.taken_for_remarks}</td>
                              <th>Monthly Payment</th>
                              <td>${loanList?.monthly_payment.toLocaleString()}</td>
                            </tr>
                            <tr>
                              <th>Collateral/Security</th>
                              <td>{loanList?.collateral_or_security}</td>
                              <th>Interest Rate Amount</th>
                              <td>
                                ${loanList?.interest_rate_amount.toLocaleString()}
                              </td>
                            </tr>
                            <tr>
                              <th>Loan Origination Date</th>
                              <td>{loanList?.original_loan_date}</td>
                              <th>Interest Rate Percentage</th>
                              <td>{loanList?.interest_rate_percentage}%</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      {/* <CardBody>
                        <Row>
                          <Col xs="12" sm="12" md={6}>
                            <FormGroup>
                              <label className="col-form-label">Client Group Name : </label> <span>{loanList?.client_info?.name}</span>
                            </FormGroup>
                            <FormGroup>
                              <label className="col-form-label">Client Info Name :</label> <span>{loanList?.client_group?.name}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label>Orginal Date :</Label> <span>{loanList?.original_loan_date}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label> Maturity Date :</Label> <span>{loanList?.maturity_date}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label>Loan Due Date :</Label> <span>{loanList?.loan_due_date}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label>Creditor :</Label> <span>{loanList?.creditor}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label>Orginal Amount :</Label> <span>${loanList?.original_amount}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label>Present Balance :</Label> <span>${loanList?.present_balance}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label>Loan Account Number :</Label> <span>{loanList?.loan_ac_no}</span>
                            </FormGroup>
                          </Col>
                          <Col xs="12" sm="12" md={6} className="mt-2">
                            <FormGroup >
                              <Label htmlFor="name">Interest Rate Percentage :</Label> <span>{loanList?.interest_rate_percentage}%</span>
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Interest Rate Amount :</Label> <span>${loanList?.interest_rate_amount}</span>
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Loan Code :</Label> <span>{loanList?.loan_code}</span>
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Monthly Payment :</Label> <span>${loanList?.monthly_payment}</span>
                            </FormGroup>
                            <FormGroup >
                              <Label htmlFor="name">Collateral/Security :</Label> <span>{loanList?.collateral_or_security}</span>
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="Address">Remarks :</Label> <span>{loanList?.taken_for_remarks}</span>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody> */}

                    </Card>
                  </Form>

                </Col>
              </Row>
              <div className="page-title-box d-sm-flex align-items-center mt-5">
                <h4 className="mb-sm-0 font-size-18">Loan Payment Details</h4>
              </div>
              <Card>
                <CardBody>
                  <Table responsive striped className='table-bordered'>
                    <thead className="table-light">
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Payment Date <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("payment_date")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("payment_date")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Beginning Balance <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("beginning_balance")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("beginning_balance")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Payment <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("payment")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("payment")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Principal <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("principal")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("principal")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Interest <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("interest_amount")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("interest_amount")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        {/* <th scope="col">Interest Percentage <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("interest_percentage")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("interest_percentage")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th> */}
                        <th scope="col">Ending Balance <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("ending_balance")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("ending_balance")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.loanData?.loan_payment_details.length !== 0 ?
                          this.state.loanData?.loan_payment_details?.map((data, index) =>
                            <tr key={data.toString() + 1}>
                              <td>{index + 1}</td>
                              <td>{data.payment_date}</td>
                              <td>${data.opening_balance.toLocaleString()}</td>
                              <td>${data.payment_total.toLocaleString()}</td>
                              <td>${data.principal_amount.toLocaleString()}</td>
                              <td>${data.interest_amount.toLocaleString()}</td>
                              {/* <td>{data.interest_percent}%</td> */}
                              <td>${data.closing_balance.toLocaleString()}</td>
                            </tr>
                          ) : <p className='ps-2 pt-2 fw-bold'>No Payment Done Yet</p>}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}

LoanDetails.propTypes = {
  match: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  msg: PropTypes.any,
  handleErrorResponse: PropTypes.any,
  createFund: PropTypes.any,
  editFundCategory: PropTypes.any,
};

export default LoanDetails;