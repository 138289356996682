import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, CardHeader, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

// toaster
import toastr from "toastr";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import { getUserDetails, createOrUpdateUser, getRoleList } from '.../../store/auth/profile/actions';
import { createClientInfo, getClientInfoDrop, getPublicClientInfoDrop } from 'store/clientInfo/actions';
import { getClientList, editClient, getPublicClientList } from '../../store/clients/actions';
import { createClient } from 'store/clients/actions';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import { values } from 'lodash';
import * as Yup from "yup";
import { createCustomerInfo, getCustomerClientCode } from 'store/Customer/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import Switch from "react-switch";
import Fade from 'react-reveal/Fade';

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
        </div>
    )
}

const OnSymbol = props => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Yes
        </div>
    )
}
class CreateCustomer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: {
                // roles: [],
                // status: 1
            },
            all_roles: [],
            custom_div2: false,
        };
    }

    fetchClientGroupList = () => {
        getPublicClientList().then((response) => {
            this.setState({
                clientData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }

    fetchClientInfoList = (clientGroup_id) => {
        getPublicClientInfoDrop(clientGroup_id).then((response) => {
            this.setState({
                clientInfoData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchUserDetails(this.props.match.params.id);
        }
        this.fetchClientGroupList();
    }

    handleInputChange = (event) => {
        event.preventDefault();
        if (event.target.name == "f_group_id") {
            this.fetchClientInfoList(event.target.value);
        }
        const { target: { name, value } } = event;

        // this.updateUserObject(name, value);
    }

    // updateUserObject = (name, value, callback = null) => {
    //     let transInfoCopy = Object.assign({}, this.state.transInfo);
    //     transInfoCopy[name] = value;

    //     this.setState({
    //         customerInfo: customerInfo
    //     }, () => {
    //         if (typeof callback == "function")
    //             callback()
    //     });
    // }


    // onHandleSubmit = (event) => {
    //     event.preventDefault();
    //     const { id, name, phone_number, email, password, password_confirmation, address } = this.state.client;

    //     if (name == "") {
    //         toastr.error("Enter Name...");
    //     }

    //     //    else if(phone_number =="")
    //     //     {
    //     //         toastr.error("Phone Number...");
    //     //     }

    //     // else if(password =="")
    //     // {
    //     //     toastr.error("Enter Password...");
    //     // }

    //     // else if (password !== password_confirmation) {
    //     //     toastr.error("Passwords don't match");
    //     // } 
    //     else {

    //         const data = {
    //             name: name,
    //             // phone_number: phone_number,
    //             // password: password,
    //             // email: email,
    //             // password_confirmation: password_confirmation,
    //             // address: address
    //         };

    //         createClient(data)
    //             .then((response) => {
    //                 if (response.data.status === true) {
    //                     this.props.history.push({
    //                         pathname: '/clientGroup',
    //                         state: { message: response.data.message }
    //                     });
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //                 // this.props.handleErrorResponse(err);

    //             });
    //     }
    // };


    render() {
        let clientGroup = [];
        let clientInfoList = [];



        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
        }

        if (this.state.hasOwnProperty('clientInfoData')) {
            clientInfoList = this.state.clientInfoData;
        }
        const { message } = this.state;
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Customer Create | Report Manager</title>
                </MetaTags>
                <Fade cascade>
                    <div className="page-content">
                        <Container fluid={true}>
                            {/* Render Breadcrumbs */}
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Customer Create</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><Link to="/customers">Customer list</Link></li>
                                        <li className="breadcrumb-item active"> Customer Create</li>
                                    </ol>
                                </div>
                            </div>
                            {message == "" || message == undefined || message == null ?
                                "" :
                                // this.state.custom_div1==true &&
                                this.state.custom_div2 ?
                                    <SweetAlert
                                        title={message}
                                        timeout={2000}
                                        showConfirm={false}
                                        showCloseButton={false}
                                        success
                                        onConfirm={() => this.setState({
                                            custom_div2: false,
                                            // message: undefined
                                        })}
                                    >
                                    </SweetAlert> : null


                            }
                            <Row>
                                <Col xs="12" sm="12">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            customer_name: this.state.customer_name || "",
                                            f_client_groups_id: this.state.f_client_groups_id || "",
                                            f_client_info_details_id: this.state.f_client_info_details_id || "",
                                            customer_code: this.state.customer_code || "",
                                            customer_phone_no: this.state.customer_phone_no || "",
                                            customer_email: this.state.customer_email || "",
                                            customer_address: this.state.customer_address || "",
                                            is_Bank: false,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            customer_name: Yup.string().required(
                                                "Please Enter Customer Name"
                                            ),
                                            f_client_groups_id: Yup.string().required(
                                                "Please Enter a Group"
                                            ),
                                            f_client_info_details_id: Yup.string().required(
                                                "Please Enter a Client"
                                            ),
                                            customer_code: Yup.string().required(
                                                "Please Enter a customer code"
                                            ),
                                        })}
                                        onSubmit={(values) => {
                                            // console.log('values', values);
                                            if (values.f_client_groups_id == "" || values.f_client_groups_id == "Select") {
                                                toastr.error('Select a Group');
                                            }
                                            if (values.f_client_info_details_id == "") {
                                                toastr.error('Select a client');
                                            } else {
                                                createCustomerInfo(values)
                                                    .then((response) => {
                                                        if (response.data.status === true) {

                                                            this.setState({
                                                                message: response.data.message,
                                                                custom_div2: true
                                                            })
                                                            setTimeout(() => {
                                                                this.props.history.push({
                                                                    pathname: '/customers',
                                                                });
                                                            }, 2000)
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        console.log(err);
                                                        window.alert(err.response.data.message);
                                                        window.location.href = '/';
                                                    });
                                            }

                                        }}
                                    >
                                        {({ errors, status, touched, handleChange, setFieldValue, values }) => (
                                            <Form>
                                                <Card>
                                                    <CardHeader className="bg-white">
                                                        <strong className='fs-4'>New Customer Info</strong>
                                                        <small className='fs-6'> Form</small>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col xs="12" sm="12" md={6}>
                                                                <FormGroup >
                                                                    <Label htmlFor="customer_name">Customer Name <sup className="text-danger">*</sup></Label>
                                                                    <Field type="text" id="customer_name" name="customer_name"
                                                                        // onChange={this.handleInputChange}
                                                                        placeholder="Enter  name..."
                                                                        // required
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.customer_name && touched.customer_name
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="customer_name"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <label className="col-form-label">Client Group Name <sup className="text-danger">*</sup></label>
                                                                    <select name="f_client_groups_id" id="f_client_groups_id" onChange={(e) => {
                                                                        handleChange(e);
                                                                        this.fetchClientInfoList(e.currentTarget.value);
                                                                    }}
                                                                        //   onClick={this.fetchClientInfoList(this.state.f_client_groups_id)}
                                                                        value={values.f_client_groups_id}
                                                                        className={"form-select"
                                                                            +
                                                                            (errors.f_client_groups_id && touched.f_client_groups_id
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    >
                                                                        <option defaultValue>Select</option>
                                                                        {clientGroup.map((client, index) =>
                                                                            <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <ErrorMessage
                                                                        name="f_client_groups_id"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                    {/* <FormText className="help-block">Please enter client group</FormText> */}
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <label className="col-form-label">Client Info Name <sup className="text-danger">*</sup></label>
                                                                    <select name="f_client_info_details_id" id="f_client_info_details_id"
                                                                        value={values.f_client_info_details_id}
                                                                        onChange={handleChange}
                                                                        className={"form-select"
                                                                            +
                                                                            (errors.f_client_info_details_id && touched.f_client_info_details_id
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    // onChange={this.handleInputChange} 
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {clientInfoList && clientInfoList.map((clientInfo, index) =>
                                                                            <option value={clientInfo.id} key={index}>{clientInfo.name}</option>
                                                                        )}

                                                                    </select>
                                                                    <ErrorMessage
                                                                        name="f_client_info_details_id"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                    {/* <FormText className="help-block">Please enter client Info </FormText> */}
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Label htmlFor="email">Email</Label>
                                                                    <Field type="email" id="customer_email" name="customer_email"
                                                                        className="form-control"
                                                                        // onChange={this.handleInputChange}
                                                                        placeholder="Enter email..." />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs="12" sm="12" md={6}>
                                                                <FormGroup>
                                                                    <Label htmlFor="customer_code">Customer Code <sup className="text-danger">*</sup></Label>
                                                                    <Field type="text" id="customer_code" name="customer_code"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.customer_code && touched.customer_code
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                        // onChange={this.handleInputChange}
                                                                        placeholder="Enter Customer Code..." />
                                                                    <ErrorMessage
                                                                        name="customer_code"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label htmlFor="mobile">Phone No.</Label>
                                                                    <Field type="text" id="customer_phone_no" name="customer_phone_no"
                                                                        className="form-control"
                                                                        // onChange={this.handleInputChange} clas
                                                                        placeholder="Enter Phone No..." />

                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label htmlFor="Address">Address</Label>
                                                                    <Field type="text" as="textarea"
                                                                        rows="4" id="customer_address" name="customer_address"
                                                                        className="form-control"
                                                                        // onChange={this.handleInputChange}
                                                                        placeholder="Enter address..."
                                                                    />

                                                                </FormGroup>

                                                                <FormGroup className="fs-4">
                                                                    <Field type="checkbox" name="is_Bank" className="fs-4 me-2" />
                                                                    <label>Is Bank</label>
                                                                </FormGroup>
                                                            </Col>
                                                            <div className="py-3 d-flex justify-content-end">
                                                                <Button type="submit" size="sm" color="primary"
                                                                    className="me-2 pull-right"><i
                                                                        className="fa fa-dot-circle-o" /> Submit</Button>
                                                                <Link to="/clientInfoList">
                                                                    <Button type="reset" size="sm" color="danger"
                                                                        className="pull-right"><i
                                                                            className="fa fa-ban" /> Cancel</Button>
                                                                </Link>
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Fade>
            </React.Fragment>

        );
    }
}
CreateCustomer.propTypes = {
    handleErrorResponse: PropTypes.any,
    match: PropTypes.any,
    history: PropTypes.any,
};

const mapStateToProps = state => {
    const { msg, error, success } = state.Profile;
    return { msg, error, success };
}




export default withRouter(connect(mapStateToProps, { getUserDetails, createClient, getRoleList })(CreateCustomer));

