import React, { Component } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from "react-router-dom";
import { Tbody } from "react-super-responsive-table";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
    Table,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from 'moment';
import toastr from "toastr";
import { createClientInfo, getClientInfoDrop, getClientCustomerInfoDrop } from 'store/clientInfo/actions';
import { getClientList, editClient } from '../../store/clients/actions';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { payableList, receivableList, createFund } from "store/fund/actions";
import { connect } from "react-redux";
import { map } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import Fade from 'react-reveal/Fade';

class FundCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            noteArray: [{ note_name: "", note_amount: "" }],
            receivable: [{ is_receivable: 1, is_payable: 0, f_fund_receivable_category_id: "", receivable_amount: "" }],
            payable: [{ is_receivable: 0, is_payable: 1, f_fund_payable_category_id: "", payable_amount: "" }],
            receivableCount: 0,
            payableCount: 0,
            total_receivable_amount: 0,
            total_payable_amount: 0,
            total_available_amount: 0,
            date: new Date(),
            custom_div2: false
        };
        // this.textareaReceveiablechange = this.textareaReceveiablechange.bind(this);
        // this.textareaPayablechange = this.textareaPayablechange.bind(this);
    }

    fetchClientGroupList = () => {
        getClientList().then((response) => {
            this.setState({
                clientData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
            })

    }

    fetchClientInfoList = (clientGroup_id) => {
        getClientInfoDrop(clientGroup_id).then((response) => {
            this.setState({
                clientInfoData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
            })

    }

    fetchReceivableList = () => {
        receivableList().then((response) => {
            this.setState({
                receivableList: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
            })

    }

    fetchPayableList = () => {
        payableList().then((response) => {
            this.setState({
                payableList: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
            })

    }




    componentDidMount() {
        this.fetchClientGroupList();
        this.fetchReceivableList();
        this.fetchPayableList();
    }

    handleAddRow = () => {
        const item = {
            name: "",
        };
        this.setState({
            rows: [...this.state.rows, item],
        });
    };

    handleAddReceivableNested = () => {
        const item1 = {
            is_receivable: 1,
            is_payable: 0,
            f_fund_receivable_category_id: "",
            receivable_amount: "",
        };
        this.setState({
            receivable: [...this.state.receivable, item1],
        });
    };

    handleAddPayableNested = () => {
        const item2 = {
            is_receivable: 0,
            is_payable: 1,
            f_fund_payable_category_id: "",
            payable_amount: ""
        };
        this.setState({
            payable: [...this.state.payable, item2],
        });
    };

    handleAddNoteNested = () => {
        const item3 = {
            note_name: "",
            note_amount: "",
        };
        this.setState({
            noteArray: [...this.state.noteArray, item3],
        });
    };

    handleRemoveRow = (e, idx) => {
        if (idx === "01") {
            document.getElementById("addr" + idx).style.display = "block";
        } else if (typeof idx != "undefined") {
            document.getElementById("addr" + idx).style.display = "none";
        }

    };

    handleRemoveReceivableNested = (e, idx) => {
        const list = [...this.state.receivable];
        list.splice(idx, 1);

        let receivable_amount = 0
        list.map(receive => {
            receivable_amount = receivable_amount + receive.receivable_amount * 1
        })

        let total_remove_receivable_amount = this.state.total_receivable_amount - receivable_amount;

        this.setState({
            total_receivable_amount: this.state.total_receivable_amount - total_remove_receivable_amount,
            total_available_amount: (this.state.total_receivable_amount - total_remove_receivable_amount) - this.state.total_payable_amount
        })

        this.setState({
            receivable: list,
        });
        this.setState.receivable = list;
    };

    handleRemovePayableNested = (e, idx) => {

        const list = [...this.state.payable];
        list.splice(idx, 1);

        let payable_amount = 0
        list.map(payable => {
            payable_amount = payable_amount + payable.payable_amount * 1
        })

        let total_remove_payable_amount = this.state.total_payable_amount - payable_amount

        this.setState({
            total_payable_amount: this.state.total_payable_amount - total_remove_payable_amount,
            total_available_amount: this.state.total_receivable_amount - (this.state.total_payable_amount - total_remove_payable_amount)
        })

        this.setState({
            payable: list,
        });
        this.setState.payable = list;
        // console.log("payable", this.state.payable)
    };

    handleRemoveNote = (e, idx) => {
        // console.log("idx", idx)

        const list = [...this.state.noteArray];
        list.splice(idx, 1);
        this.setState({
            noteArray: list,
        });
        this.setState.noteArray = list;
        // console.log("sss", this.state.noteArray)
    };


    // textareaReceveiablechange(event) {
    //     const count = event.target.value.length
    //     if (count > 0) {
    //         this.setState({ textareaReceiveablebadge: true })
    //     } else {
    //         this.setState({ textareaReceiveablebadge: false })
    //     }
    //     this.setState({ receivableCount: event.target.value.length })
    // }

    // textareaPayablechange(event) {
    //     const count = event.target.value.length
    //     if (count > 0) {
    //         this.setState({ textareaPayablechange: true })
    //     } else {
    //         this.setState({ textareaPayablebadge: false })
    //     }
    //     this.setState({ payableCount: event.target.value.length })
    // }

    handleMasterChange = (event) => {
        // event.preventDefault();
        // console.log("date", event.target.name);
        if (event.target.name == "f_client_group_id") {
            this.fetchClientInfoList(event.target.value);
        }
        // if (event.target.name == "f_client_id") {
        //     this.fetchClientCustomerInfoList(event.target.value);
        // }
        const { target: { name, value } } = event;

        this.updateUserObject(name, value);
    }

    updateUserObject = (name, value, callback = null) => {
        let fundCopy = Object.assign({}, this.state.fundMaster);
        fundCopy[name] = value;

        this.setState({
            fundMaster: fundCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }

    handleInputChange = (e, index) => {

        const receivableFund = [...this.state.receivable];

        if (Array.isArray(receivableFund) == true) {
            var recheck_index = receivableFund.findIndex(x => x.f_fund_receivable_category_id === e.target.value);

            if (recheck_index >= 0) {
                toastr.error("Already Selected");
            } else {
                const { name, value } = e.target;
                receivableFund[index][name] = value;
                this.setState({
                    receivable: receivableFund,
                });

            }
        }

        let total_receivable_amount = 0;
        this.state.receivable && this.state.receivable.map(receive =>
            total_receivable_amount = total_receivable_amount + receive.receivable_amount * 1
        )

        this.setState({
            total_receivable_amount: total_receivable_amount,
            total_available_amount: total_receivable_amount - this.state.total_payable_amount
        })

        // console.log('total_receivable_amount', total_receivable_amount)

    }

    handleNoteInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.noteArray];
        list[index][name] = value;
        this.setState({
            noteArray: list,
        });
    }
    handlePayableChange = (e, index) => {
        const payableFund = [...this.state.payable];
        if (Array.isArray(payableFund) == true) {
            var recheck_index = payableFund.findIndex(x => x.f_fund_payable_category_id === e.target.value);
            if (recheck_index >= 0) {
                toastr.error("Already Selected");
            } else {
                const { name, value } = e.target;

                payableFund[index][name] = value;
                this.setState({
                    payable: payableFund,
                });
            }
        }

        let total_payable_amount = 0;
        this.state.payable.map(payable =>
            total_payable_amount = total_payable_amount + payable.payable_amount * 1
        )

        this.setState({
            total_payable_amount: total_payable_amount,
            total_available_amount: this.state.total_receivable_amount - total_payable_amount
        })
        // console.log('total_receivable_amount', total_payable_amount)
    }


    onHandleSubmit = (event) => {
        event.preventDefault();
        const { f_client_group_id, f_client_Info_id } = this.state.fundMaster
        const master = {
            f_client_group_id: f_client_group_id,
            f_client_Info_id: f_client_Info_id,
            total_receivable_amount: this.state.total_receivable_amount,
            total_payable_amount: this.state.total_payable_amount,
            total_available_amount: this.state.total_available_amount,
            receivable: this.state.receivable,
            payable: this.state.payable,
            noteArray: this.state.noteArray,
            transection_date: moment(new Date(this.state.date)).format("yyyy-MM-DD")
        };
        const data = {
            master: master,
        }

        this.props.createFund(data);

        this.setState({
            custom_div2: true
        })
        // console.log('hi',this.state.custom_div2);
        setTimeout(() => {
            this.props.history.push({
                pathname: '/fundList',
            });
        }, 2000)

    };

    render() {
        const { date } = this.state;
        let clientGroup = [];
        let clientInfoList = [];
        let receivableCategoryList = [];
        let payableCategoryList = [];


        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
        }

        if (this.state.hasOwnProperty('clientInfoData')) {
            clientInfoList = this.state.clientInfoData;
        }


        if (this.state.hasOwnProperty('receivableList')) {
            receivableCategoryList = this.state.receivableList;
            console.log('receivableCategoryList', receivableCategoryList)
        }


        if (this.state.hasOwnProperty('payableList')) {
            payableCategoryList = this.state.payableList;
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Fund Report Create</title>
                    </MetaTags>
                    <Fade cascade>
                        <div className="container-fluid">
                            <Breadcrumbs title="Dashboard" breadcrumbItem="Fund Report Create" />
                            {/* <CardTitle className="h4">Fund Report Create</CardTitle> */}

                            {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                            {this.props.success && this.props.success ?
                                (this.state.custom_div2 ?
                                    <SweetAlert
                                        title={this.props.success}
                                        timeout={2000}
                                        showConfirm={false}
                                        showCloseButton={false}
                                        success
                                        onConfirm={() => this.setState({
                                            custom_div2: false
                                        })}
                                    >
                                    </SweetAlert> : null) : null
                            }

                            {/* {this.props.msg && this.props.msg ? (
                            <Alert color="success">
                                Fund Create Successfully!
                            </Alert>
                        ) : null} */}
                            <Form onSubmit={this.onHandleSubmit}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={12}>
                                                <Card className="shadow bg-body rounded">
                                                    <CardBody>
                                                        <Table responsive striped className='table-bordered'>
                                                            <thead className="table-light">
                                                                <tr className="text-center">
                                                                    <th>Client Group<sup className="text-danger">*</sup></th>
                                                                    <th>Client<sup className="text-danger">*</sup></th>
                                                                    <th>Date<sup className="text-danger">*</sup></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr id="addrMain" key="">
                                                                    <td>
                                                                        <select className="form-select" name="f_client_group_id" required onChange={this.handleMasterChange} >
                                                                            <option value="">Select</option>
                                                                            {clientGroup.map((client, index) =>
                                                                                <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-select" name="f_client_Info_id" required onChange={this.handleMasterChange}>
                                                                            <option value="">Select</option>
                                                                            {clientInfoList && clientInfoList.map((clientInfo, index) =>
                                                                                <option value={clientInfo.id} key={index}>{clientInfo.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>

                                                                        <InputGroup
                                                                        >
                                                                            <Flatpickr
                                                                                className="form-control d-block"
                                                                                value={date}
                                                                                options={{ allowInput: true }}
                                                                                onChange={date => {
                                                                                    this.setState({ date });
                                                                                }}
                                                                            />
                                                                        </InputGroup>
                                                                        <Input type="hidden" name="transection_date" value={this.state.date} onChange={this.handleMasterChange} />
                                                                        {/* <Label>Transaction Date</Label>



                                                                <Input type="date" id="transaction_date" name="transaction_date"
                                                                    onChange={this.handleInputChange}
                                                                    placeholder="Enter  date..." required/> */}
                                                                        {/* <FormText className="help-block">Please enter transaction Date</FormText> */}

                                                                        {/* <Input
                                                                type="text"
                                                                name="receivable_amount"
                                                                className="inner form-control"
                                                                placeholder="Enter your amount"
                                                            /> */}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Card className="shadow bg-body rounded">
                                                    <CardBody>
                                                        <div className="inner-repeater mb-4">
                                                            <CardTitle className="text-center bg-primary py-3 text-light">Fund Account Receivable</CardTitle>
                                                            <Table responsive striped className='table-bordered table-hover'>
                                                                <thead className="table-light">
                                                                    <tr className="text-center">
                                                                        <th>Receivable Category<sup className="text-danger">*</sup></th>
                                                                        <th>Amount<sup className="text-danger">*</sup></th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.receivable.map((item1, idx) => (
                                                                        <tr id={"receivable" + idx} key={idx}>
                                                                            <td>
                                                                                <select className="form-select" name="f_fund_receivable_category_id"
                                                                                    value={item1.f_fund_receivable_category_id} required
                                                                                    onChange={e => this.handleInputChange(e, idx)} >
                                                                                    <option value="">Select</option>
                                                                                    {receivableCategoryList.map((items, index) => <option value={items.fund_category_id} key={index}>{items.fund_category_name}</option>)}
                                                                                    {/* {receivableCategoryList.map((receivable, index) =>
                                                                                    <option value={receivable.fund_category_id} key={index}>{receivable.fund_category_name}</option>
                                                                                )} */}
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    type="text"
                                                                                    name="receivable_amount"
                                                                                    className="inner form-control"
                                                                                    placeholder="Enter your amount"
                                                                                    value={item1.receivable_amount}
                                                                                    onChange={e => this.handleInputChange(e, idx)}
                                                                                    required
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Button
                                                                                    onClick={e =>
                                                                                        this.handleRemoveReceivableNested(e, idx)
                                                                                    }
                                                                                    color="danger"
                                                                                    className="btn-block inner"
                                                                                    outline

                                                                                >
                                                                                    <i className="fas fa-window-close"></i>
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                            <Button
                                                                onClick={this.handleAddReceivableNested}
                                                                color="success"
                                                                title="Add Account Receivable"
                                                                outline
                                                                className="rounded"
                                                            >
                                                                <i className="fas fa-plus-circle"></i>
                                                            </Button>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                            <Col md={6}>
                                                <Card className="shadow bg-body rounded">
                                                    <CardBody>
                                                        <div className="inner-repeater mb-4">
                                                            <CardTitle className="text-center bg-primary py-3 text-light">Fund Account Payable</CardTitle>
                                                            <Table responsive striped className='table-bordered table-hover'>
                                                                <thead className="table-light">
                                                                    <tr className="text-center">
                                                                        <th>Payable Category<sup className="text-danger">*</sup></th>
                                                                        <th>Amount<sup className="text-danger">*</sup></th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.payable.map((item2, idx) => (
                                                                        <tr id={"payable" + idx} key={idx}>
                                                                            <td>
                                                                                <select className="form-select" name="f_fund_payable_category_id"
                                                                                    value={item2.f_fund_payable_category_id} required
                                                                                    onChange={e => this.handlePayableChange(e, idx)} >
                                                                                    <option value="">Select</option>
                                                                                    {payableCategoryList && payableCategoryList.map((payable, index) =>
                                                                                        <option value={payable.fund_category_id} key={index}>{payable.fund_category_name}</option>
                                                                                    )}
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    type="text"
                                                                                    name="payable_amount"
                                                                                    className="inner form-control"
                                                                                    placeholder="Enter your amount"
                                                                                    value={item2.payable_amount}
                                                                                    onChange={e => this.handlePayableChange(e, idx)}
                                                                                    required
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Button
                                                                                    onClick={e =>
                                                                                        this.handleRemovePayableNested(e, idx)
                                                                                    }
                                                                                    color="danger"
                                                                                    className="btn-block inner"
                                                                                    outline
                                                                                >
                                                                                    <i className="fas fa-window-close"></i>
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                            <Button
                                                                onClick={this.handleAddPayableNested}
                                                                color="success"
                                                                title="Add Account Payable"
                                                                outline
                                                                className="rounded"
                                                            >
                                                                <i className="fas fa-plus-circle"></i>
                                                            </Button>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <Card>
                                                    <CardBody>
                                                        <Table responsive striped className='table-bordered'>
                                                            <thead className="table-primary">
                                                                <tr className="text-center">
                                                                    <th>Net Receivable Amount</th>
                                                                    <th>Net Payable Amount</th>
                                                                    <th>Available Fund</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr id="addrMain" key="">
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            name="total_receivable_amount"
                                                                            className="inner form-control"
                                                                            placeholder="Net Receivable Amount"
                                                                            value={'$' + this.state.total_receivable_amount}
                                                                            onChange={this.handleMasterChange}
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            name="total_payable_amount"
                                                                            className="inner form-control"
                                                                            placeholder="Net Payable Amount"
                                                                            value={'$' + this.state.total_payable_amount}
                                                                            onChange={this.handleMasterChange}
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            name="total_available_amount"
                                                                            value={'$' + this.state.total_available_amount}
                                                                            className="inner form-control"
                                                                            placeholder="Available Amount"
                                                                            onChange={this.handleMasterChange}
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>

                                                    </CardBody>

                                                </Card>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <Card>
                                                    <CardBody>
                                                        <Table responsive striped className='table-bordered'>
                                                            <thead className="table-light">
                                                                <tr className="text-center">
                                                                    <th>Note Name</th>
                                                                    <th>Note Amount</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.noteArray.map((item3, idx) => (
                                                                    <tr id={"note" + idx} key={idx}>
                                                                        <td>
                                                                            <Input
                                                                                type="text"
                                                                                name="note_name"
                                                                                className="inner form-control"
                                                                                placeholder="Enter Your Note Name..."
                                                                                value={item3.note_name}

                                                                                onChange={e => this.handleNoteInputChange(e, idx)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input
                                                                                type="text"
                                                                                name="note_amount"
                                                                                className="inner form-control"
                                                                                placeholder="Enter Your Amount..."
                                                                                value={item3.note_amount}

                                                                                onChange={e => this.handleNoteInputChange(e, idx)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                onClick={(e) =>
                                                                                    this.handleRemoveNote(e, idx)
                                                                                }
                                                                                color="danger"
                                                                                className="btn-block inner"
                                                                                outline
                                                                            >
                                                                                <i className="fas fa-window-close"></i>
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                        <Button
                                                            onClick={this.handleAddNoteNested}
                                                            color="success"
                                                            title="Add Note"
                                                            outline
                                                            className="rounded"
                                                        >
                                                            <i className="fas fa-plus-circle"></i>
                                                        </Button>
                                                        {/* <div className="mt-3">
                                                    <Label className="fw-bold fs-3 text-info">Note</Label>

                                                </div> */}
                                                    </CardBody>
                                                </Card>
                                            </Col>


                                        </Row>


                                        <CardFooter className="pt-0 bg-white d-flex justify-content-end">
                                            <Button type="submit" size="sm" color="primary"
                                                className="me-2 pull-right"><i
                                                    className="fa fa-dot-circle-o" /> Submit</Button>
                                            <Link to="/#">
                                                <Button type="reset" size="sm" color="danger"
                                                    className="pull-right"><i
                                                        className="fa fa-ban" /> Cancel</Button>
                                            </Link>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                            </Form>
                        </div>
                    </Fade>
                </div>
            </React.Fragment >
        )
    }
}

FundCreate.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    msg: PropTypes.any,
    handleErrorResponse: PropTypes.any,
    createFund: PropTypes.any,
    editFundCategory: PropTypes.any,
    history: PropTypes.any,


};


const mapStatetoProps = state => {
    const { error, success } = state.Fund;
    return { error, success };
}

export default withRouter(connect(mapStatetoProps, { createFund })(FundCreate));

