import React, { Component } from 'react';
import PropTypes from "prop-types";
import { MetaTags } from 'react-meta-tags';
import { Fade } from 'react-reveal';
import { Card, CardBody, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { debtFullOverview } from 'store/Loan/actions';

class FullOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortData: '',
      sortType: 'asc'
    };
  }
  fetchDebtFullOverview = (sortField, sortType) => {
    const option = {
      sort_by_field: sortField,
      sort_type: sortType ? sortType : this.state.sortType,
    }
    debtFullOverview(this.props.match.params.id, option).then((response) => {
      this.setState({
        fullOverview: response.data.loanReport,
      })
    })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
        window.location.href = '/';
      })
  }
  handleSort = (data) => {
    this.setState({
      sortData: data
    })
    if (this.state.sortType === "asc") {
      this.setState({ sortType: "desc" })
    }
    else {
      this.setState({ sortType: "asc" })
    }
    this.fetchDebtFullOverview(data, this.state.sortType === "asc" ? "desc" : "asc")
  }
  componentDidMount() {
    this.fetchDebtFullOverview();
  }
  render() {
    const tableData = this.state.fullOverview;
    let totalCurrentBal = 0;
    let totalInterestRate = 0;
    let totalMonthlyPayement = 0;
    if (tableData !== undefined) {
      for (const value of tableData) {
        totalCurrentBal += value.original_amount;
        totalInterestRate += value.interest_rate_percentage;
        totalMonthlyPayement += value.monthly_payment;
      }
    }
    return (
      <React.Fragment >
        <MetaTags>
          <title>Business Debt Overview | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18"></h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/business-overview">Business Debt Overview</Link></li>
                    <li className="breadcrumb-item active">Full Overview </li>
                  </ol>
                </div>
              </div>
              <div className='d-flex justify-content-between my-3 printBtn'>
                <h4 className="mb-sm-0 font-size-18">{tableData !== undefined && tableData[0]?.client_group?.name}</h4>
                <button onClick={window.print} className='btn btn-success'>Print <i className="fa-solid fa-print"></i></button>
              </div>
              <Card>
                <CardBody>
                  <Table responsive striped className='table-bordered '>
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Loan Number </th>
                        <th scope="col">Current Balance {this.state.sortType === "desc" ? <i onClick={() => this.handleSort("original_amount")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("original_amount")} className="fa-sharp fa-solid fa-caret-down"></i>}</th>
                        <th scope="col">Interest Rate {this.state.sortType === "desc" ? <i onClick={() => this.handleSort("interest_rate_percentage")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("interest_rate_percentage")} className="fa-sharp fa-solid fa-caret-down"></i>}</th>
                        <th scope="col">Maturity Date {this.state.sortType === "desc" ? <i onClick={() => this.handleSort("maturity_date")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("maturity_date")} className="fa-sharp fa-solid fa-caret-down"></i>}</th>
                        <th scope="col">Creditor {this.state.sortType === "desc" ? <i onClick={() => this.handleSort("creditor")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("creditor")} className="fa-sharp fa-solid fa-caret-down"></i>}</th>
                        <th scope="col">Monthly Payment {this.state.sortType === "desc" ? <i onClick={() => this.handleSort("monthly_payment")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("monthly_payment")} className="fa-sharp fa-solid fa-caret-down"></i>}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((data, index) => (
                        <tr key={index} onClick={() => this.props.history.push(`/overview/payments/${data.loan_id}`)}>
                          <td>{index + 1}</td>
                          <td>${data.original_amount.toLocaleString()}</td>
                          <td>{data.interest_rate_percentage}%</td>
                          <td>{data.maturity_date}</td>
                          <td>{data.creditor}</td>
                          <td>${data.monthly_payment.toLocaleString()}</td>
                        </tr>
                      ))}
                      <tr>
                        <td className='fw-bold'>Total</td>
                        <td className='fw-bold'>${totalCurrentBal.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        <td className='fw-bold'></td>
                        <td className='fw-bold'></td>
                        <td className='fw-bold'></td>
                        <td className='fw-bold'>${totalMonthlyPayement.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
                {/* {paginator &&
                      <CardFooter className="bg-white py-0">
                        <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                      </CardFooter>
                    } */}
              </Card>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}
FullOverview.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any
};
export default FullOverview;