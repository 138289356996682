import { CATEGORY_ERROR,CATEGORY_SUCCESS,EDIT_CATEGORY,SHOW_CATEGORY,CREATE_CATEGORY } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createCategory = (category) => {
    return {
        type: CREATE_CATEGORY,
        payload: { category }
    }
}

export const showCategory = (category_id) => {
    return {
        type: SHOW_CATEGORY,
        payload: { category_id }
    }
}

export const editCategory = (category) => {
    return {
        type: EDIT_CATEGORY,
        payload: { category }
    }
}



export const categorySuccess = (msg) => {
    return {
        type: CATEGORY_SUCCESS,
        payload: msg
    }
}
export function clientWiseFilterData(params){
    return getApi().get('user/client/filter/list',{
        params: params
    })
}
export function monthWiseFilterData(params){
    return getApi().get('user/monthWise/filter/list',{
        params: params
    })
}

export function getFilterDashboardData(params) {
    return getApi().get('user/dashboard/filter/data', {
        params: params
    })
}
export function expenseWiseFilterData(params) {
    return getApi().get('user/expense/filter/data', {
        params: params
    })
}
export function categoryShow(category_id){
    return getApi().get('category/'+category_id)
}


export function categoryList(params){
    return getApi().get('category/list',{
        params: params
    })
}
export function clientWiseData(params){
    return getApi().get('user/client/list',{
        params: params
    })
}


export function monthWiseData(params){
    return getApi().get('user/monthWise/list',{
        params: params
    })
}
export function expenseWiseData(params){
    return getApi().get('user/expenseWise/list',{
        params: params
    })
}


export function getCategoryType(params){
    return getApi().get('category/type/list',{
        params: params
    })
}
export function getAccountHeadType(params){
    return getApi().get('category/head/type/list',{
        params: params
    })
}

export function categoryListAll(params){
    return getApi().get('category/list/all',{
        params: params
    })
}


export function subcategoryList(params,subcategory_id){
    return getApi().get('category/sub/list/'+subcategory_id,{
        params: params
    })
}

export function categoryTreeList(params){
    return getApi().get('category/list/tree',{
        params: params
    })
}

export const categoryError = (error) => {
    return {
        type: CATEGORY_ERROR,
        payload: error
    }
}

export function productCategoryImageUpload(params,image_id){
    console.log("image_id",image_id)
    if(image_id){
        return getApi().put('category/icon/edit/'+image_id,params)
    }else{
        return getApi().post('category/icon/upload',params);
    }
}

export const daleteCatagory = (category_id) => {
      return getApi().delete('category/'+category_id,{
         // params: params
      })
  }