import { CREATE_INFO_CLIENT,SHOW_INFO_CLIENT,EDIT_CLIENT_INFO,CLIENT_INFO_SUCCESS,CLIENT_INFO_ERROR } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ClientInfo= (state = initialState, action) => {
    switch (action.type) {
        case EDIT_CLIENT_INFO:
            state = { ...state }
            break;
        case CLIENT_INFO_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case CLIENT_INFO_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ClientInfo;