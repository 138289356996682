import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, CardHeader, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

// toaster
import toastr from "toastr";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
// actions
import { getUserDetails, getCustomerCode, createOrUpdateUser, getRoleList } from '../../../store/auth/profile/actions';
import { createClient } from 'store/clients/actions';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import * as Yup from "yup";
import SweetAlert from 'react-bootstrap-sweetalert';
import Fade from 'react-reveal/Fade';

class CreateGroup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: {
                // roles: [],
                // status: 1
            },
            all_roles: [],
            custom_div2: false
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchUserDetails(this.props.match.params.id);
            this.fetchCustomerCode();
        } else {
            this.fetchCustomerCode();
        }
    }
    fetchCustomerCode = () => {

        getCustomerCode()
            .then((response) => {
                console.log("response.data", response);
                this.updateUserObject('client_group_code', response);
            }, (respose) => {
            })
            .catch((err) => {
                console.log(err);
            })
    }
    handleInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;

        this.updateUserObject(name, value);
    }

    updateUserObject = (name, value, callback = null) => {
        let clientCopy = Object.assign({}, this.state.client);
        clientCopy[name] = value;

        this.setState({
            client: clientCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }

    // onHandleSubmit = (event) => {
    //     event.preventDefault();
    //     const { id, name, phone_number, email, password, password_confirmation, address } = this.state.client;

    //     if (name == "") {
    //         toastr.error("Enter Name...");
    //     }

    //     //    else if(phone_number =="")
    //     //     {
    //     //         toastr.error("Phone Number...");
    //     //     }

    //     // else if(password =="")
    //     // {
    //     //     toastr.error("Enter Password...");
    //     // }

    //     // else if (password !== password_confirmation) {
    //     //     toastr.error("Passwords don't match");
    //     // } 
    //     else {

    //         const data = {
    //             name: name,
    //             // phone_number: phone_number,
    //             // password: password,
    //             // email: email,
    //             // password_confirmation: password_confirmation,
    //             // address: address
    //         };

    //         createClient(data)
    //             .then((response) => {
    //                 if (response.data.status === true) {
    //                     this.props.history.push({
    //                         pathname: '/clientGroup',
    //                         state: { message: response.data.message }
    //                     });
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //                 // this.props.handleErrorResponse(err);

    //             });
    //     }
    // };


    render() {
        const { message } = this.state;
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Client Group Create | Report Manager</title>
                </MetaTags>
                <Fade cascade>
                    <div className="page-content">
                        <Container fluid={true}>
                            {/* Render Breadcrumbs */}
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Client Group Create</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><Link to="/clientGroup">Client Group</Link></li>
                                        <li className="breadcrumb-item active">Client Group Create</li>
                                    </ol>
                                </div>
                            </div>
                            {message == "" || message == undefined || message == null ?
                                "" :
                                // this.state.custom_div1==true &&
                                this.state.custom_div2 ?
                                    <SweetAlert
                                        title={message}
                                        timeout={2000}
                                        showConfirm={false}
                                        showCloseButton={false}
                                        success
                                        onConfirm={() => this.setState({
                                            custom_div2: false,
                                            message: undefined
                                        })}
                                    >
                                        {/* I Will close in 2 Seconds */}
                                    </SweetAlert> : null


                            }
                            <Row>
                                <Col xs="12" sm="12">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            name: this.state.client.name || "",
                                            client_group_code: this.state.client.client_group_code || "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            name: Yup.string().required(
                                                "Please Enter Client Group Name"
                                            ),
                                            client_group_code: Yup.string().required(
                                                "Please Enter Client Group Code"
                                            ),
                                        })}
                                        onSubmit={(values) => {
                                            createClient(values)
                                                .then((response) => {
                                                    if (response.data.status === true) {

                                                        this.setState({
                                                            message: response.data.message,
                                                            custom_div2: true
                                                        })
                                                        setTimeout(() => {
                                                            this.props.history.push({
                                                                pathname: '/clientGroup',
                                                            });
                                                        }, 2000)

                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    window.alert(err.response.data.message);
                                                    window.location.href = '/';
                                                });
                                        }}
                                    >
                                        {({ errors, status, touched }) => (
                                            <Form className="needs-validation">
                                                <Card>
                                                    <CardHeader className="bg-white">
                                                        <strong className='fs-4'>New Client Group</strong>
                                                        <small className='fs-6'> Form</small>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col xs="12" sm="12" md={{ size: 6, offset: 3 }}>


                                                                <FormGroup >
                                                                    <Label htmlFor="name"> Name <sup className="text-danger">*</sup></Label>
                                                                    <Field type="text" id="name" name="name"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.name && touched.name
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                        placeholder="Enter  name..." />
                                                                    <ErrorMessage
                                                                        name="name"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup >
                                                                    <Label htmlFor="client_group_code"> Client Group Code <sup className="text-danger">*</sup></Label>
                                                                    <Field type="text" id="client_group_code" name="client_group_code"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.client_group_code && touched.client_group_code
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                        placeholder="Enter  Customer Code..." />
                                                                    <ErrorMessage
                                                                        name="client_group_code"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </FormGroup>
                                                                <div className="py-3  d-flex justify-content-end">
                                                                    <Button type="submit" size="sm" color="primary"
                                                                        className="me-2 pull-right"><i
                                                                            className="fa fa-dot-circle-o" /> Submit</Button>
                                                                    <Link to="/clientGroup">
                                                                        <Button type="reset" size="sm" color="danger"
                                                                            className="pull-right"><i
                                                                                className="fa fa-ban" /> Cancel</Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>

                                                </Card>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Fade>
            </React.Fragment>

        );
    }
}
CreateGroup.propTypes = {
    handleErrorResponse: PropTypes.any,
    match: PropTypes.any,
    history: PropTypes.any,
};

const mapStateToProps = state => {
    const { msg, error, success } = state.Profile;
    return { msg, error, success };
}




export default withRouter(connect(mapStateToProps, { getUserDetails, createClient, getRoleList })(CreateGroup));

