import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button, Input, FormText, FormGroup, Label, CardHeader, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

// toaster
import toastr from "toastr";
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
// actions
import { getUserDetails, createOrUpdateUser, getRoleList } from '../../../store/auth/profile/actions';
import { createClient } from 'store/clients/actions';
import { createClientInfo, getClientCode } from 'store/clientInfo/actions';
import { getClientList, editClient, getPublicClientList } from '../../../store/clients/actions';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import * as Yup from "yup";
import { values } from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fade from 'react-reveal/Fade';

class ClientInfoCreate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clientInfo: {
                // roles: [],
                // status: 1
            },
            all_roles: [],
            custom_div2: false,
        };
    }
    fetchClientGroupList = () => {
        getPublicClientList().then((response) => {
            this.setState({
                clientData: response.data,
            })
        })
            .catch((err) => {
                console.log(err);
                window.alert(err.response.data.message);
                window.location.href = '/';
            })

    }
    fetchCustomerCode = () => {

        getClientCode()
            .then((response) => {
                console.log("response.data", response);
                this.updateUserObject('client_info_detail_code', response);
            }, (respose) => {
            })
            .catch((err) => {
                console.log(err);
            })
    }
    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchUserDetails(this.props.match.params.id);
            this.fetchCustomerCode();
        }
        this.fetchClientGroupList();
        this.fetchCustomerCode();
    }

    handleInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;

        this.updateUserObject(name, value);
    }

    updateUserObject = (name, value, callback = null) => {
        let clientInfoCopy = Object.assign({}, this.state.clientInfo);
        clientInfoCopy[name] = value;

        this.setState({
            clientInfo: clientInfoCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }

    // onHandleSubmit = (event) => {
    //     event.preventDefault();
    //     const { f_client_group_id, name, phone_number, email, eiin, address } = this.state.clientInfo;

    //     if (name == "") {
    //         toastr.error("Enter Client Name...");
    //     }
    //     else if (f_client_group_id == "") {
    //         toastr.error("Enter Client Group...");
    //     }
    //     else if (eiin == "") {
    //         toastr.error("Enter EIIN...");
    //     }
    //     else {
    //         const data = {
    //             f_client_group_id: f_client_group_id,
    //             eiin: eiin,
    //             name: name,
    //             phone_number: phone_number,
    //             email: email,
    //             address: address
    //         };

    //         createClientInfo(data)
    //             .then((response) => {
    //                 if (response.data.status === true) {
    //                     this.props.history.push({
    //                         pathname: '/clientInfoList',
    //                         state: { message: response.data.message }
    //                     });
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     }

    // };



    render() {
        let clientGroup = [];
        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
        }
        const { message } = this.state

        return (
            <React.Fragment>
                <MetaTags>
                    <title>Client Create | Report Manager</title>
                </MetaTags>
                <Fade cascade>
                    <div className="page-content">
                        <Container fluid={true}>
                            {/* Render Breadcrumbs */}
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Client Info Create</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><Link to="/clientInfoList">Client Info List</Link></li>
                                        <li className="breadcrumb-item active">Client Info Create</li>
                                    </ol>
                                </div>
                            </div>
                            {message == "" || message == undefined || message == null ?
                                "" :
                                // this.state.custom_div1==true &&
                                this.state.custom_div2 ?
                                    <SweetAlert
                                        title={message}
                                        timeout={2000}
                                        showConfirm={false}
                                        showCloseButton={false}
                                        success
                                        onConfirm={() => this.setState({
                                            custom_div2: false,
                                            message: undefined
                                        })}
                                    >

                                    </SweetAlert> : null


                            }
                            <Row>
                                <Col xs="12" sm="12">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            f_client_group_id: this.state.clientInfo.f_client_group_id || "",
                                            eiin: this.state.clientInfo.eiin || "",
                                            client_info_detail_code: this.state.clientInfo.client_info_detail_code || "",
                                            name: this.state.clientInfo.name || "",
                                            phone_number: this.state.clientInfo.phone_number || "",
                                            email: this.state.clientInfo.email || "",
                                            address: this.state.clientInfo.address || "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            name: Yup.string().required(
                                                "Please Enter Client Info Name"
                                            ),
                                            f_client_group_id: Yup.string().required(
                                                "Please Enter a Group"
                                            ),
                                            eiin: Yup.string().required(
                                                "Please Enter EIN"
                                            ),
                                            client_info_detail_code: Yup.string().required(
                                                "Please Enter Code"
                                            ),
                                        })}
                                        onSubmit={(values) => {
                                            createClientInfo(values)
                                                .then((response) => {

                                                    if (response.data.status === true) {
                                                        this.setState({
                                                            message: response.data.message,
                                                            custom_div2: true
                                                        })
                                                        setTimeout(() => {
                                                            this.props.history.push({
                                                                pathname: '/clientInfoList',
                                                            });
                                                        }, 2000)


                                                        // this.props.history.push({
                                                        //     pathname: '/clientInfoList',
                                                        //     state: { message: response.data.message }
                                                        // });
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    window.alert(err.response.data.message);
                                                    window.location.href = '/';
                                                });
                                        }}
                                    >
                                        {({ errors, status, touched, handleChange, }) => (
                                            <Form>
                                                <Card>
                                                    <CardHeader className="bg-white">
                                                        <strong className='fs-4'>New Client Info</strong>
                                                        <small className='fs-6'> Form</small>
                                                    </CardHeader>
                                                    <CardBody>

                                                        <Row>
                                                            <Col xs="12" sm="12" md={6}>

                                                                <FormGroup>
                                                                    <Label htmlFor="name"> Name <sup className="text-danger">*</sup></Label>
                                                                    <Field type="text" id="name" name="name"
                                                                        // onChange={this.handleInputChange}
                                                                        placeholder="Enter  name..."
                                                                        // required
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.name && touched.name
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="name"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <label className="col-form-label">Client Group<sup className="text-danger">*</sup></label>
                                                                    <select name="f_client_group_id" id="f_client_group_id"
                                                                        //  onChange={this.handleInputChange} 

                                                                        value={values.f_client_group_id}
                                                                        onChange={handleChange}
                                                                        className={"form-select"
                                                                            +
                                                                            (errors.f_client_group_id && touched.f_client_group_id
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    >
                                                                        <option >Select</option>
                                                                        {clientGroup.map((client, index) =>
                                                                            <option value={client.cleint_group_id} key={index}>{client.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <ErrorMessage
                                                                        name="f_client_group_id"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup >
                                                                    <Label htmlFor="client_info_detail_code"> CLient Info Code <sup className="text-danger">*</sup></Label>
                                                                    <Field type="text" id="client_info_detail_code" name="client_info_detail_code"
                                                                        // onChange={this.handleInputChange}
                                                                        placeholder="Enter  client info detail code..."
                                                                        // required
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.client_info_detail_code && touched.client_info_detail_code
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="client_info_detail_code"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label htmlFor="email">Email</Label>
                                                                    <Field type="email" id="email" name="email"
                                                                        className="form-control"
                                                                        // onChange={this.handleInputChange}
                                                                        placeholder="Enter email..." />

                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs="12" sm="12" md={6}>
                                                                <FormGroup>
                                                                    <Label htmlFor="eiin">EIN <sup className="text-danger">*</sup></Label>
                                                                    <Field type="text" id="eiin" name="eiin"
                                                                        // onChange={this.handleInputChange}
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.eiin && touched.eiin
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                        placeholder="Enter EIN..." />
                                                                    <ErrorMessage
                                                                        name="eiin"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label htmlFor="mobile">Phone No.</Label>
                                                                    <Field type="text" id="phone_number" name="phone_number"
                                                                        className="form-control"
                                                                        placeholder="Enter Phone No..." />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label htmlFor="Address">Address</Label>
                                                                    <Field type="text" id="address" name="address" as="textarea" rows="6"
                                                                        className="form-control"
                                                                        placeholder="Enter address..."
                                                                    />

                                                                </FormGroup>

                                                            </Col>
                                                            <div className="py-3  d-flex justify-content-end">
                                                                <Button type="submit" size="sm" color="primary"
                                                                    className="me-2 pull-right"><i
                                                                        className="fa fa-dot-circle-o" /> Submit</Button>
                                                                <Link to="/clientInfoList">
                                                                    <Button type="reset" size="sm" color="danger"
                                                                        className="pull-right"><i
                                                                            className="fa fa-ban" /> Cancel</Button>
                                                                </Link>
                                                            </div>
                                                        </Row>
                                                    </CardBody>

                                                </Card>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Fade>
            </React.Fragment>

        );
    }
}
ClientInfoCreate.propTypes = {
    handleErrorResponse: PropTypes.any,
    match: PropTypes.any,
    history: PropTypes.any,
};

const mapStateToProps = state => {
    const { msg, error, success } = state.Profile;
    return { msg, error, success };
}




export default withRouter(connect(mapStateToProps, { getUserDetails, createClient, getRoleList })(ClientInfoCreate));

