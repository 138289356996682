import { DataTabeSearch } from 'pages/Settings/Common/DataTabeSearch';
import React, { Component } from 'react';
import { MetaTags } from 'react-meta-tags';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, Container, FormGroup, InputGroup, Label, Table } from 'reactstrap';
import { debtschedule } from 'store/Loan/actions';
import Flatpickr from "react-flatpickr"
import moment from 'moment';
import { CustomPagination } from 'pages/Settings/Common/CustomPagination';

class BusinessDeptSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: 0,
      endDate: 0,
      page: 1,
      per_page: 10,
      f_group_id: "",
      sortData: '',
      sortType: 'asc'
    };
  }
  getShowData = (sortField, sortType) => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let f_g_id = authUser.data.user[0].f_client_id;
    let is_admine = authUser.data.is_admin
    let group_id = "";
    this.setState({
      f_group_id: f_g_id,
    });
    if (authUser.data.is_admin == 0) {
      group_id = f_g_id;
    } else {
      group_id = this.state.f_group_id;
    }
    let options = {
      page: this.state.page,
      per_page: this.state.per_page,
      search: this.state.search,
      value: this.state.value,
      f_group_id: group_id,
      is_admin: is_admine,
      sort_by_field: sortField,
      sort_type: sortType ? sortType : this.state.sortType,
    };

    debtschedule(this.state.startDate, this.state.endDate, options).then((response) => {
      if (response) {
        this.setState({
          scheduleData: response?.data?.loanReport,
        })
      }
    })
  }
  handleStartDate = (e) => {
    this.setState({
      startDate: moment(new Date(e)).format("YYYY-MM-DD")
    })
    this.getShowData();
  }
  handleEndDate = (e) => {
    this.setState({
      endDate: moment(new Date(e)).format("YYYY-MM-DD")
    })
    this.getShowData();
  }

  handlePageClick = (e, index) => {
    e.preventDefault()
    this.setState({
      page: index
    }, () => {
      this.getShowData();
    });
  }
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.getShowData();
    });
  }

  handleSort = (data) => {
    this.setState({
      sortData: data
    })
    if (this.state.sortType === "asc") {
      this.setState({ sortType: "desc" })
    }
    else {
      this.setState({ sortType: "asc" })
    }
    this.getShowData(data, this.state.sortType === "asc" ? "desc" : "asc")
  }

  componentDidMount() {
    this.getShowData();
  }
  render() {
    const tableData = this.state.scheduleData?.data;
    let paginator;
    paginator = {
      'currentPage': this.state.scheduleData?.current_page,
      'pagesCount': this.state.scheduleData?.last_page,
      'total': this.state.loanData?.total,
      'to': this.state.scheduleData?.to,
      'from': this.state.scheduleData?.from,
    };
    let totalOrginalAmount = 0;
    let totalPresentBal = 0;
    let totalInterestRate = 0;
    let totalMonthlyPayement = 0;
    if (tableData !== undefined) {
      for (const value of tableData) {
        totalOrginalAmount += value.original_amount;
        totalPresentBal += value.present_balance;
        totalInterestRate += value.interest_rate_amount;
        totalMonthlyPayement += value.monthly_payment;
      }
    }
    return (
      <React.Fragment >
        <MetaTags>
          <title>Business Debt Schedule | Report Manager</title>
        </MetaTags>
        <Fade cascade>
          <div className="page-content">
            <Container fluid={true}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Business Debt Schedule</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/loanList">Loan List</Link></li>
                    <li className="breadcrumb-item active">Business Debt Schedule </li>
                  </ol>
                </div>
              </div>
              <Card >
                <CardBody>
                  <DataTabeSearch handleInputChange={this.handleInputChange} />
                  <div className='d-flex'>
                    <FormGroup>
                      <InputGroup>
                        <Flatpickr
                          autoComplete='off'
                          className="form-control me-3"
                          name='orginal_loan_date'
                          options={{ allowInput: true }}
                          placeholder="Start Date"
                          onChange={this.handleStartDate}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup>
                        <Flatpickr
                          autoComplete='off'
                          className="form-control"
                          name='maturity_date'
                          options={{ allowInput: true }}
                          placeholder="End Date"
                          onChange={this.handleEndDate}
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <Table responsive striped className='table-bordered '>
                    <thead className="table-light">
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Company Name <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("company_name")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("company_name")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        {/* <th scope="col">Loan Id</th> */}
                        <th scope="col">Creditor <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("creditor")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("creditor")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Loan Origination Date <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("original_date")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("original_date")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Original Amount <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("original_amount")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("original_amount")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Maturity Date <span>{this.state.sortType === "desc " ? <i onClick={() => this.handleSort("maturity_date")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("maturity_date")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Present Balance <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("present_balance")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("present_balance")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Interest/Fixed Rate <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("interest_rate")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("interest_rate")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                        <th scope="col">Monthly Payment <span>{this.state.sortType === "desc" ? <i onClick={() => this.handleSort("monthly_payment")} className="fa-solid fa-caret-up"></i> : <i onClick={() => this.handleSort("monthly_payment")} className="fa-sharp fa-solid fa-caret-down"></i>}</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.client_group.name}</td>
                          {/* <td>{data.loan_id}</td> */}
                          <td>{data.creditor}</td>
                          <td>{data.original_loan_date}</td>
                          <td>${data.original_amount.toLocaleString()}</td>
                          <td>{data.maturity_date}</td>
                          <td>${data.present_balance === null ? 0 : data.present_balance.toLocaleString()}</td>
                          <td>${data.interest_rate_amount.toLocaleString()}</td>
                          <td>${data.monthly_payment.toLocaleString()}</td>
                        </tr>
                      ))}
                      <tr>
                        <td className='fw-bold'>Total</td>
                        <td className='fw-bold'></td>
                        <td className='fw-bold'></td>
                        <td className='fw-bold'></td>
                        <td className='fw-bold'>${totalOrginalAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        <td className='fw-bold'></td>
                        <td className='fw-bold'>${totalPresentBal.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        {/* <td className='fw-bold'>${totalInterestRate.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td> */}
                        <td className='fw-bold'></td>
                        <td className='fw-bold'>${totalMonthlyPayement.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                      </tr>
                    </tbody>

                  </Table>
                </CardBody>
                {paginator &&
                  <CardFooter className="bg-white py-0">
                    <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                  </CardFooter>
                }
              </Card>
            </Container>
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}

export default BusinessDeptSchedule;